export const OrdersStoreActionConstants = {
    REQUEST_ORDERS: 'REQUEST_ORDERS',
    RECEIVE_ORDERS: 'RECEIVE_ORDERS',
    CANCEL_REQUEST_ORDERS: 'CANCEL_REQUEST_ORDERS',
    REQUEST_SERIAL_NUMBERS: 'REQUEST_SERIAL_NUMBERS',
    RECEIVE_SERIAL_NUMBERS: 'RECEIVE_SERIAL_NUMBERS',
    SET_OPEN_ITEMS_INITIAL_COLUMN_STATE: 'SET_OPEN_ITEMS_INITIAL_COLUMN_STATE',
    SET_OPEN_KITS_INITIAL_COLUMN_STATE: 'SET_OPEN_KITS_INITIAL_COLUMN_STATE',
    SET_MY_BATCHES_INITIAL_COLUMN_STATE: 'SET_MY_BATCHES_INITIAL_COLUMN_STATE',
    SET_MY_KITS_INITIAL_COLUMN_STATE: 'SET_MY_KITS_INITIAL_COLUMN_STATE',
    TOGGLE_COLUMN_VISIBILITY: 'TOGGLE_COLUMN_VISIBILITY',
    DRAG_COLUMN_ITEMS: "DRAG_COLUMN_ITEMS",
    RECEIVE_OPEN_ITEMS_TABLE_STATE: 'RECEIVE_OPEN_ITEMS_TABLE_STATE',
    REQUEST_OPTION_PART_NUMBER: 'REQUEST_OPTION_PART_NUMBER',
    RECEIVE_OPTION_PART_NUMBER: 'RECEIVE_OPTION_PART_NUMBER',
    RESET_STATE: 'RESET_STATE'
};

export const requestOrders = batchType => ({
    type: OrdersStoreActionConstants.REQUEST_ORDERS,
    payload: batchType
});

export const receiveOrders = batchType => ({
    type: OrdersStoreActionConstants.RECEIVE_ORDERS,
    payload: batchType
});

export const cancelRequestOrders = () => ({
    type: OrdersStoreActionConstants.CANCEL_REQUEST_ORDERS
});

export const requestSerialNumbers = queryParams => ({
    type: OrdersStoreActionConstants.REQUEST_SERIAL_NUMBERS,
    payload: queryParams
});

export const receiveSerialNumbers = serialNumbers => ({
    type: OrdersStoreActionConstants.RECEIVE_SERIAL_NUMBERS,
    payload: serialNumbers
});

export const openItemsInitialColumn = columnItems => ({
    type: OrdersStoreActionConstants.SET_OPEN_ITEMS_INITIAL_COLUMN_STATE,
    payload: columnItems
});

export const openKitsInitialColumn = columnItems => ({
    type: OrdersStoreActionConstants.SET_OPEN_KITS_INITIAL_COLUMN_STATE,
    payload: columnItems
});

export const myBatchesInitialColumn = columnItems => ({
    type: OrdersStoreActionConstants.SET_MY_BATCHES_INITIAL_COLUMN_STATE,
    payload: columnItems
});
export const myKitsInitialColumn = columnItems => ({
    type: OrdersStoreActionConstants.SET_MY_KITS_INITIAL_COLUMN_STATE,
    payload: columnItems
});

export const receiveOpenItemsTableRowGroupState = tableState => ({
    type: OrdersStoreActionConstants.RECEIVE_OPEN_ITEMS_TABLE_STATE,
    payload: tableState
});

export const requestOptionPartNumber = (companyID) => ({
    type: OrdersStoreActionConstants.REQUEST_OPTION_PART_NUMBER,
    payload: companyID
});

export const receiveOptionPartNumber = partNumber => ({
    type: OrdersStoreActionConstants.RECEIVE_OPTION_PART_NUMBER,
    payload: partNumber
});

export const resetOrdersState = () => ({
    type: OrdersStoreActionConstants.RESET_STATE,
});

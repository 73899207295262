export const QaFailureStoreActionConstants = {
    REQUEST_QA_FAILURES: 'REQUEST_QA_FAILURES',
    RECEIVE_QA_FAILURES: 'RECEIVE_QA_FAILURES',
    REQUEST_CREATE_QA_FAILURES: 'REQUEST_CREATE_QA_FAILURES',
    RECEIVE_CREATED_QA_FAILURES: 'RECEIVE_CREATED_QA_FAILURES',
    REQUEST_DELETE_QA_FAILURE: 'REQUEST_DELETE_QA_FAILURE',
    RECEIVE_DELETED_QA_FAILURE_ID: 'RECEIVE_DELETED_QA_FAILURE_ID'
};

export const requestQaFailures = queryParams => ({
    type: QaFailureStoreActionConstants.REQUEST_QA_FAILURES,
    payload: queryParams
});

export const receiveQaFailures = qaFailures => ({
    type: QaFailureStoreActionConstants.RECEIVE_QA_FAILURES,
    payload: qaFailures
});

export const requestCreateQaFailures = requestBody => ({
    type: QaFailureStoreActionConstants.REQUEST_CREATE_QA_FAILURES,
    payload: requestBody
});

export const receiveCreatedQaFailures = (createdQaFailures) => ({
    type: QaFailureStoreActionConstants.RECEIVE_CREATED_QA_FAILURES,
    payload: createdQaFailures
});

export const requestDeleteQaFailure = (id, batchID, kitItemID) => ({
    type: QaFailureStoreActionConstants.REQUEST_DELETE_QA_FAILURE,
    payload: id,
    batchID,
    kitItemID
});

export const receiveDeletedQaFailureId = deletedId => ({
    type: QaFailureStoreActionConstants.RECEIVE_DELETED_QA_FAILURE_ID,
    payload: deletedId
});

import { TechLayout } from "@/Layouts";
import { ProjectsPage } from "@/Pages/Private/Admin/Projects";
import { InternalNotesPage } from "@/Pages/Private/Tech/InternalNotes";

const RoutesConfig = [
    {
        path: '/',
        redirect: true,
        redirectTo : '/open-items',
        exact : true
    },
    { 
        path : '/open-items',
        component : TechLayout
    },
    { 
        path : '/open-kits',
        component : TechLayout
    },
    { 
        path : '/my-batches',
        component : TechLayout
    },
    { 
        path : '/my-kits',
        component : TechLayout
    },
    { 
        path : '/kit-details',
        component : TechLayout
    },
    {
        path : '/batch-details',
        component : TechLayout
    },
    {
        path : '/internal-notes',
        component : InternalNotesPage
    },
    {
        path : '/projects',
        component : ProjectsPage
    }
];

export default RoutesConfig;

import React from 'react'
import { createContext } from 'react';

export const PanelListContext = createContext()

const PanelList = ({ className, children, id }) => {
     return (
          <div className={`${className}`}>
                <PanelListContext.Provider value={{id}}>
                    {children}
               </PanelListContext.Provider>
          </div>
     )
}

export default PanelList

const SortListIcon = ({ className }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className}`}>
            <path d="M12.4096 20.4148C12.2106 20.6992 11.7894 20.6992 11.5904 20.4148L7.30071 14.2867C7.06874 13.9553 7.30582 13.5 7.71033 13.5H16.2897C16.6942 13.5 16.9313 13.9553 16.6993 14.2867L12.4096 20.4148Z" fill="currentColor" />
            <path d="M12.4096 3.58517C12.2106 3.30081 11.7894 3.30081 11.5904 3.58517L7.30071 9.71327C7.06874 10.0447 7.30582 10.5 7.71033 10.5H16.2897C16.6942 10.5 16.9313 10.0447 16.6993 9.71327L12.4096 3.58517Z" fill="currentColor" />
        </svg>
    );
};

export default SortListIcon;

const ReportIcon = ({ className }) => {
     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className || ''}`} viewBox="0 0 24 25" fill="none">
               <mask id="mask0_692_28349" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                    <rect y="0.5" width="24" height="24" fill="currentColor" />
               </mask>
               <g mask="url(#mask0_692_28349)">
                    <path d="M12 15.2C12.255 15.2 12.4688 15.1138 12.6413 14.9412C12.8138 14.7688 12.9 14.555 12.9 14.3C12.9 14.045 12.8138 13.8313 12.6413 13.6588C12.4688 13.4862 12.255 13.4 12 13.4C11.745 13.4 11.5312 13.4862 11.3588 13.6588C11.1862 13.8313 11.1 14.045 11.1 14.3C11.1 14.555 11.1862 14.7688 11.3588 14.9412C11.5312 15.1138 11.745 15.2 12 15.2ZM11.1 11.6H12.9V6.2H11.1V11.6ZM3 21.5V5.3C3 4.805 3.17625 4.38125 3.52875 4.02875C3.88125 3.67625 4.305 3.5 4.8 3.5H19.2C19.695 3.5 20.1187 3.67625 20.4713 4.02875C20.8237 4.38125 21 4.805 21 5.3V16.1C21 16.595 20.8237 17.0188 20.4713 17.3713C20.1187 17.7238 19.695 17.9 19.2 17.9H6.6L3 21.5ZM5.835 16.1H19.2V5.3H4.8V17.1125L5.835 16.1Z" />
               </g>
          </svg>
     );
};

export default ReportIcon;

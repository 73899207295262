import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    useHover,
    useInteractions,
    FloatingPortal
} from '@floating-ui/react';

const Tooltip = ({
    trigger,
    direction,
    content,
    className,
    offset: offsetProps
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [middleware, setMiddleware] = useState([
        flip(),
        shift()
    ]);

    const { refs: { setReference, setFloating }, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware,
        whileElementsMounted: autoUpdate,
        placement: direction
    });

    const hover = useHover(context, { move: false });

    // Merge all the interactions into prop getters
    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover
    ]);

    useEffect(() => {
        if (isNaN(offsetProps)) {
            setMiddleware(prevMiddleware => (
                [
                    ...prevMiddleware,
                    offset(10)
                ]
            ));
            return;
        }

        setMiddleware(prevMiddleware => (
            [
                ...prevMiddleware,
                offset(offsetProps)
            ]
        ))
    }, [offsetProps]);

    return (
        <>
            {trigger({ ref: setReference, ...getReferenceProps() })}
            {isOpen && content && (
                <FloatingPortal>
                    <div
                        ref={setFloating}
                        style={{ ...floatingStyles }}
                        {...getFloatingProps()}
                        className={`tooltip ${className || ''}`}
                    >
                        {content}
                    </div>
                </FloatingPortal>
            )}
        </>
    );
};

Tooltip.defaultProps = {
    direction: 'bottom'
};

Tooltip.propTypes = {
    trigger: PropTypes.func,
    direction: PropTypes.oneOf([
        'top',
        'right',
        'bottom',
        'left',
        'top-start',
        'bottom-start',
        'top-end',
        'bottom-end'
    ]),
    className: PropTypes.string
};

export default Tooltip;

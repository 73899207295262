import { useCallback, useEffect, useMemo, useState } from "react";

import { CloseIcon } from "@/Icons";
import { DataTable } from "@/Components/DataDisplay";

import AddQualityCheckReasonTableNotes from "./AddQualityCheckReasonTableNotes";

import { UnitOfTime, getIntlDateTime } from "@/Helpers/timezones";
import { BatchType } from "@/Helpers/constants";

import { useDispatch, useSelector } from "@/Store/StoreContextProvider";
import { requestDeleteQaFailure, requestQaFailures } from "@/Store/Actions";

const AddQualityCheckReasonTable = ({
    onOpenRemoveDialog,
    onRemoveSuccess,
    onRemoving,
    onOpenTableDialog,
    qaFailureIdToRemove,
    isQaFailureToRemoveSure
}) => {
    const batchDetailsToDisplay = useSelector(({ batch }) => batch?.batchDetailsToDisplay);
    const activeDetailsId = useSelector(({ batch }) => batch?.activeDetailsId);
    const activeKitDetailsItemId = useSelector(({ kit }) => kit?.activeKitDetailsItemId);
    const qaFailures = useSelector(({ qaFailure }) => qaFailure?.qaFailures);

    const qaFailureDispatch = useDispatch(dispatch => dispatch?.qaFailure);

    const activeDetails = useMemo(() => {
        return batchDetailsToDisplay?.find(batch => batch?.batchID?.toString() === activeDetailsId?.toString());
    }, [batchDetailsToDisplay, activeDetailsId]);

    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [hoveredRow, setHoveredRow] = useState(null);

    const handleRemove = useCallback(async (id) => {
        if (id) {
            try {
                setIsLoading(true);
                if (typeof onRemoving === 'function') {
                    onRemoving(true);
                }
    
                await qaFailureDispatch(requestDeleteQaFailure(
                    id,
                    // Added for state management only
                    activeDetailsId,
                    activeDetails?.batchType === BatchType.KIT ? activeKitDetailsItemId : 0
                ));

                if (typeof onRemoveSuccess === 'function') {
                    onRemoveSuccess();
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
                if (typeof onRemoving === 'function') {
                    onRemoving(false);
                }
            }
        }
    }, [
        qaFailureDispatch,
        activeDetailsId,
        activeDetails?.batchType,
        activeKitDetailsItemId,
        onRemoveSuccess,
        onRemoving
    ]);

    const handleRemoveClick = rowData => {
        if (typeof onOpenRemoveDialog === 'function' && rowData) {
            onOpenRemoveDialog(rowData);
        }
    };

    const handleRowHover = ({ row }) => {
        if (row) {
            setHoveredRow(row);
            return;
        }
        setHoveredRow(null);
    };

    useEffect(() => {
        if (activeDetails?.batchType === BatchType.KIT && !activeKitDetailsItemId) {
            return;
        }

        const batchQaFailure = qaFailures?.find(qaFailure => {
            const isBatchIdSame = qaFailure?.batchID?.toString() === activeDetailsId?.toString();

            if (activeDetails?.batchType === BatchType.BATCH) {
                return isBatchIdSame;
            }

            return isBatchIdSame && activeKitDetailsItemId?.toString() === qaFailure?.kitItemID?.toString();
        });

        if (Array.isArray(qaFailures) && batchQaFailure) {
            setTableData(batchQaFailure?.data);
            return;
        }

        (async () => {
            try {
                setIsLoading(true);

                await qaFailureDispatch(requestQaFailures({
                    batchID: activeDetailsId,
                    kitItemID: activeDetails?.batchType === BatchType.KIT ? activeKitDetailsItemId : 0
                }));
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [
        qaFailures,
        activeDetailsId,
        qaFailureDispatch,
        activeDetails?.batchType,
        activeKitDetailsItemId
    ]);

    useEffect(() => {
        if (isQaFailureToRemoveSure && qaFailureIdToRemove && isLoading === false) {
            (async () => {
                await handleRemove(qaFailureIdToRemove);
            })();
        }
    }, [isQaFailureToRemoveSure, qaFailureIdToRemove, handleRemove, isLoading]);

    return (
        <div className={`mt-6 w-full ${activeDetails?.batchType === BatchType.BATCH ? 'mb-6' : 'mb-[119px]'}`}>
            <DataTable
                className="quality-reason-check-table"
                isLoading={isLoading}
                rows={tableData}
                rowRenderer={rowData => {
                    return [
                        <div className="flex items-center justify-center w-full">
                            <CloseIcon
                                className="!size-[18px] cursor-pointer"
                                onClick={() => handleRemoveClick(rowData)}
                            />
                        </div>,
                        <p className="px-3 py-4 w-full text-on-surface">
                            {rowData?.serial}
                        </p>,
                        <p className="px-3 py-4 w-full text-on-surface">
                            {rowData.reasonCode}
                        </p>,
                        <p className="px-3 py-4 w-full text-on-surface">
                            {getIntlDateTime(rowData?.createDate).format(UnitOfTime.MMDDYYYY)}
                        </p>,
                        <AddQualityCheckReasonTableNotes 
                            additionalNotes={rowData?.additionalNotes}
                            rowData={rowData}
                            hoveredRow={hoveredRow}
                            onOpenTableDialog={onOpenTableDialog}
                        />
                    ];
                }}
                columns={[
                    {
                        field: '',
                        label: '',
                        width: 32,
                        style: { minWidth: 'unset' },
                        sortable: false
                    },
                    {
                        field: '',
                        label: <p className="flex items-center font-inter text-on-surface text-xs font-semibold gap-[10px]">Serial Number</p>,
                        width: 300,
                        style: { minWidth: 'unset' },
                        sortable: false
                    },
                    {
                        field: '',
                        label: <p className="flex items-center font-inter text-on-surface text-xs font-semibold gap-[10px]">QC Reason Code</p>,
                        fluid: true,
                        style: { minWidth: 'unset' },
                        sortable: false
                    },
                    {
                        field: '',
                        label: <p className="flex items-center font-inter text-on-surface text-xs font-semibold gap-[10px]">Date</p>,
                        width: 198,
                        style: { minWidth: 'unset' },
                        sortable: false
                    },
                    {
                        field: '',
                        label: <p className="flex items-center font-inter text-on-surface text-xs font-semibold gap-[10px]">Additional Notes</p>,
                        fluid: true,
                        style: { minWidth: 'unset' },
                        sortable: false
                    }
                ]}
                onRowHover={handleRowHover}
            />
        </div>
    );
};

export default AddQualityCheckReasonTable;

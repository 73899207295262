import { useCallback, useEffect, useImperativeHandle, useState } from "react";
import { CloseIcon } from "@/Icons";
import SingleSortAscendingIcon from "@/Icons/SingleSortAscendingIcon";
import SingleSortDescendingIcon from "@/Icons/SingleSortDescendingIcon";

const unFilteredColumns = ['createDate'];

const nonSelectionFilter = {
    total: 'total',
    createDate: 'createDate'
}

const CustomFilterSection = ({ api, sortModelResetRef, currentTab, gridApiRef }) => {

    const [filterSortModel, setFilterSortModel] = useState([]);
    const [filterModel, setFilterModel] = useState([]);
    const [selectedFilterChips, setSelectedFilterChips] = useState([]);

    const deleteSortModel = (colId) => {
        setFilterSortModel((prevFilterSortModel) => {
            return prevFilterSortModel?.filter(item => {
                return item.colId !== colId;
            });
        });
    };

    const deleteFilterItem = useCallback((itemToDelete) => {
        const colId = itemToDelete.colId;
        const columnName = itemToDelete.colId === nonSelectionFilter.total ? nonSelectionFilter.total : itemToDelete.name;

        if (selectedFilterChips.length === 1) {
            localStorage.removeItem(`filter-${currentTab}`);
        }

        if (api && colId && columnName) {
            if (!itemToDelete.filter) {
                deleteSortModel(colId);
                const sortModel = api.getColumnState().map((item) => {
                    if (item.colId === colId) {
                        return { ...item, sort: null };
                    }
                    return item;
                });

                api.applyColumnState({
                    state: sortModel,
                    applyOrder: true
                });
            } else {
                api.getFilterInstance(colId, (filterInstance) => {
                    if (filterInstance) {
                        if (columnName.toLowerCase() === nonSelectionFilter.total.toLowerCase()) {
                            filterInstance.sourceParams.gridApiRef.current?.resetSearchFilter();
                        } else {
                            filterInstance.sourceParams.gridApiRef.current?.onSelectedOptionChanged(columnName);
                        }
                    }
                });
                setFilterModel((prevFilterModel) => {
                    return prevFilterModel?.filter(item => {
                        return item.name !== itemToDelete.name;
                    });
                });
            };
        };
    }, [api, currentTab, selectedFilterChips.length]);

    useEffect(() => {
        setSelectedFilterChips(filterSortModel?.concat(filterModel));
    }, [filterSortModel, filterModel]);

    useEffect(() => {
        if (api) {
            const handleFilterChipsUpdate = () => {
                api.eventService.addEventListener('filterChanged', ({ columns, api }) => {
                    if (columns && columns.length > 0) {
                        const colId = columns[0]?.colId;
                        const applyFilter = !unFilteredColumns.includes(colId.toLowerCase());
                        if (applyFilter) {
                            const getFilterModel = api.getFilterModel();
                            const flattenedFilterModel = [];
                            Object.keys(getFilterModel)?.forEach(key => {
                                if (key.toLowerCase() === nonSelectionFilter.total.toLowerCase()) {
                                    const model = {
                                        name: getFilterModel[key],
                                        colId: key,
                                        filter: true
                                    }
                                    flattenedFilterModel.push(model);
                                } else {
                                    getFilterModel[key].forEach(value => {
                                        flattenedFilterModel.push({
                                            name: value,
                                            colId: key,
                                            filter: true
                                        });
                                    });
                                }
                            });
                            setFilterModel(flattenedFilterModel);
                        };
                    }
                });

                api.eventService.addEventListener('sortChanged', ({ columns, api }) => {
                    if (columns && columns.length > 0) {
                        let columnDirection = columns[0].getSort();
                        let colId = columns[0].colId;
                        let columnHeaderName = columns[0].colDef.headerName;

                        const columnSortState = api.getColumnState().map((col) => {
                            if (col.colId.toLowerCase() === colId.toLowerCase()) {
                                col.sortIndex = 0;
                            }
                            return col;
                        });

                        api.applyColumnState({
                            state: columnSortState,
                            applyOrder: true
                        });

                        if ((columnDirection === 'asc' || columnDirection === 'desc') && (colId && columnHeaderName)) {
                            const itemToAdd = { name: columnHeaderName, direction: columnDirection, colId: colId };
                            setFilterSortModel(prevValue => {
                                const exists = prevValue.some(item => item.name === columnHeaderName);
                                let prevState;
                                if (itemToAdd.direction === null) {
                                    prevState = prevValue.filter(item => item.name !== columnHeaderName);
                                }
                                if (exists) {
                                    prevState = prevValue.map(item =>
                                        item.name === columnHeaderName ? itemToAdd : item
                                    );
                                } else {
                                    prevState = [...prevValue, itemToAdd];
                                }
                                return prevState;
                            });
                        } else {
                            deleteFilterItem({ name: columnHeaderName, colId: colId });
                        };
                    };
                })
            };
            handleFilterChipsUpdate();
            api.eventService.addEventListener('sortChanged', handleFilterChipsUpdate);
            api.eventService.addEventListener('filterChanged', handleFilterChipsUpdate);

            return () => {
                api.eventService?.removeEventListener('filterChanged', handleFilterChipsUpdate);
                api.eventService?.removeEventListener('sortChanged', handleFilterChipsUpdate);
            };
        }
    }, [api, deleteFilterItem]);

    const resetFilterSortModel = () => {
        setFilterSortModel([]);
        setSelectedFilterChips([]);
        setFilterModel([]);
        localStorage.removeItem(`filter-${currentTab}`);
    };

    useImperativeHandle(sortModelResetRef, () => ({
        resetFilterSortModel
    }));

    const isSortChip = (item) => {
        return ['asc', 'desc', null].includes(item?.direction);
    };

    useEffect(() => {
        if (selectedFilterChips.length > 0) {
            try {
                const filterState = JSON.stringify(selectedFilterChips);
                localStorage.setItem(`filter-${currentTab}`, filterState);
            } catch (error) {
                console.log(error);
            };
        }
    }, [selectedFilterChips, currentTab]);

    const onFirstDataRendered = useCallback(() => {
        if (api) {
            return new Promise((resolve) => {
                const handleFirstDataRendered = () => {
                    resolve();
                    api?.removeEventListener('firstDataRendered', handleFirstDataRendered);
                };
                api.addEventListener('firstDataRendered', handleFirstDataRendered);
            });
        } else {
            return Promise.reject(new Error('grid API is not available.'));
        }
    }, [api]);

    useEffect(() => {
        try {
            const savedFilterState = JSON.parse(localStorage.getItem(`filter-${currentTab}`)) || [];

            if (savedFilterState.length > 0 && api && gridApiRef.current) {

                setFilterSortModel(savedFilterState.filter(item => item.direction));

                savedFilterState.forEach(async item => {
                    if (item.direction) {
                        const sortModel = api.getColumnState().map((col) => {
                            if (col.colId === item.colId) {
                                return { ...col, sort: item.direction };
                            }
                            return col;
                        });
                        api.applyColumnState({ state: sortModel, applyOrder: true });
                    } else {
                        const filterInstance = await api.getColumnFilterInstance(item.colId);
                        await onFirstDataRendered();
                        if (filterInstance) {
                            if (item.colId.toLowerCase() === nonSelectionFilter.total.toLowerCase()) {
                                filterInstance.sourceParams.gridApiRef.current?.setInputModel(item.name);
                            } else {
                                filterInstance.sourceParams.gridApiRef.current?.onSelectedOptionChanged(item.name);
                            }
                        }
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    }, [api, currentTab, gridApiRef, onFirstDataRendered]);

    const showFilterSection = Array.isArray(selectedFilterChips) && selectedFilterChips.length === 0;

    return (
        <div className={`max-w-[1320px] h-fit pb-4 ${showFilterSection ? "hidden" : "block"}`}>
            <div className="w-full h-full flex flex-wrap gap-2">
                {Array.isArray(selectedFilterChips) && selectedFilterChips.map((item, index) => {
                    return (
                        <div
                            className={`
                                flex h-8 rounded-[5px] space-x-1 items-center px-2
                                ${isSortChip(item) ? " bg-secondary-95" : "bg-primary-fixed-dim"}
                            `}
                            key={index}
                        >
                            <p className="font-roboto text-xs tracking-[0.5px] text-on-primary-container">
                                {item.name}
                            </p>
                            {item.direction === 'asc' &&
                                <div className="size-6">
                                    <SingleSortAscendingIcon />
                                </div>
                            }
                            {item.direction === 'desc' &&
                                <div className="size-6">
                                    <SingleSortDescendingIcon />
                                </div>
                            }
                            <CloseIcon className="cursor-pointer !size-4" onClick={() => deleteFilterItem(item)} />
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default CustomFilterSection;

const SdCardIcon = ({ className }) => {
  return (
    <svg className={`svg-regular ${className || ''}`} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <mask id="mask0_225_4433" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <rect width="18" height="18" fill="#171C20" />
      </mask>
      <g mask="url(#mask0_225_4433)">
        <path d="M6.96634 8.16343H8.09132V5.37981H6.96634V8.16343ZM9.14423 8.16343H10.2692V5.37981H9.14423V8.16343ZM11.3221 8.16343H12.4471V5.37981H11.3221V8.16343ZM4.73077 16.125C4.35193 16.125 4.03125 15.9937 3.76875 15.7312C3.50625 15.4687 3.375 15.148 3.375 14.7692V6.15864L7.65864 1.875H13.2692C13.648 1.875 13.9687 2.00625 14.2312 2.26875C14.4937 2.53125 14.625 2.85193 14.625 3.23078V14.7692C14.625 15.148 14.4937 15.4687 14.2312 15.7312C13.9687 15.9937 13.648 16.125 13.2692 16.125H4.73077ZM4.73077 15H13.2692C13.3269 15 13.3798 14.9759 13.4279 14.9279C13.4759 14.8798 13.5 14.8269 13.5 14.7692V3.23078C13.5 3.17308 13.4759 3.12018 13.4279 3.07209C13.3798 3.02402 13.3269 2.99998 13.2692 2.99998H8.13748L4.49998 6.63748V14.7692C4.49998 14.8269 4.52402 14.8798 4.57209 14.9279C4.62018 14.9759 4.67308 15 4.73077 15ZM4.73077 15H4.49998H13.5H4.73077Z" fill="#2F4455" />
      </g>
    </svg>
  );
};

export default SdCardIcon;

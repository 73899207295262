export const UserStoreActionConstants = {
    REQUEST_LOGIN: 'REQUEST_LOGIN',
    RECEIVE_USER_INFO: 'RECEIVE_USER_INFO',
    CLEAR_LOGGED_USER: 'CLEAR_LOGGED_USER',
    IS_USER_AUTHENTICATED: "IS_USER_AUTHENTICATED",
    AUTHENTICATE_USER: "AUTHENTICATE_USER",
    RECEIVE_IS_USER_AUTHENTICATED: "RECEIVE_IS_USER_AUTHENTICATED",
    TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
    RECEIVE_PROBLEM_TEMPLATE: 'RECEIVE_PROBLEM_TEMPLATE',
    REQUEST_API_HEALTH_CHECK: 'REQUEST_API_HEALTH_CHECK',
    RECEIVE_API_HEALTH_CHECK_RESPONSE: 'RECEIVE_API_HEALTH_CHECK_RESPONSE',
    RESET_STATE: 'RESET_STATE'
};

export const requestLogin = user => ({
    type: UserStoreActionConstants.REQUEST_LOGIN,
    payload: user
});

export const receiveUserInfo = user => ({
    type: UserStoreActionConstants.RECEIVE_USER_INFO,
    payload: user
});

export const clearLoggedUser = () => ({
    type: UserStoreActionConstants.CLEAR_LOGGED_USER
});

export const isUserAuthenticated = username => ({
    type: UserStoreActionConstants.IS_USER_AUTHENTICATED,
    payload: username
});

export const receiveIsUserAuthenticated = isUserAuthenticated => ({
    type: UserStoreActionConstants.RECEIVE_IS_USER_AUTHENTICATED,
    payload: isUserAuthenticated
});

export const authenticateUser = username => ({
    type: UserStoreActionConstants.AUTHENTICATE_USER,
    payload: username
});

export const toggleSidebar = isOpen => ({
    type: UserStoreActionConstants.TOGGLE_SIDEBAR,
    payload: isOpen
});

export const receiveProblemTemplate = template => ({
    type: UserStoreActionConstants.RECEIVE_PROBLEM_TEMPLATE,
    payload: template
});

export const requestApiHealthCheck = () => ({
    type: UserStoreActionConstants.REQUEST_API_HEALTH_CHECK
});

export const receiveApiHealthCheckResponse = payload => ({
    type: UserStoreActionConstants.RECEIVE_API_HEALTH_CHECK_RESPONSE,
    payload
});

export const resetUserState = () => ({
    type: UserStoreActionConstants.RESET_STATE
});

import React from 'react';
import PropTypes from 'prop-types';

const Stack = ({
    direction,
    spacing,
    divider,
    alignItems,
    justifyContent,
    style,
    children,
    className,
    ...props
}) => {
    const getStackDirection = () => {
        return direction === 'column' ? 'flex-col' : 'flex-row';
    };

    const getSpacing = () => {
        if (spacing && !isNaN(spacing)) {
            return direction === 'column' ? `space-y-${spacing}` : `space-x-${spacing}`;
        }
        return '';
    };

    const mergeChildrenWithDividers = () => {
        let mergedData = [];

        if (!Array.isArray(children)) {
            return children;
        }

        children && children.forEach((child, index) => {
            if (index !== 0 && divider && divider !== '') {
                mergedData.push(<div key={index} className="select-none">{divider}</div>)
            }
            mergedData.push(child);
        });
        return mergedData;
    };

    const getItemsAlignment = () => {
        let alignment;

        switch (alignItems) {
            case 'flex-start':
                alignment = 'items-start';
                break;
            case 'center':
                alignment = 'items-center';
                break;
            case 'flex-end':
                alignment = 'items-end';
                break;
            case 'stretch':
                alignment = 'items-stretch';
                break;
            case 'baseline':
                alignment = 'items-baseline';
                break;
            default:
                alignment = 'items-start';
        }
        return alignment;
    };

    const generateClassNames = () => {
        let classNames = [];

        classNames.push(getStackDirection());
        classNames.push(getSpacing());
        classNames.push(getItemsAlignment());
        classNames.push(getJustifyContent());

        if (className) {
            classNames.push(className);
        }

        return classNames.join(' ');
    };

    const getJustifyContent = () => {
        let tempJustifyContent;

        switch (justifyContent) {
            case 'flex-start':
                tempJustifyContent = 'justify-start';
                break;
            case 'center':
                tempJustifyContent = 'justify-center';
                break;
            case 'flex-end':
                tempJustifyContent = 'justify-end';
                break;
            case 'space-around':
                tempJustifyContent = 'justify-around';
                break;
            case 'space-evenly':
                tempJustifyContent = 'justify-evenly';
                break;
            case 'space-between':
                tempJustifyContent = 'justify-between';
                break;
            default:
                tempJustifyContent = 'justify-start';
        }
        return tempJustifyContent;
    };

    return (
        <div style={style} className={`flex ${generateClassNames()}`} {...props}>
            {mergeChildrenWithDividers()}
        </div>
    );
};

Stack.propTypes = {
    direction: PropTypes.oneOf(['column', 'row']),
    spacing: PropTypes.number,
    divider: PropTypes.string,
    alignItems: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
    justifyContent: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'space-between', 'space-around', 'space-evenly']),
    className: PropTypes.string
};

Stack.defaultProps = {
    direction: 'row',
    spacing: 0,
    divider: '',
    alignItems: 'center',
    justifyContent: 'center',
    className: ''
};

export default Stack;

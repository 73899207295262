import { kitStoreActionConstants, receiveKitItems, receiveKitsInfo } from '@/Store/Actions';
import { getKitItems, getKitsInfo, createKitAndClaim, passKit, removeKitPrimaryItem } from '@/Store/Services';

export const kitStoreMiddleware = ({ action, dispatch }) => {
    let success;
    switch (action.type) {
        case kitStoreActionConstants.REQUEST_KIT_ITEMS:
            success = (companyID) => {
                dispatch(receiveKitItems(companyID))
            };
            return getKitItems(success, action.payload);

        case kitStoreActionConstants.REQUEST_KITS_INFO:
            success = (kitID) => {
                dispatch(receiveKitsInfo(kitID))
            };
            return getKitsInfo(success, action.payload);

        case kitStoreActionConstants.REQUEST_CREATE_KITS_AND_CLAIMS:
            return createKitAndClaim(action.payload, dispatch);

        case kitStoreActionConstants.REQUEST_PASS_KIT:
            success = () => { };
            return passKit(success, action?.payload);

        case kitStoreActionConstants.REQUEST_REMOVE_KIT_PRIMARY_ITEM:
            success = () => { };
            return removeKitPrimaryItem(success, action?.payload);

        default:
            return;
    }
};

import { BaseStore } from '@/Store/BaseStore';

export const getDoaReasons = (success, payload) => {
    return BaseStore.httpClient.get('/doa-reasons', {
        ...(payload || {})
    })
        .then((response) => {
            if (response) {
                success(response.data);
                return Promise.resolve(response.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};
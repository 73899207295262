const SingleSortDescendingIcon = ({ className }) => {
    
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" className={`svg-regular ${className || ''}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5904 14.9148C11.7894 15.1992 12.2106 15.1992 12.4096 14.9148L16.6993 8.78673C16.9313 8.45534 16.6942 8 16.2897 8L7.71033 8C7.30582 8 7.06874 8.45534 7.30071 8.78673L11.5904 14.9148Z" fill="#006688" />
        </svg>
    );
};

export default SingleSortDescendingIcon;

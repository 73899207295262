import React from 'react';

const SelectOption = ({ index, cursor, setCursor, setKeyWord, setHeight, searchRefFocus, searchable, value, selectedValue, multiselect, handleClick, children, className, ...props }) => {
    const handleFunction = (value) => {
        if (multiselect) {
            let arrayOfValue = [];

            if (selectedValue.includes(value)) {
                arrayOfValue = [...selectedValue.filter(e => e !== value)];
            } else {
                arrayOfValue = [...selectedValue, value];
            }

            handleClick({ target: { value: arrayOfValue } });
            setKeyWord('');
            setHeight(0);
            callOutFunctions();
        } else {
            if(typeof handleClick !== "function") return;
            handleClick({ target: { value } });
        }
    }

    const callOutFunctions = () => {
        if (multiselect && !searchable) {
            setCursor(index);
            return;
        }

        if (searchable) {
            setCursor(index);
            searchRefFocus();
        }
    }

    return (
        <button
            role="option"
            aria-selected={(selectedValue && selectedValue === value)}
            className={`
                relative
                ${(index === cursor ? 'active' : '')}
                ${(selectedValue && selectedValue === value ? 'bg-gray-4' : '')}
                ${className || ''}
            `}
            onClick={() => handleFunction(value)}
            onMouseMove={() => multiselect ? callOutFunctions() : {}} data-value={value}
            {...props}
        >
            {children}
            <span className='absolute inset-0' aria-hidden='true' />
        </button>
    );
};

export default SelectOption;

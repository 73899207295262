import React from 'react';
import { createContext } from 'react';

export const TablistContext = createContext()

const Tablist = ({ children, className,id,onChange}) => {
     return (
          <div className={`tab-container ${className ?? ''}`}>
               <TablistContext.Provider value={{id,onChange}}>
                    {children}
               </TablistContext.Provider>
          </div>
     );
};

export default Tablist;

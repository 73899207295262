

const PhoneLinkRingIcon = ({ className }) => {

     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className}`} viewBox="0 0 18 19" fill="none">
          <mask id="mask0_3588_351" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
            <rect y="0.0224609" width="18" height="18" fill="#171C20"/>
          </mask>
          <g mask="url(#mask0_3588_351)">
            <path d="M13.5173 11.0157L12.7038 10.2023C12.8779 10.0503 13.0163 9.87294 13.1192 9.67005C13.2221 9.46716 13.2736 9.25129 13.2736 9.02244C13.2736 8.79359 13.2221 8.57772 13.1192 8.37484C13.0163 8.17195 12.8779 7.99454 12.7038 7.84262L13.5173 7.02919C13.7952 7.29265 14.0115 7.59553 14.1663 7.93783C14.3211 8.28014 14.3985 8.64168 14.3985 9.02244C14.3985 9.40321 14.3211 9.76474 14.1663 10.1071C14.0115 10.4494 13.7952 10.7522 13.5173 11.0157ZM15.2394 12.7378L14.449 11.9474C14.8365 11.5599 15.1353 11.115 15.3454 10.6126C15.5555 10.1102 15.6605 9.58013 15.6605 9.02244C15.6605 8.46476 15.5555 7.93471 15.3454 7.43229C15.1353 6.92989 14.8365 6.48494 14.449 6.09744L15.2394 5.30707C15.7384 5.79842 16.1209 6.36501 16.3867 7.00684C16.6526 7.64865 16.7855 8.32052 16.7855 9.02244C16.7855 9.72437 16.6526 10.3962 16.3867 11.038C16.1209 11.6799 15.7384 12.2465 15.2394 12.7378ZM5.48077 16.8974C5.10674 16.8974 4.78727 16.765 4.52237 16.5001C4.25746 16.2352 4.125 15.9157 4.125 15.5416V2.50324C4.125 2.1292 4.25746 1.80973 4.52237 1.54483C4.78727 1.27992 5.10674 1.14746 5.48077 1.14746H12.5192C12.8932 1.14746 13.2127 1.27992 13.4776 1.54483C13.7425 1.80973 13.875 2.1292 13.875 2.50324V5.24359H12.75V4.33492H5.24998V13.71H12.75V12.8013H13.875V15.5416C13.875 15.9157 13.7425 16.2352 13.4776 16.5001C13.2127 16.765 12.8932 16.8974 12.5192 16.8974L5.48077 16.8974ZM5.24998 14.8349V15.5417C5.24998 15.5994 5.27402 15.6523 5.32209 15.7003C5.37018 15.7484 5.42307 15.7725 5.48077 15.7725H12.5192C12.5769 15.7725 12.6298 15.7484 12.6779 15.7003C12.7259 15.6523 12.75 15.5994 12.75 15.5417V14.8349H5.24998ZM5.24998 3.20998H12.75V2.50326C12.75 2.44556 12.7259 2.39266 12.6779 2.34457C12.6298 2.2965 12.5769 2.27246 12.5192 2.27246H5.48077C5.42307 2.27246 5.37018 2.2965 5.32209 2.34457C5.27402 2.39266 5.24998 2.44556 5.24998 2.50326V3.20998Z" />
          </g>
        </svg>
     )
}

export default PhoneLinkRingIcon
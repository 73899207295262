import { BatchStoreActionConstants, receiveActiveDetailsId, receiveBatchDetails, receiveMyBatches, receiveMyKits, receivePausedTimers } from '@/Store/Actions';
import { completeBatch, getBatchDetails, getMyBatches, getMyKits, pauseAllTimers, pauseTimer, removeFromBatch, startTimer } from '@/Store/Services';

export const BatchStoreMiddleware = ({ state, action, dispatch }) => {
    let success;
    switch (action.type) {
        case BatchStoreActionConstants.REQUEST_MY_BATCHES:
            success = myBatches => {
                dispatch(receiveMyBatches(myBatches));
            };
            return getMyBatches(success, action?.payload);
        case BatchStoreActionConstants.REQUEST_BATCH_DETAILS:
            success = batchDetails => {
                batchDetails = {
                    ...(batchDetails || {}),
                    batchName: batchDetails?.kitDef?.name || batchDetails?.items?.[0]?.fullname
                };

                let batchDetailsToDisplay = [batchDetails];

                if (Array.isArray(state?.batchDetailsToDisplay)) {
                    const isBatchAlreadyDisplayed = state?.batchDetailsToDisplay?.find(batch => batch?.batchID?.toString() === action.payload?.toString()) !== undefined;
                    if (isBatchAlreadyDisplayed) {
                        batchDetailsToDisplay = state?.batchDetailsToDisplay.map(batch => {
                            if (batchDetails?.batchID?.toString() === batch?.batchID?.toString()) {
                                return { ...batchDetails };
                            }

                            return batch;
                        });
                    } else {
                        batchDetailsToDisplay = [
                            ...state?.batchDetailsToDisplay,
                            batchDetails
                        ];
                    }
                }

                dispatch(receiveBatchDetails(batchDetailsToDisplay));
            };
            return getBatchDetails(success, action?.payload);
        case BatchStoreActionConstants.REQUEST_START_TIMER:
            success = () => {
                if (Array.isArray(state?.pausedTimers) && state?.pausedTimers?.find(pausedTimer => pausedTimer?.batchID?.toString() === action?.payload?.batchID?.toString()) !== undefined) {
                    const newReceivedPausedTimers = this?.props?.pausedTimers?.filter(pausedTimer => pausedTimer?.batchID.toString() !== this.props?.activeDetailsId?.toString());
                    dispatch(receivePausedTimers(newReceivedPausedTimers));
                }
            };

            return startTimer(success, action?.payload);
        case BatchStoreActionConstants.REQUEST_PAUSE_TIMER:
            success = () => {
                if (Array.isArray(state?.pausedTimers) && state?.pausedTimers?.find(pausedTimer => pausedTimer?.batchId?.toString() === action?.payload?.batchID?.toString()) === undefined) {
                    dispatch(receivePausedTimers([
                        ...state.pausedTimers,
                        {
                            batchID: action?.payload?.batchID?.toString(),
                            isComplete: false
                        }
                    ]));
                    return;
                }

                dispatch(receivePausedTimers([
                    {
                        batchID: action?.payload?.batchID?.toString(),
                        isComplete: false
                    }
                ]));
            };

            return pauseTimer(success, action?.payload);
        case BatchStoreActionConstants.REQUEST_MY_KITS:
            success = myKits => {
                dispatch(receiveMyKits(myKits));
            };
            return getMyKits(success, action?.payload);
        case BatchStoreActionConstants.REQUEST_PAUSE_ALL_TIMERS:
            success = () => {
                dispatch(receiveBatchDetails([]));
                dispatch(receiveActiveDetailsId(null));
                dispatch(receivePausedTimers(null));
            };
            return pauseAllTimers(success, action?.payload);
        case BatchStoreActionConstants.REQUEST_COMPLETE_BATCH:
            success = responseData => { };
            return completeBatch(success, action?.payload);
        case BatchStoreActionConstants.REQUEST_REMOVE_FROM_BATCH:
            success = responseData => { };
            return removeFromBatch(success, action?.payload);
        default:
            return;
    }
};

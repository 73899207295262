import { useMemo, useState, useEffect } from "react";
import { Stack } from "@/Components/Display/Stack";

const CustomGroupRowHeader = ({
    node,
    value,
    api
}) => {

    const calculateLeafNodeTotal = (rowData, value) => {
        const leafNode = rowData.filter(company => company.key && company.key === value)
            .flatMap(children => children.childrenAfterFilter.map(row => row.data));

        return leafNode.reduce((acc, data) => acc + data.total, 0);
    };

    const initialRowData = api?.rowModel.rowsToDisplay;
    const initialLeafNodeCount = useMemo(() => calculateLeafNodeTotal(initialRowData, value), [initialRowData, value]);
    const [leafNodeCount, setLeafNodeCount] = useState(initialLeafNodeCount);

    useEffect(() => {
        if (!api) {
            return;
        }

        const updateFilterCount = () => {
            const rowData = api?.rowModel?.rowsToDisplay ?? [];
            const total = calculateLeafNodeTotal(rowData, value);
            setLeafNodeCount(total);
        };

        const onFilterChanged = () => {
            updateFilterCount();
        };

        updateFilterCount();

        api.addEventListener('filterChanged', onFilterChanged);

        return () => {
            api.removeEventListener('filterChanged', onFilterChanged);
        };
    }, [api, value]);

    return node?.group && (
        <Stack className="h-full gap-0.5 text-inverse-surface text-xs font-semibold leading-[18px]">
            <p className="font-semibold">{value}</p>
            {isNaN(leafNodeCount) ? '' : (
                <p className="font-normal">{`- ${leafNodeCount} Total Items`}</p>
            )}
        </Stack>
    );
};

export default CustomGroupRowHeader;

const TabContainerButton = ({ children, className }) => {
     return (
          <div className={`flex gap-[24px] ${className || ''} `}>
               {children}
          </div>
     );
};

export default TabContainerButton;

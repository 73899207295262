

const NavigateBefore = ({ className }) => {

     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className}`} viewBox="0 0 24 24" fill="none">
          <path d="M15.7049 7.41L14.2949 6L8.29492 12L14.2949 18L15.7049 16.59L11.1249 12L15.7049 7.41Z" fill="currentColor"/>
        </svg>
     )
   }
   
   export default NavigateBefore
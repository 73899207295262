import React, {
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
    // useCallback,
    useMemo,
    Fragment
} from "react";

import PropTypes from "prop-types";

// import SortAscendingIcon from '@/Icons/SortAscendingIcon';
// import SortDescendingIcon from '@/Icons/SortDescendingIcon';
// import SortDefaultIcon from '@/Icons/SortDefaultIcon';

// import useSortableData from "@/Hooks/useSortableData";
import { isElementOverflowing } from "@/Helpers/browserDetailsHelper";

import loaderLogo from '@/Assets/Loading.png';

const RowHeight = 48;

const DataTable = ({
    columns,
    rows: items,
    rowRenderer,
    style,
    className,
    bodyClassName,
    // defaultSort = {},
    childRows,
    // sortingMode = 'client',
    // onSortChange,
    shouldAutoPinFirstCol,
    isLoading,
    getRowClass,
    onRowHover
}) => {
    const tableBodyRef = useRef();
    const pinnedColumnsRef = useRef();
    const [scrollPosition, setScrollPosition] = useState(0);
    const [tableHeaderPaddingRight, setTableHeaderPaddingRight] = useState(0);
    const [tableWidth, setTableWidth] = useState(0);
    const [rawColumns, setRawColumns] = useState();
    const [pinnedColumns, setPinnedColumns] = useState([]);
    const [isTableBodyOverflowingHorizontally, setIsTableBodyOverflowingHorizontally] = useState(false);
    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

    // const tableDefaultSort = useMemo(() => {
    //     if (Array.isArray(columns)
    //         && defaultSort
    //         && Object.keys(defaultSort).length === 2
    //         && defaultSort.hasOwnProperty('key')
    //         && defaultSort.hasOwnProperty('direction')) {
    //         return defaultSort;
    //     }

    //     return {
    //         key: columns[0]?.field,
    //         direction: 'descending'
    //     };
    // }, [defaultSort, columns]);

    // const { items, requestSort, sortConfig } = useSortableData({ rows, tableDefaultSort, childRows, sortingMode });

    // const handleRequestSort = field => {
    //     const newSortConfig = requestSort(field);

    //     if (onSortChange && typeof onSortChange === 'function') {
    //         onSortChange(newSortConfig);
    //     }
    // };

    const handleScroll = () => {
        const pinnedColumnsWidth = pinnedColumnsRef?.current?.clientWidth;
        const tableBodyScrollPosition = tableBodyRef?.current?.scrollLeft;

        if (pinnedColumnsWidth > 0) {
            setScrollPosition(pinnedColumnsWidth - tableBodyScrollPosition);
            return;
        }

        setScrollPosition(-Math.abs(tableBodyScrollPosition));
    };

    const handleResize = () => {
        setTableWidth(tableBodyRef.current?.offsetWidth);
        setIsTableBodyOverflowingHorizontally(isElementOverflowing(tableBodyRef.current, 'horizontal'));
    };

    const handleRowMouseEnter = rowIndex => {
        setHoveredRowIndex(rowIndex);

        if (typeof onRowHover === 'function') {
            onRowHover({
                index: rowIndex,
                row: items?.[rowIndex]
            });
        }
    };

    const handleRowMouseLeave = () => {
        setHoveredRowIndex(null);

        if (typeof onRowHover === 'function') {
            onRowHover({
                index: null,
                row: null
            });
        }
    };

    useEffect(() => {
        if (tableBodyRef?.current) {
            const tableScrollRefElement = tableBodyRef.current;

            tableScrollRefElement?.addEventListener('scroll', handleScroll, { passive: true });

            return () => {
                tableScrollRefElement?.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    useEffect(() => {
        if (pinnedColumns?.length > 0 && pinnedColumnsRef?.current) {
            handleScroll();
            return;
        }
        setScrollPosition(0);
    }, [pinnedColumns]);

    useEffect(() => {
        if (rawColumns?.length > 0 || pinnedColumns?.length > 0) {
            setTableHeaderPaddingRight(tableBodyRef.current.offsetWidth - tableBodyRef.current.clientWidth);
            handleResize();
        }
    }, [rawColumns, pinnedColumns]);

    useLayoutEffect(() => {
        if (columns && Array.isArray(columns)) {
            const allFixedColumns = columns?.filter(column => !column.fluid);
            const allFluidColumns = columns?.filter(column => !!column.fluid);
            const initialValue = 0;
            const sumOfFixedWidths = allFixedColumns?.reduce((column, iterator) => {
                return column + (iterator?.width || 0);
            }, initialValue);

            const remainingWidth = (tableWidth - sumOfFixedWidths) - tableHeaderPaddingRight;

            const widthOfFluidColumns = remainingWidth / allFluidColumns.length;

            const newColumns = columns?.map(column => {
                if (!!column.fluid) {
                    column.width = widthOfFluidColumns;

                    return column;
                }
                return column;
            });

            if (isTableBodyOverflowingHorizontally && shouldAutoPinFirstCol) {
                const firstColumn = newColumns?.[0];
                const secondColumn = newColumns?.[1];
                const toBePinnedColumns = [{
                    ...firstColumn,
                    pinned: true
                }];
                if (childRows) {
                    toBePinnedColumns.push({
                        ...secondColumn,
                        pinned: true
                    });
                }
                setPinnedColumns(toBePinnedColumns);
                const idxToCompare = childRows ? 1 : 0
                setRawColumns(newColumns.filter((_, index) => index > idxToCompare));
                return;
            }

            setRawColumns(newColumns);
            setPinnedColumns([]);
        }
    }, [
        tableWidth,
        columns,
        tableHeaderPaddingRight,
        isTableBodyOverflowingHorizontally,
        childRows,
        shouldAutoPinFirstCol
    ]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // const getSortIconFor = useCallback((name) => {
    //     if (!sortConfig) {
    //         return;
    //     }
        
    //     if (sortConfig.key === name) {
    //         if (sortConfig.direction === 'ascending') {
    //             return (
    //                 <Tooltip
    //                     trigger={props => (
    //                         <SortDescendingIcon {...props} />
    //                     )}
    //                     content="Sort descending"
    //                 />
    //             );
    //         }

    //         return (
    //             <Tooltip
    //                 trigger={props => (
    //                     <SortAscendingIcon {...props} />
    //                 )}
    //                 content="Sort ascending"
    //             />
    //         );
    //     }
        
    //     return (
    //         <Tooltip
    //             trigger={props => (
    //                 <SortDefaultIcon className="datatable-sort-button-icon" {...props} />
    //             )}
    //             content="Sort ascending"
    //         />
    //     );
    // }, [sortConfig]);

    const pinnedExpandColumnsLength = useMemo(() => {
        if (childRows?.rows && Array.isArray(childRows?.rows)) {
            let totalCount = 0;
            childRows?.rows?.forEach(row => {
                const parentRow = items?.find(item => item?.[childRows?.rowId] === row?.rowId);
                if (parentRow && parentRow?.[childRows?.field] && Array.isArray(parentRow?.[childRows?.field])) {
                    totalCount += parentRow?.[childRows?.field]?.length;
                }
            });
            return totalCount;
        }
        return 0;
    }, [childRows, items]);

    const renderHeaders = columnsToIterate => {
        return columnsToIterate?.map((column, index) => {
            const {
                label,
                width,
                // field,
                // sortable = true,
                style
            } = column;
            return (
                <div key={index} className="datatable-header-label" style={{ width, ...style }}>
                    {label}
                    {/* {!!sortable && (
                        <button className="datatable-sort-button" onClick={() => handleRequestSort(field)}>
                            {getSortIconFor(field)}
                        </button>
                    )} */}
                </div>
            );
        });
    };

    const renderColumnData = ({ key, className, rowIndex, width, style, shouldTruncate, dataToRender, rowExpanded, rowData }) => {
        const isRowCurrentlyExpanded = childRows && rowExpanded?.rowId === rowData?.[childRows?.rowId];

        const truncatedData = (
            <div className="truncate">
                {dataToRender}
            </div>
        );

        return (
            <div
                key={key}
                className={`
                    datatable-row-data
                    ${className ?? ''}
                    ${hoveredRowIndex === rowIndex && !isRowCurrentlyExpanded ? 'bg-gray-5' : ''}
                    ${isRowCurrentlyExpanded ? 'bg-gray-3' : ''}
                `}
                style={{ width, ...style }}
            >
                {shouldTruncate && truncatedData}
                {!shouldTruncate && dataToRender}
            </div>
        );
    };

    const renderRows = (columnsToIterate, isRemoveFirstColumnRender) => {
        return items && Array.isArray(items) && items?.map((rowData, rowIndex) => {
            let rowRender = rowRenderer && typeof rowRenderer === 'function' ? rowRenderer(rowData, rowIndex) : [];
            if (isRemoveFirstColumnRender) {
                const idxToCompare = childRows ? 1 : 0
                rowRender = rowRender?.filter((_, index) => index > idxToCompare);
            }

            const rowExpanded = childRows?.rows && Array.isArray(childRows?.rows) && childRows?.rows.length > 0 && childRows?.rows?.find(childRow => childRow?.rowId === rowData?.[childRows?.rowId]);

            let rowClassName = '';
            if (typeof getRowClass === 'function') {
                rowClassName = getRowClass(rowData);
            }

            return (
                <Fragment key={rowIndex}>
                    <div
                        className={`
                            datatable-row
                            ${rowClassName || ''}    
                        `}
                        style={{ height: RowHeight }}
                        onMouseEnter={() => handleRowMouseEnter(rowIndex)}
                        onMouseLeave={handleRowMouseLeave}
                    >
                        {columnsToIterate && Array.isArray(columnsToIterate) && columnsToIterate?.map((columnData, columnIndex) => {
                            const {
                                width,
                                // shouldTextTruncate,
                                className,
                                style
                            } = columnData || {};
                            let dataToRender = rowRender?.[columnIndex];

                            if (dataToRender && typeof dataToRender === 'function') {
                                dataToRender = dataToRender(columnData, columnIndex);
                            }

                            // if (shouldTextTruncate) {
                            //     return (
                            //         <BfTooltip direction="bottom" key={columnIndex} content={dataToRender}>
                            //             {renderColumnData({
                            //                 className,
                            //                 rowIndex,
                            //                 width,
                            //                 style,
                            //                 shouldTruncate: true,
                            //                 dataToRender,
                            //                 rowExpanded,
                            //                 rowData
                            //             })}
                            //         </BfTooltip>
                            //     );
                            // }

                            return renderColumnData({
                                key: columnIndex,
                                className,
                                rowIndex,
                                width,
                                style,
                                shouldTruncate: false,
                                dataToRender,
                                rowExpanded,
                                rowData
                            });
                        })}
                    </div>
                    {rowExpanded && (
                        rowData?.[childRows?.field]?.map((childRow, childRowIndex) => {
                            const childRowSingleData = childRows.rows?.find(childRow => childRow?.rowId === rowData?.id)?.childRowData;
                            let childRowToIterate = childRowSingleData && typeof childRowSingleData === 'function' ? childRowSingleData(childRow) : [];
                            const offsetIndex = childRowToIterate?.findIndex(data => !!data);

                            if (isRemoveFirstColumnRender === false && isTableBodyOverflowingHorizontally) {
                                return (
                                    <div
                                        key={childRowIndex}
                                        className="datatable-row" style={{ height: RowHeight }}
                                    >
                                        {Array.from({ length: offsetIndex }).map((_, childRowDataIndex) => {
                                            const { width, className, style } = columnsToIterate?.[childRowDataIndex] || {};
                                            return (
                                                <div
                                                    key={childRowDataIndex}
                                                    className={`
                                                        datatable-row-data
                                                        datatable-child-row-data
                                                        ${className ?? ''}
                                                    `}
                                                    style={{ width, ...style }}
                                                />
                                            );
                                        })}
                                    </div>
                                );
                            }

                            if (isRemoveFirstColumnRender) {
                                const idxToCompare = isTableBodyOverflowingHorizontally ? offsetIndex : 0;
                                childRowToIterate = childRowToIterate?.filter((_, index) => index >= idxToCompare);
                            }

                            return (
                                <div
                                    key={childRowIndex}
                                    className="datatable-row" style={{ height: RowHeight }}
                                >
                                    {childRowToIterate.map((data, childRowDataIndex) => {
                                        const {
                                            width,
                                            // shouldTextTruncate,
                                            className,
                                            style
                                        } = columnsToIterate?.[childRowDataIndex] || {};
                                        const dataToRender = data && typeof data === 'function' ? data(columnsToIterate?.[childRowDataIndex], childRowDataIndex) : data;

                                        // if (shouldTextTruncate && dataToRender) {
                                        //     return (
                                        //         <BfTooltip direction="bottom" key={childRowDataIndex} content={dataToRender}>
                                        //             <div 
                                        //                 className={`
                                        //                     datatable-row-data
                                        //                     datatable-child-row-data
                                        //                     ${className ?? ''}
                                        //                 `}
                                        //                 style={{ width, ...style }}
                                        //             >
                                        //                 <div className="truncate">
                                        //                     {dataToRender}
                                        //                 </div>
                                        //             </div>
                                        //         </BfTooltip>
                                        //     );
                                        // }

                                        return (
                                            <div
                                                key={childRowDataIndex}
                                                className={`
                                                    datatable-row-data
                                                    datatable-child-row-data
                                                    ${className ?? ''}
                                                `}
                                                style={{ width, ...style }}
                                            >
                                                {dataToRender}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })
                    )}
                </Fragment>
            );
        });
    };

    return (
        <div className={`datatable ${className ?? ''}`} style={style}>
            <div className="datatable-headers" >
                {pinnedColumns && Array.isArray(pinnedColumns) && pinnedColumns?.length > 0 && (
                    <div className="datatable-header datatable-header-pinned" ref={pinnedColumnsRef}>
                        {renderHeaders(pinnedColumns)}
                    </div>
                )}
                <div className="datatable-headers-scroll" style={{ transform: `translate3d(${scrollPosition}px, 0px, 0px)` }}>
                    <div className="datatable-header">
                        {rawColumns && Array.isArray(rawColumns) && renderHeaders(rawColumns)}
                    </div>
                </div>
            </div>
            <div className={`datatable-body ${bodyClassName ?? ''}`} ref={tableBodyRef}>
                {pinnedColumns && Array.isArray(pinnedColumns) && pinnedColumns?.length > 0 && (
                    <div className="datatable-body-rows-pinned" style={{ height: (items?.length + pinnedExpandColumnsLength) * RowHeight }}>
                        {renderRows(pinnedColumns, false)}
                    </div>
                )}
                <div className="datatable-body-rows">
                    {renderRows(rawColumns, isTableBodyOverflowingHorizontally && shouldAutoPinFirstCol)}
                </div>
            </div>
            <div className="datatable-pagination">

            </div>
            {isLoading && (
                <div className="datatable-loading">
                    <img src={loaderLogo} className="datatable-loading-image" alt="loader" />
                </div>
            )}
        </div>
    );
};

DataTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string,
        label: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node,
            PropTypes.string
        ]),
        fluid: PropTypes.bool,
        shouldTextTruncate: PropTypes.bool,
        className: PropTypes.string,
        style: PropTypes.object
    })).isRequired,
    rows: PropTypes.array.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    defaultSort: PropTypes.shape({
        key: PropTypes.string,
        direction: PropTypes.string
    }),
    rowRenderer: PropTypes.func,
    childRows: PropTypes.shape({
        rowId: PropTypes.string,
        rows: PropTypes.arrayOf(PropTypes.shape({
            rowId: PropTypes.string,
            childRowData: PropTypes.func
        }))
    }),
    sortingMode: PropTypes.oneOf(['client', 'server']),
    onSortChange: PropTypes.func
};

DataTable.defaultProps = {
    sortingMode: 'client'
};

export default DataTable;

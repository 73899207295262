export const QaChecklistStoreActionConstants = {
    REQUEST_QA_CHECKLIST: 'REQUEST_QA_CHECKLIST',
    RECEIVE_QA_CHECKLIST: 'RECEIVE_QA_CHECKLIST'
};

export const requestQaChecklist = queryParams => ({
    type: QaChecklistStoreActionConstants.REQUEST_QA_CHECKLIST,
    payload: queryParams
});

export const receiveQaChecklist = qaCheckList => ({
    type: QaChecklistStoreActionConstants.RECEIVE_QA_CHECKLIST,
    payload: qaCheckList
});

import { BaseStore } from '@/Store/BaseStore';
import axios from "axios";

export const login = (success, payload) => {
    return BaseStore.httpClient.post('users/login', {
        ...(payload || {})
    })
        .then((response) => {
            if (response) {
                success(response.data);
                return Promise.resolve(response.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const healthCheck = success => {
    return axios.get('/healthcheck', {
        baseURL: BaseStore.httpClient.getUri().replace('/api/v1', '')
    })
        .then((response) => {
            if (response) {
                success(response.data);
                return Promise.resolve(response.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

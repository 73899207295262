
import { Dialog } from "@/Components/Feedback";
import { Button } from "@/Components/Inputs";

const CannotPassDeviceDialog = ({
    isOpen,
    onClose,
    onYes
}) => {
    const handleClose = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    const handleYes = () => {
        if (typeof onYes === 'function') {
            onYes();
        }
    };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={handleClose}
            className="!w-[624px]"
        >
            <Dialog.Title className="text-[32px] leading-[40px] font-normal">
                Cannot pass device
            </Dialog.Title>
            <Dialog.Content>
                <p className="text-base leading-[24px] tracking-[0.5px] font-normal text-on-surface">
                    You must fill out all options to pass the device. You may need to scroll to see all available options.
                    <br />
                    <br />
                    Would you like to submit this as a bug report?
                </p>
            </Dialog.Content>
            <Dialog.Actions className="justify-end">
                <Button onClick={handleClose} variant="flat" className="uppercase w-[78px] border-0">No</Button>
                <Button onClick={handleYes} className="uppercase">Yes</Button>
            </Dialog.Actions>
        </Dialog>
    );
};

export default CannotPassDeviceDialog;

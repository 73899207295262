import React, { useState, useMemo, useContext, createContext, forwardRef, useEffect } from "react";
import PropTypes from 'prop-types';
import {
    useClick,
    useInteractions,
    useDismiss,
    useRole,
    useFloating,
    autoUpdate,
    offset,
    shift,
    flip,
    FloatingFocusManager,
    FloatingPortal,
    FloatingOverlay,
    useMergeRefs
} from "@floating-ui/react";

import { Button } from "@/Components/Inputs";
import { CloseIcon } from "@/Icons";

const usePopover = ({
    placement,
    open: controlledOpen,
    onOpenChange: setControlledOpen,
    onClose,
    offsetOptions,
    flipOptions,
    shiftOptions,
    dismissOptions,
    shouldAdjustToScrolling,
    disableOutsideClick,
    scrollBarElement,
    hasOverlay,
    hasCloseButton
}) => {
    const [uncontrolledOpen, setUncontrolledOpen] = useState(false);

    const open = useMemo(() => (
        controlledOpen ?? uncontrolledOpen
    ), [controlledOpen, uncontrolledOpen]);

    const setOpen = useMemo(() => (
        setControlledOpen ?? setUncontrolledOpen
    ), [setControlledOpen, setUncontrolledOpen]);

    const compiledDismissOptions = useMemo(() => {
        if (dismissOptions) return { ...dismissOptions };
        return { escapeKey: !disableOutsideClick, outsidePress: !disableOutsideClick };
    }, [dismissOptions, disableOutsideClick]);

    // Custom middleware for shifting the popover while scrolling or adjusting to the scroll
    const shiftPopover = useMemo(() => ({
        name: 'shiftPopover',
        fn: ({ y, elements }) => {
            const scrollRect = scrollBarElement?.getBoundingClientRect();
            const referenceRect = elements?.reference?.getBoundingClientRect();
            const floatingRect = elements?.floating?.getBoundingClientRect();

            // If the popover is already at its position (not mounted for the first time)
            const isFloatingElementInPosition = floatingRect?.top !== 0 || floatingRect?.left !== 0;

            const isFloatingElementNotAlignedToScroll = floatingRect?.top <= scrollRect?.top;

            // If the reference element is partially or fully hidden within the scrolling parent
            const isReferenceElementHiddenInScroll = referenceRect?.top <= scrollRect?.top;

            // Bottom boundary of the popover
            const boundaryBottom = floatingRect?.top - (floatingRect?.bottom - scrollRect?.bottom);

            // Apply the maximum bottom boundary when the bottom of the floating element exceeds or 
            // is at the bottom of the viewport or the floating element is already or exceeding at the max bottom boundary.
            if (isFloatingElementInPosition && (floatingRect?.bottom >= window?.innerHeight || boundaryBottom <= floatingRect?.y)) {
                return {
                    y: boundaryBottom
                };
            }

            // Assign the scrollRect.top to the floating element to make it aligned with the scrollbar parent's top.
            if (isFloatingElementInPosition && (isFloatingElementNotAlignedToScroll || isReferenceElementHiddenInScroll)) {
                return {
                    y: scrollRect?.top,
                };
            }

            // Return the original y value
            return {
                y
            };
        }
    }), [scrollBarElement]);

    const data = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(offsetOptions || 0),
            flip(flipOptions || 0),
            shift(shiftOptions || 0),
            shouldAdjustToScrolling && scrollBarElement && shiftPopover
        ]
    });

    const context = data?.context;

    const click = useClick(context, {
        enabled: controlledOpen == null
    });
    const dismiss = useDismiss(context, compiledDismissOptions);
    const role = useRole(context);

    const interactions = useInteractions([click, dismiss, role]);

    useEffect(() => {
        if (!open && onClose && typeof onClose === 'function') {
            onClose();
        }
    }, [open, onClose]);

    return useMemo(() => ({
        open,
        setOpen,
        hasOverlay,
        hasCloseButton,
        ...interactions,
        ...data
    }), [open, setOpen, hasOverlay, hasCloseButton, interactions, data]);
};

const PopoverContext = createContext(null);

const usePopoverContext = () => {
    const context = useContext(PopoverContext);

    if (context === null) {
        console.error("Popover components must be wrapped in <Popover />");
        return { context: null };
    }

    return context;
};

export const Popover = ({
    children,
    ...rest
}) => {
    // This can accept any props as options, e.g. `placement`,
    // or other positioning options.
    const popover = usePopover({ ...rest });
    return (
        <PopoverContext.Provider value={popover}>
            {children}
        </PopoverContext.Provider>
    );
};

const PopoverTrigger = forwardRef(({
    children,
    asCustomComponent,
    className,
    ...props
}, propRef) => {
    const popoverContext = usePopoverContext();
    const childrenRef = (children)?.ref;
    const ref = useMergeRefs([popoverContext?.refs?.setReference, propRef, childrenRef]);

    if (!popoverContext?.context) return null;

    // `asCustomComponent` allows the user to pass any element as the trigger
    if (asCustomComponent && React.isValidElement(children)) {
        return React.cloneElement(
            children,
            popoverContext?.getReferenceProps({
                ref,
                ...props,
                ...children?.props,
            })
        );
    }

    return (
        <Button
            ref={ref}
            className={`popover-trigger ${className || ''}`}
            {...popoverContext?.getReferenceProps(props)}
        >
            {children}
        </Button>
    );
});

Popover.Trigger = PopoverTrigger;

const PopoverContent = forwardRef(({
    children,
    className,
    style,
    ...props
}, propRef) => {
    const { context: floatingContext, ...rest } = usePopoverContext();
    const ref = useMergeRefs([rest?.refs?.setFloating, propRef]);

    if (!floatingContext?.open) return null;

    const popoverContent = (
        <FloatingPortal>
            <FloatingFocusManager context={floatingContext}>
                <div
                    ref={ref}
                    style={{
                        position: floatingContext?.strategy,
                        top: floatingContext?.y ?? 0,
                        left: floatingContext?.x ?? 0,
                        ...style,
                    }}
                    className={`popover-content ${className || ''}`}
                    {...rest?.getFloatingProps(props)}
                >
                    {rest?.hasCloseButton && (
                        <Button
                            className="popover-close"
                            onClick={() => rest?.setOpen(false)}
                        >
                            <CloseIcon />
                        </Button>
                    )}
                    {children}
                </div>
            </FloatingFocusManager>
        </FloatingPortal>
    );

    if (rest?.hasOverlay) {
        return (
            <FloatingOverlay className="popover-overlay">
                {popoverContent}
            </FloatingOverlay>
        );
    }

    return popoverContent;
});

Popover.Content = PopoverContent;

Popover.propTypes = {
    placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left', 'top-start', 'top-end', 'right-start', 'right-end', 'bottom-start', 'bottom-end', 'left-start', 'left-end', '']),
    offsetOptions: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    flipOptions: PropTypes.object,
    shiftOptions: PropTypes.object,
    disableOutsideClick: PropTypes.bool,
    dismissOptions: PropTypes.object,
    scrollBarElement: PropTypes.instanceOf(Element),
    shouldAdjustToScrolling: PropTypes.bool,
    hasOverlay: PropTypes.bool,
    hasCloseButton: PropTypes.bool
};

Popover.Trigger.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    asCustomComponent: PropTypes.bool,
    className: PropTypes.string
};

Popover.Content.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    className: PropTypes.string,
    style: PropTypes.object
};

Popover.defaultProps = {
    placement: 'bottom',
    offsetOptions: null,
    flipOptions: null,
    shiftOptions: null,
    disableOutsideClick: false,
    dismissOptions: null,
    scrollBarElement: null,
    shouldAdjustToScrolling: false,
    hasOverlay: false,
    hasCloseButton: false
};

Popover.Trigger.defaultProps = {
    children: null,
    asCustomComponent: false,
    className: ''
};

Popover.Content.defaultProps = {
    children: null,
    className: '',
    style: null
};

export default Popover;

import React from 'react';
import PropTypes from 'prop-types';



const Radio = ({ label, disabled, onChange, value, checked, checkIcon, uncheckIcon, className, ...props }) => {

    // const [isChecked, setIsChecked] = useState(checked);

    // useEffect(() => {
    //     setIsChecked(!!checked);
    // }, [checked]);

    const onChangeEvent = event => {
        // event.preventDefault();
        // event.stopPropagation();
        if (typeof onChange !== "function") return
        // setIsChecked(!isChecked);
        onChange(event);
    };

    const handleKeyPress = event => {
        if (event.keyCode === 13) {
            // setIsChecked(!isChecked);
            event.target.value = value;
            onChangeEvent(event);
        }
    };

    return (
        <div className={`radio ${disabled ? 'radio-disabled' : 'radio-default'} ${className ?? ""} `} tabIndex="0" onKeyDown={handleKeyPress} {...props}>
            <div onClick={onChangeEvent} className="radio-icon">
                {!checkIcon ? <>
                    {checked ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path fill="#2F4455" fillRule="evenodd" d="M12 7c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5zm0-5C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z" clipRule="evenodd"
                        ></path></svg>
                        : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path fill="#BDC8D0" fillRule="evenodd" d="M2 12c0 5.5 4.5 10 10 10s10-4.5 10-10S17.5 2 12 2 2 6.5 2 12zm10 8c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z" clipRule="evenodd" ></path>
                        </svg>}</> : <>{
                            checked ? checkIcon : uncheckIcon
                        }</>}
            </div>
            {/* <input
                type="radio"
                checked={checked}
                disabled={disabled}
                onChange={onChangeEvent}
                readOnly
                value={value}
                className="radio-input"
                tabIndex="-1"
            /> */}
            {label ? <span className="radio-label">{label}</span> : null}
        </div>
    );
};

Radio.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
    ]),
    disabled: PropTypes.bool
};

Radio.defaultProps = {
    disabled: false
};

export default Radio;

import { Component } from "react";
import { Stack, Tag } from "@/Components/Display";
import {
    // AddCircleOutlineIcon
} from "@/Icons";
import {
    // Button,
    TextField
} from "@/Components/Inputs";
import { withStore } from "@/Helpers/withStore";

class Tags extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAddingATag: false,
            recentlyAddedTags: ['Battery', 'Case', 'Factory Reset', 'Tag Name 1', 'Tag Name 2'],
            tagToBeAdded: ''
        };
    }

    handleAddTagClick = () => {
        this.setState({
            isAddingATag: true
        });
    };

    handleTagToBeAddedChange = event => {
        const target = event?.target;
        const value = target?.value ?? '';

        this.setState({
            tagToBeAdded: value
        });
    };

    handleTagToBeAddedKeyDown = event => {
        if (event?.key?.toLowerCase().trim() === 'enter' && this.state.tagToBeAdded && this.state.addedTags.includes(this.state.tagToBeAdded) === false) {
            this.setState(prevState => ({
                addedTags: [
                    ...prevState?.addedTags,
                    this.state.tagToBeAdded
                ],
                tagToBeAdded: ''
            }));
        }
    };

    handleAddedTagRemove = tag => {
        if (tag && this.state.addedTags.includes(tag)) {
            this.setState(prevState => ({
                addedTags: prevState?.addedTags.filter(addedTag => addedTag !== tag)
            }));
        }
    };

    getActiveDetails = () => {
        const batch = this.props?.batchDetailsToDisplay?.find(batch => batch?.batchID?.toString() === this.props?.activeDetailsId?.toString());
        return batch || {};
    }

    getTags = () => {
        const activeDetails = this.getActiveDetails();
        const activeKitDetailsItemId = this.props?.activeKitDetailsItemId;
        if (activeDetails) {
            if (activeDetails?.batchType === 'kit' && activeKitDetailsItemId) {
                return activeDetails?.items?.[activeKitDetailsItemId]?.[0]?.tags;
            }

            return activeDetails?.items?.[0]?.tags;
        }
        return [];
    };

    getActiveDetailsFullName = () => {
        const activeDetails = this.getActiveDetails();
        const activeKitDetailsItemId = this.props?.activeKitDetailsItemId;

        if (activeDetails) {
            if (activeDetails?.batchType === 'kit' && activeKitDetailsItemId) {
                return activeDetails?.items?.[activeKitDetailsItemId]?.[0]?.fullname;
            }

            return activeDetails?.items?.[0]?.fullname;
        }

        return '';
    }

    render() {
        const {
            isAddingATag,
            recentlyAddedTags,
            tagToBeAdded
        } = this.state;

        const tags = this.getTags() || [];

        return (
            <Stack direction="column" alignItems="flex-start" className="gap-[16px] text-white">
                <p className="text-[16px] font-medium leading-[24px] tracking-[0.5px]">
                    Tags for
                    <br />
                    {this.getActiveDetailsFullName()}
                </p>
                <p className="text-[12px] leading-[16px] tracking-[0.4px]">
                    Description for tag usage goes in this section.
                </p>
                <div className="border-t-[0.5px] border-primary-fixed-dim w-full">
                    {tags?.length > 0 && (
                        <Stack justifyContent="flex-start" className="gap-[9px] flex-wrap mt-[16px]">
                            {tags.map(({ id, name }) => {
                                return (
                                    <Tag
                                        // Removing tags are not allowed for techs
                                        // hasCloseButton
                                        key={id}
                                        className="flex gap-[4px] bg-white text-on-primary-fixed-variant rounded-[5px] px-[8px] shrink-0 h-[22px]"
                                        label={<p className="text-[12px] font-semibold leading-[16px]">{name}</p>}
                                        // onRemove={() => this.handleAddedTagRemove(id)}
                                    />
                                );
                            })}
                        </Stack>
                    )}
                    {tags?.length === 0 && (
                        <Stack className="mt-[16px] h-[128px] bg-primary-35">
                            <p className="text-center text-white text-[12px] leading-[16px] tracking-[0.4px]">There are no tags for<br /> this item.</p>
                        </Stack>
                    )}
                    {isAddingATag && (
                        <Stack direction="column" alignItems="flex-start" className="gap-[3px] mt-[16px]">
                            <div className="w-full">
                                <TextField 
                                    placeholder="Type to pick a tag..."
                                    className="text-on-secondary-fixed"
                                    value={tagToBeAdded}
                                    onChange={this.handleTagToBeAddedChange}
                                    onKeyDown={this.handleTagToBeAddedKeyDown}
                                />
                            </div>
                            <div className="bg-white p-[10px] rounded-[5px]">
                                <p className="uppercase text-outline text-[9px] leading-[12px] tracking-[0.4px] mb-[8px]">Recent</p>
                                <Stack  justifyContent="flex-start" className="gap-[9px] flex-wrap">
                                    {recentlyAddedTags.map((recentlyAddedTag, index) => {
                                        return (
                                            <Tag
                                                key={index}
                                                className="bg-on-surface-variant rounded-[5px] px-[8px] shrink-0 h-[22px]"
                                                label={<p className="text-[12px] font-semibold leading-[16px]">{recentlyAddedTag}</p>}
                                            />
                                        );
                                    })}
                                </Stack>
                            </div>
                        </Stack>
                    )}
                    {/* Adding tags are not allowed for techs */}
                    {/* {!isAddingATag && (
                        <Button
                            startIcon={<AddCircleOutlineIcon />}
                            className="mt-[24px] mx-auto"
                            onClick={this.handleAddTagClick}
                        >
                            Add tag
                        </Button>
                    )} */}
                </div>
            </Stack>
        );
    };
}

const mapStateToProps = ({ batch, kit }) => {
    return {
        batchDetailsToDisplay: batch?.batchDetailsToDisplay,
        activeDetailsId: batch?.activeDetailsId,
        activeKitDetailsItemId: kit?.activeKitDetailsItemId
    };
};

export default withStore(
    mapStateToProps,
)(Tags);

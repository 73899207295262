const AddNotesIcon = ({ className }) => {
  return (
    <svg className={`svg-regular ${className || ''}`} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <mask id="mask0_315_15851" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <rect width="18" height="18" fill="#171C20" />
      </mask>
      <g mask="url(#mask0_315_15851)">
        <path d="M3.98078 15.375C3.60674 15.375 3.28727 15.2425 3.02237 14.9776C2.75746 14.7127 2.625 14.3932 2.625 14.0192V3.98078C2.625 3.60674 2.75746 3.28727 3.02237 3.02237C3.28727 2.75746 3.60674 2.625 3.98078 2.625H14.0192C14.3932 2.625 14.7127 2.75746 14.9776 3.02237C15.2425 3.28727 15.375 3.60674 15.375 3.98078V8.72449C15.1904 8.64565 15.0055 8.57883 14.8204 8.52403C14.6353 8.46922 14.4452 8.42547 14.25 8.39278V3.98078C14.25 3.92308 14.2259 3.87018 14.1779 3.82209C14.1298 3.77402 14.0769 3.74998 14.0192 3.74998H3.98078C3.92308 3.74998 3.87018 3.77402 3.82209 3.82209C3.77402 3.87018 3.74998 3.92308 3.74998 3.98078V14.0192C3.74998 14.0769 3.77402 14.1298 3.82209 14.1779C3.87018 14.2259 3.92308 14.25 3.98078 14.25H8.37403C8.40191 14.4577 8.44325 14.6541 8.49806 14.8391C8.55286 15.0242 8.61969 15.2029 8.69854 15.375H3.98078ZM3.74998 14.25V3.74998V8.39278V8.33653V14.25ZM5.4375 12.4759H8.45048C8.48316 12.2807 8.53172 12.0906 8.59614 11.9055C8.66057 11.7204 8.73076 11.5356 8.80673 11.351H5.4375V12.4759ZM5.4375 9.56246H10.1928C10.5159 9.28844 10.8606 9.05888 11.2269 8.87378C11.5932 8.68869 11.9855 8.5572 12.4038 8.47931V8.4375H5.4375V9.56246ZM5.4375 6.64901H12.5625V5.52403H5.4375V6.64901ZM13.5 16.9182C12.5635 16.9182 11.7668 16.5899 11.1101 15.9331C10.4534 15.2764 10.125 14.4798 10.125 13.5433C10.125 12.6067 10.4534 11.8101 11.1101 11.1534C11.7668 10.4966 12.5635 10.1683 13.5 10.1683C14.4365 10.1683 15.2331 10.4966 15.8899 11.1534C16.5466 11.8101 16.875 12.6067 16.875 13.5433C16.875 14.4798 16.5466 15.2764 15.8899 15.9331C15.2331 16.5899 14.4365 16.9182 13.5 16.9182ZM13.1683 15.75H13.8317V13.875H15.7067V13.2115H13.8317V11.3365H13.1683V13.2115H11.2933V13.875H13.1683V15.75Z" fill="#2F4455" />
      </g>
    </svg>
  );
};

export default AddNotesIcon;

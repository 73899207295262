import { BaseStore } from '@/Store/BaseStore';

let controller = null;

export const getOrders = (success, payload) => {
    controller = new AbortController();
    const signal = controller.signal;

    return BaseStore.httpClient.get('/orders', {
        params: {
            batchType: payload
        },
        signal
    })
        .then((response) => {
            if (response) {
                success(response.data);
                return Promise.resolve(response.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const cancelRequestOrders = () => {
    if (controller) {
        controller.abort();
    }
};

export const getSerialNumbers = (success, payload) => {
    return BaseStore.httpClient.get('/orders/options/serial-number', {
        params: {
            ...(payload || {})
        }
    })
        .then((response) => {
            if (response) {
                success(response.data);
                return Promise.resolve(response.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const createBatch = (success, payload) => {
    return BaseStore.httpClient.post('/claim-orders/create-batch', {
        ...(payload || {})
    })
        .then((response) => {
            if (response) {
                success(response.data);
                return Promise.resolve(response.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const createBatchAndClaim = async (success, payload) => {
    return BaseStore.httpClient.post('/orders/create-batch-and-claim', {
        ...(payload || {})
    })
        .then((response) => {
            if (response) {
                success(response.data);
                return Promise.resolve(response.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const getOptionPartNumber = async(success,payload) => {
    return BaseStore.httpClient.get(`/orders/options/part-number?companyID=${payload}`)
        .then((response) => {
            if (response) {
                success(response.data);
                return Promise.resolve(response.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const checkDuplicateSerials = async(success, payload) => {
    return BaseStore.httpClient.get('/orders/check-duplicate-serials', {
        params: {
            ...(payload || {})
        }
    })
        .then((response) => {
            if (response) {
                success(response.data);
                return Promise.resolve(response.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

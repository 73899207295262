import { BaseStore } from '@/Store/BaseStore';

export const getKitItems = (success, payload) => {
    return BaseStore.httpClient.get('/kit-items/options/kits', {
        params: {
            companyID: payload
        }
    })
        .then((response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};


export const getKitsInfo = (success, payload) => {
    return BaseStore.httpClient.get('/kit-items/kit-info', {
        params: {
            kitID: payload
        }
    })
        .then((response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const createKitAndClaim = async (payload) => {
    
    return BaseStore.httpClient.post('/orders/create-kit-and-claim', payload)
        .then(async (response) => {
            if (response) {
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const passKit = async (success, payload) => {
    return BaseStore.httpClient.put('/orders/pass-kit', {
        ...(payload || {})
    })
        .then(async (response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const removeKitPrimaryItem = async (success, payload) => {
    return BaseStore.httpClient.delete('/batch/remove-kit-primary-item', {
        data: {
            ...(payload || {})
        }
    })
        .then(async (response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

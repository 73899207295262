import { Button } from "@/Components/Inputs";
import { ArrowRightIcon } from "@/Icons";
import { useEffect, useRef, useState } from "react";

const AddQualityCheckReasonTableNotes = ({
    rowData,
    hoveredRow,
    onOpenTableDialog
}) => {
    const textRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        if (textRef?.current) {
            const { scrollHeight, clientHeight } = textRef.current || {};
            setIsTruncated(scrollHeight > clientHeight);
        }
    }, []);

    const handleButtonClick = () => {
        if (typeof onOpenTableDialog === 'function') {
            onOpenTableDialog(rowData);
        }
    };

    return (
        <>
            <p
                ref={textRef}
                className={`
                    pl-3 text-on-surface line-clamp-2 w-[calc(100%-35px)]
                    ${isTruncated ? 'pr-[10px]' : 'pr-3'}
                `}
            >
                {rowData?.additionalNotes || ''}
            </p>
            <Button
                variant="flat"
                className={`
                    size-[24px] p-0 ml-auto mr-3 border-0 drop-shadow-[0px_2px_6px_rgba(128,_128,_128,_0.25)] hidden
                    ${rowData?.id === hoveredRow?.id ? '!block' : ''}
                `}
                onClick={handleButtonClick}
            >
                <ArrowRightIcon className="rotate-[320deg] w-full h-full text-red" />
            </Button>
        </>
    );
};

export default AddQualityCheckReasonTableNotes;

import { Component } from "react";
import { withRouter } from "react-router-dom";

import { AdminLayout, MainLayout } from "@/Layouts";

class ProjectsPage extends Component {
    constructor(props) {
        super(props)
        this.state = {

        };
    }

    render() {
        return (
            <MainLayout>
                <AdminLayout>
                    Hello
                </AdminLayout>
            </MainLayout>
        );
    }
}

export default withRouter(ProjectsPage);

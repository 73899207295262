import { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; 
import "ag-grid-community/styles/ag-theme-quartz.css"

class Table extends Component {

     render() {
          const {className} = this.props
         return (
               <div style={{ height: 'calc(100vh - 256px)' }} className={`ag-theme-quartz ag-grid-table ${className ?? ""}`}>
                    <AgGridReact {...this.props}/>
               </div>
          )
     }
}
export default Table
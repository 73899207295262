import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { TabContainerButton } from "@/Components/Navigation";
import { Button } from "@/Components/Inputs";
import { RefreshIcon, ChangeCircleIcon } from "@/Icons";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import { AgGridIcon, Table, Tag } from "@/Components/Display";
import {
    CustomColumnHeader,
    ReorderColumnsPopover,
    CustomNoRowsOverlay,
    CustomLoadingOverlay,
    CustomFilterSection
} from "@/Components/Display/Table/Custom/Components";
import { withStore } from "@/Helpers/withStore";
import { requestMyKits } from "@/Store/Actions";
import { getColumnState, setColumnState } from "@/Helpers/TableColumnHelper";

class MyKitsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rowData: [],
            value: 0,
            isClaimItemsDialogOpen: false,
            selectedSerialNumbers: [],
            showAddKitsDialog: false,
            selectedPartNumber: '',
            addItemRowData: [
                {
                    id: 0,
                    checked: false,
                    orderNumber: '',
                    serialNumber: '',
                    boxSerial: ''
                }
            ],
            isRefreshing: false,
            isToastOpen: false,
            isGridLoading: true,
            columnDefs: props.myKitsColumnDefs
        };
    }

    gridOptions = () => {
        return {
            groupDisplayType: 'custom',
            onFilterChanged: (params) => this.handleShowNoOverlay(params),
            getRowHeight: params => params.node.group ? 48 : 40,
            onGridReady: ({ api, columnApi }) => {
                this.props.handleGridApiSetState(api);
                try {
                    var columnState = JSON.parse(localStorage.getItem(this.props.currentTab));
                    if (columnState) {
                        setTimeout(() => {
                            columnApi?.api?.applyColumnState({
                                state: columnState,
                                applyOrder: true,
                            }, 'api');
                        }, 500);
                    };
                } catch (error) {
                    console.log(error);
                }
            },
            onColumnMoved: ({ columnApi }) => {
                setColumnState(columnApi, this.props.currentTab);
            },
            onColumnVisible: ({ columnApi }) => {
                setColumnState(columnApi, this.props.currentTab);
            },
            onColumnResized: ({ columnApi }) => {
                setColumnState(columnApi, this.props.currentTab);
            },
            icons: {
                groupExpanded: () => AgGridIcon('ArrowDropUpIcon', { className: 'text-primary' }),
                groupContracted: () => AgGridIcon('DropDownIcon', { className: 'text-primary' }),
            },
            popupParent: document.querySelector('body'),
            getRowClass: params => {
                if (params.node.rowPinned) {
                    return 'ag-row-pinned';
                }
            },
            postProcessPopup: params => {
                // check callback is for columnFilter
                if (params.type !== 'columnFilter') {
                    return;
                }

                // Calculate the position based on the column header width
                const eventSource = params.eventSource;
                const ePopup = params.ePopup;
                const popupWidth = ePopup.offsetWidth;
                const headerRect = eventSource.getBoundingClientRect();
                const popupLeft = headerRect.right - popupWidth + window.scrollX;

                if (popupLeft > 0) {
                    // Consider header padding
                    ePopup.style.left = `${popupLeft + 12}px`;
                }

                ePopup.style.width = `220px`;
                if (params?.column?.userProvidedColDef?.headerName?.toLowerCase() === 'date') {
                    ePopup.style.maxWidth = '360px';
                    ePopup.style.minWidth = '360px';
                }
            },
            suppressLoadingOverlay: true,
            alwaysMultiSort: true,
            getRowStyle: params => {
                if (params?.node?.group) {
                    return {
                        background: '#E2F3FF',
                        borderTop: '1px solid #77D1FF',
                        borderBottom: '0px'
                    }
                }
                if (params?.node?.childIndex % 2 === 0) {
                    return { background: '#ffffff', border: '0px' };
                }
                return { background: '#EFF4F9', border: '0px' }
            }
        }
    }

    getDefaultColDef() {
        return {
            rowHeight: 50,
            flex: 1,
            filter: true,
            suppressHeaderMenuButton: true,
            menuTabs: [],
            minWidth: 220,
            headerComponent: CustomColumnHeader,
            headerComponentParams: {
                gridRef: this.props.myKitsGridRef
            },
            resizable: false,
            suppressMovable: true,
            cellStyle: params => {
                if (params?.node?.rowPinned) {
                    return { 'border': '0px' };
                }
                if (!params?.node?.group) {
                    return { 'border': '1px solid #EAEEF3' };
                }
                return null;
            }
        };
    };

    handleRefreshClick = async () => {
        if (this.props.myKitsGridApi) {
            this.setState({
                isRefreshing: true,
                isGridLoading: true
            }, async () => {
                await this.fetchMyKits();
                this.setState({
                    isRefreshing: false,
                    isGridLoading: false
                });
            });
        };
    };

    formatToTags = (props) => {
        return <Tag className={props.getValue()} label={props.getValue()} />
    };

    handleRowDoubleClicked = ({ node, data }) => {
        if (node?.footer){
            return;
        } 
        this.props?.history?.push('/kit-details', {
            batchId: data?.batchID,
            itemID: data?.itemID
        });
    };

    fetchMyKits = async () => {
        if (!this.props.userID) {
            this.setState({
                isGridLoading: false
            });
            return;
        }
        try {
             await this.props.requestMyKits(this.props?.userID);
        } catch (error) {
            console.error(error);
        } finally {
            this.setState({
                isGridLoading: false
            });
        };
    };

    handleShowNoOverlay = params => params.api.getDisplayedRowCount() === 0 ? this.props.myKitsGridApi.showNoRowsOverlay() : this.props.myKitsGridApi.hideOverlay();

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps?.myKits !== this.props.myKits) {
            this.setState({
                rowData: this.props.myKits
            });
        }

        if (this.props.myKitsGridApi) {
            if (this.state.isGridLoading) {
                this.props.myKitsGridApi?.showLoadingOverlay();
            } else {
                this.props.myKitsGridApi?.hideOverlay();
            };
        };

        if (prevProps?.userID !== this.props.userID) {
            await this.fetchMyKits();
        };
    };

    async componentDidMount() {
        const getState = getColumnState(this.props.currentTab,this.state.columnDefs);

        if(getState){
            this.setState({ columnDefs: getState });
        }
        
        await this.fetchMyKits();
    };

    renderResetFilterButton = () => {
        const filterRef = this.props.myKitsFilterGridApiRef.current;
        const myKitsGridApi = this.props.myKitsGridApi;
        const filterDateRef = this.props.myKitsFilterDateGridApiRef.current;
        const resetSortModel = this.props.sortModelResetRef.current;
        const reOrderResetRef = this.props.reOrderResetRef.current;

        const handleResetFilters = () => {
            if (filterRef && myKitsGridApi && filterDateRef && resetSortModel && reOrderResetRef) {
                myKitsGridApi.setFilterModel(null);
                myKitsGridApi.resetColumnState();

                const colIds = myKitsGridApi.getColumnDefs().map(item => (item.colId));

                colIds?.forEach((colId) => {
                    filterRef.getFilterInstance(colId, (filterInstance) => {
                        if (filterInstance) {
                            const currentFunctions = filterInstance.params.gridApiRef?.current;
                            if (currentFunctions) {
                                currentFunctions.resetSelectedOptions();
                            }
                        }
                    });
                });
                filterDateRef.getFilterInstance('createDate', (filterInstance) => {
                    if (filterInstance) {
                        const currentFunctions = filterInstance.params.gridApiRef?.current;
                        if (currentFunctions) {
                            currentFunctions.resetDateSelected();
                        }
                    }
                });
                resetSortModel.resetFilterSortModel();
                reOrderResetRef.resetReOrderModel();
            };
        }

        return (
            <Button
                variant="flat"
                className="w-[152px]"
                startIcon={<ChangeCircleIcon className="text-on-surface-variant" />}
                onClick={handleResetFilters}
            >
                Reset Filters
            </Button>
        );
    };


    render() {
        const { isRefreshing, rowData, isGridLoading, columnDefs } = this.state;

        const {
            myKitsGridRef,
            myKitsGridApi,
            myKitsColumnDefs,
            currentTab,
            myKitsFilterGridApiRef,
            sortModelResetRef,
            reOrderResetRef
        } = this.props;

        return (
            <div>
                <div className="flex justify-between py-2 pt-3 mb-2">
                    <TabContainerButton>
                        <ReorderColumnsPopover
                            gridApi={myKitsGridApi}
                            columns={myKitsColumnDefs}
                            currentTab={currentTab}
                            reOrderResetRef={reOrderResetRef}
                            
                        />
                        {this.renderResetFilterButton()}
                    </TabContainerButton>
                    <TabContainerButton>
                        <Button
                            className="w-[122px]"
                            startIcon={<RefreshIcon />}
                            isLoading={isRefreshing}
                            onClick={this.handleRefreshClick}
                        >
                            Refresh
                        </Button>
                    </TabContainerButton>
                </div>
                <CustomFilterSection
                    api={myKitsGridApi}
                    gridApiRef={myKitsFilterGridApiRef}
                    columns={myKitsColumnDefs}
                    sortModelResetRef={sortModelResetRef}
                    currentTab={currentTab}
                />
                <Table
                    className="my-kits-table"
                    reactiveCustomComponents
                    ref={myKitsGridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={this.getDefaultColDef()}
                    suppressMenuHide
                    columnMenu="new"
                    rowGroupPanelSuppressSort
                    gridOptions={this.gridOptions()}
                    suppressLoadingOverlay={false}
                    noRowsOverlayComponent={CustomNoRowsOverlay}
                    loadingOverlayComponent={CustomLoadingOverlay}
                    suppressNoRowsOverlay={isGridLoading}
                    rowSelection="single"
                    onRowDoubleClicked={this.handleRowDoubleClicked}
                    suppressContextMenu
                    grandTotalRow="bottom"
                />
            </div>
        );
    }
};

const mapStateToProps = ({ orders, user, batch }) => {
    return {
        myKitsColumnItems: orders?.myKitsColumnItems,
        userID: user?.user?.userID,
        myKits: batch?.myKits,
    };
};

const mapDispatchToProps = ({ batch }) => {
    return {
        requestMyKits: userID => batch.dispatch(requestMyKits(userID))
    };
};

export default withStore(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(MyKitsPage));



const CheckBoxIcon = ({ className }) => {

     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className}`} viewBox="0 0 24 24" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z" />
          <path d="M8 13L10.0317 15L16 9" stroke="white" stroke-width="2" stroke-linecap="round"/>
        </svg>
        
     )
}

export default CheckBoxIcon










import { useEffect, useMemo, useState } from "react";

import { Stack } from "@/Components/Display";
import { Select, Button, SelectOption, Checkbox, TextArea } from "@/Components/Inputs";
import { Toast } from "@/Components/Display";
import { Dialog } from "@/Components/Feedback";

import AddQualityCheckReasonTable from "../QA/AddQualityCheckReasonTable";
import AddQualityCheckReasonTableDialog from "./AddQualityCheckReasonTableDialog";

import { BatchType } from "@/Helpers/constants";

import { useDispatch, useSelector } from "@/Store/StoreContextProvider";
import { requestCreateQaFailures, requestQaChecklist } from "@/Store/Actions";

const AddQualityCheckReason = () => {
    const batchDetailsToDisplay = useSelector(({ batch }) => batch?.batchDetailsToDisplay);
    const activeDetailsId = useSelector(({ batch }) => batch?.activeDetailsId);
    const activeKitDetailsItemId = useSelector(({ kit }) => kit?.activeKitDetailsItemId);
    const qaChecklist = useSelector(({ qaChecklist }) => qaChecklist?.qaChecklist);
    const loggedUserId = useSelector(({ user }) => user?.user?.userID);

    const qaChecklistDispatch = useDispatch(dispatch => dispatch?.qaChecklist);
    const qaFailureDispatch = useDispatch(dispatch => dispatch?.qaFailure);

    const [reasons, setReasons] = useState([]);
    const [selectedReason, setSelectedReason] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [additionalNotes, setAdditionalNotes] = useState('');
    const [isAddQcReasonToastOpen, setIsAddQcReasonToastOpen] = useState(false);
    const [isRemoveQcReasonToastOpen, setIsRemoveQcReasonToastOpen] = useState(false);
    const [isValidationToastOpen, setIsValidationToastOpen] = useState(false);
    const [isRemoveQcReasonDialogOpen, setIsRemoveQcReasonDialogOpen] = useState(false);
    const [qaFailureToRemove, setQaFailureToRemove] = useState(null);
    const [isQaFailureToRemoveSure, setQaFailureToRemoveSure] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isTableDialogOpen, setIsTableDialogOpen] = useState(false);
    const [qaFailureToOpen, setQaFailureToOpen] = useState(null);

    const activeDetails = useMemo(() => {
        return batchDetailsToDisplay?.find(batch => batch?.batchID?.toString() === activeDetailsId?.toString());
    }, [batchDetailsToDisplay, activeDetailsId]);

    const activeDetailsItems = useMemo(() => {
        if (activeDetails) {
            const items = activeDetails?.batchType === BatchType.BATCH
                ? activeDetails?.items
                : activeDetails?.items?.[activeKitDetailsItemId];
            return items;
        }
        return [];
    }, [activeDetails, activeKitDetailsItemId]);

    useEffect(() => {
        if (!qaChecklist) {
            (async () => {
                await qaChecklistDispatch(requestQaChecklist({
                    tech: 0
                }));
            })();
        }
    }, [qaChecklist, qaChecklistDispatch]);

    useEffect(() => {
        if (Array.isArray(qaChecklist)) {
            setReasons(qaChecklist);
        }
    }, [qaChecklist]);

    const handleAddQcReason = async () => {
        if (selectedItems?.length === 0 || !selectedReason) {
            handleOpenValidationToast();
            return;
        }

        try {
            setIsSubmitting(true);

            const data = selectedItems?.map(selectedItem => {
                const reason = qaChecklist?.find(qa => qa?.id?.toString() === selectedReason?.toString());

                return {
                    orderItemID: selectedItem?.orderItemID,
                    checklistID: selectedReason,
                    additionalNotes: additionalNotes?.trim(),
                    isAddedByAuditor: activeDetails?.step && parseInt(activeDetails?.step) === 2 ? 1 : 0,
                    // Added for state management only
                    reasonCode: reason?.name || '',
                    serial: selectedItem?.serial
                };
            });

            await qaFailureDispatch(requestCreateQaFailures({
                data,
                userID: loggedUserId,
                // Added for state management only
                batchID: activeDetailsId,
                kitItemID: activeDetails?.batchType === BatchType.KIT ? activeKitDetailsItemId : 0
            }));

            handleOpenAddQcReasonToast();
            setSelectedItems([]);
            setAdditionalNotes('');
            setSelectedReason(0);
        } catch (error) {
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleRemoveQcReasonSuccess = () => {
        setQaFailureToRemoveSure(false);
        setQaFailureToRemove(null);
        handleCloseRemoveQcReasonDialog();
        handleOpenRemoveQcReasonToast();
    };

    const handleReasonOnChange = event => {
        const value = event?.target?.value;
        setSelectedReason(value);
    };

    const handleAdditionalNotesOnChange = event => {
        const value = event?.target?.value || '';
        setAdditionalNotes(value);
    };

    const handleSerialNumberSelect = (isSelected, item) => {
        const isItemAlreadySelected = selectedItems?.find(selectedItem => selectedItem?.orderItemID === item?.orderItemID);

        if (isSelected) {
            if (isItemAlreadySelected) {
                return;
            }

            setSelectedItems([...selectedItems, item]);
            return;
        }

        setSelectedItems(selectedItems?.filter(selectedItem => selectedItem?.orderItemID !== item?.orderItemID));
    };

    const handleOpenAddQcReasonToast = () => {
        setIsAddQcReasonToastOpen(true);
    };

    const handleCloseAddQcReasonToast = () => {
        setIsAddQcReasonToastOpen(false);
    };

    const handleOpenRemoveQcReasonToast = () => {
        setIsRemoveQcReasonToastOpen(true);
    };

    const handleCloseRemoveQcReasonToast = () => {
        setIsRemoveQcReasonToastOpen(false);
    };

    const handleOpenRemoveQcReasonDialog = qaFailureToDelete => {
        if (qaFailureToDelete) {
            setQaFailureToRemove(qaFailureToDelete);
            setIsRemoveQcReasonDialogOpen(true);
        }
    };

    const handleCloseRemoveQcReasonDialog = () => {
        setIsRemoveQcReasonDialogOpen(false);
    };

    const handleOpenValidationToast = () => {
        setIsValidationToastOpen(true);
    };

    const handleCloseValidationToast = () => {
        setIsValidationToastOpen(false);
    };

    const handleRemoving = isRemoving => {
        if (typeof isRemoving === 'boolean') {
            setIsRemoving(isRemoving);
        }
    };

    const handleRemoveQcReason = () => {
        setQaFailureToRemoveSure(true);
    };

    const handleOpenTableDialog = qaFailureToOpen => {
        if (qaFailureToOpen) {
            setQaFailureToOpen(qaFailureToOpen);
            setIsTableDialogOpen(true);
        }
    };

    const handleCloseTableDialog = () => {
        setIsTableDialogOpen(false);
    };

    const renderRemoveQcReasonDialog = () => {
        return (
            <Dialog
                isOpen={isRemoveQcReasonDialogOpen}
                className="pt-[32px] pb-[24px] px-[48px] w-[516px]"
                onClose={handleCloseRemoveQcReasonDialog}
            >
                <Dialog.Title className="mb-[12px]">
                    Remove Quality Check Reason
                </Dialog.Title>
                <Dialog.Content>
                    <p className="text-[14px] leading-[20px] tracking-[0.25px]">
                        Are you sure you want to remove QC reason: <span className="font-bold tracking-[0.1px]">{qaFailureToRemove?.reasonCode || ''} ({qaFailureToRemove?.serial || ''})</span>?
                    </p>
                </Dialog.Content>
                <Dialog.Actions className="justify-between">
                    <Button variant="flat" className="w-[87px]" onClick={handleCloseRemoveQcReasonDialog} disabled={isRemoving}>Cancel</Button>
                    <Button className="w-[116px]" onClick={handleRemoveQcReason} disabled={isRemoving} isLoading={isRemoving}>Remove</Button>
                </Dialog.Actions>
            </Dialog>
        );
    };

    const renderTableDialog = () => {
        return (
            <AddQualityCheckReasonTableDialog
                rowData={qaFailureToOpen}
                isOpen={isTableDialogOpen}
                onClose={handleCloseTableDialog}
            />
        );
    };

    return (
        <>
            <div className="border w-full mt-6 rounded-[5px] shadow-custom">
                <div className="w-full py-[16px] px-3 rounded-[5px] rounded-b-none bg-primary-fixed h-[56px] flex items-center">
                    <p className=" ont-roboto text-inverse-surface text-xs leading-4 tracking-[0.5px] font-semibold">Add Quality Check Reason</p>
                </div>
                <div className="flex justify-evenly w-full p-6 pr-[56px] space-x-12 font-roboto">
                    <div className="overflow-hidden">
                        <Stack direction="column" alignItems="flex-start" justifyContent="flex-start" className="h-[200px] custom-scroll overflow-hidden grow min-w-[192px] overflow-y-auto gap-[8px]">
                            {Array.isArray(activeDetailsItems) && activeDetailsItems
                                ?.map(({ orderItemID, serial }) => ({ orderItemID, serial }))
                                ?.map(item => {
                                    const isChecked = selectedItems.find(selectedItem => selectedItem?.orderItemID === item?.orderItemID) !== undefined;

                                    return (
                                        <Checkbox
                                            key={item?.orderItemID}
                                            checked={isChecked}
                                            onChange={event => handleSerialNumberSelect(event.target?.checked ?? false, item)}
                                            className="flex space-x-3  w-full justify-start"
                                            label={item?.serial}
                                        />
                                    );
                                })}
                        </Stack>
                    </div>
                    <div className="grow max-w-[383px]">
                        <p className="text-sm font-normal mb-[9px] leading-5 tracking-[0.25x] text-inverse-surface">Reason</p>
                        <Select
                            className="quality-check-reason-select"
                            value={selectedReason}
                            onChange={handleReasonOnChange}
                            searchable
                        >
                            {reasons.map(({ id, name }) => {
                                return (
                                    <SelectOption key={name} value={id} className="font-normal pt-[14px] h-[48px] text-[13.329px] leading-[17.77px] tracking-[0.25px] !px-[24px]">
                                        <p>{name}</p>
                                    </SelectOption>
                                );
                            })}
                        </Select>
                    </div>
                    <div className="grow">
                        <p className="text-sm font-normal mb-[9px] leading-5 tracking-[0.25x] text-inverse-surface">Additional Notes</p>
                        <TextArea
                            value={additionalNotes}
                            onChange={handleAdditionalNotesOnChange}
                            className="w-full text-xs mb-6 mykits-textarea-notes"
                            resize={false}
                            rows={5}
                        />
                        <div className="flex justify-end">
                            <Button
                                isLoading={isSubmitting}
                                disabled={isSubmitting}
                                onClick={handleAddQcReason}
                            >
                                Add QC Reason
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <AddQualityCheckReasonTable
                onOpenRemoveDialog={handleOpenRemoveQcReasonDialog}
                onRemoveSuccess={handleRemoveQcReasonSuccess}
                onRemoving={handleRemoving}
                onOpenTableDialog={handleOpenTableDialog}
                qaFailureIdToRemove={qaFailureToRemove?.id}
                isQaFailureToRemoveSure={isQaFailureToRemoveSure}
            />
            <Toast
                message="Quality Check Reason added."
                open={isAddQcReasonToastOpen}
                onClose={handleCloseAddQcReasonToast}
            />
            <Toast
                message="Quality Check Reason removed."
                open={isRemoveQcReasonToastOpen}
                onClose={handleCloseRemoveQcReasonToast}
            />
            <Toast
                status="error"
                message="Serial Number and Reason are required fields."
                open={isValidationToastOpen}
                onClose={handleCloseValidationToast}
            />
            {isRemoveQcReasonDialogOpen && renderRemoveQcReasonDialog()}
            {isTableDialogOpen && renderTableDialog()}
        </>
    );
};

export default AddQualityCheckReason;

const ArrowBackIcon = ({ className }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className || ''}`}>
      <mask id="mask0_6342_67886" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#171C20" />
      </mask>
      <g mask="url(#mask0_6342_67886)">
        <mask id="mask1_6342_67886" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="-1" y="0" width="24" height="24">
          <rect x="-1" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask1_6342_67886)">
          <path d="M9.09025 12.2424L16.623 19.7752C16.8217 19.9739 16.9185 20.2078 16.9135 20.4769C16.9083 20.7463 16.8064 20.9803 16.6078 21.1789C16.4089 21.3776 16.1749 21.4769 15.9058 21.4769C15.6366 21.4769 15.4026 21.3776 15.2038 21.1789L7.53275 13.5232C7.35192 13.3424 7.21792 13.1398 7.13075 12.9154C7.04358 12.6911 7 12.4668 7 12.2424C7 12.0181 7.04358 11.7938 7.13075 11.5694C7.21792 11.3451 7.35192 11.1425 7.53275 10.9617L15.2038 3.29045C15.4026 3.09178 15.6392 2.99503 15.9135 3.0002C16.1878 3.00536 16.4243 3.10728 16.623 3.30595C16.8217 3.50461 16.921 3.73861 16.921 4.00795C16.921 4.27711 16.8217 4.51103 16.623 4.7097L9.09025 12.2424Z" fill="#2F4455" />
        </g>
      </g>
    </svg>

  );
};

export default ArrowBackIcon;

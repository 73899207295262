const StagingSuccessIcon = ({ className }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className || ''}`}>
      <rect x="1.5" y="1.5" width="15" height="15" rx="7.5" fill="#C2E8FF" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2503 6.19077C12.5591 5.91476 13.0332 5.9414 13.3093 6.25026C13.564 6.53536 13.5609 6.96128 13.316 7.2422L13.2498 7.30925L7.71953 12.2512L5.50613 10.3144C5.1944 10.0417 5.16282 9.56786 5.43558 9.25613C5.68736 8.96838 6.11046 8.91933 6.41924 9.12802L6.49389 9.18558L7.70851 10.2488L12.2503 6.19077Z" fill="#006688" />
    </svg>
  );
};

export default StagingSuccessIcon;

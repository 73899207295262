
import { Dialog } from "@/Components/Feedback";
import { Button } from "@/Components/Inputs";

const BatchRequiresAuditDialog = ({
     isOpen,
     onOk
}) => {
     const handleOk = () => {
          if (typeof onOk === 'function') {
               onOk();
          }
     };

     return (
          <Dialog
               isOpen={isOpen}
               className="pb-[24px]"
          >
               <Dialog.Title className="flex font-normal text-[32px] leading-[40px] mb-[13px] text-on-surface">
                    Batch Requires Audit
               </Dialog.Title>
               <Dialog.Content className="space-y-6 !mb-[24px]">
                    <p className="text-base leading-[24px] tracking-[0.5px] font-normal text-on-surface">
                         Device Selected for Random Audit. Take unit to management immediately.
                    </p>
               </Dialog.Content>
               <Dialog.Actions className="justify-end">
                    <Button variant="fixed-dim" onClick={handleOk} className="uppercase">Ok</Button>
               </Dialog.Actions>
          </Dialog>
     );
};

export default BatchRequiresAuditDialog;

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
        redirectUri: `${window.location.origin}/callback`,
        authority: process.env.REACT_APP_MSAL_AUTHORITY
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true
    }
};

export const msalScopes = {
    scopes: [ "User.Read" ],
    prompt: 'select_account'
};

import { Dialog } from "@/Components/Feedback";
import { Button } from "@/Components/Inputs";
import { useMemo } from "react";

const ErrorScanningDevicesDialog = ({
    isOpen,
    onClose,
    scannedDevices
}) => {
    const handleClose = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    const unableToScanDevices = useMemo(() => {
        if (Array.isArray(scannedDevices?.unableToScanDevices)) {
            return scannedDevices?.unableToScanDevices;
        }
        return [];
    }, [scannedDevices?.unableToScanDevices]);

    const notInBatchDevices = useMemo(() => {
        if (Array.isArray(scannedDevices?.notInBatchDevices)) {
            return scannedDevices?.notInBatchDevices;
        }
        return [];
    }, [scannedDevices?.notInBatchDevices]);

    return (
        <Dialog
            isOpen={isOpen}
            onClose={handleClose}
        >
            <Dialog.Title className="flex font-normal text-[32px] leading-[40px] mb-[24px] text-on-surface">
                Error Scanning Devices
            </Dialog.Title>
            <Dialog.Content className="space-y-6">
                {unableToScanDevices?.length > 0 && (
                    <>
                        <p className="text-base leading-[24px] tracking-[0.5px] font-normal text-on-surface">
                            The following devices could <span className="font-bold">not</span> be scanned:</p>
                        <div className=" py-5 px-7 server-error-dialog-custom-scroll overflow-y-auto border-[1px] h-[122.5px] border-[#A2AEB6] rounded-[5px] min-h-[122.5px] whitespace-pre-wrap">
                            <p className="text-base tracking-[0.4px] leading-[24px] text-on-surface">
                                {unableToScanDevices.map((serialNumber, idx) => {
                                    return <p key={idx}>Serial: {serialNumber}</p>;
                                })}
                            </p>
                        </div>
                    </>
                )}
                {notInBatchDevices?.length > 0 && (
                    <>
                        <p className="text-base leading-[24px] tracking-[0.5px] font-normal text-on-surface">The following devices were scanned but are not in your batch, they should <br /> be unplugged:</p>
                        <div className="py-5 px-7 server-error-dialog-custom-scroll overflow-y-auto border-[1px] h-[179.268px] border-[#A2AEB6] rounded-[5px] min-h-[179.268px] whitespace-pre-wrap">
                            <p className="text-base tracking-[0.4px] leading-[24px] text-on-surface ">
                                {notInBatchDevices.map((serialNumber, idx) => {
                                    return <p key={idx}>Serial: {serialNumber}</p>;
                                })}
                            </p>
                        </div>
                    </>
                )}
            </Dialog.Content>
            <Dialog.Actions className="justify-end">
                <Button onClick={handleClose} className="uppercase">ok</Button>
            </Dialog.Actions>
        </Dialog>
    );
};

export default ErrorScanningDevicesDialog;

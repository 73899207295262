import { useCallback, useEffect, useRef } from "react";

import { receiveDeviceServiceResponse, receiveDeviceServiceVersion, receiveWebSocket } from "@/Store/Actions";
import { useDispatch } from "@/Store/StoreContextProvider";
import { DeviceAction, DeviceService } from "@/Helpers/constants";

const withDeviceService = Component => props => {
    const deviceDispatch = useDispatch(dispatch => dispatch?.device);
    const isPolling = useRef(false);
    const reconnectTimeout = useRef(null);
    const pollingCount = useRef(0);

    const resetPollingCount = useCallback(() => {
        pollingCount.current = 0;
    }, []);

    const initWsDeviceService = useCallback((isReconnecting) => {
        isPolling.current = true;

        return new Promise((resolve, reject) => {
            if (pollingCount.current >= DeviceService.RECONNECT_MAX_ATTEMPTS) {
                console.warn('Maximum reconnect attempts reached');
                isPolling.current = false;
                resolve({ status: 'max_attempts_reached' });
                return;
            }

            const webSocket = new WebSocket(`ws://localhost:${DeviceService.PORT}`);

            const onOpen = (event) => {
                console.debug('Connected to Device Service', event);
                deviceDispatch(receiveWebSocket(webSocket));
                isPolling.current = false;
                resetPollingCount();
                resolve({ status: 'connected', event });
            };

            const onClose = (event) => {
                console.warn('Connection with Device Service closed', event);
                deviceDispatch(receiveWebSocket(null));

                if (isPolling.current && isReconnecting) {
                    pollingCount.current += 1;
                    console.debug(`Reconnecting to Device Service attempt ${pollingCount?.current} of ${DeviceService.RECONNECT_MAX_ATTEMPTS}`);
                    reconnectTimeout.current = setTimeout(() => {
                        initWsDeviceService(isReconnecting)
                            .then(resolve)
                            .catch(reject);
                    }, DeviceService.RECONNECT_TIMEOUT);
                } else {
                    resolve({ status: 'closed', event });
                }
            };

            const onMessage = (event) => {
                const message = JSON.parse(event?.data || "{}");
                console.debug('Message returned from Device Service', message);
                if (message?.success && message?.result && message?.action === DeviceAction.GET_VERSION) {
                    deviceDispatch(receiveDeviceServiceVersion(message?.result?.version));
                } else {
                    deviceDispatch(receiveDeviceServiceResponse(message));
                }
            };

            webSocket.addEventListener('open', onOpen);
            webSocket.addEventListener('close', onClose);
            webSocket.addEventListener('message', onMessage);

            return () => {
                webSocket.removeEventListener('open', onOpen);
                webSocket.removeEventListener('close', onClose);
                webSocket.removeEventListener('message', onMessage);
                webSocket.close();
            };
        });
    }, [deviceDispatch, resetPollingCount]);

    useEffect(() => {
        return () => {
            clearTimeout(reconnectTimeout.current);
            isPolling.current = false;
        };
    }, []);

    return (
        <Component 
            {...props}
            initWsDeviceService={initWsDeviceService} 
            resetPollingCount={resetPollingCount}
        />
    );
};

export default withDeviceService;

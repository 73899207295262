import { Stack } from "@/Components/Display";
import { ListItem } from "@/Components/DataDisplay";

const List = ({
    items,
    onItemRemove,
    className
}) => {
    const handleItemRemove = item => {
        if (onItemRemove && typeof onItemRemove === 'function') {
            onItemRemove(item);
        }
    };

    return (
        <Stack direction="column" justifyContent="flex-start" className={`list ${className || ''}`}>
            {Array.isArray(items) && items.map(item => {
                return <ListItem item={item} onItemRemove={handleItemRemove} />;
            })}
        </Stack>
    );
};

export default List;



const SpreadSheetIcon = ({ className }) => {

     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className}`} viewBox="0 0 32 32" fill="none">
          <rect width="32" height="32" rx="5.33333" fill="white"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M3.84 0C1.71923 0 0 1.71923 0 3.84V28.16C0 30.2808 1.71923 32 3.84 32H28.16C30.2808 32 32 30.2808 32 28.16V3.84C32 1.71923 30.2808 0 28.16 0H3.84ZM20.3616 22.2293C20.5611 22.1589 20.7957 22.0063 21.0656 21.7717C21.4645 21.4314 21.6581 21.1263 21.6464 20.8565C21.6464 20.5866 21.4821 20.2698 21.1536 19.9061L17.7669 15.9513L21.1536 12.0037C21.4821 11.6517 21.6464 11.3349 21.6464 11.0533C21.6581 10.7717 21.4645 10.4607 21.0656 10.1205C20.8075 9.87406 20.5787 9.71565 20.3792 9.64525C20.1797 9.57485 19.9861 9.58659 19.7984 9.68046C19.6107 9.77433 19.4053 9.95619 19.1824 10.2261L16.0232 13.9151L12.864 10.2261C12.6411 9.95619 12.4357 9.77433 12.248 9.68046C12.0603 9.58659 11.8667 9.57485 11.6672 9.64525C11.4677 9.71565 11.2389 9.87406 10.9808 10.1205C10.5936 10.4607 10.4 10.7717 10.4 11.0533C10.4 11.3349 10.5643 11.6517 10.8928 12.0037L14.2795 15.9513L10.8928 19.9061C10.5643 20.2698 10.4 20.5866 10.4 20.8565C10.4 21.1263 10.5936 21.4314 10.9808 21.7717C11.2507 22.0063 11.4853 22.1589 11.6848 22.2293C11.8843 22.2997 12.0779 22.2879 12.2656 22.1941C12.4533 22.1002 12.6528 21.9242 12.864 21.6661L16.0232 17.9837L19.1824 21.6661C19.4053 21.9242 19.6048 22.1002 19.7808 22.1941C19.9685 22.2879 20.1621 22.2997 20.3616 22.2293Z"/>
        </svg>
     )
}

export default SpreadSheetIcon
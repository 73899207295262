import { Component } from "react";
import { withRouter } from "react-router-dom";
import { ReportIcon, SupportingDocumentsIcon, TagsIcon } from "@/Icons";
import SidebarItems from "./components/SidebarItem";
import SidebarContainer from "./components/SidebarContainer";
import { Stack } from "@/Components/Display";
import { SideBarItem } from "@/Helpers/constants";
import { withHandleClickOutside } from "@/HOC";
import { withStore } from "@/Helpers/withStore";
import { receiveProblemTemplate, toggleSidebar } from "@/Store/Actions";

class Sidebar extends Component {
     constructor(props) {
          super(props);
          this.state = {
               activeSideBarItem: ''
          };
     }

     handleToggleSidebar = sidebarItem => {
          if (this.props?.setIsOpen && typeof this.props?.setIsOpen === 'function' && this.props?.isOpen === false) {
               this.props?.setIsOpen(true);
          }

          this.setState({
               activeSideBarItem: sidebarItem
          });
     }

     componentDidUpdate(prevProps) {
          if (this.props?.isSidebarOpen !== prevProps?.isSidebarOpen) {
               this.props?.setIsOpen(this.props?.isSidebarOpen);

               if (this.props?.isSidebarOpen === true) {
                    this.setState({
                         activeSideBarItem: SideBarItem.REPORT_A_PROBLEM
                    });
               }
          }

          if (this.props?.isOpen !== prevProps?.isOpen && this.props?.isOpen === false) {
               this.props?.toggleSidebar(false);
               this.props?.receiveProblemTemplate('');
          }
     }

     render() {
          const { handleShowToast, isOpen, refNode } = this.props;

          return (
               <div className=" relative w-10 flex-none h-[100dvh] bg-on-primary-fixed-variant" ref={refNode}>
                    <Stack direction="column" className="gap-[16px] px-[6px] py-[16px]">
                         {['kit-details', 'batch-details', 'internal-notes'].includes(this.props.location?.pathname?.replace('/', ''))  && (
                              <>
                                   <SidebarItems
                                        toggleSideBar={() => this.handleToggleSidebar(SideBarItem.SUPPORTING_DOCUMENTS)}
                                        icon={<SupportingDocumentsIcon className={`${isOpen && this.state.activeSideBarItem === SideBarItem.SUPPORTING_DOCUMENTS ? 'text-primary bg-white' : 'text-white'} hover:text-primary hover:bg-white`} />}
                                        tooltipContent="Supporting documents"
                                        isSidebarOpen={isOpen}
                                   />
                                   <SidebarItems
                                        toggleSideBar={() => this.handleToggleSidebar(SideBarItem.TAGS)}
                                        icon={<TagsIcon className={`${isOpen && this.state.activeSideBarItem === SideBarItem.TAGS ? 'text-primary bg-white' : 'text-white'} hover:text-primary hover:bg-white`} />}
                                        tooltipContent="Tags"
                                        isSidebarOpen={isOpen}
                                   />
                              </>
                         )}
                         <SidebarItems
                              toggleSideBar={() => this.handleToggleSidebar(SideBarItem.REPORT_A_PROBLEM)}
                              icon={<ReportIcon className={`${isOpen && this.state.activeSideBarItem === SideBarItem.REPORT_A_PROBLEM ? 'text-primary bg-white' : 'text-white'} hover:text-primary rounded-sm hover:bg-white`} />}
                              tooltipContent="Report a problem"
                              isSidebarOpen={isOpen}
                         />
                    </Stack>
                    <SidebarContainer
                         handleShowToast={handleShowToast}
                         onShowSideBar={isOpen}
                         activeSideBarItem={this.state.activeSideBarItem}
                    />
               </div>
          );
     }
}

const mapStateToProps = ({ user }) => {
     return {
          isSidebarOpen: user?.isSidebarOpen
     };
};

const mapDispatchToProps = ({ user }) => {
     return {
          toggleSidebar: isOpen => user.dispatch(toggleSidebar(isOpen)),
          receiveProblemTemplate: template => user.dispatch(receiveProblemTemplate(template))
     };
};


export default withStore(
     mapStateToProps,
     mapDispatchToProps
)(withHandleClickOutside(withRouter(Sidebar)));

const AdUnitsIcon = ({ className }) => {
  return (
    <svg className={`svg-regular ${className || ''}`} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <mask id="mask0_225_4436" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <rect width="18" height="18" fill="#171C20" />
      </mask>
      <g mask="url(#mask0_225_4436)">
        <path d="M6.1875 6.37496V5.24998H11.8125V6.37496H6.1875ZM5.48077 16.875C5.10192 16.875 4.78125 16.7437 4.51875 16.4812C4.25625 16.2187 4.125 15.898 4.125 15.5192V2.48078C4.125 2.10193 4.25625 1.78125 4.51875 1.51875C4.78125 1.25625 5.10192 1.125 5.48077 1.125H12.5192C12.898 1.125 13.2187 1.25625 13.4812 1.51875C13.7437 1.78125 13.875 2.10193 13.875 2.48078V15.5192C13.875 15.898 13.7437 16.2187 13.4812 16.4812C13.2187 16.7437 12.898 16.875 12.5192 16.875H5.48077ZM5.24998 14.8125V15.5192C5.24998 15.5769 5.27402 15.6298 5.32209 15.6779C5.37018 15.7259 5.42307 15.75 5.48077 15.75H12.5192C12.5769 15.75 12.6298 15.7259 12.6779 15.6779C12.7259 15.6298 12.75 15.5769 12.75 15.5192V14.8125H5.24998ZM5.24998 13.6875H12.75V4.31246H5.24998V13.6875ZM5.24998 3.1875H12.75V2.48078C12.75 2.42308 12.7259 2.37018 12.6779 2.32209C12.6298 2.27402 12.5769 2.24998 12.5192 2.24998H5.48077C5.42307 2.24998 5.37018 2.27402 5.32209 2.32209C5.27402 2.37018 5.24998 2.42308 5.24998 2.48078V3.1875Z" fill="#2F4455" />
      </g>
    </svg>
  );
};

export default AdUnitsIcon;

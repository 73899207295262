import { BaseStore } from '@/Store/BaseStore';

export const getCompanies = (success) => {

    return BaseStore.httpClient.get(`/companies`)
        .then((response) => {
            if (response) {
                success(response.data);
                return Promise.resolve(response.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};
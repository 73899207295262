export const BatchStoreActionConstants = {
    REQUEST_MY_BATCHES: 'REQUEST_MY_BATCHES',
    RECEIVE_MY_BATCHES: 'RECEIVE_MY_BATCHES',
    REQUEST_BATCH_DETAILS: 'REQUEST_BATCH_DETAILS',
    RECEIVE_BATCH_DETAILS: 'RECEIVE_BATCH_DETAILS',
    RECEIVE_ACTIVE_DETAILS_ID: 'RECEIVE_ACTIVE_DETAILS_ID',
    REQUEST_START_TIMER: 'REQUEST_START_TIMER',
    REQUEST_PAUSE_TIMER: 'REQUEST_PAUSE_TIMER',
    RECEIVE_PAUSED_TIMERS: 'RECEIVE_PAUSED_TIMERS',
    REQUEST_MY_KITS: 'REQUEST_MY_KITS',
    RECEIVE_MY_KITS: 'RECEIVE_MY_KITS',
    REQUEST_PAUSE_ALL_TIMERS: 'REQUEST_PAUSE_ALL_TIMERS',
    REQUEST_COMPLETE_BATCH: 'REQUEST_COMPLETE_BATCH',
    REQUEST_REMOVE_FROM_BATCH: 'REQUEST_REMOVE_FROM_BATCH',
    RESET_STATE:'RESET_STATE'
};

export const requestMyBatches = userID => ({
    type: BatchStoreActionConstants.REQUEST_MY_BATCHES,
    payload: userID
});

export const receiveMyBatches = myBatches => ({
    type: BatchStoreActionConstants.RECEIVE_MY_BATCHES,
    payload: myBatches
});

export const requestBatchDetails = batchId => ({
    type: BatchStoreActionConstants.REQUEST_BATCH_DETAILS,
    payload: batchId
});

export const receiveBatchDetails = batchDetailsToDisplay => ({
    type: BatchStoreActionConstants.RECEIVE_BATCH_DETAILS,
    payload: batchDetailsToDisplay
});

export const receiveActiveDetailsId = detailsId => ({
    type: BatchStoreActionConstants.RECEIVE_ACTIVE_DETAILS_ID,
    payload: detailsId
});

export const requestStartTimer = requestBody => ({
    type: BatchStoreActionConstants.REQUEST_START_TIMER,
    payload: requestBody
});

export const requestPauseTimer = requestBody => ({
    type: BatchStoreActionConstants.REQUEST_PAUSE_TIMER,
    payload: requestBody
});

export const receivePausedTimers = pausedTimers  => ({
    type: BatchStoreActionConstants.RECEIVE_PAUSED_TIMERS,
    payload: pausedTimers
});

export const requestMyKits = userID => ({
    type: BatchStoreActionConstants.REQUEST_MY_KITS,
    payload: userID
});

export const receiveMyKits = myKits => ({
    type: BatchStoreActionConstants.RECEIVE_MY_KITS,
    payload: myKits
});

export const requestPauseAllTimers = requestBody => ({
    type: BatchStoreActionConstants.REQUEST_PAUSE_ALL_TIMERS,
    payload: requestBody
});

export const requestCompleteBatch = requestBody => ({
    type: BatchStoreActionConstants.REQUEST_COMPLETE_BATCH,
    payload: requestBody
});

export const requestRemoveFromBatch = requestBody => ({
    type: BatchStoreActionConstants.REQUEST_REMOVE_FROM_BATCH,
    payload: requestBody
});

export const resetBatchState = () => ({
    type: BatchStoreActionConstants.RESET_STATE
});

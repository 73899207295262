import { Tooltip } from "@/Components/DataDisplay";

const SideBarItems = ({
     toggleSideBar,
     className,
     icon,
     tooltipContent,
     isSidebarOpen
}) => {
     const renderButton = props => {
          return (
               <button {...(props || {})} onClick={toggleSideBar} className={`${className || ''} size-[24px]`}>
                    {icon}
               </button>
          );
     };

     if (isSidebarOpen) {
          return renderButton();
     }

     return (
          <Tooltip
               trigger={renderButton}
               content={tooltipContent}
               direction="left"
               className="w-[157px]"
               offset={0}
          />
     );
};

export default SideBarItems;
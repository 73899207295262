export const CompaniesStoreActionConstants = {
    REQUEST_COMPANIES: 'REQUEST_COMPANIES',
    RECEIVE_COMPANIES: 'RECEIVE_COMPANIES'
};

export const requestCompanies = () => ({
    type: CompaniesStoreActionConstants.REQUEST_COMPANIES,
});

export const receiveCompanies = companies => ({
    type: CompaniesStoreActionConstants.RECEIVE_COMPANIES,
    payload: companies
});


import CheckCircleIcon from '@/Icons/CheckCircleIcon';
import UnCheckCircleIcon from '@/Icons/UnCheckCircleIcon';
import React from 'react';
import { useContext, createContext } from 'react';

const RadioContext = createContext();

export const RadioButton = ({
    children,
    className,
    ...props
}) => {
    const { value, onChange } = useContext(RadioContext);

    return (
        <label className={`py-2 hover:bg-[#C2E8FF]  w-full gap-[2px] rounded-md flex items-center cursor-pointer text-sm font-normal leading-5 tracking-[0.25px] text-on-surface-variant ${className || ''}`}>
            {props.value === value ? <CheckCircleIcon className="text-secondary-container" /> : <UnCheckCircleIcon className=" text-outline-variant" />}
            <input type='radio' className='hidden' checked={value === props.value} onChange={onChange} {...props} />
            {children}
        </label>
    );
};

export const RadioGroup = ({
    children,
    value,
    onChange
}) => {
    return (
        <RadioContext.Provider value={{ onChange, value }} >
            {children}
        </RadioContext.Provider>
    );
};

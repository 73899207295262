export const ProductStoreActionConstants = {
     REQUEST_PART_NUMBER: 'REQUEST_PART_NUMBER',
     RECEIVE_PART_NUMBER: 'RECEIVE_PART_NUMBER'
 };
 
 export const requestPartNumber = () => ({
     type: ProductStoreActionConstants.REQUEST_PART_NUMBER,
 });
 
 export const receivePartNumber = partNumber => ({
     type: ProductStoreActionConstants.RECEIVE_PART_NUMBER,
     payload: partNumber
 });
 
import PropTypes from 'prop-types';
import React from 'react';

import { CloseIcon } from "@/Icons";
import { OrderItemStatus } from "@/Helpers/constants";

export const EnumTags = Object.freeze({
    ready_for_audit: OrderItemStatus.READY_FOR_AUDIT,
    ready_for_staging: OrderItemStatus.READY_FOR_STAGING,
    in_process_tech: OrderItemStatus.IN_PROCESS_TECH,
    kit_assignment: OrderItemStatus.KIT_ASSIGNMENT,
    ready_for_qa: OrderItemStatus.READY_FOR_QA,
    in_process_qa: OrderItemStatus.IN_PROCESS_QA,
    inactive: OrderItemStatus.INACTIVE,
    failed: OrderItemStatus.FAILED,
    in_process_audit: OrderItemStatus.IN_PROCESS_AUDIT
});

const findKeyByValue = (enumObject, value) => {
    const entry = Object.entries(enumObject).find(([_, val]) => val === value);
    return entry ? entry[0] : "";
};

export const Tag = ({
    className,
    label,
    hasCloseButton,
    onRemove,
    ...props
}) => {
    const tag = findKeyByValue(EnumTags, label);

    const handleRemove = event => {
        event?.stopPropagation();

        if (typeof onRemove === 'function') {
            onRemove();
        }
    };

    return (
        <div className={`tag ${tag} ${className ?? ""}`} {...props}>
            {label}
            {hasCloseButton && (
                <button onClick={handleRemove}>
                    <CloseIcon className="tag-close-button-icon" />
                    {hasCloseButton}
                </button>
            )}
        </div>
    );
};

Tag.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    hasCloseButton: PropTypes.bool,
    onRemove: PropTypes.func,
};

Tag.defaultProps = {
    className: "",
    hasCloseButton: false,
    onRemove: () => {},
};


import AboutIcon from "@/Icons/InfoIcon";
import OptionItem from "../option/OptionItem";
import { LogoutIcon } from "@/Icons";
import { RadioGroup } from "@/Components/Inputs/Radio/RadioButton";
import { RadioButton } from "@/Components/Inputs/Radio/RadioButton";
import { useResetStore } from "@/Store/StoreContextProvider";
import { Tooltip } from "@/Components/DataDisplay";
import { UserType } from "@/Helpers/constants";
import { useLocation, useHistory } from 'react-router-dom';
import { AppStorage } from "@/Helpers/storage";

const ProfileContent = ({ user, handleShowAboutDialog }) => {
	const history = useHistory();
	const currentPath = useLocation();

	const currentUserType = currentPath?.pathname.replace('/', '') === "projects" ? UserType.ADMIN : UserType.TECH;

	const resetAllContexts = useResetStore();

	const handleLogOut = () => {
		resetAllContexts();
		AppStorage.Local.clear();
		history?.push(`/login`);
	};

	const userFullName = user?.name;
	const userEmail = user?.username;

	const handleChangeUserType = event => {
		const value = event?.target?.value;

		if (value === UserType.ADMIN) {
			history.push('/projects');
			return;
		}

		history.push('/open-items');
	};

	const renderEmail = (props) => (
		<p {...(props || {})}
			className="cursor-pointer truncate text-xs leading-5 font-sans font-normal text-outline tracking-[0.458px]">
			{userEmail}
		</p>
	);

	return (
		<div className="absolute right-0 w-[296px] max-w-[296px] p-6 bg-[#FFF] shadow-md h-fit rounded top-0 flex flex-col gap-[16px]">
			<div className="flex flex-col gap-[8px]">
				<h1 className="uppercase text-xs leading-5 font-medium text-outline tracking-[0.1px]">Profile</h1>
				<div className="flex flex-col gap-[4px]">
					<p className="text-sm leading-5 font-normal text-on-surface tracking-[0.25px] truncate">{userFullName}</p>
					<Tooltip
						trigger={props => {
							return renderEmail(props);
						}}
						content={`${userEmail}`}
						direction="bottom"
						offset={3}
					/>
				</div>
			</div>
			<div className="w-full h-[0.5px] bg-outline-variant" />
			<div className="flex flex-col gap-[8px]">
				<h1 className="uppercase text-xs leading-5 font-medium text-outline tracking-[0.1px]">Application</h1>
				<div className="flex flex-col">
					<RadioGroup value={currentUserType} onChange={handleChangeUserType}>
						<RadioButton className="px-[8px]" value={UserType.TECH}>{UserType.TECH}</RadioButton>
						<RadioButton className="px-[8px]" value={UserType.ADMIN}>{UserType.ADMIN}</RadioButton>
					</RadioGroup>
				</div>
			</div>
			<div className="w-full h-[0.5px] bg-outline-variant" />
			<div>
				<OptionItem
					onClick={() => handleShowAboutDialog()}
					className="rounded-md"
					text="About"
					icon={
						<AboutIcon className="text-on-surface-variant" />
					}
				/>
				<OptionItem
					onClick={() => handleLogOut()}
					className="rounded-md"
					text="Logout"
					icon={
						<LogoutIcon className="text-on-surface-variant" />
					}
				/>
			</div>
		</div>
	);
};

export default ProfileContent;

import React, { Component } from "react";

import axios from "axios";

import Routes from '@/Routes';
import { BaseStore } from "@/Store/BaseStore";
import { ServerErrorDialog } from "@/Components/Feedback/ServerErrorDialog";

import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";

import { withStore } from "@/Helpers/withStore";

import { DeviceAction } from "@/Helpers/constants";
import { requestApiHealthCheck } from "./Store/Actions";

import { withDeviceService } from "./HOC";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: []
        };
    }

    handleCloseServerErrorDialog = errorId => {
        if (errorId) {
            const filteredErrors = this.state.errors?.filter(error => error?.id !== errorId);
            this.setState({ errors: filteredErrors });
        }
    };

    async componentDidMount() {
        BaseStore.httpClient.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            console.error(error);
            const apisToTriggerServerErrorDialog = [
                '/batch/timer/start',
                '/devices/update-info',
                '/orders/create-batch-and-claim',
                '/orders/create-kit-and-claim',
                '/claim-orders/create-batch',
                '/orders',
                '/batch/my-batches',
                '/batch/my-kits',
                '/devices/fail-doa',
                '/devices/pass',
                '/devices/update-info'
            ];
            const { url, data, headers, method } = error.config || {};

            if (apisToTriggerServerErrorDialog?.includes(url) && !axios.isCancel(error)) {
                let requestJson = {
                    url,
                    headers
                };
    
                if (method === 'post' || method === 'put') {
                    const requestBody = JSON.parse(data);
                    requestJson = {
                        ...requestJson,
                        data: requestBody
                    };
                }

                const errorObj = {
                    errorTitle: error?.response?.data?.message || error?.response?.data?.name || error?.message || '',
                    sqlError: error?.response?.data?.sql ? error?.response?.data : '',
                    responseCode: error?.response?.status,
                    requestJson
                };
                errorObj.id = Object.values(errorObj).join('-');
    
                this.setState({
                    errors: [
                        ...this.state.errors,
                        errorObj
                    ]
                });
            }

            return Promise.reject(error);
        });
        
        if (!this.props?.apiHealthCheckResponse) {
            await this.props?.requestApiHealthCheck();
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps?.deviceServiceResponse !== this.props?.deviceServiceResponse) {
            const deviceServiceResponse = this.props?.deviceServiceResponse;

            const excludedActions = [
                DeviceAction?.GET_MENU_OPTIONS,
                DeviceAction?.GET_VERSION
            ];

            if (!deviceServiceResponse?.success && !excludedActions.includes(deviceServiceResponse.action)) {
                let errorTitle = '';

                if ([DeviceAction.STAGE, DeviceAction.STAGE_ALL].includes(deviceServiceResponse.action)) {
                    if (deviceServiceResponse?.result?.data) {
                        errorTitle = Object.values(deviceServiceResponse.result.data)
                            .filter((value) => typeof value === 'object')
                            .flatMap((value) => value.output)
                            .map((value) => value?.reason?.message)
                            .join('');
                    }
                }
                
                if (errorTitle.length === 0) {
                    errorTitle = deviceServiceResponse?.error || deviceServiceResponse?.result?.data?.error;
                }

                const errorObj = {
                    errorTitle,
                    responseCode: deviceServiceResponse?.action
                };
                errorObj.id = Object.values(errorObj).join('-');

                this.setState({
                    errors: [
                        ...this.state.errors,
                        errorObj
                    ]
                });
            }
        }

        if (this.props?.webSocket && !this.props?.deviceServiceVersion && this.props?.deviceServiceResponse?.success !== false) {
            this.props.webSocket.send(JSON.stringify({
                action: DeviceAction.GET_VERSION,
                url: 'service-version'
            }));
        }

        if (prevState.errors !== this.state.errors) {
            const getAllOptionsErrors = this.state.errors.filter(error => error.responseCode === DeviceAction.GET_ALL_OPTIONS);
            const idAllErrors = this.state.errors.filter(error => error.responseCode === DeviceAction.ID_ALL);
            const resetAllErrors = this.state.errors.filter(error => error.responseCode === DeviceAction.RESET_ALL);
            const otherErrors = this.state.errors.filter(error => ![DeviceAction.GET_ALL_OPTIONS, DeviceAction.ID_ALL, DeviceAction.RESET_ALL].includes(error.responseCode));
            
            let updatedErrors = [
                ...(otherErrors || [])
            ];

            if (getAllOptionsErrors.length > 1 || idAllErrors.length > 1 || resetAllErrors.length > 1) {
                if (getAllOptionsErrors.length > 1) {
                    updatedErrors = [
                        ...updatedErrors,
                        {
                            ...(getAllOptionsErrors?.[0] || {}),
                            errorTitle: getAllOptionsErrors.map(error => error.errorTitle).join('\n')
                        }
                    ];
                }
    
                if (idAllErrors.length > 1) {
                    updatedErrors = [
                        ...updatedErrors,
                        {
                            ...(idAllErrors?.[0] || {}),
                            errorTitle: idAllErrors.map(error => error.errorTitle).join('\n')
                        }
                    ];
                }
    
                if (resetAllErrors.length > 1) {
                    updatedErrors = [
                        ...updatedErrors,
                        {
                            ...(resetAllErrors?.[0] || {}),
                            errorTitle: resetAllErrors.map(error => error.errorTitle).join('\n')
                        }
                    ];
                }

                this.setState({
                    errors: updatedErrors
                });
            }
        }
    }

    render() {
        return (
            <>
                <Routes />
                {this.state.errors.length > 0 && this.state.errors.map((errorObj, index) => {
                    return (
                        <ServerErrorDialog
                            key={index}
                            error={errorObj}
                            isOpen={!!(this.state.errors.find(error => error?.id === errorObj?.id))}
                            handleCloseServerErrorDialog={() => this.handleCloseServerErrorDialog(errorObj?.id)}
                        />
                    );
                })}
            </>
        );
    }
}

const mapStateToProps = ({ device, user }) => {
    return {
        deviceServiceResponse: device?.deviceServiceResponse,
        apiHealthCheckResponse: user?.apiHealthCheckResponse,
        webSocket: device?.webSocket,
        deviceServiceVersion: device?.deviceServiceVersion
    };
};

const mapDispatchToProps = ({ user }) => {
    return {
        requestApiHealthCheck: () => user?.dispatch(requestApiHealthCheck())
    };
};

export default withDeviceService(
    withStore(
        mapStateToProps,
        mapDispatchToProps
    )(App)
);

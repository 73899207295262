import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { useGridFilter } from "ag-grid-react";
import moment from "moment";
import { DatePicker } from "@/Components/Display";

const FilterDate = forwardRef((props, refs) => {
     const { onModelChange, model, getValue, gridApiRef } = props;
     const hidePopupRef = useRef();
     const [isHidden, setIsHidden] = useState(false);

     const [dateRange, setDateRange] = useState({
          start: null,
          end: null,
     });
     const [withFooterActions, setWithFooterActions] = useState(true);
     const [inBetweenDays, setInBetweenDays] = useState([]);

     const onValueChanged = useCallback((value, type) => {
          onModelChange((!value?.start && !value?.end) ? null : value);
     }, [onModelChange]);

     const doesFilterPass = useCallback(({ node }) => {
          const value = getValue(node);
          if (model?.end) {
               const start = moment(model.start).add(-1, 'day');
               const end = moment(model.end).add(1, 'day');
               return moment(value).isBetween(start, end);
          }
          return moment(value).isSame(moment(model?.start), 'day');
     }, [getValue, model]);

     const afterGuiAttached = params => {
          setIsHidden(false);
          hidePopupRef.current = params?.hidePopup;
     };

     useGridFilter({
          doesFilterPass,
          afterGuiAttached
     });

     const hideFooterAction = () => {
          setWithFooterActions(false);
     };
     const showFooterAction = () => {
          setWithFooterActions(true);
     };

     const resetDateSelected = () => {
          setDateRange({
               start: null,
               end: null
          });
          onValueChanged({
               start: null,
               end: null
          });
          setInBetweenDays([]);
     };

     useImperativeHandle(gridApiRef, () => ({
          resetDateSelected
     }));

     const handleFilterShow = () => {
          if (hidePopupRef?.current) {
               hidePopupRef?.current();
               return;
          }
          setIsHidden(true);
     };

     return (
          <div className={`w-[360px] h-fit !rounded-[5px] ${isHidden ? 'hidden' : ''}`}>
               <DatePicker
                    //  singleDate
                    inBetweenDays={inBetweenDays}
                    setInBetweenDays={setInBetweenDays}
                    withFooterActions={withFooterActions}
                    hideFooterAction={hideFooterAction}
                    showFooterAction={showFooterAction}
                    label="Select date"
                    className="h-fit"
                    dateRange={dateRange}
                    onSetSelectedDate={(event) => {
                         setDateRange({
                              start: event.singleDate,
                              end: null
                         });
                    }}
                    onSetSelectedDates={(event) => {
                         setDateRange({
                              start: event.startDate ?? null,
                              end: event.endDate ?? null
                         });
                    }}
                    onCancel={() => {
                         resetDateSelected();
                         handleFilterShow();
                    }}
                    onApply={() => {
                         onValueChanged(dateRange);
                         if (dateRange.start || dateRange.end) {
                              handleFilterShow();
                         };
                    }}
               />
          </div>
     );
});

export default FilterDate;
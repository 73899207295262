import React from 'react';
import PropTypes from 'prop-types';

const MenuItem = ({
    className,
    disabled,
    onClick,
    children
}) => {
    const disableClass = disabled ? 'menu-item-disabled' : '';

    return (
        <li onClick={disabled ? () => { } : onClick}
            className={`menu-item ${disableClass} ${className ?? ''}`}>
            {!Array.isArray(children) &&
                <div key={children}>{children}</div>
            }
            {Array.isArray(children) && children.map((child, index) => {
                return <div key={index}>{child}</div>
            })}
        </li>
    );
};

MenuItem.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

export default MenuItem;

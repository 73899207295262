
import { useState } from "react";

import { Dialog } from "@/Components/Feedback";
import { Button, TextField } from "@/Components/Inputs";

const MenuOptionPromptDialog = ({
    isOpen,
    onClose,
    onOk,
    menuOption
}) => {
    const [description, setDescription] = useState(menuOption?.prompt?.defaultValue || '');

    const handleClose = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    const handleOk = () => {
        if (typeof onOk === 'function') {
            onOk(menuOption, description);
        }
    };

    const handleDescriptionChange = event => {
        const value = event?.target?.value || '';
        setDescription(value);
    };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={handleClose}
        >
            <Dialog.Title className="flex font-normal text-[32px] leading-[40px] mb-[24px] text-on-surface">
                {menuOption?.text}
            </Dialog.Title>
            <Dialog.Content className="space-y-6">
                <p className="text-base leading-[24px] tracking-[0.5px] font-normal text-on-surface">
                    {menuOption?.prompt?.helpText || 'Description'}
                </p>
                <TextField 
                    className="w-[420px] !h-[48px]"
                    value={description}
                    onChange={handleDescriptionChange}
                />
            </Dialog.Content>
            <Dialog.Actions className="justify-end">
                <Button onClick={handleClose} variant="flat" className="uppercase w-[78px]">Cancel</Button>
                <Button onClick={handleOk} className="uppercase">Ok</Button>
            </Dialog.Actions>
        </Dialog>
    );
};

export default MenuOptionPromptDialog;

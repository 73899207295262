import React from 'react';
import PropTypes from 'prop-types';

const TextArea = ({
    fenced,
    onChange,
    readonly,
    disabled,
    label,
    size,
    resize,
    rows,
    value,
    status,
    error,
    name,
    className,
    helperText,
    ...props
}) => {
    const getSize = () => {
        if (size === 'large') {
            return 'textarea-input-large';
        } else if (size === 'small') {
            return 'textarea-input-small';
        } else if (size === 'medium') {
            return 'textarea-input-medium';
        } else if (size === 'full') {
            return 'textarea-input-full';
        }

        return 'textarea-input-normal';
    };

    const renderHelperText = () => {
        return (
            <div
                className={`
                    textarea-helper ${status !== 'default'
                        ? status === 'validation'
                            ? 'text-primary'
                            : 'text-error'
                        : ''
                    }
                `}
            >
                {helperText}
            </div>
        );
    };

    return (
        <div
            className={`textarea ${className ?? ""} ${error ? 'error' : ''} ${getSize()}`}
        >
            {label && <div className={`textarea-label`}>{label}</div>}
            <textarea
                readOnly={readonly}
                disabled={disabled}
                onChange={onChange}
                className={`
                    textarea-input 
                    ${fenced ? 'thick-textarea' : ''}
                    ${resize ? 'resize' : 'no-resize'}
                `}
                rows={rows}
                value={value}
                name={name}
                {...props}
            ></textarea>
            {helperText && renderHelperText()}
        </div>
    );
};

TextArea.propTypes = {
    size: PropTypes.oneOf(['default', 'large', 'full', 'small', 'medium']),
    label: PropTypes.string,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    rows: PropTypes.string,
    resize: PropTypes.bool,
    onChange: PropTypes.func,
    fenced: PropTypes.bool,
    value: PropTypes.string,
    placeholder: PropTypes.string
};

TextArea.defaultProps = {
    size: 'full',
    disabled: false,
    readonly: false,
    rows: '7',
    resize: false,
    fenced: false
};

export default TextArea;

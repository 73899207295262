
import { Component } from "react"
import { SideBarItem } from "@/Helpers/constants";
import ReportAProblem from "./ReportProblem"
import SupportingDocuments from "./SupportingDocuments";
import Tags from "./Tags";

class SideBarContainer extends Component {
     renderContent = activeSideBarItem => {
          const { onShowSideBar, handleShowToast } = this.props;

          switch (activeSideBarItem) {
               case SideBarItem.REPORT_A_PROBLEM:
                    return <ReportAProblem handleShowToast={handleShowToast} />;
               case SideBarItem.SUPPORTING_DOCUMENTS:
                    return <SupportingDocuments isSidebarOpen={onShowSideBar} />;
               case SideBarItem.TAGS:
                    return <Tags isSidebarOpen={onShowSideBar} />;
               default:
                    return '';
          }

     };

     render() {
          const { onShowSideBar, activeSideBarItem } = this.props;

          return (
               <div className={` ${onShowSideBar ? "block -translate-x-full" : " hidden"}  h-full pl-4 pr-2 py-8 duration-500 ease-in-out w-[256px] top-0 border-r-[0.5px] border-primary-25 absolute bg-on-primary-fixed-variant z-[5]`}>
                    <div className="h-full overflow-y-auto overflow-x-hidden">
                         {this.renderContent(activeSideBarItem)}
                    </div>
               </div>
          );
     }
}

export default SideBarContainer;

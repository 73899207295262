import { CompaniesStoreActionConstants } from '@/Store/Actions';

export const CompaniesStoreReducer = ({ state, initialState, action }) => {

    switch (action.type) {
        case CompaniesStoreActionConstants.RECEIVE_COMPANIES:
            return {
                ...state,
                companies: action.payload
            };
        default:
            return state;
    }
};

import { BaseStore } from '@/Store/BaseStore';

export const getMyBatches = (success, payload) => {
    return BaseStore.httpClient.get('/batch/my-batches', {
        params: {
            userID: payload
        }
    })
        .then((response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const getBatchDetails = (success, payload) => {
    return BaseStore.httpClient.get('/batch/get-status', {
        params: {
            batchID: payload
        }
    })
        .then((response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const startTimer = (success, payload) => {
    return BaseStore.httpClient.post('/batch/timer/start', {
        ...(payload || {})
    })
        .then((response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const pauseTimer = (success, payload) => {
    return BaseStore.httpClient.put('/batch/timer/stop', {
        ...(payload || {})
    })
        .then((response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const getMyKits = (success, payload) => {
    return BaseStore.httpClient.get('/batch/my-kits', {
        params: {
            userID: payload
        }
    })
        .then((response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const pauseAllTimers = (success, payload) => {
    return BaseStore.httpClient.put('/batch/timer/stop-all', {
        ...(payload || {})
    })
        .then((response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const isBatchComplete = (success, payload) => {
    return BaseStore.httpClient.get('/batch/is-complete', {
        params: {
            batchID: payload
        }
    })
        .then((response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const completeBatch = (success, payload) => {
    return BaseStore.httpClient.post('/batch/complete', {
        ...(payload || {})
    })
        .then((response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const removeFromBatch = (success, payload) => {
    return BaseStore.httpClient.delete('/batch/remove-from-batch', {
        data: {
            ...(payload || {})
        }
    })
        .then((response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

import {
    createContext,
    useContext,
    useMemo
} from 'react';
import { useReducerWithMiddleware } from '@/Hooks';
import { DeviceStoreMiddleware } from '@/Store/Middlewares';
import { DeviceStoreReducer } from '@/Store/Reducers';
import { ProviderName } from '@/Helpers/constants';

export const DeviceStoreContext = createContext();

const initialState = {
    deviceServiceVersion: null
};

export const DeviceStoreProvider = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(DeviceStoreReducer, initialState, [DeviceStoreMiddleware]);

    const DeviceStoreState = useMemo(() => {
        return { ...(state || {}) };
    }, [state]);

    return (
        <DeviceStoreContext.Provider value={{ state: DeviceStoreState, dispatch, name: ProviderName.DEVICE }}>
            {children}
        </DeviceStoreContext.Provider>
    );
};

export const useDeviceStoreContext = () => {
    return useContext(DeviceStoreContext);
};

const CloseIcon = ({ className, ...props }) => {
   
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className ?? ""}`} viewBox="0 0 24 24" fill="none" {...props}>
      <mask id="mask0_740_128164" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
        <rect x="0.174805" y="0.140625" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_740_128164)">
        <path d="M6.5748 19.1406L5.1748 17.7406L10.7748 12.1406L5.1748 6.54062L6.5748 5.14062L12.1748 10.7406L17.7748 5.14062L19.1748 6.54062L13.5748 12.1406L19.1748 17.7406L17.7748 19.1406L12.1748 13.5406L6.5748 19.1406Z" />
      </g>
    </svg>

  );
};

export default CloseIcon;

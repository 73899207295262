import React from "react";

const UnCheckCircleIcon = ({ className }) => {

     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className ?? ""}`} fill="none" viewBox="0 0 24 24">
          <mask id="mask0_5123_42663" style={{ maskType: "alpha" }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse"><path fill="currentColor" d="M0 0H24V24H0z"></path></mask>
          <g mask="url(#mask0_5123_42663)">
            <path fill="currentColor" fillRule="evenodd" d="M2 12c0 5.5 4.5 10 10 10s10-4.5 10-10S17.5 2 12 2 2 6.5 2 12zm10 8c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z" clipRule="evenodd"></path>
          </g>
        </svg>
        
      
     )
}
export default UnCheckCircleIcon;
export const DoaReasonsStoreActionConstants = {
    REQUEST_DOA_REASONS: 'REQUEST_DOA_REASONS',
    RECEIVE_DOA_REASONS: 'RECEIVE_DOA_REASONS'
};

export const requestDoaReasons = queryParams => ({
    type: DoaReasonsStoreActionConstants.REQUEST_DOA_REASONS,
    payload: queryParams
});

export const receiveDoaReasons = doaReasons => ({
    type: DoaReasonsStoreActionConstants.RECEIVE_DOA_REASONS,
    payload: doaReasons
});

import { DoaReasonsStoreActionConstants } from '@/Store/Actions';

export const DoaReasonsStoreReducer = ({ state, initialState, action }) => {
    switch (action.type) {
        case DoaReasonsStoreActionConstants.RECEIVE_DOA_REASONS:
            return {
                ...state,
                doaReasons: action.payload
            };
        default:
            return state;
    }
};


import { Dialog } from "@/Components/Feedback";
import { Button } from "@/Components/Inputs";

const BatchCompletedDialog = ({
     isOpen,
     onClose,
     onCloseBatch
}) => {
     const handleClose = () => {
          if (typeof onClose === 'function') {
               onClose();
          }
     };

     const handleCloseBatch = () => {
          if (typeof onCloseBatch === 'function') {
               onCloseBatch();
          }
     };

     return (
          <Dialog
               isOpen={isOpen}
               className="pb-[24px]"
          >
               <Dialog.Title className="flex font-normal text-[32px] leading-[40px] mb-[13px] text-on-surface">
                    Batch Complete
               </Dialog.Title>
               <Dialog.Content className="space-y-6 !mb-[24px]">
                    <p className=" text-base leading-[24px] tracking-[0.5px] font-normal text-on-surface"> All devices on this batch are complete. Would you like to close this tab?</p>
               </Dialog.Content>
               <Dialog.Actions className="justify-end">
                    <Button variant="flat" className="uppercase w-[78px] bg-transparent border-0" onClick={handleClose}>No</Button>
                    <Button variant="fixed-dim" onClick={handleCloseBatch} className="uppercase">Yes</Button>
               </Dialog.Actions>
          </Dialog>
     );
};

export default BatchCompletedDialog;

import { createElement } from "react";
import { render } from "react-dom";
import * as Icons from '@/Icons'

export const Icon = ({ name, ...props}) => {
    const component = createElement(Icons[name], props, '');
    return (
        <div>{component}</div>
    );
};

export const AgGridIcon = (icon, props) => {
    const domNode = document.createElement('div');

    render(<Icon name={icon} {...props} />, domNode);
    return domNode;
};

import { ProductStoreActionConstants } from '@/Store/Actions';

export const ProductStoreReducer = ({ state, initialState, action }) => {

    switch (action.type) {
        case ProductStoreActionConstants.RECEIVE_PART_NUMBER:
            return {
                ...state,
                partNumber: action.payload
            };
        default:
            return state;
    }
};

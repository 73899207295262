export const UserType = {
    TECH: 'Tech',
    ADMIN: 'Admin'
};

export const BatchType = {
    BATCH: 'batch',
    KIT: 'kit'
};

export const DeviceAction = {
    RESCAN: 'RESCAN',
    RESCAN_ALL: 'RESCAN_ALL',
    GET_ALL_OPTIONS: 'GET_ALL_OPTIONS',
    GET_OPTIONS: 'GET_OPTIONS',
    STAGE: 'STAGE',
    STAGE_ALL: 'STAGE_ALL',
    ID: 'ID',
    ID_ALL: 'ID_ALL',
    RESET: 'RESET',
    RESET_ALL: 'RESET_ALL',
    GET_MENU_OPTIONS: 'GET_MENU_OPTIONS',
    GET_VERSION: 'GET_VERSION'
};

export const StagingStatus = {
    STAGING: 'staging',
    NONE: 'none',
    FAILED: 'failed',
    PASSED: 'passed'
};

export const StagingStatusFilter = {
    ALL_DEVICES: 'All Devices',
    READY_FOR_STAGING: 'Ready for Staging',
    CURRENTLY_STAGING: 'Currently Staging',
    PASSED_STAGING: 'Passed Staging',
    FAILED_STAGING: 'Failed Staging'
};

export const OrderItemStatus = {
    READY_FOR_QA: 'Ready for QA',
    READY_FOR_AUDIT: 'Ready for Audit',
    READY_FOR_STAGING: 'Ready for Staging',
    IN_PROCESS_QA: 'In Process QA',
    IN_PROCESS_AUDIT: 'In Process Audit',
    IN_PROCESS_TECH: 'In Process Tech',
    IN_PROCESS: 'In Process',
    STAGING: 'STAGING',
    KIT_ASSIGNMENT: 'Kit Assignment',
    COMPLETE: 'Complete',
    PASSED: 'Passed',
    FAILED: 'Failed',
    INACTIVE: 'INACTIVE',
    UNKNOWN: 'Unknown'
};

export const ProviderName = {
    BATCH: 'batch',
    USER: 'user',
    COMPANIES: 'companies',
    DEVICE: 'device',
    DOA_REASONS: 'doaReasons',
    ORDERS: 'orders',
    PART_NUMBER: 'partNumber',
    QA_CHECKLIST: 'qaChecklist',
    QA_FAILURE: 'qaFailure',
    KIT:'kit'
};

export const DeviceService = {
    PORT: 5344,
    RECONNECT_TIMEOUT: 3000,
    RECONNECT_MAX_ATTEMPTS: 5
};

export const SideBarItem = {
    REPORT_A_PROBLEM: 'REPORT_A_PROBLEM',
    SUPPORTING_DOCUMENTS: 'SUPPORTING_DOCUMENTS',
    TAGS: 'TAGS'
};

export const KitPairingName = {
    NON_PRIMARY_ORDER_ITEM_ID: 'nonPrimaryOrderItemID',
    NON_PRIMARY_SERIAL: 'nonPrimarySerial'
};

export const KeyboardKeys = {
    ENTER: 'Enter',
    TAB: 'Tab',
    ESCAPE: 'Escape'
};

const ArrowLeftIcon = ({
     className,
     ...props
}) => {
     return (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className={`svg-regular ${className || ''}`} {...props}>
               <mask id="mask0_7413_84150" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                    <rect x="24" y="24" width="24" height="24" transform="rotate(-180 24 24)" fill="#D9D9D9" />
               </mask>
               <g mask="url(#mask0_7413_84150)">
                    <path d="M7.825 11L20 11V13L7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11Z" fill="currentColor" />
               </g>
          </svg>
     );
};

export default ArrowLeftIcon;

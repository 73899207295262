const CheckIcon = ({ className }) => {
     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className || ''}`} viewBox="0 0 24 24" fill="none">
               <mask id="mask0_740_129579" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                    <rect y="0.0224609" width="24" height="24" fill="currentColor" />
               </mask>
               <g mask="url(#mask0_740_129579)">
                    <path d="M9.54998 18.0221L3.84998 12.3221L5.27498 10.8971L9.54998 15.1721L18.725 5.99707L20.15 7.42207L9.54998 18.0221Z" />
               </g>
          </svg>
     );
};

export default CheckIcon;

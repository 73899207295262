import { BaseStore } from '@/Store/BaseStore';

export const getQaFailures = (success, payload) => {
    return BaseStore.httpClient.get('/qa-failures', {
        params: {
            ...(payload || {})
        }
    })
        .then((response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const createQaFailures = (success, payload) => {
    return BaseStore.httpClient.post('/qa-failures', {
        ...(payload || {})
    })
        .then((response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const deleteQaFailure = (success, payload) => {
    return BaseStore.httpClient.delete('/qa-failures', {
        data: {
            id: payload
        }
    })
        .then((response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

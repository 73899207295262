const SupportingDocumentsIcon = ({ className }) => {
     return (
          <svg className={`svg-regular ${className || ''}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
               <mask id="mask0_315_12287" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                    <rect width="24" height="24" fill="currentColor" />
               </mask>
               <g mask="url(#mask0_315_12287)">
                    <path d="M4.8 19.2C4.305 19.2 3.88125 19.0238 3.52875 18.6713C3.17625 18.3188 3 17.895 3 17.4V4.8C3 4.305 3.17625 3.88125 3.52875 3.52875C3.88125 3.17625 4.305 3 4.8 3H17.4C17.895 3 18.3188 3.17625 18.6713 3.52875C19.0238 3.88125 19.2 4.305 19.2 4.8V10.83C18.915 10.695 18.6225 10.5787 18.3225 10.4812C18.0225 10.3837 17.715 10.3125 17.4 10.2675V4.8H4.8V17.4H10.245C10.29 17.73 10.3612 18.045 10.4588 18.345C10.5563 18.645 10.6725 18.93 10.8075 19.2H4.8ZM4.8 17.4V4.8V10.2675V10.2V17.4ZM6.6 15.6H10.2675C10.3125 15.285 10.3837 14.9775 10.4812 14.6775C10.5787 14.3775 10.6875 14.085 10.8075 13.8H6.6V15.6ZM6.6 12H12.09C12.57 11.55 13.1062 11.175 13.6987 10.875C14.2912 10.575 14.925 10.3725 15.6 10.2675V10.2H6.6V12ZM6.6 8.4H15.6V6.6H6.6V8.4ZM16.5 21C15.255 21 14.1938 20.5613 13.3162 19.6838C12.4388 18.8062 12 17.745 12 16.5C12 15.255 12.4388 14.1938 13.3162 13.3162C14.1938 12.4388 15.255 12 16.5 12C17.745 12 18.8062 12.4388 19.6838 13.3162C20.5613 14.1938 21 15.255 21 16.5C21 17.745 20.5613 18.8062 19.6838 19.6838C18.8062 20.5613 17.745 21 16.5 21ZM16.05 19.2H16.95V16.95H19.2V16.05H16.95V13.8H16.05V16.05H13.8V16.95H16.05V19.2Z" fill="currentColor" />
               </g>
          </svg>
     );
};

export default SupportingDocumentsIcon;

const ImageIcon = ({ className }) => {
     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className || ''}`} width="32" height="33" viewBox="0 0 32 33" fill="none">
               <rect y="0.5" width="32" height="32" rx="5.33333" fill="white" />
               <path fillRule="evenodd" clipRule="evenodd" d="M3.84 0.5C1.71923 0.5 0 2.21923 0 4.34V28.66C0 30.7808 1.71923 32.5 3.84 32.5H28.16C30.2808 32.5 32 30.7808 32 28.66V4.34C32 2.21923 30.2808 0.5 28.16 0.5H3.84ZM11.7481 19.7818C12.3712 20.6132 12.9959 21.4466 13.5014 21.4466C14.4514 21.4466 15.5018 19.8837 16.5753 18.2864C18.0375 16.1107 19.5427 13.871 20.896 15.4294C21.7469 16.4098 22.6239 18.5555 23.3963 20.4456C23.6654 21.1039 23.9218 21.7312 24.16 22.2675V23.028C24.16 23.9305 23.4305 24.66 22.528 24.66H9.472C9.03917 24.66 8.62406 24.4881 8.318 24.182C8.01194 23.8759 7.84 23.4608 7.84 23.028V22.4911C8.14923 21.7978 8.46425 21.0175 8.74958 20.3107C9.03046 19.615 9.28256 18.9905 9.472 18.5906C10.0078 17.4601 10.8765 18.619 11.7481 19.7818ZM12.736 10.788C12.736 12.1409 11.6409 13.236 10.288 13.236C8.93507 13.236 7.84 12.1409 7.84 10.788C7.84 9.43507 8.93507 8.34 10.288 8.34C11.6409 8.34 12.736 9.43507 12.736 10.788Z" fill="#FFBA5A" />
          </svg>
     );
};

export default ImageIcon;

const RefreshSpinnerIcon = ({ className }) => {
     return (
          <svg className={`svg-regular ${className || ''}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_4409_39" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                         <rect width="24" height="24" fill="#171C20" />
                    </mask>
                    <g mask="url(#mask0_4409_39)">
                         <path d="M20 12C20 13.0506 19.7931 14.0909 19.391 15.0615C18.989 16.0321 18.3997 16.914 17.6569 17.6569C16.914 18.3997 16.0321 18.989 15.0615 19.391C14.0909 19.7931 13.0506 20 12 20C10.9494 20 9.90914 19.7931 8.93853 19.391C7.96793 18.989 7.08601 18.3997 6.34315 17.6569C5.60028 16.914 5.011 16.0321 4.60896 15.0615C4.20693 14.0909 4 13.0506 4 12C4 10.9494 4.20693 9.90914 4.60896 8.93853C5.011 7.96793 5.60028 7.08601 6.34315 6.34314C7.08601 5.60028 7.96793 5.011 8.93853 4.60896C9.90914 4.20693 10.9494 4 12 4C13.0506 4 14.0909 4.20693 15.0615 4.60896C16.0321 5.011 16.914 5.60028 17.6569 6.34315C18.3997 7.08602 18.989 7.96793 19.391 8.93853C19.7931 9.90914 20 10.9494 20 12L20 12Z" stroke="#76CD53" strokeWidth="2.66667" />
                         <path d="M20 12C20 13.6894 19.4652 15.3355 18.4721 16.7023C17.4791 18.0691 16.0789 19.0864 14.4721 19.6085C12.8654 20.1305 11.1346 20.1305 9.52786 19.6085C7.92112 19.0864 6.52089 18.0691 5.52786 16.7023" stroke="#0B3400" strokeWidth="2.66667" strokeLinecap="round" />
                    </g>
               </svg>

          </svg>
     );
};

export default RefreshSpinnerIcon;

import { Dialog } from "@/Components/Feedback";
import { Button } from "@/Components/Inputs";

const PauseAllTimersDialog = ({
    isOpen,
    onClose,
    onUnPauseAllTimers,
    isLoading
}) => {
    const handleClose = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    const handlePauseAllTimers = () => {
        if (typeof onUnPauseAllTimers === 'function') {
            onUnPauseAllTimers();
        }
    };

    return (
        <Dialog
            isOpen={isOpen}
            className="text-on-surface p-8 pb-6"
        >
            <Dialog.Title className="flex font-normal text-[32px] leading-[40px] text-on-surface mb-[13px]">
                Pause all timers?
            </Dialog.Title>
            <Dialog.Content>
                <p className="text-[16px] w-[560px] h-[48px] leading-[24px] tracking-[0.5px] font-normal">This will close your open batch tabs and pause all running timers. Would you like to proceed?</p>
            </Dialog.Content>
            <Dialog.Actions className="justify-end">
                <Button variant="flat" className="w-[78px] uppercase border-0" onClick={handleClose} disabled={!!isLoading}>No</Button>
                <Button className="w-[82px] uppercase text-on-secondary-container" onClick={handlePauseAllTimers} isLoading={!!isLoading} disabled={!!isLoading}>Yes</Button>
            </Dialog.Actions>
        </Dialog>
    );
};

export default PauseAllTimersDialog;

import { Component } from "react";

import { MainLayout } from "@/Layouts";
import { Stack } from "@/Components/Display";
import { Button } from "@/Components/Inputs";
import { ArrowBackIcon } from "@/Icons";

class InternalNotesPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orderItems: [
                {
                    orderNumber: '10998144',
                    orderNotes: [
                        {
                            category: 'Staging Notes',
                            title: 'Provisioning Instructions',
                            body: 'LOST 398558'
                        },
                        {
                            category: 'Staging Notes',
                            title: 'Phone Number',
                            body: '+14706960705'
                        }
                    ],
                    internalNotes: 'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit.',
                    rmaProblemDescription: 'MISC - Lost/Stolen Device: LOST 398558',
                    rmaProvInstructions: ''
                },
                {
                    orderNumber: '10998144',
                    orderNotes: [
                        {
                            category: 'Staging Notes',
                            title: 'Provisioning Instructions',
                            body: 'LOST 398558'
                        },
                        {
                            category: 'Staging Notes',
                            title: 'Phone Number',
                            body: '+14706960705'
                        }
                    ],
                    internalNotes: 'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit.',
                    rmaProblemDescription: 'MISC - Lost/Stolen Device: LOST 398558',
                    rmaProvInstructions: ''
                }
            ]
        };
    }

    handleGoBack = () => {
        window.close();
    };

    render() {
        const {
            orderItems
        } = this.state;

        return (
            <MainLayout>
                <Stack direction="column" alignItems="flex-start" justifyContent="flex-start" className="text-on-surface gap-[24px] pt-[40px] pb-[48px] pl-[43px] pr-[15px] overflow-y-auto h-full">
                    <Stack className="gap-[8px]">
                        <Button className="bg-transparent hover:bg-primary-fixed p-0 size-[24px]" onClick={this.handleGoBack}>
                            <ArrowBackIcon />
                        </Button>
                        <h1 className="text-[32px] leading-[40px]">Internal Notes</h1>
                    </Stack>
                    {(Array.isArray(orderItems) && orderItems.length > 0) && orderItems.map((orderItem, index) => {
                        return (
                            <table key={index} className="border-separate border border-surface-container-high border-spacing-0 text-[12px] w-full text-left rounded-[5px] shadow-[0px_0px_5px_0px_rgba(162,174,182,0.28)]">
                                <tr className="bg-neutral-95">
                                    <th className="px-[24px] py-[16px] font-semibold" colSpan={4}>
                                        Order Number {orderItem?.orderNumber}
                                    </th>
                                </tr>
                                <tr className="font-inter bg-primary/[.05]">
                                    <th rowSpan={orderItem?.orderNotes?.length + 1} className="px-[24px] py-[16px] font-semibold border-t border-outline-variant">
                                        Order Notes
                                    </th>
                                    <th className="px-[24px] py-[16px] font-semibold border-t border-l border-outline-variant">
                                        Category
                                    </th>
                                    <th className="px-[24px] py-[16px] font-semibold border-t border-l border-outline-variant">
                                        Title
                                    </th>
                                    <th className="px-[24px] py-[16px] font-semibold border-t border-l border-outline-variant">
                                        Body
                                    </th>
                                </tr>
                                {(Array.isArray(orderItem?.orderNotes) && orderItem?.orderNotes.length > 0) && orderItem?.orderNotes.map((orderNote, index) => {
                                    return (
                                        <tr key={index} className="font-inter">
                                            <td className="px-[24px] py-[16px] border-t border-l border-outline-variant">
                                                {orderNote?.category}
                                            </td>
                                            <td className="px-[24px] py-[16px] border-t border-l border-outline-variant">
                                                {orderNote?.title}
                                            </td>
                                            <td className="px-[24px] py-[16px] border-t border-l border-outline-variant">
                                                {orderNote?.body}
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr className="font-inter bg-[#F1D1F7] bg-opacity-40">
                                    <th className="px-[24px] py-[16px] font-semibold border-t border-outline-variant">
                                        Internal Notes
                                    </th>
                                    <td className="px-[24px] py-[16px] border-t border-l border-outline-variant" colSpan={3}>
                                        {orderItem?.internalNotes}
                                    </td>
                                </tr>
                                <tr className="font-inter">
                                    <th className="px-[24px] py-[16px] font-semibold bg-primary/[.05] border-t border-outline-variant">
                                        RMA Problem Description
                                    </th>
                                    <td className="px-[24px] py-[16px] border-t border-l border-outline-variant" colSpan={3}>
                                        {orderItem?.rmaProblemDescription}
                                    </td>
                                </tr>
                                <tr className="font-inter">
                                    <th className="px-[24px] py-[16px] font-semibold bg-primary/[.05] border-t border-outline-variant">
                                        RMA Prov. Instructions
                                    </th>
                                    <td className="px-[24px] py-[16px] border-t border-l border-outline-variant" colSpan={3}>
                                        {orderItem?.rmaProvInstructions}
                                    </td>
                                </tr>
                            </table>
                        );
                    })}
                </Stack>
            </MainLayout>
        );
    }
}

export default InternalNotesPage;

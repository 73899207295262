export const AppStorageMethod = {
    LOCAL: 'local',
    SESSION: 'session'
}

export class AppStorage {
    constructor(method) {
        this.storage = method === AppStorageMethod.LOCAL ? localStorage : sessionStorage;
    }

    get(key) {
        return this.storage.getItem(key);
    }
    set(key, value) {
        this.storage.setItem(key, value);
    }
    remove(key) {
        this.storage.removeItem(key);
    }
    clear() {
        this.storage.clear();
    }

    static Local = new AppStorage(AppStorageMethod.LOCAL)
    static Session = new AppStorage(AppStorageMethod.SESSION)
}


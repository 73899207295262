import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Menu = ({
    menuWidth,
    open,
    anchorEl,
    anchorOrigin,
    transformOrigin,
    onClose,
    children,
    dontHide,
    className,
    preventHideOnSelect
}) => {
    const refNode = useRef(null);
    const refMenuIsMounted = useRef(false);
    const [top, setTop] = useState(null);
    const [left, setLeft] = useState(null);
    const [width, setWidth] = useState(null);
    const [innerMenuWidth, setInnerMenuWidth] = useState('auto');

    const ulRefNode = useRef(null);

    const [show, setShow] = useState(false);

    useEffect(() => {
        refMenuIsMounted.current = true;

        return () => {
            refMenuIsMounted.current = false;
        }
    }, [])

    useLayoutEffect(() => {
        const updateWidth = () => {
            if (menuWidth) {
                setInnerMenuWidth(menuWidth + 'px')
            }
            setWidth(window.innerWidth);
        }
        window.addEventListener('resize', updateWidth);
        updateWidth();
        return () => window.removeEventListener('resize', updateWidth);
    }, [menuWidth]);

    const getScrollbarWidth = () => {
        const outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.overflow = 'scroll';
        outer.style.msOverflowStyle = 'scrollbar';
        document.body.appendChild(outer);

        const inner = document.createElement('div');
        outer.appendChild(inner);

        const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);
        outer.parentNode.removeChild(outer);

        return scrollbarWidth;
    }

    const hideOverlay = () => {
        if (!dontHide) {
            setShow(false);
            setTimeout(() => {
                if (onClose) {
                    document.body.style = null;
                    if (refMenuIsMounted.current) {
                        //Dev Note: Call onClose() only when component is mounted.
                        onClose();
                    }
                }
            }, 100);
        }
    }

    useEffect(() => {
        const body = document.body;
        if (open) {
            if (body.scrollHeight > window.innerHeight) {
                body.style.overflow = 'hidden';
                body.style.paddingRight = getScrollbarWidth() + 'px';
            }
            setTimeout(() => { setShow(true); }, 100);
            document.getElementById("root").ariaHidden = true;
        } else {
            document.getElementById("root").ariaHidden = false;
            body.style = null
        }
    }, [open]);

    useEffect(() => {
        if (anchorEl && refNode && refNode.current) {
            const anchorElBounding = anchorEl.getBoundingClientRect();
            const refNodeBounding = refNode.current.getBoundingClientRect();

            const padding = 0;
            let top = anchorElBounding.top;
            let left = anchorElBounding.left;

            if (anchorOrigin.vertical === 'center') {
                top = anchorElBounding.top + (anchorElBounding.height / 2);
            } else if (anchorOrigin.vertical === 'bottom') {
                top = anchorElBounding.top + anchorElBounding.height;
            }

            if (anchorOrigin.horizontal === 'center') {
                left = anchorElBounding.left + (anchorElBounding.width / 2);
            } else if (anchorOrigin.horizontal === 'right') {
                left = anchorElBounding.left + (anchorElBounding.width);
            }

            if (transformOrigin.vertical === 'top') {
                if ((padding + refNodeBounding.height) < (window.innerHeight - anchorElBounding.y)) {
                    setTop(top); //New Design adds space between anchor and menu.
                } else {
                    setTop(top - (refNodeBounding.height));
                }
            } else if (transformOrigin.vertical === 'center') {
                setTop(top - (refNodeBounding.height / 2));
            } else if (transformOrigin.vertical === 'bottom') {
                if ((padding + refNodeBounding.height) > (window.innerHeight - anchorElBounding.y)) {
                    setTop(top - (refNodeBounding.height));
                } else {
                    setTop(top);
                }
            }

            if (transformOrigin.horizontal === 'left') {
                setLeft(left);
            } else if (transformOrigin.horizontal === 'center') {
                setLeft(left - (refNodeBounding.width / 2));
            } else if (transformOrigin.horizontal === 'right') {
                setLeft(left - refNodeBounding.width);
            }
        }
    }, [open, width, anchorEl, anchorOrigin, transformOrigin]);

    const handleRefNodeClick = event => {
        if (preventHideOnSelect) {
            event?.stopPropagation();
        }
    };

    const renderLayout = () => {
        return (
            open && anchorEl &&
            (
                <div className={`overlay-root ${(show ? 'show' : 'hide')}`} onClick={() => hideOverlay()} >
                    <div className='overlay' />
                    <div tabIndex={-1} ref={refNode} className='menu' style={{ top: top, left: left, width: innerMenuWidth }} onClick={handleRefNodeClick}>
                        <ul
                            ref={ulRefNode}
                            className={`menu-list ${className ?? ''}`}
                        >
                            {children}
                        </ul>
                    </div>
                </div>
            )
        );
    }
    return ReactDOM.createPortal(renderLayout(), document.querySelector("body"));
};

Menu.propTypes = {
    anchorOrigin: PropTypes.object,
    transformOrigin: PropTypes.object,
    numberOfItemsToshow: PropTypes.number
};

Menu.defaultProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
    numberOfItemsToshow: 10,
    menuWidth: 209
};

export default Menu;

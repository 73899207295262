const BoltIcon = ({ className }) => {
     return (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className || ''}`}>
               <g id="bolt">
                    <mask id="mask0_7476_81749" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                         <rect id="Bounding box" width="16" height="16" fill="currentColor" />
                    </mask>
                    <g mask="url(#mask0_7476_81749)">
                         <path id="bolt_2" d="M6.60004 13.4337L7.40004 9.13372H4.85004L8.93337 2.65039H8.80004L8.2167 7.66706H10.95L6.4667 13.4337H6.60004Z" fill="currentColor" />
                    </g>
               </g>
          </svg>
     );
};

export default BoltIcon;

import { Dialog } from "@/Components/Feedback";
import { Button } from "@/Components/Inputs";

import { getIntlDateTime, UnitOfTime } from "@/Helpers/timezones";

const AddQualityCheckReasonTableDialog = ({
    isOpen,
    onClose,
    rowData
}) => {
    const handleClose = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={handleClose}
            className="pb-[24px] w-[491px]"
        >
            <Dialog.Title className="text-[16px] font-medium tracking-[0.5px] text-on-surface-variant w-[380px]">
                {rowData?.reasonCode} {`(${rowData?.serial})`}
            </Dialog.Title>
            <Dialog.Content className="flex flex-col gap-[12px] !mb-[24px]">
                <p className="leading-[20px] tracking-[0.1px] font-medium text-on-surface-variant">
                    {getIntlDateTime(rowData?.createDate).format(UnitOfTime.MMDDYYYY)}
                </p>
                <div className="flex flex-col gap-[4px]">
                    <p className="leading-[20px] tracking-[0.1px] font-medium text-on-surface-variant">
                        Additional Notes
                    </p>
                    <p className="leading-[16px] tracking-[0.4px] text-on-surface">
                        {rowData?.additionalNotes}
                    </p>
                </div>
            </Dialog.Content>
            <Dialog.Actions className="justify-end">
                <Button className="w-[116px]" onClick={handleClose}>Close</Button>
            </Dialog.Actions>
        </Dialog>
    );
};

export default AddQualityCheckReasonTableDialog;

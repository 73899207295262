import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useGridFilter } from "ag-grid-react";
import { Checkbox, Radio, TextField } from "@/Components/Inputs";
import { MenuSearchIcon } from "@/Icons";

const FilterSet = forwardRef(({
    onModelChange,
    model,
    getValue,
    gridRef,
    colDef,
    customCol,
    optionId,
    gridApiRef
}, ref) => {

    const { field, filterParams } = colDef;
    const [searchFilter, setSearchFilter] = useState('');
    const [selectedOption, setSelectedOption] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [modelInput, setModelInput] = useState(model);

    const options = useMemo(() => {
        const rowData = gridRef?.current.props?.rowData || [];
        const field = colDef.filterParams?.field ?? colDef.field;

        const set = new Set(
            rowData.map(row => {
                if (customCol) {
                    return row?.completedOptions?.[optionId];
                } else {
                    return row?.[field];
                }
            })
                .filter(value => value !== undefined && value !== 0)
        );

        return Array.from(set).sort((a, b) => {
            return String(a).localeCompare(String(b));
          });
          
    }, [gridRef, colDef, customCol, optionId]);

    const statusFilterHeight = options.length * 32;

    const getFilteredOptions = useMemo(() => {
        return Array.from(options).filter(data => {
            if (data === undefined) return false;
            return data.toString().toLowerCase().includes(searchFilter.toLowerCase());
        });
    }, [searchFilter, options]);

    const onValueChanged = useCallback((event, type) => {
        if (type === 'array') {
            onModelChange(selectedOption?.length >= 0 ? selectedOption : null);
            return;
        }
        const value = event.target.value;
        setModelInput(value);
        onModelChange(value === '' ? null : value);
    }, [onModelChange, selectedOption]);


    const onSearchFilterChanged = useCallback((event) => {
        setSearchFilter(event.target.value);
    }, []);

    const onSelectedOptionChanged = useCallback((option) => {
        setSelectedOption((prevSelectedOption) => {
            let data = prevSelectedOption;
            if (prevSelectedOption?.includes(option)) {
                data = prevSelectedOption?.filter((selectedOption) => selectedOption !== option);
            } else {
                data = [
                    ...data,
                    option
                ];
            };
            return data;
        });
    }, []);

    const isFilterActive = useCallback(() => {
        return true;
    }, []);

    const getModel = useCallback((params) => {
        return undefined;
    }, []);

    const setModel = useCallback(() => {
        return undefined;
    }, []);

    const doesFilterPass = useCallback(({ node }) => {
        const value = getValue(node);
        if (typeof model !== 'string') {
            return selectedOption.includes(value);
        };
        return value?.toString() === model?.toString();
    }, [getValue, model, selectedOption]);

    const resetSearchFilter = () => {
        onModelChange(null);
        setModelInput('');
    };

    const setInputModel = (value) => {
        onModelChange(value);
        setModelInput(value);
    };
    
    const resetSelectedOptions = () => {
        resetSearchFilter();
        setSelectedOption([]);
    };

    useGridFilter({
        isFilterActive,
        getModel,
        setModel,
        doesFilterPass
    });

    useImperativeHandle(gridApiRef, () => ({
        onSelectedOptionChanged,
        resetSelectedOptions,
        resetSearchFilter,
        setInputModel
    }));

    useEffect(() => {
        setFilteredOptions(getFilteredOptions);
    }, [options, getFilteredOptions]);

    useEffect(() => {
        onModelChange(selectedOption?.length ? selectedOption : null);
    }, [selectedOption, onModelChange]);

    return (
        <div
            className="h-fit"
            style={{
                maxWidth: `220px`
            }}
        >
            <div className="p-3">
                {!filterParams?.suppressFilterSearchBar && (
                    <TextField
                        autoFocus
                        className={`filterset-textfield`}
                        style={{
                            marginBottom: filterParams?.suppressFilterLabel ? "8px" : "20px"
                        }}
                        type="text"
                        placeholder={filterParams?.filterSearchBarOption?.placeholder ?? "Search"}
                        startIcon={
                            !filterParams?.filterSearchBarOption?.suppressIcon &&
                            <MenuSearchIcon name="SearchIcon" className="!h-[20px] !w-[20px]" />
                        }
                        value={filterParams?.filterSearchBarOption?.type === 'row' ? modelInput : searchFilter}
                        onChange={filterParams?.filterSearchBarOption?.type === 'row' ? onValueChanged : onSearchFilterChanged}
                    />
                )}
                {!filterParams?.suppressFilterLabel && (
                    <div className={`
                        font-semibold text-on-surface-variant text-[12px] leading-[16px] 
                        ${field === "status" ? "!mt-0" : "!mt-3 mb-[12px]"}
                    `}>
                        {`Select ${filterParams?.filterLabelOption?.field ?? field}`}
                    </div>
                )}
            </div>
            {!filterParams?.suppressFilterList && (
                <div
                    style={{ height: field === "status" ? statusFilterHeight + options?.length * 12 : 293 }}
                    className={`w-full relative`}
                >
                    <div className={`
                                     flex flex-col absolute pl-3 w-full gap-[12px] h-full overflow-y-auto
                                     ${field === "status" ? "pr-3" : "pr-0"}
                                    `}>

                        {filteredOptions?.map((option, index) => (
                            <div
                                key={option}
                                className={`
                                    flex flex-row w-full items-center cursor-pointer !shrink-0
                                    ${filterParams?.filterListOption?.spacing === 'medium' ? 'h-[32px]' : 'h-24px'}
                                    ${filterParams?.filterListOption?.spacing === 'medium' ? 'p-[4px]' : 'p-0'}
                                    ${filterParams?.filterListOption?.spacing === 'medium' ? 'rounded-[4px]' : 'rounded-0'}
                                    ${filterParams?.filterListOption?.enableRowHoverBackground ? 'hover:bg-primary-fixed' : 'hover:bg-none'}
                                    ${filterParams?.filterListOption?.enableRowHoverBackground ? 'cursor-pointer' : 'cursor-default'}
                                    ${options.length > 10 && (options.length - 1) === index ? 'mb-[12px]' : ''}
                                `}
                            >
                                {filterParams?.filterListOption?.componentType === 'radio'
                                    ? <Radio
                                        checked={selectedOption.includes(option)}
                                        onChange={() => onSelectedOptionChanged(option)}
                                        label={
                                            <div className="font-normal text-[12px] leading-[16.34px]">
                                                {option}
                                            </div>
                                        }
                                    />
                                    : <Checkbox
                                        checked={selectedOption.includes(option)}
                                        onChange={() => onSelectedOptionChanged(option)}
                                        label={
                                            <div className="font-normal text-[12px] leading-[16.34px]">
                                                {option}
                                            </div>
                                        }
                                    />
                                }
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
});

export default FilterSet;

import { DoaReasonsStoreActionConstants, receiveDoaReasons } from '@/Store/Actions';
import { getDoaReasons } from '@/Store/Services';

export const DoaReasonsStoreMiddleware = ({ action, dispatch }) => {
    let success;

    switch (action.type) {
        case DoaReasonsStoreActionConstants.REQUEST_DOA_REASONS:
            success = doaReasons => {
                dispatch(receiveDoaReasons(doaReasons))
            }
            return getDoaReasons(success, action?.payload);
        default:
            return;
    }
};

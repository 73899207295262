

const TextIcon = ({ className }) => {

     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className}`} viewBox="0 0 24 24" fill="none">
               <mask id="mask0_1561_50828" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                    <rect width="24" height="24" fill="#171C20" />
               </mask>
               <g mask="url(#mask0_1561_50828)">
                    <path d="M19.5 5.25V8.25C19.5 8.44891 19.421 8.63968 19.2803 8.78033C19.1397 8.92098 18.9489 9 18.75 9C18.5511 9 18.3603 8.92098 18.2197 8.78033C18.079 8.63968 18 8.44891 18 8.25V6H12.75V18H15C15.1989 18 15.3897 18.079 15.5303 18.2197C15.671 18.3603 15.75 18.5511 15.75 18.75C15.75 18.9489 15.671 19.1397 15.5303 19.2803C15.3897 19.421 15.1989 19.5 15 19.5H9C8.80109 19.5 8.61032 19.421 8.46967 19.2803C8.32902 19.1397 8.25 18.9489 8.25 18.75C8.25 18.5511 8.32902 18.3603 8.46967 18.2197C8.61032 18.079 8.80109 18 9 18H11.25V6H6V8.25C6 8.44891 5.92098 8.63968 5.78033 8.78033C5.63968 8.92098 5.44891 9 5.25 9C5.05109 9 4.86032 8.92098 4.71967 8.78033C4.57902 8.63968 4.5 8.44891 4.5 8.25V5.25C4.5 5.05109 4.57902 4.86032 4.71967 4.71967C4.86032 4.57902 5.05109 4.5 5.25 4.5H18.75C18.9489 4.5 19.1397 4.57902 19.2803 4.71967C19.421 4.86032 19.5 5.05109 19.5 5.25Z" />
               </g>
          </svg>
     )
}

export default TextIcon
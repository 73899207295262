import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { ArrowDropDownIcon, CheckIcon, NavigateBefore, NavigateNext } from '@/Icons';

const Calendar = ({
    date,
    today,
    selectedDate,
    singleDate,
    setDate,
    startDate,
    endDate,
    onSetSelectedDate,
    disablePastDates,
    onSetSelectedDates,
    inBetweenDays,
    setInBetweenDays,
    disableFutureDates = true,
    hideFooterAction,
    showFooterAction
}) => {

    const getYearsDownto1970 = year => {
        const years = [];
        let currentLoopYear = year;

        if (!disableFutureDates) {
            currentLoopYear = 2099;
        }

        while (currentLoopYear > 1970) {
            years.push(currentLoopYear);
            currentLoopYear -= 1;
        }
        return years;
    };

    const DAYS_OF_THE_WEEK = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthWithSelection = MONTHS?.map((month, idx) => ({ month: month, selected: false, numeric: idx }));

    const [day, setDay] = useState(date.getDate());
    const [month, setMonth] = useState(date.getMonth());
    const [year, setYear] = useState(date.getFullYear());
    const [years, setYears] = useState(getYearsDownto1970(year));
    const [yearsWithSelection, setYearsWithSelection] = useState([]);
    const [enablePrevious, setEnablePrevious] = useState(true);
    const [seletedYear, setSelectedYear] = useState(year);
    const [selectedMonth, setSelectedMonth] = useState(month);
    const [isYearDropDownOpen, setIsYearDropDownOpen] = useState(false);
    const [isMonthDropDownOpen, setIsMonthDropDownOpen] = useState(false);

    const currentMonth = today.getMonth();
    const currentDate = today.getDate();
    const currentYear = today.getFullYear();
    let isPast = false;

    const getStartDayOfMonth = useCallback((date, startDate) => {
        return startDate === 0 ? 7 : new Date(date.getFullYear(), date.getMonth(), 2).getDay();
    }, []);

    // generating date range from start and endTime
    const getDateRange = useCallback(() => {
        if (!startDate || !endDate) {
            return;
        }

        const newInBetweenDays = [];
        const endTime = endDate.getTime();

        for (let dt = new Date(startDate); dt.getTime() <= endTime; dt.setDate(dt.getDate() + 1)) {
            const currentTime = dt.getTime();
            if (currentTime <= endTime) {
                newInBetweenDays.push(new Date(dt));
            }
        }
        setInBetweenDays(newInBetweenDays);
    }, [endDate, setInBetweenDays, startDate]);

    useEffect(() => {
        setYearsWithSelection(years.map(year => ({ year: year, selected: false })));
    }, [years, year]);

    useEffect(() => {
        setDay(date.getDate());
        setMonth(date.getMonth());
        setYear(date.getFullYear());

        if (singleDate && disablePastDates) {
            if (new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) || new Date(date).setHours(0, 0, 0, 0) === new Date(today).setHours(0, 0, 0, 0)) {
                return setEnablePrevious(false);
            }

            return setEnablePrevious(true);
        }
    }, [date, today, singleDate, disablePastDates, getStartDayOfMonth]);

    useEffect(() => {
        getDateRange(startDate, endDate);
    }, [startDate, endDate, getDateRange]);

    const convertDate = selectedDate => {
        const date = new Date(selectedDate),
            month = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);

        if (singleDate) {
            return [date.getFullYear(), month, day].join("/");
        } else {
            return moment(selectedDate).isValid() ? [date.getFullYear(), month, day].join("/") : null;
        }
    }
    //handle single Date select
    const handleSelectDate = selectedDate => {
        if (typeof onSetSelectedDate !== "function") return
        onSetSelectedDate({ singleDate: selectedDate });
    }

    const handleSetDates = (selectedDate) => {

        const isValidEndDate = (date) => {
            const start = moment(startDate, 'days');
            const end = moment(date, 'days');
            return start.isBefore(end);
        };
        if (startDate && endDate) {
            onSetSelectedDates({ startDate: selectedDate, endDate: null });
            setInBetweenDays([]);
        } else {
            if (startDate && !endDate) {

                const isValidEnd = isValidEndDate(selectedDate);

                onSetSelectedDates({
                    startDate: isValidEnd ? startDate : selectedDate,
                    endDate: isValidEnd ? selectedDate : null
                });

            } else {
                onSetSelectedDates({ startDate: selectedDate, endDate: null });
                setInBetweenDays([]);
            }
        }
    };

    const handleNext = () => {
        const newDate = new Date(year, month + 1, day);
        const newYear = newDate.getFullYear();
        setDate(newDate);
        if (newYear > year && !years.includes(newYear)) setYears([newYear, ...years]);
        setSelectedYear(newYear);
        setSelectedMonth(newDate.getMonth());
    };

    const handlePrevious = () => {
        const newDate = new Date(year, month - 1, day);
        if (enablePrevious) {
            setDate(newDate);
            setSelectedMonth(newDate.getMonth());
            setSelectedYear(newDate.getFullYear());
        };
    };

    const handleNextofYear = () => {
        const incrementYear = new Date(year + 1, month, day);
        const newYear = incrementYear.getFullYear();
        setDate(incrementYear);
        if (newYear > year && !years.includes(newYear)) {
            setYears([newYear, ...years]);
        };
    };

    const handlePreviousOfYear = () => {
        const decrementYear = new Date(year - 1, month, day);
        setDate(decrementYear);
        setYears(years.filter(item => item !== year));
    };


    useEffect(() => {
        setSelectedYear(year);
    }, [year]);

    const handleDaysKey = (day, index) => {
        if (index === 0) {
            return "SU";
        } else if (index === 2) {
            return "TU";
        } else {
            return day;
        };
    };

    const handleYearOnChange = (year) => {
        const newYear = new Date(year, month, day);
        setDate(newYear);
        setSelectedYear(year);
        setIsYearDropDownOpen(false);
    };

    const handleMonthOnChange = (month) => {
        const newYear = new Date(year, month, day);
        setDate(newYear);
        setSelectedMonth(month);
        setIsMonthDropDownOpen(false);
    };

    const handleSetYearIsOpenDropDown = () => {
        setIsYearDropDownOpen(prev => !prev);
        setIsMonthDropDownOpen(false);
    };

    const handleSetMonthIsOpenDropDown = () => {
        setIsMonthDropDownOpen(prev => !prev);
        setIsYearDropDownOpen(false);
    };
    useEffect(() => {
        if (isMonthDropDownOpen || isYearDropDownOpen) {
            hideFooterAction();
        } else {
            showFooterAction();
        }
    }, [isMonthDropDownOpen, isYearDropDownOpen, hideFooterAction, showFooterAction]);

    const getDaysInMonth = (month, year) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const generateCalendarDays = (year, month) => {
        const firstDay = new Date(year, month, 1).getDay();
        const lastDay = new Date(year, month, getDaysInMonth(month, year)).getDay();

        const daysInMonth = getDaysInMonth(month, year);

        const prevMonthDays = [];
        for (let i = firstDay - 1; i >= 0; i--) {
            prevMonthDays.push({
                day: new Date(year, month, -i).getDate(),
                currentMonth: false
            });
        }
        const currentMonthDays = [];
        for (let i = 1; i <= daysInMonth; i++) {
            currentMonthDays.push({
                day: i,
                currentMonth: true
            });
        }

        const nextMonthDays = [];
        for (let i = 1; i < 7 - lastDay; i++) {
            nextMonthDays.push({
                day: i,
                currentMonth: false
            });
        }
        return [...prevMonthDays, ...currentMonthDays, ...nextMonthDays];
    };

    return (
        <React.Fragment>
            <div className="flex h-[64px] items-center justify-between">
                <div className="flex items-center ">
                    <div className="size-[48px] flex items-center justify-center ">
                        {!isMonthDropDownOpen && !isYearDropDownOpen &&
                            <button className="flex items-center justify-center p-2" disabled={!enablePrevious} onClick={handlePrevious}>
                                <NavigateBefore className="text-on-surface !size-[24px]" />
                            </button>}
                    </div>
                    <div className="flex space-x-2 py-[10px] pl-2 pr-1 ">
                        <p className={`
                                     ${isYearDropDownOpen ? "opacity-20" : "text-on-surface"}
                                     font-roboto text-sm  tracking-[0.1px] leading-5 font-medium`
                        }>
                            {MONTHS[month].substring(0, 3)}
                        </p>
                        <button onClick={handleSetMonthIsOpenDropDown} className="size-[18px] flex justify-center h-full items-center ">
                            {!isYearDropDownOpen && <ArrowDropDownIcon className=" text-on-surface size-[18px]" />}
                        </button>
                    </div>
                    <div className="size-[48px] flex items-center justify-center">
                        {!isMonthDropDownOpen && !isYearDropDownOpen &&
                            <button className="flex  items-center justify-center p-2" onClick={handleNext}>
                                <NavigateNext className="text-on-surface !size-[24px]" />
                            </button>
                        }
                    </div>
                </div>
                <div className='flex h-fit items-center '>
                    <div className="size-[48px] flex items-center justify-center ">
                        {!isYearDropDownOpen && !isMonthDropDownOpen &&
                            <button className={`flex items-center justify-center p-2`} disabled={!enablePrevious} onClick={handlePreviousOfYear}>
                                <NavigateBefore className="text-on-surface !size-[24px]" />
                            </button>
                        }
                    </div>
                    <div className="flex space-x-2 py-[10px] pl-2 pr-1">
                        <div className={`
                                        ${isMonthDropDownOpen ? "opacity-20" : "text-on-surface"}  
                                        font-roboto text-sm  tracking-[0.1px] leading-5 font-medium`
                        }>
                            {year}
                        </div>
                        <button onClick={handleSetYearIsOpenDropDown} className="size-[18px] flex justify-center h-full items-center">
                            {!isMonthDropDownOpen &&
                                <ArrowDropDownIcon className=" cursor-pointer text-on-surface h-[18px] w-[18px]" />
                            }
                        </button>
                    </div>
                    <div className="size-[48px] flex items-center justify-center">
                        {!isYearDropDownOpen && !isMonthDropDownOpen &&
                            <button className={`items-center justify-center p-2`} onClick={handleNextofYear}>
                                <NavigateNext className="text-on-surface !size-[24px]" />
                            </button>}
                    </div>
                </div>
            </div>

            {(isMonthDropDownOpen || isYearDropDownOpen) && (
                <div className="w-full h-[1px] bg-outline-variant" />
            )}

            {isYearDropDownOpen && <div style={{ height: 7 * 48 }} className="pt-[6px] overflow-y-auto h-[336px] w-[360px]">
                {yearsWithSelection?.map((year, idx) => {
                    return <div
                        onClick={() => handleYearOnChange(year.year)}
                        className={`
                                    flex cursor-pointer w-full h-[48px] hover:bg-surface-tin-8 items-center py-1 gap-4 pl-4 ${year.year === seletedYear ? "bg-primary-fixed" : "bg-none"}`}
                        key={idx}
                    >
                        {year.year === seletedYear ?
                            <CheckIcon className="size-[24px] text-primary" />
                            : <div className='size-[24px]' />
                        }
                        <p className=' text-on-surface font-roboto text-base tracking-[0.5px]'>{year?.year}</p>
                    </div>
                })}
            </div>}

            {isMonthDropDownOpen &&
                <div style={{ height: 7 * 48 }} className="pt-[6px] overflow-y-auto h-[336px] w-[360px]">
                    {monthWithSelection?.map((month) => {
                        return <div
                            onClick={() => handleMonthOnChange(month.numeric)}
                            className={`
                                                flex cursor-pointer w-full h-[48px] items-center py-1 gap-4 pl-4 hover:bg-surface-tin-8
                                                ${month.numeric === selectedMonth ? "bg-primary-fixed" : "bg-none"}
                                            `}
                            key={month.numeric}
                        >
                            {month.numeric === selectedMonth ?
                                <CheckIcon className="size-[24px] text-primary" />
                                : <div className='size-[24px]' />
                            }
                            <p className=' text-on-surface font-roboto text-base tracking-[0.5px]'>
                                {month.month}
                            </p>
                        </div>
                    })}
                </div>}

            {!isYearDropDownOpen && !isMonthDropDownOpen &&
                <div className="calendar-body">
                    <div className="calendar-week-container">
                        {DAYS_OF_THE_WEEK.map((d, idx) => (
                            <div className="calendar-body-week " key={handleDaysKey(d, idx)}>
                                <span>{d}</span>
                            </div>
                        ))}
                    </div>

                    <div className="calendar-content">
                        {generateCalendarDays(year, month)?.map((day, index) => {

                            const d = day.day
                            const value = year + '/' + (month + 1) + '/' + d;
                            const valueGMT = new Date(day.currentMonth ? value : null);
                            const newCalendarValue = convertDate(valueGMT);
                            const isSelectedDate = valueGMT?.getTime() === selectedDate?.getTime()
                            const isDateInBetweenSelected = inBetweenDays.some(date => date.getTime() === valueGMT.getTime());
                            const isInBetweenStartAndEnd = inBetweenDays.slice(1, -1).some(date => date.getTime() === valueGMT.getTime());
                            const isStartDate = valueGMT.getTime() === startDate?.getTime()
                            const isEndDate = valueGMT.getTime() === endDate?.getTime()

                            if (singleDate && disablePastDates) isPast = new Date(newCalendarValue).setHours(0, 0, 0, 0) < new Date(today).setHours(0, 0, 0, 0);

                            // Display for Single Date Picker
                            if (singleDate) {
                                return (
                                    <button
                                        disabled={!day.currentMonth}
                                        className={
                                            `calendar-days-container flex items-center justify-center 
                                                 ${isInBetweenStartAndEnd ? "relative after:content-[''] after:h-[40px] after:w-[46px] after:bg-primary-fixed  after:absolute  after:left-5 after:-z-30 before:content-[''] before:h-[40px] before:w-[46px] before:bg-secondary-container  before:absolute before:right-5 before:-z-30 " : " "}
                                                 ${endDate === null || !isDateInBetweenSelected ? "after:hidden" : ""} 
                                         `}
                                    >
                                        <div
                                            className={`
                                                    calendar-body-days
                                                    ${disablePastDates && isPast && 'cursor-not-allowed text-gray-3'}
                                                    ${isSelectedDate ? 'font-normal !bg-primary rounded-full !text-surface' : 'bg-transparent'}
                                                    ${month === currentMonth && d === currentDate && currentYear === year ? '  border-[1px] rounded-full !text-primary border-primary font-normal' : ''}
                                                    ${!day.currentMonth ? " !text-outline-variant !cursor-not-allowed hover:!bg-none" : "hover:bg-surface-tin-8 hover:rounded-full"}
                                            `}
                                            key={'enabled' + index}
                                            onClick={() => {
                                                if (disablePastDates && isPast) return null;
                                                return handleSelectDate(valueGMT);
                                            }}
                                            date-value={newCalendarValue}
                                            tabIndex={d > 0 ? 0 : -1}
                                            role={d > 0 ? 'button' : 'div'}
                                        >
                                            {day.day}
                                        </div>
                                    </button>
                                );
                            } else {
                                return (
                                    <div
                                        className={`
                                                calendar-days-container flex items-center justify-center 
                                                ${isInBetweenStartAndEnd ? "relative after:content-[''] after:h-[40px] after:w-[46px] after:bg-primary-fixed  after:absolute  after:left-5 after:-z-30 before:content-[''] before:h-[40px] before:w-[46px] before:bg-primary-fixed  before:absolute before:right-5 before:-z-30 " : " "}
                                                ${endDate === null || !isDateInBetweenSelected ? "after:hidden" : ""} 
                                        `}
                                    >
                                        <button
                                            disabled={!day.currentMonth}
                                            className={`
                                                calendar-body-days 
                                                ${isStartDate ? `!bg-primary !rounded-full !text-surface relative  after:h-[40px] after:w-[46px] after:bg-primary-fixed after:absolute after:left-5 after:-z-30 ` : ""}
                                                ${isEndDate ? `!bg-primary !rounded-full !text-surface relative  after:content-[''] after:h-[40px] after:w-[46px] after:bg-primary-fixed after:absolute after:right-5 after:-z-30 ` : ""}
                                                ${month === currentMonth && d === currentDate && currentYear === year ? " border-[1px] rounded-full text-primary border-primary" : ''}
                                                ${isDateInBetweenSelected ? 'font-normal text-on-secondary-container bg-primary-fixed  rounded-none !border-0 ' : ''}
                                                ${endDate === null || !isDateInBetweenSelected ? "after:hidden" : ""}  
                                                ${isStartDate || isEndDate ? "!text-surface" : ""}
                                                ${!day.currentMonth ? " !text-outline-variant !cursor-not-allowed hover:!bg-none" : "hover:bg-surface-tin-8 hover:rounded-full"}

                                        `}
                                            role={d > 0 ? 'button' : 'div'} onClick={() => d > 0 ? handleSetDates(valueGMT) : {}}>
                                            {day.day}
                                        </button>
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>}
        </React.Fragment>
    );
};

export default Calendar;
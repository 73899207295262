import { DeviceStoreActionConstants } from '@/Store/Actions';

export const DeviceStoreReducer = ({ state, initialState, action }) => {
    switch (action.type) {
        case DeviceStoreActionConstants.RECEIVE_SCANNED_DEVICES:
            return {
                ...state,
                scannedDevices: action.payload
            };
        case DeviceStoreActionConstants.RECEIVE_OPTIONS:
            return {
                ...state,
                options: action.payload
            };
        case DeviceStoreActionConstants.RECEIVE_WEB_SOCKET:
            return {
                ...state,
                webSocket: action.payload
            };
        case DeviceStoreActionConstants.RECEIVE_DEVICE_SERVICE_RESPONSE:
            return {
                ...state,
                deviceServiceResponse: action.payload
            };
        case DeviceStoreActionConstants.RECEIVE_DEVICE_SERVICE_VERSION:
            return {
                ...state,
                deviceServiceVersion: action.payload
            };
        default:
            return state;
    }
};

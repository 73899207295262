

const ViewColumnIcon = ({ className }) => {
     
     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className}`}  viewBox="0 0 24 24" fill="none">
          <mask id="mask0_423_72999" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_423_72999)">
            <path d="M15.1923 20C14.6871 20 14.2596 19.825 13.9096 19.475C13.5596 19.125 13.3846 18.6974 13.3846 18.1923V4.8077C13.3846 4.30257 13.5596 3.875 13.9096 3.525C14.2596 3.175 14.6871 3 15.1923 3H18.5768C19.0819 3 19.5095 3.175 19.8595 3.525C20.2095 3.875 20.3845 4.30257 20.3845 4.8077V18.1923C20.3845 18.6974 20.2095 19.125 19.8595 19.475C19.5095 19.825 19.0819 20 18.5768 20H15.1923ZM5.80768 20C5.30256 20 4.875 19.825 4.525 19.475C4.175 19.125 4 18.6974 4 18.1923V4.8077C4 4.30257 4.175 3.875 4.525 3.525C4.875 3.175 5.30256 3 5.80768 3H9.19225C9.69737 3 10.1249 3.175 10.4749 3.525C10.8249 3.875 10.9999 4.30257 10.9999 4.8077V18.1923C10.9999 18.6974 10.8249 19.125 10.4749 19.475C10.1249 19.825 9.69737 20 9.19225 20H5.80768ZM5.49998 4.8077V18.1923C5.49998 18.2692 5.53202 18.3397 5.59613 18.4038C5.66023 18.4679 5.73074 18.5 5.80768 18.5H9.19225C9.26918 18.5 9.3397 18.4679 9.4038 18.4038C9.46792 18.3397 9.49997 18.2692 9.49997 18.1923V4.8077C9.49997 4.73077 9.46792 4.66024 9.4038 4.59613C9.3397 4.53203 9.26918 4.49998 9.19225 4.49998H5.80768C5.73074 4.49998 5.66023 4.53203 5.59613 4.59613C5.53202 4.66024 5.49998 4.73077 5.49998 4.8077Z"/>
          </g>
        </svg>
     )
}

export default ViewColumnIcon
import { OrdersStoreActionConstants } from '@/Store/Actions';

export const OrdersStoreReducer = ({ state, initialState, action }) => {
    switch (action.type) {
        case OrdersStoreActionConstants.RECEIVE_ORDERS:
            return {
                ...state,
                orders: action.payload
            };
        case OrdersStoreActionConstants.RECEIVE_COMPANIES:
            return {
                ...state,
                companies: action.payload
            };
        case OrdersStoreActionConstants.RECEIVE_SERIAL_NUMBERS:
            return {
                ...state,
                serialNumbers: action.payload
            };
        case OrdersStoreActionConstants.SET_OPEN_ITEMS_INITIAL_COLUMN_STATE:
            return {
                ...state,
                openItemsColumnItems: action.payload
            };
        case OrdersStoreActionConstants.SET_MY_BATCHES_INITIAL_COLUMN_STATE:
            return {
                ...state,
                myBatchesColumnItems: action.payload
            };
        case OrdersStoreActionConstants.SET_OPEN_KITS_INITIAL_COLUMN_STATE:
            return {
                ...state,
                openKitsColumnItems: action.payload
            };
        case OrdersStoreActionConstants.SET_MY_KITS_INITIAL_COLUMN_STATE:
            return {
                ...state,
                myKitsColumnItems: action.payload
            };
        case OrdersStoreActionConstants.RECEIVE_OPEN_ITEMS_TABLE_STATE:
            return {
                ...state,
                openItemsTableRowGroupState: action.payload
            };
        case OrdersStoreActionConstants.RECEIVE_OPTION_PART_NUMBER:
            return {
                ...state,
                partNumber: action.payload
            };
        case OrdersStoreActionConstants.RESET_STATE:
            return initialState
        case OrdersStoreActionConstants.SET_COLUMN_REORDERS:{
                const columnState = action.payload.column;
                let updatedState;
                switch (action.payload.currentTab) {
                    case 'open-items':
                        updatedState = { openItemsColumnItems: columnState };
                        break;
                    case 'open-kits':
                        updatedState = { openKitsColumnItems: columnState };
                        break;
                    case 'my-batches':
                        updatedState = { myBatchesColumnItems: columnState };
                        break;
                    case 'my-kits':
                        updatedState = { myKitsColumnItems: columnState };
                        break;
                    default:
                        return state;
                }
                return {
                    ...state,
                    ...updatedState
                };
            }
        default:
            return state;
    }
};

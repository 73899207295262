const AddKitErrorIcon = ({ className }) => {
     
     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className ?? ''}`} viewBox="0 0 18 18" fill="none">
               <path d="M9 0.851562C7.39303 0.851563 5.82214 1.32809 4.486 2.22087C3.14985 3.11366 2.10844 4.38261 1.49348 5.86726C0.87852 7.35191 0.717618 8.98558 1.03112 10.5617C1.34463 12.1378 2.11846 13.5855 3.25476 14.7218C4.39106 15.8581 5.8388 16.6319 7.4149 16.9454C8.99099 17.2589 10.6247 17.098 12.1093 16.4831C13.594 15.8681 14.8629 14.8267 15.7557 13.4906C16.6485 12.1544 17.125 10.5835 17.125 8.97656C17.1227 6.82238 16.266 4.75707 14.7427 3.23383C13.2195 1.71059 11.1542 0.853837 9 0.851562ZM9 15.8516C7.64026 15.8516 6.31105 15.4484 5.18046 14.6929C4.04987 13.9375 3.16868 12.8638 2.64833 11.6075C2.12798 10.3513 1.99183 8.96894 2.2571 7.63532C2.52238 6.3017 3.17716 5.07669 4.13864 4.1152C5.10013 3.15372 6.32514 2.49894 7.65876 2.23366C8.99238 1.96839 10.3747 2.10454 11.631 2.62489C12.8872 3.14524 13.9609 4.02643 14.7164 5.15702C15.4718 6.2876 15.875 7.61682 15.875 8.97656C15.8729 10.7993 15.1479 12.5468 13.8591 13.8356C12.5702 15.1245 10.8227 15.8495 9 15.8516ZM8.375 9.60156V5.22656C8.375 5.0608 8.44085 4.90183 8.55806 4.78462C8.67527 4.66741 8.83424 4.60156 9 4.60156C9.16576 4.60156 9.32474 4.66741 9.44195 4.78462C9.55916 4.90183 9.625 5.0608 9.625 5.22656V9.60156C9.625 9.76732 9.55916 9.92629 9.44195 10.0435C9.32474 10.1607 9.16576 10.2266 9 10.2266C8.83424 10.2266 8.67527 10.1607 8.55806 10.0435C8.44085 9.92629 8.375 9.76732 8.375 9.60156ZM9.9375 12.4141C9.9375 12.5995 9.88252 12.7807 9.77951 12.9349C9.67649 13.0891 9.53007 13.2092 9.35877 13.2802C9.18746 13.3512 8.99896 13.3697 8.81711 13.3335C8.63525 13.2974 8.4682 13.2081 8.33709 13.077C8.20598 12.9459 8.11669 12.7788 8.08052 12.597C8.04434 12.4151 8.06291 12.2266 8.13387 12.0553C8.20482 11.884 8.32499 11.7376 8.47916 11.6346C8.63333 11.5315 8.81458 11.4766 9 11.4766C9.24864 11.4766 9.4871 11.5753 9.66292 11.7512C9.83873 11.927 9.9375 12.1654 9.9375 12.4141Z" fill="currentColor" />
          </svg>
     );
};

export default AddKitErrorIcon;

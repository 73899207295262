import React, { forwardRef, useEffect, useState } from 'react';

const Checkbox = forwardRef(({
  label,
  name,
  checked,
  className,
  disabled,
  endIcon,
  onClick,
  value,
  style,
  attributes,
  listeners,
  Names,
  draggable,
  ...props
}, ref) => {
  const [isChecked, setIsChecked] = useState(false);
  const classNames = `checkbox-container select-none ${className || ''} ${disabled ? 'checkbox-disabled' : ''}`

  useEffect(() => {
    if (typeof checked === 'boolean') {
      setIsChecked(checked);
    }
  }, [checked]);

  const onChange = event => {
    if (draggable) {
      return;
    }
    setIsChecked(event?.target?.checked);
    onClick && typeof onClick === 'function' && onClick(event, name ?? "");
  };

  return (
    <label ref={ref} style={style} {...attributes} {...listeners} {...props} className={`${classNames}`}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
        value={value}
        className="checkbox"
        disabled={disabled}
        name={name}
      />
      {isChecked && (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="shrink-0">
          <path fillRule="evenodd" clipRule="evenodd" d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z" fill="#2F4455" />
          <path d="M8 13L10.0317 15L16 9" stroke="white" strokeWidth="2" strokeLinecap="round" />
        </svg>
      )}
      {!isChecked && (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="shrink-0">
          <path d="M5 4H19C19.5477 4 20 4.45228 20 5V19C20 19.5477 19.5477 20 19 20H5C4.45228 20 4 19.5477 4 19V5C4 4.45228 4.45228 4 5 4Z" stroke="#BDC8D0" strokeWidth="2" />
        </svg>
      )}
      {label && <div className="checkbox-label">{label}</div>}
    </label>
  );
})

export default Checkbox;

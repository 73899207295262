export const setColumnState = (columnApi, currentTab) => {
    if (columnApi.api && currentTab !== "") {
        const getColumnName = columnApi.api.getColumnDefs();
        const columnState = columnApi.api.getColumnState().map((item, idx) => {
            const getColumnHeader = getColumnName.find(coldef => coldef.colId === item.colId);
            return {
                id: idx + 1,
                colId: item.colId,
                hide: item.hide,
                columnName: getColumnHeader.headerName
            };
        });
        const columnOrderState = JSON.stringify(columnState);
        localStorage.setItem(currentTab, columnOrderState);
    };
};

export const getColumnState = (currentTab, columnDefs) => {
    if (columnDefs && currentTab) {
        try {
            const columnState = JSON.parse(localStorage.getItem(currentTab));
            if (columnState) {
                const reorderedColumnDefs = columnState.map((column) => {
                    const foundColumn = columnDefs.find((colDef) => colDef.headerName === column.columnName);
                    return { ...foundColumn, hide: column.hide };
                });
                return reorderedColumnDefs;
            }
        } catch (error) {
            console.error('Error loading or processing column state:', error);
        };
    }
};

export const customComparator = (valueA, valueB) => {
    const a = !!valueA ? valueA.toString().toLowerCase() : '';
    const b = !!valueB ? valueB.toString().toLowerCase() : '';

    const numA = parseFloat(a);
    const numB = parseFloat(b);

    const isNumA = !isNaN(numA);
    const isNumB = !isNaN(numB);

    if (isNumA && isNumB) {
        return numA - numB;
    }

    if (isNumA) return -1;
    if (isNumB) return 1;

    return a.localeCompare(b);
};


import { kitStoreActionConstants } from '@/Store/Actions';

export const kitStoreReducer = ({ state, initialState, action }) => {
    switch (action.type) {
        case kitStoreActionConstants.RECEIVE_KIT_ITEMS:
            return {
                ...state,
                kit: action.payload
            };
        case kitStoreActionConstants.RECEIVE_ACTIVE_KIT_DETAILS_ITEM_ID:
            return {
                ...state,
                activeKitDetailsItemId: action.payload
            };
        case kitStoreActionConstants.RECEIVE_KITS_INFO:
            return {
                ...state,
                kitsInfo: action.payload
            };
        case kitStoreActionConstants.RESET_STATE:
                return {
                    ...state,
                    kitsInfo: [],
                    kit:[],
                };
        default:
            return state;
    }
};


export const kitStoreActionConstants = {
    REQUEST_KIT_ITEMS: 'REQUEST_KIT_ITEMS',
    RECEIVE_KIT_ITEMS: 'RECEIVE_KIT_ITEMS',
    RECEIVE_ACTIVE_KIT_DETAILS_ITEM_ID: 'RECEIVE_ACTIVE_KIT_DETAILS_ITEM_ID',
    REQUEST_KITS_INFO: 'REQUEST_KITS_INFO',
    RECEIVE_KITS_INFO: 'RECEIVE_KITS_INFO',
    REQUEST_CREATE_KITS_AND_CLAIMS: 'REQUEST_CREATE_KITS_AND_CLAIMS',
    RECEIVE_CREATED_KITS_AND_CLAIMS: 'RECEIVE_CREATED_KITS_AND_CLAIMS',
    CREATE_KITS_AND_CLAIMS_SUCCESS: 'CREATE_KITS_AND_CLAIMS_SUCCESS',
    REQUEST_PASS_KIT: 'REQUEST_PASS_KIT',
    REQUEST_REMOVE_KIT_PRIMARY_ITEM: 'REQUEST_REMOVE_KIT_PRIMARY_ITEM',
    RESET_STATE: 'RESET_STATE'
};

export const requestKitItems = (companyId) => ({
    type: kitStoreActionConstants.REQUEST_KIT_ITEMS,
    payload: companyId
});

export const receiveKitItems = (companyId) => ({
    type: kitStoreActionConstants.RECEIVE_KIT_ITEMS,
    payload: companyId
});

export const requestKitsInfo = (kitsId) => ({
    type: kitStoreActionConstants.REQUEST_KITS_INFO,
    payload: kitsId
})

export const receiveKitsInfo = (kitsId) => ({
    type: kitStoreActionConstants.RECEIVE_KITS_INFO,
    payload: kitsId
})

export const receiveActiveKitDetailsItemId = itemId => ({
    type: kitStoreActionConstants.RECEIVE_ACTIVE_KIT_DETAILS_ITEM_ID,
    payload: itemId
});

export const requestCreateKitsAndClaims = (kits, handleSaveAndClaim) => ({
    type: kitStoreActionConstants.REQUEST_CREATE_KITS_AND_CLAIMS,
    payload: kits,
    function: handleSaveAndClaim
});

export const receiveCreatedKitsAndClaims = kits => ({
    type: kitStoreActionConstants.RECEIVE_CREATED_KITS_AND_CLAIMS,
    payload: kits
});

export const requestPassKit = requestBody => ({
    type: kitStoreActionConstants.REQUEST_PASS_KIT,
    payload: requestBody
});

export const requestRemoveKitPrimaryItem = requestBody => ({
    type: kitStoreActionConstants.REQUEST_REMOVE_KIT_PRIMARY_ITEM,
    payload: requestBody
});

export const resetkitState = () => ({
    type: kitStoreActionConstants.RESET_STATE
});

import { QaChecklistStoreActionConstants, receiveQaChecklist } from '@/Store/Actions';
import { getQaChecklist } from '@/Store/Services';

export const QaChecklistStoreMiddleware = ({ action, dispatch }) => {
    let success;

    switch (action.type) {
        case QaChecklistStoreActionConstants.REQUEST_QA_CHECKLIST:
            success = qaChecklist => {
                dispatch(receiveQaChecklist(qaChecklist));
            };
            return getQaChecklist(success, action?.payload);
        default:
            return;
    }
};

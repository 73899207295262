import { BatchStoreActionConstants } from '@/Store/Actions';

export const BatchStoreReducer = ({ state, initialState, action }) => {
    switch (action.type) {
        case BatchStoreActionConstants.RECEIVE_MY_BATCHES:
            return {
                ...state,
                myBatches: action.payload
            };
        case BatchStoreActionConstants.RECEIVE_BATCH_DETAILS:
            return {
                ...state,
                batchDetailsToDisplay: action.payload
            };
        case BatchStoreActionConstants.RECEIVE_ACTIVE_DETAILS_ID:
            return {
                ...state,
                activeDetailsId: action.payload
            }
        case BatchStoreActionConstants.RECEIVE_PAUSED_TIMERS:
            return {
                ...state,
                pausedTimers: action.payload
            }
        case BatchStoreActionConstants.RECEIVE_MY_KITS:
            return {
                ...state,
                myKits: action.payload
            };
        case BatchStoreActionConstants.RESET_STATE:
            return initialState
        default:
            return state;
    }
};

import React, { Component, createRef } from "react";
import { MainLayout } from "@/Layouts";
import { Stack, Tag, Toast } from "@/Components/Display";
import { Tab, TabContext, TabList, TabPanel, PanelList } from "@/Components/Navigation";
import { OpenKitsPage } from "@/Pages/Private/Tech/OpenKits";
import { MyBatchesPage } from "@/Pages/Private/Tech/MyBatches";
import { MyKitsPage } from "@/Pages/Private/Tech/MyKits";
import { OpenItemsPage } from "@/Pages/Private/Tech/OpenItems";
import { DetailsPage } from "@/Pages/Private/Tech/Details";
import { withStore } from "@/Helpers/withStore";
import {
    openItemsInitialColumn,
    openKitsInitialColumn,
    myBatchesInitialColumn,
    myKitsInitialColumn,
    receiveActiveDetailsId,
    receiveBatchDetails,
    receiveActiveKitDetailsItemId,
    requestDoaReasons,
    receiveWebSocket,
    receiveDeviceServiceResponse,
    receiveQaFailures,
    cancelRequestOrders
} from "@/Store/Actions";
import { FilterSet, FilterDate, CustomGroupRowHeader } from "@/Components/Display/Table/Custom/Components";
import { UnitOfTime, getIntlDateTime } from "@/Helpers/timezones";
import { withDeviceService } from "@/HOC";
import { customComparator } from "@/Helpers/TableColumnHelper";

class TechLayout extends Component {

    constructor(props) {

        super(props)

        this.isKitDetailsPage = props.location?.pathname?.includes('kit-details');
        this.isBatchDetailsPage = props.location?.pathname?.includes('batch-details');

        this.openItemsGridRef = createRef();
        this.openItemsFilterGridApiRef = createRef();
        this.openItemsFilterDateGridApiRef = createRef();

        this.sortModelResetRef = createRef(null);
        this.reOrderResetRef = createRef(null);
        //open kits
        this.openKitsGridRef = createRef();
        this.openKitsFilterGridApiRef = createRef();
        this.openKitsFilterDateGridApiRef = createRef();
        // my batches
        this.myBatchesGridRef = createRef();
        this.myBatchesFilterGridApiRef = createRef();
        this.myBatchesFilterDateGridApiRef = createRef();
        //my kits
        this.myKitsGridRef = createRef();
        this.myKitsFilterGridApiRef = createRef();
        this.myKitsFilterDateGridApiRef = createRef();

        this.state = {
            openItemsColumnDefs: [
                {
                    headerName: 'Company Name',
                    showRowGroup: true,
                    filter: FilterSet,
                    filterParams: {
                        gridRef: this.openItemsGridRef,
                        gridApiRef: this.openItemsFilterGridApiRef,
                        field: 'company',
                        filterLabelOption: {
                            field: 'Company'
                        }
                    },
                    filterValueGetter: (params) => params.data?.company,
                    cellRenderer: 'agGroupCellRenderer',
                    cellRendererParams: {
                        suppressCount: true,
                        innerRenderer: CustomGroupRowHeader,
                        innerRendererParams: {
                            tableData: this.props.orders,
                            gridRef: this.openItemsGridRef
                        }
                    },
                    valueGetter: (params) => params,
                    visible: false,
                    colSpan: ({ node, api }) => node?.group ? api?.getColumns()?.length : 1,
                    colId: 'Company Name',
                    sort: 'asc',
                    cellClass: 'locked-col',
                    lockPosition: 'left'
                },
                {
                    headerName: 'Company',
                    field: 'company',
                    rowGroup: true,
                    hide: true,
                    sort: 'asc'
                },
                {
                    headerName: 'Order Number',
                    field: 'orderNumber',
                    filter: FilterSet,
                    filterParams: {
                        gridRef: this.openItemsGridRef,
                        gridApiRef: this.openItemsFilterGridApiRef,
                        filterLabelOption: {
                            field: 'Order Number'
                        },
                    }
                },
                {
                    headerName: 'Date',
                    field: 'createDate',
                    filter: FilterDate,
                    filterParams: {
                        gridRef: this.openItemsGridRef,
                        gridApiRef: this.openItemsFilterDateGridApiRef,
                    },
                    cellRenderer: ({ getValue }) => getValue() ? getIntlDateTime(getValue()).format(UnitOfTime.MMDDYYYY) : '',
                    sort: 'desc'
                },
                {
                    headerName: 'Part Number',
                    field: 'partNumber',
                    filter: FilterSet,
                    comparator: customComparator,
                    filterParams: {
                        gridRef: this.openItemsGridRef,
                        gridApiRef: this.openItemsFilterGridApiRef,
                        filterLabelOption: {
                            field: 'Part Number'
                        }
                    },
                },
                {
                    headerName: 'Num Items',
                    field: 'total',
                    filter: FilterSet,
                    filterParams: {
                        gridRef: this.openItemsGridRef,
                        gridApiRef: this.openItemsFilterGridApiRef,
                        gridApi: this.gridApi,
                        filterSearchBarOption: {
                            type: 'row',
                            placeholder: 'Type QTY',
                            suppressIcon: true
                        },
                        suppressFilterLabel: true,
                        suppressFilterList: true,
                        showNoRowsOverlay: this.showNoRowsOverlay,
                        getRowCount: this.getRowCount
                    }
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    filter: FilterSet,
                    filterParams: {
                        gridRef: this.openItemsGridRef,
                        gridApiRef: this.openItemsFilterGridApiRef,
                        filterListOption: {
                            spacing: 'medium',
                            enableRowHoverBackground: true
                        },
                        suppressFilterSearchBar: true
                    },
                    cellRenderer: this.formatToTags
                }
            ],
            openKitsColumnDefs: [
                {
                    headerName: 'Company Name',
                    showRowGroup: true,
                    filter: FilterSet,
                    filterParams: {
                        gridRef: this.openKitsGridRef,
                        gridApiRef: this.openKitsFilterGridApiRef,
                        field: 'company',
                        filterLabelOption: {
                            field: 'Company'
                        }
                    },
                    filterValueGetter: (params) => params.data?.company,
                    cellRenderer: 'agGroupCellRenderer',
                    cellRendererParams: {
                        suppressCount: true,
                        innerRenderer: CustomGroupRowHeader,
                        innerRendererParams: {
                            tableData: this.props.orders,
                            gridRef: this.openKitsGridRef
                        }
                    },
                    visible: false,
                    colSpan: ({ node, api }) => node?.group ? api?.getColumns()?.length : 1,
                    colId: 'Company Name',
                    sort: 'asc',
                    cellClass: 'locked-col',
                    lockPosition: 'left'
                },
                {
                    headerName: 'Company',
                    field: 'company',
                    rowGroup: true,
                    hide: true,
                    sort: 'asc'
                },
                {
                    headerName: 'Date',
                    field: 'createDate',
                    filter: FilterDate,
                    filterParams: {
                        gridRef: this.openKitsGridRef,
                        gridApiRef: this.openKitsFilterDateGridApiRef
                    },
                    cellRenderer: ({ getValue }) => getValue() ? getIntlDateTime(getValue()).format(UnitOfTime.MMDDYYYY) : '',
                    sort: 'desc'
                },
                {
                    headerName: 'Part Number',
                    field: 'kitName',
                    filter: FilterSet,
                    comparator: customComparator,
                    filterParams: {
                        gridRef: this.openKitsGridRef,
                        gridApiRef: this.openKitsFilterGridApiRef,
                        filterLabelOption: {
                            field: 'Part Number'
                        }
                    },
                },
                {
                    headerName: 'Num Items',
                    field: 'total',
                    filter: FilterSet,
                    filterParams: {
                        gridRef: this.openKitsGridRef,
                        gridApiRef: this.openKitsFilterGridApiRef,
                        filterSearchBarOption: {
                            type: 'row',
                            placeholder: 'Type QTY',
                            suppressIcon: true
                        },
                        suppressFilterLabel: true,
                        suppressFilterList: true
                    }
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    filter: FilterSet,
                    filterParams: {
                        gridRef: this.openKitsGridRef,
                        gridApiRef: this.openKitsFilterGridApiRef,
                        filterListOption: {
                            spacing: 'medium',
                            enableRowHoverBackground: true
                        },
                        suppressFilterSearchBar: true
                    },
                    cellRenderer: this.formatToTags
                }
            ],
            myBatchesColumnDefs: [
                {
                    headerName: 'Date',
                    field: 'createDate',
                    filter: FilterDate,
                    filterParams: {
                        gridRef: this.myBatchesGridRef,
                        gridApiRef: this.myBatchesFilterDateGridApiRef,
                    },
                    cellRenderer: ({ getValue }) => getValue() ? getIntlDateTime(getValue()).format(UnitOfTime.MMDDYYYYHHmma) : '',
                    sort: 'desc'
                },
                {
                    headerName: 'Part Number',
                    field: 'partNumber',
                    filter: FilterSet,
                    comparator: customComparator,
                    filterParams: {
                        gridRef: this.myBatchesGridRef,
                        gridApiRef: this.myBatchesFilterGridApiRef,
                        filterLabelOption: {
                            field: 'Part Number'
                        }
                    },
                },
                {
                    headerName: 'Total',
                    field: 'total',
                    filter: FilterSet,
                    filterParams: {
                        gridRef: this.myBatchesGridRef,
                        gridApiRef: this.myBatchesFilterGridApiRef,
                        filterSearchBarOption: {
                            type: 'row',
                            placeholder: 'Type QTY',
                            suppressIcon: true
                        },
                        suppressFilterLabel: true,
                        suppressFilterList: true
                    },
                    aggFunc: ({ values }) => {
                        const totalItems = values?.reduce((accumulator, total) => {
                            return accumulator + total;
                        }, 0);

                        return `${totalItems} Total Items`;
                    }
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    filter: FilterSet,
                    filterParams: {
                        gridRef: this.myBatchesGridRef,
                        gridApiRef: this.myBatchesFilterGridApiRef,
                        filterListOption: {
                            spacing: 'medium',
                            enableRowHoverBackground: true
                        },
                        suppressFilterSearchBar: true
                    },
                    cellRenderer: this.formatToTags
                }
            ],
            myKitsColumnDefs: [
                {
                    headerName: 'Date',
                    field: 'createDate',
                    filter: FilterDate,
                    filterParams: {
                        gridRef: this.myKitsGridApi,
                        gridApiRef: this.myKitsFilterDateGridApiRef,
                    },
                    cellRenderer: ({ getValue }) => getValue() ? getIntlDateTime(getValue()).format(UnitOfTime.MMDDYYYYHHmma) : '',
                    sort: 'desc'
                },
                {
                    field: 'kitName',
                    headerName: 'Part Number',
                    filter: FilterSet,
                    comparator: customComparator,
                    filterParams: {
                        gridRef: this.myKitsGridRef,
                        gridApiRef: this.myKitsFilterGridApiRef,
                        filterLabelOption: {
                            field: 'Part Number'
                        }
                    }
                },
                {
                    headerName: 'Num Items',
                    field: 'total',
                    filter: FilterSet,
                    filterParams: {
                        gridRef: this.myKitsGridRef,
                        gridApiRef: this.myKitsFilterGridApiRef,
                        filterSearchBarOption: {
                            type: 'row',
                            placeholder: 'Type QTY',
                            suppressIcon: true
                        },
                        suppressFilterLabel: true,
                        suppressFilterList: true
                    },
                    aggFunc: ({ values }) => {
                        const totalItems = values?.reduce((accumulator, total) => {
                            return accumulator + total;
                        }, 0);

                        return `${totalItems} Total Items`;
                    }
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    filter: FilterSet,
                    filterParams: {
                        gridRef: this.myKitsGridRef,
                        gridApiRef: this.myKitsFilterGridApiRef,
                        filterListOption: {
                            spacing: 'medium',
                            enableRowHoverBackground: true
                        },
                        suppressFilterSearchBar: true
                    },
                    cellRenderer: this.formatToTags
                }
            ],
            isLoadingDialogOpen: true,
            selectedPartNumber: '',
            isRefreshing: false,
            kits: ['Test Kit 1', 'Test Kit 2'],
            openItemsGridApi: null,
            openKitsGridApi: null,
            myBatchesGridApi: null,
            myKitsGridApi: null,
            isCloseTimerRunningToastOpen: false
        };

        this.pathName = props.location?.pathname?.replace('/', '') || 'open-items';
    };

    formatToTags = (props) => {
        return <Tag className={props.getValue()} label={props.getValue()} />
    };

    handleToggleLoadingDialog = isLoadingDialogOpen => {
        this.setState({
            isLoadingDialogOpen
        });
    };

    handleTabListChange = tabValue => {
        this.props.history?.push(`/${tabValue}`);
    };

    handleBatchTagClick = (batchId, batchType) => {
        if (batchId?.toString() === this.props.activeDetailsId?.toString()) {
            return;
        }

        if (this.isBatchDetailsPage && batchType === 'batch') {
            this.props.receiveActiveDetailsId(batchId);
            return;
        }

        if (this.isKitDetailsPage && batchType === 'kit') {
            this.props.receiveActiveDetailsId(batchId);
            return;
        }

        this.props.history?.push(batchType === 'batch' ? '/batch-details' : '/kit-details', {
            batchId
        });
    };

    handleRemoveBatchClick = batchToBeRemoved => {
        const isTimerPausedForBatchToBeRemoved = Array.isArray(this.props.pausedTimers) 
            && this.props.pausedTimers?.find(pausedTimer => pausedTimer?.batchID.toString() === batchToBeRemoved?.toString()) !== undefined
        if (isTimerPausedForBatchToBeRemoved) {
            if (batchToBeRemoved && this.props.batchDetailsToDisplay?.find(batch => batch?.batchID?.toString() === batchToBeRemoved?.toString()) !== undefined) {
                const newBatchesToBeDisplayed = this.props.batchDetailsToDisplay?.filter(batch => batch?.batchID?.toString() !== batchToBeRemoved);

                if (this.props.activeDetailsId?.toString() === batchToBeRemoved?.toString()) {
                    const indexBatchToBeRemoved = this.props.batchDetailsToDisplay?.findIndex(batch => batch?.batchID?.toString() === batchToBeRemoved?.toString())
                    const next = this.props.batchDetailsToDisplay[indexBatchToBeRemoved + 1];
                    const previous = this.props.batchDetailsToDisplay[indexBatchToBeRemoved - 1];

                    if (next) {
                        this.props.receiveActiveDetailsId(next?.batchID);
                    } else if (previous) {
                        this.props.receiveActiveDetailsId(previous?.batchID);
                    } else {
                        this.props.receiveActiveDetailsId(null);
                    }
                }

                const filteredQaFailures = this.props.qaFailures?.filter(qaFailure => qaFailure?.batchID?.toString() !== batchToBeRemoved?.toString());
                this.props.receiveQaFailures(filteredQaFailures?.length === 0 ? null : filteredQaFailures);
                this.props.receiveBatchDetails(newBatchesToBeDisplayed);
            }

            return;
        }

        this.setState({
            isCloseTimerRunningToastOpen: true
        });
    };

    handleCloseCloseTimerRunningToastOpen = () => {
        this.setState({
            isCloseTimerRunningToastOpen: false
        });
    };

    initializeColDef = (colDef) => {
        return colDef?.map((columnDef, idx) => {
            return {
                id: idx + 1,
                hide: columnDef.hide || false,
                columnName: columnDef.headerName ? columnDef.headerName : columnDef.field,
                colId: columnDef.colId ? columnDef.colId : columnDef.field,
                showRowGroup: columnDef.showRowGroup ? columnDef.showRowGroup : null,
                rowGroup: columnDef.rowGroup ? columnDef.rowGroup : null
            }
        });
    };

    fetchData = async () => {
        this.setState({
            isLoadingDialogOpen: false,
            isRefreshing: false,
        });
    };

    handleGridApiSetState = (api) => {
        this.openItemsFilterGridApiRef.current = api;
        this.openKitsFilterGridApiRef.current = api;
        this.myBatchesFilterGridApiRef.current = api;
        this.myKitsFilterGridApiRef.current = api;

        this.openItemsFilterDateGridApiRef.current = api;
        this.openKitsFilterDateGridApiRef.current = api;
        this.myBatchesFilterDateGridApiRef.current = api;
        this.myKitsFilterDateGridApiRef.current = api;

        this.setState({
            openItemsGridApi: api,
            openKitsGridApi: api,
            myBatchesGridApi: api,
            myKitsGridApi: api
        });
    };

    getDetailsToDisplay = () => {
        return Array.isArray(this.props.batchDetailsToDisplay) ? this.props.batchDetailsToDisplay?.map(({ batchID, batchType, batchName }) => {
            return {
                label: batchName,
                batchID,
                batchType
            };
        }) : [];
    };

    async componentDidMount() {
        if (!this.props.webSocket) {
            await this.props.initWsDeviceService();
        }

        try {
            await this.fetchData();

            if (!this?.props.doaReasons) {
                await this.props.requestDoaReasons();
            }
        } catch (error) {
            console.log(error);
        }

        this.unlisten = this.props.history.listen(location => {
            this.props.cancelRequestOrders();
        });
    };

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
        }
    };

    render() {
        const {
            isRefreshing,
            openKitsColumnDefs,
            openItemsGridApi,
            openKitsGridApi,
            openItemsColumnDefs,
            myBatchesGridApi,
            myBatchesColumnDefs,
            myKitsColumnDefs,
            myKitsGridApi,
            isCloseTimerRunningToastOpen
        } = this.state;
        
        const currentTab = this.pathName;
        const detailsToDisplay = this.getDetailsToDisplay();

        return (
            <MainLayout>
                <div className="px-10 pt-2  overflow-auto" style={{ height: 'calc(100vh - 64px)' }}>
                    <TabContext className="relative">
                        <TabList id={this.pathName} onChange={this.handleTabListChange}>
                            <Tab className="shrink-0" value="open-items" label="Open Items" />
                            <Tab className="shrink-0" value="open-kits" label="Open Kits" />
                            <Tab className="shrink-0" value="my-batches" label="My Batches" />
                            <Tab className="shrink-0" value="my-kits" label="My Kits" />
                            {detailsToDisplay.length > 0 && (
                                <Stack justifyContent="flex-start" className="gap-[9px] overflow-x-auto" style={{ width: this.isKitDetailsPage ? 'calc(100% - 550px)' : '' }}>
                                    {detailsToDisplay.map(({ batchID, label, batchType }) => {
                                        const isBatchActive = this.props.activeDetailsId?.toString() === batchID?.toString();

                                        return (
                                            <Tag
                                                hasCloseButton
                                                key={batchID}
                                                className={`flex gap-[4px] rounded-[5px] px-[12px] shrink-0 h-[24px] text-white cursor-pointer bg-primary ${isBatchActive ? '' : 'opacity-80'}`}
                                                label={
                                                    <>
                                                        {isBatchActive && <div className="rounded-full size-[7px] bg-secondary-fixed" />}
                                                        <p className="text-[12px] leading-[16px] font-normal tracking-[0.4px]">{label}</p>
                                                    </>
                                                }
                                                onClick={() => this.handleBatchTagClick(batchID, batchType)}
                                                onRemove={() => this.handleRemoveBatchClick(batchID)}
                                            />
                                        );
                                    })}
                                </Stack>
                            )}
                        </TabList>
                        <PanelList id={this.pathName}>
                            <TabPanel className="" value="open-items">
                                <OpenItemsPage
                                    isRefreshing={isRefreshing}
                                    handleGridApiSetState={this.handleGridApiSetState}
                                    openItemsGridApi={openItemsGridApi}
                                    openItemsColumnDefs={openItemsColumnDefs}
                                    openItemsGridRef={this.openItemsGridRef}
                                    currentTab={currentTab}
                                    openItemsFilterGridApiRef={this.openItemsFilterGridApiRef}
                                    openItemsFilterDateGridApiRef={this.openItemsFilterDateGridApiRef}
                                    sortModelResetRef={this.sortModelResetRef}
                                    reOrderResetRef={this.reOrderResetRef}
                                />
                            </TabPanel>
                            <TabPanel className="" value="open-kits">
                                <OpenKitsPage
                                    openKitsGridRef={this.openKitsGridRef}
                                    openKitsGridApi={openKitsGridApi}
                                    openKitsColumnDefs={openKitsColumnDefs}
                                    handleGridApiSetState={this.handleGridApiSetState}
                                    isRefreshing={isRefreshing}
                                    currentTab={currentTab}
                                    openKitsFilterGridApiRef={this.openKitsFilterGridApiRef}
                                    openKitsFilterDateGridApiRef={this.openKitsFilterDateGridApiRef}
                                    sortModelResetRef={this.sortModelResetRef}
                                    reOrderResetRef={this.reOrderResetRef}
                                />
                            </TabPanel>
                            <TabPanel className="" value="my-batches">
                                <MyBatchesPage
                                    myBatchesGridRef={this.myBatchesGridRef}
                                    myBatchesGridApi={myBatchesGridApi}
                                    myBatchesColumnDefs={myBatchesColumnDefs}
                                    handleGridApiSetState={this.handleGridApiSetState}
                                    isRefreshing={isRefreshing}
                                    currentTab={currentTab}
                                    myBatchesFilterGridApiRef={this.myBatchesFilterGridApiRef}
                                    myBatchesFilterDateGridApiRef={this.myBatchesFilterDateGridApiRef}
                                    sortModelResetRef={this.sortModelResetRef}
                                    reOrderResetRef={this.reOrderResetRef}
                                />
                            </TabPanel>
                            <TabPanel className="" value="my-kits">
                                <MyKitsPage
                                    myKitsGridRef={this.myKitsGridRef}
                                    myKitsGridApi={myKitsGridApi}
                                    myKitsColumnDefs={myKitsColumnDefs}
                                    handleGridApiSetState={this.handleGridApiSetState}
                                    currentTab={currentTab}
                                    myKitsFilterGridApiRef={this.myKitsFilterGridApiRef}
                                    myKitsFilterDateGridApiRef={this.myKitsFilterDateGridApiRef}
                                    sortModelResetRef={this.sortModelResetRef}
                                    reOrderResetRef={this.reOrderResetRef}
                                />
                            </TabPanel>
                            <TabPanel value="kit-details">
                                <DetailsPage />
                            </TabPanel>
                            <TabPanel value="batch-details">
                                <DetailsPage />
                            </TabPanel>
                        </PanelList>
                    </TabContext>
                </div>
                <Toast
                    status="error"
                    message="You need to pause the timer before closing the batch tab."
                    open={isCloseTimerRunningToastOpen}
                    onClose={this.handleCloseCloseTimerRunningToastOpen}
                />
            </MainLayout>
        );
    };
}

const mapStateToProps = ({ orders, batch, kit, doaReasons, device, qaFailure }) => {
    return {
        openItemsColumnItems: orders?.openItemsColumnItems,
        openKitsColumnItems: orders?.openKitsColumnItems,
        myBatchesColumnItems: orders?.myBatchesColumnItems,
        myKitsColumnItems: orders?.myKitsColumnItems,
        batchDetailsToDisplay: batch?.batchDetailsToDisplay,
        activeDetailsId: batch?.activeDetailsId,
        pausedTimers: batch?.pausedTimers,
        activeKitDetailsItemId: kit?.activeKitDetailsItemId,
        doaReasons: doaReasons?.doaReasons,
        webSocket: device?.webSocket,
        qaFailures: qaFailure?.qaFailures
    };
};

const mapDispatchToProps = ({ orders, batch, kit, doaReasons, device, qaFailure }) => {
    return {
        openItemsInitialColumn: openItemsColumnItems => orders.dispatch(openItemsInitialColumn(openItemsColumnItems)),
        openKitsInitialColumn: openKitsColumnItems => orders.dispatch(openKitsInitialColumn(openKitsColumnItems)),
        myBatchesInitialColumn: myBatchesColumnItems => orders.dispatch(myBatchesInitialColumn(myBatchesColumnItems)),
        myKitsInitialColumn: myKitsColumnItems => orders.dispatch(myKitsInitialColumn(myKitsColumnItems)),
        receiveActiveDetailsId: activeDetailsId => batch.dispatch(receiveActiveDetailsId(activeDetailsId)),
        receiveBatchDetails: batchDetailsToDisplay => batch.dispatch(receiveBatchDetails(batchDetailsToDisplay)),
        receiveActiveKitDetailsItemId: itemId => kit.dispatch(receiveActiveKitDetailsItemId(itemId)),
        requestDoaReasons: queryParams => doaReasons.dispatch(requestDoaReasons(queryParams)),
        receiveWebSocket: webSocket => device.dispatch(receiveWebSocket(webSocket)),
        receiveDeviceServiceResponse: response => device.dispatch(receiveDeviceServiceResponse(response)),
        receiveQaFailures: response => qaFailure.dispatch(receiveQaFailures(response)),
        cancelRequestOrders: () => orders.dispatch(cancelRequestOrders())
    };
};

export default withStore(
    mapStateToProps,
    mapDispatchToProps
)(withDeviceService(TechLayout));

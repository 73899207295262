import { Stack } from "@/Components/Display";
import { CloseIcon } from "@/Icons";

const ListItem = ({
    item,
    className,
    onItemRemove,
}) => {
    const handleItemRemove = () => {
        if (onItemRemove && typeof onItemRemove === 'function') {
            onItemRemove(item);
        }
    };
    
    return (
        <Stack className={`list-item-element ${className || ''}`}>
            <p className="list-item-text">{item}</p>
            <button  onClick={handleItemRemove}>
                <CloseIcon className="!size-4" />
            </button>
        </Stack>
    );
};

export default ListItem;

export const DeviceStoreActionConstants = {
    REQUEST_SCAN_DEVICES: 'REQUEST_SCAN_DEVICES',
    RECEIVE_SCANNED_DEVICES: 'RECEIVE_SCANNED_DEVICES',
    REQUEST_OPTIONS: 'REQUEST_OPTIONS',
    RECEIVE_OPTIONS: 'RECEIVE_OPTIONS',
    REQUEST_UPDATE_DEVICE_INFO: 'REQUEST_UPDATE_DEVICE_INFO',
    REQUEST_PASS_DEVICE: 'REQUEST_PASS_DEVICE',
    REQUEST_FLAG_DEVICE: 'REQUEST_FLAG_DEVICE',
    REQUEST_FLAG_DEVICE_DOA: 'REQUEST_FLAG_DEVICE_DOA',
    RECEIVE_WEB_SOCKET: 'RECEIVE_WEB_SOCKET',
    RECEIVE_DEVICE_SERVICE_RESPONSE: 'RECEIVE_DEVICE_SERVICE_RESPONSE',
    RECEIVE_DEVICE_SERVICE_VERSION: 'RECEIVE_DEVICE_SERVICE_VERSION'
};

export const requestScanDevices = queryParams => ({
    type: DeviceStoreActionConstants.REQUEST_SCAN_DEVICES,
    payload: queryParams
});

export const receiveScannedDevices = scannedDevices => ({
    type: DeviceStoreActionConstants.RECEIVE_SCANNED_DEVICES,
    payload: scannedDevices
});

export const requestOptions = requestBody => ({
    type: DeviceStoreActionConstants.REQUEST_OPTIONS,
    payload: requestBody
});

export const receiveOptions = options => ({
    type: DeviceStoreActionConstants.RECEIVE_OPTIONS,
    payload: options
});

export const requestUpdateDeviceInfo = requestBody => ({
    type: DeviceStoreActionConstants.REQUEST_UPDATE_DEVICE_INFO,
    payload: requestBody
});

export const requestPassDevice = requestBody => ({
    type: DeviceStoreActionConstants.REQUEST_PASS_DEVICE,
    payload: requestBody
});

export const requestFlagDevice = requestBody => ({
    type: DeviceStoreActionConstants.REQUEST_FLAG_DEVICE,
    payload: requestBody
});

export const requestFlagDeviceDoa = requestBody => ({
    type: DeviceStoreActionConstants.REQUEST_FLAG_DEVICE_DOA,
    payload: requestBody
});

export const receiveWebSocket = webSocket => ({
    type: DeviceStoreActionConstants.RECEIVE_WEB_SOCKET,
    payload: webSocket
});

export const receiveDeviceServiceResponse = response => ({
    type: DeviceStoreActionConstants.RECEIVE_DEVICE_SERVICE_RESPONSE,
    payload: response
});

export const receiveDeviceServiceVersion = version => ({
    type: DeviceStoreActionConstants.RECEIVE_DEVICE_SERVICE_VERSION,
    payload: version
});



const BarcodeReaderIcon = ({ className }) => {

     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className}`} viewBox="0 0 18 19" fill="none">
          <mask id="mask0_315_15847" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
            <rect y="0.0224609" width="18" height="18" fill="#171C20"/>
          </mask>
          <g mask="url(#mask0_315_15847)">
            <path d="M4.50072 15.1952C3.8753 15.1952 3.37797 14.9526 3.00875 14.4675C2.63952 13.9825 2.53183 13.4438 2.68567 12.8515L4.07172 7.62886C3.66403 7.42887 3.32101 7.11951 3.04265 6.70076C2.76427 6.28201 2.62508 5.8058 2.62508 5.27215C2.62508 4.55029 2.88212 3.93233 3.39618 3.41827C3.91024 2.9042 4.5282 2.64717 5.25007 2.64717H10.875C11.3829 2.64717 11.7665 2.86184 12.0261 3.29116C12.2856 3.72049 12.3024 4.16111 12.0765 4.61304L10.8073 7.15147C10.6832 7.37742 10.5168 7.55818 10.3081 7.69375C10.0994 7.82934 9.86528 7.89714 9.60582 7.89714H7.88513L7.64283 8.79858H7.78128C7.96974 8.80339 8.12863 8.87007 8.25796 8.9986C8.38728 9.12715 8.45195 9.28644 8.45195 9.47647V10.7457C8.45195 10.9377 8.38698 11.0987 8.25706 11.2287C8.12713 11.3586 7.96614 11.4236 7.77408 11.4236H6.94908L6.31447 13.8265C6.1998 14.2309 5.97569 14.5601 5.64213 14.8141C5.30857 15.0682 4.9281 15.1952 4.50072 15.1952ZM4.50007 14.0702C4.67507 14.0702 4.82507 14.0202 4.95007 13.9202C5.07507 13.8202 5.16257 13.689 5.21257 13.5265L6.71834 7.89714H5.16209L3.76882 13.1327C3.70632 13.3702 3.75007 13.5858 3.90007 13.7796C4.05007 13.9733 4.25007 14.0702 4.50007 14.0702ZM5.25007 6.77215H9.60582C9.6491 6.77215 9.68997 6.76014 9.72843 6.7361C9.76689 6.71206 9.79574 6.6808 9.81498 6.64233L11.0842 4.10392C11.1226 4.02698 11.119 3.95246 11.0734 3.88034C11.0277 3.80822 10.9616 3.77216 10.875 3.77216H5.25007C4.83757 3.77216 4.48444 3.91903 4.19069 4.21278C3.89694 4.50653 3.75007 4.85965 3.75007 5.27215C3.75007 5.68465 3.89694 6.03778 4.19069 6.33153C4.48444 6.62528 4.83757 6.77215 5.25007 6.77215ZM13.8678 3.64233L13.4856 2.81015L16.0169 1.65918L16.3875 2.50291L13.8678 3.64233ZM16.0169 8.88513L13.4856 7.75291L13.8678 6.90918L16.3875 8.06015L16.0169 8.88513ZM13.9183 5.73369V4.81062H16.7164V5.73369H13.9183Z" />
          </g>
        </svg>
     )
}

export default BarcodeReaderIcon












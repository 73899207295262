

const KeyBoardReturnIcon = ({ className }) => {
    
     
     
     return (
          <svg xmlns="http://www.w3.org/2000/svg"  className={`svg-regular ${className}`} viewBox="0 0 24 24" fill="none">
          <mask id="mask0_1561_53417" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="#171C20"/>
          </mask>
          <g mask="url(#mask0_1561_53417)">
            <path d="M17.3846 10.1538V13.2308C17.3846 13.4348 17.3036 13.6304 17.1593 13.7747C17.0151 13.919 16.8194 14 16.6154 14H9.24135L10.2365 14.9942C10.308 15.0657 10.3647 15.1505 10.4034 15.2439C10.4421 15.3373 10.462 15.4374 10.462 15.5385C10.462 15.6395 10.4421 15.7396 10.4034 15.833C10.3647 15.9264 10.308 16.0112 10.2365 16.0827C10.1651 16.1542 10.0802 16.2109 9.98684 16.2495C9.89346 16.2882 9.79338 16.3081 9.69231 16.3081C9.59123 16.3081 9.49115 16.2882 9.39777 16.2495C9.30439 16.2109 9.21955 16.1542 9.14808 16.0827L6.84038 13.775C6.76886 13.7036 6.71213 13.6187 6.67342 13.5253C6.6347 13.432 6.61478 13.3319 6.61478 13.2308C6.61478 13.1297 6.6347 13.0296 6.67342 12.9362C6.71213 12.8428 6.76886 12.758 6.84038 12.6865L9.14808 10.3788C9.29242 10.2345 9.48818 10.1534 9.69231 10.1534C9.89643 10.1534 10.0922 10.2345 10.2365 10.3788C10.3809 10.5232 10.462 10.719 10.462 10.9231C10.462 11.1272 10.3809 11.323 10.2365 11.4673L9.24135 12.4615H15.8462V10.1538C15.8462 9.94983 15.9272 9.75418 16.0715 9.60992C16.2157 9.46566 16.4114 9.38462 16.6154 9.38462C16.8194 9.38462 17.0151 9.46566 17.1593 9.60992C17.3036 9.75418 17.3846 9.94983 17.3846 10.1538ZM22 5.53846V19.3846C22 19.7926 21.8379 20.184 21.5494 20.4725C21.2609 20.761 20.8696 20.9231 20.4615 20.9231H3.53846C3.13044 20.9231 2.73912 20.761 2.4506 20.4725C2.16209 20.184 2 19.7926 2 19.3846V5.53846C2 5.13044 2.16209 4.73912 2.4506 4.4506C2.73912 4.16209 3.13044 4 3.53846 4H20.4615C20.8696 4 21.2609 4.16209 21.5494 4.4506C21.8379 4.73912 22 5.13044 22 5.53846ZM20.4615 19.3846V5.53846H3.53846V19.3846H20.4615Z" />
          </g>
        </svg>
     )
}

export default KeyBoardReturnIcon
import { useState } from "react";

import { useDispatch, useSelector } from "@/Store/StoreContextProvider";

import { receivePausedTimers, requestRemoveFromBatch } from "@/Store/Actions";

import { Dialog } from "@/Components/Feedback";
import { Button } from "@/Components/Inputs";

const RemoveFromBatchDialog = ({
    isOpen,
    rowData,
    onClose,
    onCheckIsBatchComplete,
    onRemove,
}) => {
    const dispatch = useDispatch(dispatch => dispatch?.batch);

    const pausedTimers = useSelector(({ batch }) => batch?.pausedTimers);
    const activeDetailsId = useSelector(({ batch }) => batch?.activeDetailsId);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleClose = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    const handleYes = async () => {
        setIsSubmitting(true);

        try {
            await dispatch(requestRemoveFromBatch({
                batchID: rowData?.batchID,
                orderItemID: rowData?.orderItemID
            }));

            if (typeof onCheckIsBatchComplete === 'function') {
                const isComplete = await onCheckIsBatchComplete(rowData);
                if (isComplete) {
                    dispatch(receivePausedTimers([
                        ...(pausedTimers || []),
                        {
                            batchID: activeDetailsId?.toString(),
                            isComplete
                        }
                    ]));
                }
            }

            handleClose();

            if (typeof onRemove === 'function') {
                onRemove(rowData?.orderItemID);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Dialog
            isOpen={isOpen}
            className="!w-[624px]"
        >
            <Dialog.Title className="text-[32px] leading-10 font-normal">
                Remove from Batch
            </Dialog.Title>
            <Dialog.Content>
                <p className="text-base leading-6 tracking-[0.5px] font-normal text-on-surface">
                    Are you sure you want to remove this device from the batch?
                </p>
            </Dialog.Content>
            <Dialog.Actions className="justify-end">
                <Button onClick={handleClose} variant="flat" className="uppercase w-[78px] border-0" disabled={isSubmitting}>No</Button>
                <Button onClick={handleYes} className="uppercase" isLoading={isSubmitting}>Yes</Button>
            </Dialog.Actions>
        </Dialog>
    );
};

export default RemoveFromBatchDialog;

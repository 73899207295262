const PrintIcon = ({ className }) => {
     return (
          <svg className={`svg-regular ${className || ''}`} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
               <mask id="mask0_225_4430" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
                    <rect width="18" height="18" fill="#171C20" />
               </mask>
               <g mask="url(#mask0_225_4430)">
                    <path d="M12 6.23045V3.98045H6.00004V6.23045H4.87506V2.85547H13.125V6.23045H12ZM13.3558 9.23041C13.5683 9.23041 13.7464 9.15854 13.8902 9.01479C14.0339 8.87104 14.1058 8.69291 14.1058 8.48041C14.1058 8.26791 14.0339 8.08979 13.8902 7.94604C13.7464 7.80229 13.5683 7.73041 13.3558 7.73041C13.1433 7.73041 12.9652 7.80229 12.8214 7.94604C12.6777 8.08979 12.6058 8.26791 12.6058 8.48041C12.6058 8.69291 12.6777 8.87104 12.8214 9.01479C12.9652 9.15854 13.1433 9.23041 13.3558 9.23041ZM12 14.2497V11.0477H6.00004V14.2497H12ZM13.125 15.3746H4.87506V12.3746H2.09143V8.10541C2.09143 7.57417 2.27292 7.12887 2.63589 6.76949C2.99887 6.41013 3.44237 6.23045 3.96639 6.23045H14.0337C14.5649 6.23045 15.0102 6.41013 15.3696 6.76949C15.729 7.12887 15.9086 7.57417 15.9086 8.10541V12.3746H13.125V15.3746ZM14.7837 11.2497V8.10541C14.7837 7.89291 14.7118 7.71479 14.5681 7.57104C14.4243 7.42729 14.2462 7.35541 14.0337 7.35541H3.96639C3.75389 7.35541 3.57577 7.42729 3.43202 7.57104C3.28827 7.71479 3.21639 7.89291 3.21639 8.10541V11.2497H4.87506V9.92274H13.125V11.2497H14.7837Z" fill="#2F4455" />
               </g>
          </svg>
     );
};

export default PrintIcon;

import {
    // Providers
    UserStoreProvider,
    OrdersStoreProvider,
    CompaniesStoreProvider,
    BatchStoreProvider,
    KitStoreProvider,
    DeviceStoreProvider,
    ProductStoreProvider,
    QaChecklistStoreProvider,
    QaFailureStoreProvider,
    DoaReasonsStoreProvider,
    // useContexts
    useOrdersStoreContext,
    useUserStoreContext,
    useCompaniesStoreContext,
    useBatchStoreContext,
    useKitStoreContext,
    useDeviceStoreContext,
    useProductStoreContext,
    useQaChecklistStoreContext,
    useQaFailureStoreContext,
    useDoaReasonsStoreContext
} from './Providers';
import { CombineStoreContextProvider } from './CombineStoreContextProvider';

export const storeContexts = [
    useOrdersStoreContext,
    useUserStoreContext,
    useCompaniesStoreContext,
    useBatchStoreContext,
    useKitStoreContext,
    useDeviceStoreContext,
    useProductStoreContext,
    useQaChecklistStoreContext,
    useQaFailureStoreContext,
    useDoaReasonsStoreContext
];

const providers = [
    UserStoreProvider,
    OrdersStoreProvider,
    CompaniesStoreProvider,
    BatchStoreProvider,
    KitStoreProvider,
    DeviceStoreProvider,
    ProductStoreProvider,
    QaChecklistStoreProvider,
    QaFailureStoreProvider,
    DoaReasonsStoreProvider
];

export const StoreContextProvider = CombineStoreContextProvider(...providers);

export const useSelector = (selector) => {
    const contextValuesPerProvider = {};
    storeContexts.forEach(storeContextHook => {
        const { state, name } = storeContextHook(); 
        contextValuesPerProvider[name] = state;
    });

    if (typeof selector === 'function') {
        return selector(contextValuesPerProvider);
    }

    return contextValuesPerProvider;
};

export const useDispatch = (callback) => {
    const contextValuesPerProvider = {};
    storeContexts.forEach(storeContextHook => {
        const { dispatch, name } = storeContextHook(); 
        contextValuesPerProvider[name] = dispatch;
    });

    if (typeof callback === 'function') {
        return callback(contextValuesPerProvider);
    }

    return contextValuesPerProvider;
};

export const useResetStore = (target = []) => {
    const contexts = storeContexts.filter(storeContext => !target.includes(storeContext.name)).map(contextHook => contextHook());

    const resetAllStore = () => {
        contexts.forEach(({dispatch}) => {
            try {
                if (dispatch) {
                    dispatch({ type: "RESET_STATE" });
                } else {
                    console.error("Dispatch method not found in context");
                }
            } catch (error) {
                console.error("Error resetting context:", error);
            }
        });
    };
    return resetAllStore;
};
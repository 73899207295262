import { BaseStore } from '@/Store/BaseStore';

export const scanUsbCli = (success, payload) => {
    return BaseStore.httpClient.post('/scan/usb-cli', {}, {
        params: {
            ...(payload || {})
        }
    })
        .then((response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const scanCli = (success, payload) => {
    return BaseStore.httpClient.post('/scan/cli', {
        ...(payload || {})
    })
        .then((response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const updateDeviceInfo = (success, payload) => {
    return BaseStore.httpClient.put('/devices/update-info', {
        ...(payload || {})
    })
        .then((response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const passDevice = (success, payload) => {
    return BaseStore.httpClient.post('/devices/pass', {
        ...(payload || {})
    })
        .then((response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const flagDevice = (success, payload) => {
    return BaseStore.httpClient.post('/devices/fail', {
        ...(payload || {})
    })
        .then((response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

export const flagDeviceDoa = (success, payload) => {
    return BaseStore.httpClient.post('/devices/fail-doa', {
        ...(payload || {})
    })
        .then((response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

import { useCallback, useMemo } from "react";

import { useSelector } from "@/Store/StoreContextProvider";

import { BatchType } from "@/Helpers/constants";

import Menu from "@/Components/Navigation/Menu";
import MenuItem from "@/Components/Navigation/MenuItem";

import { Icon } from "@/Components/Display";

const TableActionsMenu = ({
    anchorElement,
    activeDetails,
    onClose,
    onRescanDevice,
    onGetOptions,
    onStageDevice,
    onIdDevice,
    onResetDevice,
    onRemoveFromBatch,
    onProjectSpecificOptionClick
}) => {
    const activeKitDetailsItemId = useSelector(({ kit }) => kit?.activeKitDetailsItemId);

    const handleClose = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    const handleRescanDevice = useCallback(() => {
        if (typeof onRescanDevice === 'function') {
            onRescanDevice();
        }
    }, [onRescanDevice]);
    
    const handleGetOptions = useCallback(() => {
        if (typeof onGetOptions === 'function') {
            onGetOptions();
        }
    }, [onGetOptions]);

    const handleStageDevice = useCallback(() => {
        if (typeof onStageDevice === 'function') {
            onStageDevice();
        }
    }, [onStageDevice]);

    const handleIdDevice = useCallback(() => {
        if (typeof onIdDevice === 'function') {
            onIdDevice();
        }
    }, [onIdDevice]);

    const handleResetDevice = useCallback(() => {
        if (typeof onResetDevice === 'function') {
            onResetDevice();
        }
    }, [onResetDevice]);

    const handleRemoveFromBatch = useCallback(() => {
        if (typeof onRemoveFromBatch === 'function') {
            onRemoveFromBatch();
        }
    }, [onRemoveFromBatch]);

    const standardMenuOptions = useMemo(() => {
        return [
            {
                label: 'Rescan Device',
                icon: 'BarcodeReaderIcon',
                handler: handleRescanDevice
            },
            {
                label: 'Get Options',
                icon: 'AddNotesIcon',
                handler: handleGetOptions
            },
            {
                label: 'Stage Device',
                icon: 'AdUnitsIcon',
                handler: handleStageDevice
            },
            {
                label: 'Identify Device',
                icon: 'PhoneLinkRingIcon',
                handler: handleIdDevice
            },
            {
                label: 'Reset Device',
                icon: 'PhoneLinkSetupIcon',
                handler: handleResetDevice
            },
            {
                label: 'Remove From Batch',
                icon: 'RoundedCloseIcon',
                handler: handleRemoveFromBatch
            }
        ];
    }, [
        handleRescanDevice,
        handleGetOptions,
        handleStageDevice,
        handleIdDevice,
        handleResetDevice,
        handleRemoveFromBatch
    ]);

    const handleProjectSpecificOptionClick = useCallback(menuOption => {
        if (typeof onProjectSpecificOptionClick === 'function') {
            onProjectSpecificOptionClick(menuOption);
        }
    }, [onProjectSpecificOptionClick]);

    const projectSpecificOptions = useMemo(() => {
        const itemID = activeDetails?.batchType === BatchType.BATCH
            ? activeDetails?.items?.[0]?.itemID
            : activeKitDetailsItemId;
        const menuOptions = activeDetails?.menus?.[itemID];

        if (Array.isArray(menuOptions) && menuOptions?.length > 0) {
            return menuOptions.map(menuOption => {
                let iconName = 'LinkIcon';

                if (menuOption?.text?.toLowerCase().trim().includes('sd card')) {
                    iconName = 'SdCardIcon';
                } else if (menuOption?.text?.toLowerCase().trim().includes('print')) {
                    iconName = 'PrintIcon';
                }

                return {
                    label: menuOption?.text,
                    icon: iconName,
                    option: menuOption
                };
            });
        }

        return [];
    }, [
        activeDetails,
        activeKitDetailsItemId
    ]);

    return (
        <Menu
            className="p-3 max-h-[calc(100vh-490px)] min-h-[229px] overflow-y-auto"
            anchorEl={anchorElement}
            open={Boolean(anchorElement)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            onClose={handleClose}
        >
            <p className="text-xs text-on-surface-variant-2 font-medium tracking-[0.5px] pb-2">
                Standard
            </p>
            {standardMenuOptions.map((tableActionsMenuOption, index) => {
                return (
                    <MenuItem
                        key={index}
                        className="flex gap-2 px-1 py-2 text-on-surface-variant rounded-[4px] hover:bg-primary-fixed"
                        onClick={tableActionsMenuOption.handler}
                    >
                        <Icon name={tableActionsMenuOption.icon} className="!size-[18px]" />
                        <p>{tableActionsMenuOption.label}</p>
                    </MenuItem>
                );
            })}
            {projectSpecificOptions.length > 0 && (
                <>
                    <p className="text-xs text-on-surface-variant-2 font-medium tracking-[0.5px] pt-6 pb-2">
                        Project Specific
                    </p>
                    {projectSpecificOptions?.map((projectSpecificOption, index) => {
                        return (
                            <MenuItem
                                key={index}
                                className="flex gap-2 px-1 py-2 text-on-surface-variant rounded-[4px] hover:bg-primary-fixed"
                                onClick={() => handleProjectSpecificOptionClick(projectSpecificOption?.option)}
                            >
                                <Icon name={projectSpecificOption?.icon } className="!size-[18px]" />
                                <p>{projectSpecificOption?.label}</p>
                            </MenuItem>
                        );
                    })}
                </>
            )}
        </Menu>
    );
};

export default TableActionsMenu;

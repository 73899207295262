

const FilterListIcon = ({ className }) => {

     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className}`} viewBox="0 0 24 24" fill="none">
               <mask id="mask0_286_18777" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                    <rect width="24" height="24" fill="#171C20" />
               </mask>
               <g mask="url(#mask0_286_18777)">
                    <path d="M10.2789 17.5V16H13.7115V17.5H10.2789ZM6.40385 12.75V11.25H17.5865V12.75H6.40385ZM3.5 7.99998V6.5H20.5V7.99998H3.5Z" />
               </g>
          </svg>
     )
};
export default FilterListIcon;
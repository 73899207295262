
const RefreshIcon = ({ className }) => {
    return (
        <svg className={`svg-regular ${className || ''}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_4409_85" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="currentColor" />
            </mask>
            <g mask="url(#mask0_4409_85)">
                <path d="M5.1673 15.6264C4.86473 15.0636 4.63621 14.4825 4.48172 13.8832C4.32724 13.2838 4.25 12.6726 4.25 12.0495C4.25 9.88671 5.00256 8.04601 6.50768 6.52743C8.01281 5.00885 9.84357 4.24955 12 4.24955H12.7808L10.9308 2.39953L11.9846 1.3457L15.6384 4.99953L11.9846 8.65335L10.9308 7.59953L12.7808 5.7495H12C10.2628 5.7495 8.78683 6.36104 7.57207 7.58413C6.35732 8.80721 5.74995 10.2957 5.74995 12.0495C5.74995 12.4636 5.79514 12.8774 5.88552 13.2909C5.97591 13.7043 6.11148 14.1079 6.29225 14.5015L5.1673 15.6264ZM12.0153 22.6534L8.36155 18.9995L12.0153 15.3457L13.0692 16.3995L11.2192 18.2496H12C13.7372 18.2496 15.2131 17.638 16.4279 16.4149C17.6426 15.1918 18.25 13.7034 18.25 11.9495C18.25 11.5354 18.2048 11.1216 18.1144 10.7082C18.024 10.2947 17.8885 9.8912 17.7077 9.4976L18.8327 8.37263C19.1352 8.93545 19.3637 9.51653 19.5182 10.1159C19.6727 10.7152 19.75 11.3265 19.75 11.9495C19.75 14.1123 18.9974 15.953 17.4923 17.4716C15.9871 18.9902 14.1564 19.7495 12 19.7495H11.2192L13.0692 21.5995L12.0153 22.6534Z" fill="currentColor" />
            </g>
        </svg>
    );
};

export default RefreshIcon;

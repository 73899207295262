import { Component } from "react";
import Logo from "@/Assets/logo";
import Profile from "./Profile/Profile";
import { withStore } from "@/Helpers/withStore";
import { Stack } from "@/Components/Display";
import { DeviceServiceStatus } from "./DeviceServiceStatus";

class Navbar extends Component {
    render() {
        const { onShowProfileMenu, onToggleProfileMenu, handleShowAboutDialog, user } = this.props || {};
        return (
            <div
                className="w-full h-16 flex items-center justify-between pt-[9px] pb-[12px] pl-6 pr-8 bg-primary"
            >
                <Logo />
                <Stack className="gap-[8px]">
                    <DeviceServiceStatus />
                    <Profile
                        handleShowAboutDialog={handleShowAboutDialog}
                        onToggleProfileMenu={onToggleProfileMenu}
                        onShowProfileMenu={onShowProfileMenu}
                        user={user}
                    />
                </Stack>
            </div>
        );
    }
}

const mapStateToProps = ({ user }) => {
    return {
        user: user?.user
    };
};

export default withStore(mapStateToProps)(Navbar);

import {
    createContext,
    useContext,
    useMemo
} from 'react';
import { useReducerWithMiddleware } from '@/Hooks';
import { OrdersStoreMiddleware } from '@/Store/Middlewares';
import { OrdersStoreReducer } from '@/Store/Reducers';
import { ProviderName } from '@/Helpers/constants';

export const OrdersStoreContext = createContext();

const initialState = {
    orders: [],
    companies: [],
    partNumber: [],
    openItemsTableRowGroupState: [],
    openItemsColumnItems: null,
    openKitsColumnItems: null,
    myBatchesColumnItems: null,
    myKitsColumnItems: null,
    serialNumbers:null,
};

export const OrdersStoreProvider = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(OrdersStoreReducer, initialState, [OrdersStoreMiddleware]);

    const OpenItemsStoreState = useMemo(() => {
        return { ...(state || {}) };
    }, [state]);

    return (
        <OrdersStoreContext.Provider value={{ state: OpenItemsStoreState, dispatch, name: ProviderName.ORDERS }}>
            {children}
        </OrdersStoreContext.Provider>
    );
};

export const useOrdersStoreContext = () => {
    return useContext(OrdersStoreContext);
};

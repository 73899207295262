import { Redirect } from "react-router-dom";
 import { isUserAuthenticated } from '@/Authentication'

import { getAllowedRoutes } from "@/Helpers/routesHelper";
import RoutesConfig from "@/Routes/routes-config";
import BuildAllowedRoutes from "@/Routes/build-allowed-routes";

const PrivateRoutes = () => {
    // TODO: UNCOMMENT REDIRECT BELOW IF THERE IS ALREADY AUTH IMPLEMENTED
    // const location = useLocation();
    
    if (!isUserAuthenticated()) {
        return <Redirect to={{ pathname: '/login' }} />
    }

    const allowedRoutes = getAllowedRoutes(RoutesConfig);

    return (
        <div className="flex flex-col min-h-screen bg-grey-2">
            <BuildAllowedRoutes routes={allowedRoutes} />
        </div>
    );
};

export default PrivateRoutes



const MenuSearchIcon = ({ className }) => {
     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className ?? ""}`} viewBox="0 0 23 24" fill="currentColor">
               <path fillRule="evenodd" clipRule="evenodd" d="M13.7441 13.4951H14.3535L17.6239 16.781C17.9401 17.0972 17.9401 17.614 17.6239 17.9303C17.3077 18.2465 16.7909 18.2465 16.4746 17.9303L13.1965 14.6521V14.0428L12.9882 13.8268C11.9084 14.7524 10.4351 15.2306 8.86931 14.9684C6.72502 14.6058 5.01267 12.8163 4.75041 10.6566C4.34932 7.3939 7.09526 4.64797 10.358 5.04906C12.5177 5.31131 14.3072 7.02366 14.6697 9.16796C14.932 10.7338 14.4537 12.207 13.5281 13.2869L13.7441 13.4951ZM6.25458 10.0249C6.25458 11.9455 7.80495 13.4959 9.72556 13.4959C11.6462 13.4959 13.1965 11.9455 13.1965 10.0249C13.1965 8.10433 11.6462 6.55396 9.72556 6.55396C7.80495 6.55396 6.25458 8.10433 6.25458 10.0249Z" fill="currentColor" />
          </svg>
     )
}

export default MenuSearchIcon
import { Component, createRef } from "react";
import { withRouter } from "react-router-dom";

import { Button, TextField } from "@/Components/Inputs";
import { Stack, Table, Icon } from "@/Components/Display";
import { Tooltip } from "@/Components/DataDisplay";
import Menu from "@/Components/Navigation/Menu";
import MenuItem from "@/Components/Navigation/MenuItem";
import { TabContainerButton } from "@/Components/Navigation";

import {
    CustomLoadingOverlay,
    CustomNoRowsOverlay,
    FilterSet,
    CustomColumnHeader
} from "@/Components/Display/Table/Custom/Components";

import {
    AddNotesIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    BarcodeReaderIcon,
    CheckIcon,
    DevicesIcon,
    EllipseIcon,
    FilterListIcon,
    FlagIcon,
    MoreVertIcon,
    PauseIcon,
    PhoneLinkRingIcon,
    PhoneLinkSetupIcon,
    RefreshIcon,
    SearchIcon,
} from "@/Icons";

import { LoadingDialog } from "@/Components/Feedback";
import CustomCellEditor from "./CustomCellEditor";
import CustomCellRenderer from "./CustomCellRenderer";
import TableActionsMenu from "./TableActionsMenu";
import { Toast } from "@/Components/Display";
import {
    BatchCompletedDialog,
    BatchRequiresAuditDialog,
    CannotPassDeviceDialog,
    ErrorScanningDevicesDialog,
    KitPairingDialog,
    MenuOptionPromptDialog,
    PauseAllTimersDialog,
    PausedTimerDialog,
    RemoveFromBatchDialog,
    ReportUnitDoaDialog
} from "./Dialog";
import AddQualityCheckReason from "./QA/AddQualityCheckReason";

import {
    receiveActiveDetailsId,
    receiveActiveKitDetailsItemId,
    receiveBatchDetails,
    receivePausedTimers,
    receiveProblemTemplate,
    receiveQaFailures,
    requestBatchDetails,
    requestCompleteBatch,
    requestFlagDevice,
    requestPauseAllTimers,
    requestPauseTimer,
    requestStartTimer,
    requestUpdateDeviceInfo,
    toggleSidebar
} from "@/Store/Actions";
import { withStore } from "@/Helpers/withStore";
import { isBatchComplete, passDevice } from "@/Store/Services";

import {
    BatchType,
    DeviceAction,
    KeyboardKeys,
    OrderItemStatus,
    StagingStatus,
    StagingStatusFilter
} from "@/Helpers/constants";

import { withDeviceService } from "@/HOC";

class DetailsPage extends Component {
    constructor(props) {
        super(props);

        this.gridRef = createRef();
        this.gridApiRef = createRef();

        this.isKitDetailsPage = props.location?.pathname?.includes('kit-details');
        this.isBatchDetailsPage = props.location?.pathname?.includes('batch-details');

        this.defaultColumnDefs = [
            {
                headerName: 'Actions',
                pinned: true,
                width: 136,
                filter: false,
                sortable: false,
                suppressSizeToFit: true,
                cellRenderer: ({ data }) => {
                    const status = data?.status;
                    const isDisabled = [
                        OrderItemStatus.PASSED,
                        OrderItemStatus.FAILED,
                        OrderItemStatus.KIT_ASSIGNMENT
                    ].includes(status);

                    const renderPassButton = props => (
                        <Button
                            {...(props || {})}
                            variant="flat"
                            className={`
                                size-6 p-0 border-0 !bg-transparent
                                ${isDisabled ? 'cursor-not-allowed' : 'hover:!bg-primary-fixed'}
                            `}
                            onClick={async () => await this.handlePassDevice(data)}
                        >
                            <CheckIcon className={status === OrderItemStatus.PASSED || data?.passed === 1 ? 'text-secondary-container' : 'text-on-surface-variant'} />
                        </Button>
                    );

                    const renderFlagButton = props => (
                        <Button
                            {...(props || {})}
                            variant="flat"
                            className={`
                                size-6 p-0 border-0 !bg-transparent
                                ${isDisabled ? 'cursor-not-allowed' : 'hover:!bg-primary-fixed'}
                            `}
                            onClick={() => this.handleFlagDeviceClick(data)}
                        >
                            <FlagIcon className={status === OrderItemStatus.FAILED ? 'text-[#A90A0A]' : 'text-on-surface-variant'} />
                        </Button>
                    );

                    return (
                        <Stack className="gap-4 w-full">
                            <Button
                                variant="flat"
                                className="size-6 p-0 border-0 !bg-transparent hover:!bg-primary-fixed"
                                onClick={event => {
                                    this.setState({ menuAnchorElement: event?.currentTarget });
                                }}
                            >
                                <MoreVertIcon />
                            </Button>
                            {isDisabled ? renderPassButton() : (
                                <Tooltip
                                    trigger={props => {
                                        return renderPassButton(props);
                                    }}
                                    content={`Pass ${data?.orderNumber}`}
                                    direction="bottom-start"
                                    offset={0}
                                />
                            )}
                            {isDisabled ? renderFlagButton() : (
                                <Tooltip
                                    trigger={props => {
                                        return renderFlagButton(props);
                                    }}
                                    content={`Flag ${data?.orderNumber}`}
                                    direction="bottom-start"
                                    offset={0}
                                />
                            )}
                        </Stack>
                    );
                }
            },
            {
                headerName: 'Order Number',
                field: 'orderNumber',
                pinned: true,
                width: 176,
                suppressSizeToFit: true,
                filter: FilterSet,
                filterParams: {
                    gridRef: this.gridRef,
                    gridApiRef: this.gridApiRef,
                    filterLabelOption: {
                        field: 'Order Number'
                    },
                }
            },
            {
                headerName: 'Serial Number',
                field: 'serial',
                pinned: true,
                width: 176,
                suppressSizeToFit: true,
                filter: FilterSet,
                filterParams: {
                    gridRef: this.gridRef,
                    gridApiRef: this.gridApiRef,
                    filterLabelOption: {
                        field: 'Serial Number'
                    },
                },
                cellRenderer: ({ getValue, data }) => {
                    let iconName = '';
                    switch (data?.stagingStatus) {
                        case StagingStatus.STAGING:
                            iconName = 'RefreshSpinnerIcon';
                            break;
                        case StagingStatus.PASSED:
                            iconName = 'StagingSuccessIcon';
                            break;
                        case StagingStatus.FAILED:
                            iconName = 'StagingFailedIcon';
                            break;
                        case StagingStatus.NONE:
                        default:
                            break;
                    }

                    return (
                        <Stack justifyContent="space-between" className="w-full">
                            {getValue()}
                            {iconName && <Icon name={iconName} className={data?.stagingStatus === StagingStatus.STAGING ? 'animate-spin' : ''} />}
                        </Stack>
                    );
                }
            }
        ];
        
        this.rowClassRules = {
            '!bg-secondary-95': ({ data }) => data?.status === OrderItemStatus.PASSED || data?.passed === 1 || data?.stagingStatus === StagingStatus.PASSED,
            '!bg-error-container': ({ data }) => data?.status === OrderItemStatus.FAILED || data?.stagingStatus === StagingStatus.FAILED
        };

        this.state = {
            menuAnchorElement: null,
            isRefreshing: false,
            rowData: [],
            columnDefs: [],
            isPauseAllTimersDialogOpen: false,
            isPauseAllTimersSubmitting: false,
            filterMenuAnchorElement: null,
            selectedFilter: StagingStatusFilter.ALL_DEVICES,
            isErrorScanningDevicesDialogOpen: false,
            scannedDevices: null,
            tableValidationToastMsg: '',
            isClaimItemsToastOpen: false,
            detailsGridApi: null,
            searchFilterValue: '',
            isPausedTimerDialogOpen: false,
            isLoadingDialogOpen: false,
            isDeviceServiceNotRunningToastOpen: false,
            isReportUnitDoaDialogOpen: false,
            isDeviceFlaggedAsDoaToastOpen: false,
            flagDeviceDoaData: null,
            isBatchCompletedDialogOpen: false,
            isNoUsbIdDeviceFoundToastOpen: false,
            isGridLoading: false,
            isMenuOptionPromptDialogOpen: false,
            activeMenuOption: null,
            isCannotPassDeviceDialogOpen: false,
            isKitPairingDialogOpen: false,
            isBatchRequiresAuditDialogOpen: false,
            kitPairingRows: [],
            ongoingDeviceActions: [],
            isRemoveFromBatchDialogOpen: false
        };
    };

    handleRefreshClick = async () => {
        if (this.state?.detailsGridApi) {
            try {
                this.setState({
                    isRefreshing: true,
                    isGridLoading: true
                });

                await this.fetchBatchDetails(this.props.activeDetailsId);

                await this.handleRetrieveMenuOptions();
            } catch (error) {
                console.error(error);
            } finally {
                this.setState({
                    isRefreshing: false,
                    isGridLoading: false
                });
            }
        }
    };

    getDefaultColDef = () => {
        return {
            suppressHeaderMenuButton: true,
            resizable: false,
            suppressMovable: true,
            menuTabs: [],
            headerComponent: CustomColumnHeader,
            headerComponentParams: {
                gridRef: this.gridRef,
                customCol: true
            },
            filter: true
        };
    };

    handlePauseTimerClick = async () => {
        try {
            this.handleOpenLoadingDialog();

            await this.props.requestPauseTimer({
                batchID: this.props.activeDetailsId,
                userID: this.props.userID
            });

            this.handleOpenPausedTimerDialog();
        } catch (error) {
            console.error(error);
        } finally {
            this.handleCloseLoadingDialog();
        }
    };

    handleCloseErrorScanningDevicesDialog = () => {
        this.setState({
            isErrorScanningDevicesDialogOpen: false
        });
    };

    handleOpenErrorScanningDevicesDialog = () => {
        this.setState({
            isErrorScanningDevicesDialogOpen: true
        });
    };

    getDeviceActionItemId = () => {
        const activeDetails = this.getActiveDetails();
        const itemID = activeDetails?.batchType === BatchType.BATCH
            ? activeDetails?.items?.[0]?.itemID
            : activeDetails?.items?.[this.props.activeKitDetailsItemId]?.[0]?.itemID;

        return itemID;
    };

    getFocusedTableRowData = () => {
        const detailsGridApi = this.state?.detailsGridApi;
        if (detailsGridApi) {
            const { rowIndex } = detailsGridApi?.getFocusedCell() || {};
            const { data } = detailsGridApi?.getDisplayedRowAtIndex(rowIndex) || {};
            return data || null;
        }

        return null;
    };

    setOngoingDeviceActions = deviceAction => {
        if (deviceAction) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    ongoingDeviceActions: [
                        ...(prevState.ongoingDeviceActions || []),
                        deviceAction
                    ]
                };
            });
        }
    };

    sendWsRescan = (itemID, deviceAction) => {
        const webSocket = this.props.webSocket;
        const url = `scan-usb-cli?itemID=${itemID}&flag=x`;
        console.debug(deviceAction, url);

        this.setOngoingDeviceActions(url);

        return webSocket.send(JSON.stringify({
            action: deviceAction,
            url
        }));
    };

    sendWsGetOptions = (itemID, deviceID, serialNumber, deviceAction) => {
        const webSocket = this.props.webSocket;
        const url = `scan-cli?itemID=${itemID}&deviceID=${deviceID}&flag=O&serial=${serialNumber}`;
        this.setOngoingDeviceActions(url);
        
        console.debug(deviceAction, url);
        return webSocket.send(JSON.stringify({
            action: deviceAction,
            url
        }));
    };

    sendWsStageDevice = (itemID, deviceID, deviceAction) => {
        const webSocket = this.props.webSocket;
        const url = `cgi-bin/sendKeyEvent.pl?command=stageDevice&usbDeviceID=${deviceID}&itemID=${itemID}`;
        this.setOngoingDeviceActions(url);

        console.debug(deviceAction, url);
        return webSocket.send(JSON.stringify({
            action: deviceAction,
            url
        }));
    };

    sendWsIdDevice = (itemID, deviceID, color, serialNumber, deviceAction) => {
        const webSocket = this.props.webSocket;
        const url = `cgi-bin/sendKeyEvent.pl?command=IdDevices&color=${color}&usbDeviceID=${deviceID}&itemID=${itemID}&serial=${serialNumber}`;
        this.setOngoingDeviceActions(url);

        console.debug(deviceAction, url);
        return webSocket.send(JSON.stringify({
            action: deviceAction,
            url
        }));
    };

    sendWsResetDevice = (deviceID, deviceAction) => {
        const webSocket = this.props.webSocket;
        const url = `cgi-bin/sendKeyEvent.pl?command=resetStatus&usbDeviceID=${deviceID}`;
        this.setOngoingDeviceActions(url);

        console.debug(deviceAction, url);
        return webSocket.send(JSON.stringify({
            action: deviceAction,
            url
        }));
    };

    sendWsGetMenuOptions = (itemIDs) => {
        const webSocket = this.props.webSocket;
        const url = `project-menu?itemIDs=${itemIDs}`;
        this.setOngoingDeviceActions(url);

        console.debug(DeviceAction.GET_MENU_OPTIONS, url);
        return webSocket.send(JSON.stringify({
            action: DeviceAction.GET_MENU_OPTIONS,
            url
        }));
    };

    sendWsMenuOptions = (action, url) => {
        const webSocket = this.props.webSocket;
        this.setOngoingDeviceActions(url);

        console.debug(action, url);
        return webSocket.send(JSON.stringify({
            action,
            url
        }));
    };

    handleRescanAllDevicesClick = () => {
        const itemID = this.getDeviceActionItemId();
        const webSocket = this.props.webSocket;

        if (!webSocket) {
            this.handleOpenDeviceServiceNotRunningToast();
            return;
        }

        this.handleOpenLoadingDialog();
        this.sendWsRescan(itemID, DeviceAction.RESCAN_ALL);
    };

    handleRescanDeviceClick = () => {
        const itemID = this.getDeviceActionItemId();
        const webSocket = this.props.webSocket;

        if (!webSocket) {
            this.handleOpenDeviceServiceNotRunningToast();
            return;
        }

        this.handleOpenLoadingDialog();
        this.sendWsRescan(itemID, DeviceAction.RESCAN);
    };

    handleGetAllOptionsClick = () => {
        const webSocket = this.props.webSocket;
        const allRowsHaveNoUsbId = this.state.rowData.every(data => !data?.usbID);

        if (!webSocket) {
            this.handleOpenDeviceServiceNotRunningToast();
            return;
        }

        if (allRowsHaveNoUsbId) {
            this.handleOpenNoUsbIdDeviceFoundToast();
            return;
        }

        this.handleOpenLoadingDialog();
        this.state.rowData
            .filter(row => !!row?.usbID)
            .forEach(row => {
                this.sendWsGetOptions(row?.itemID, row?.usbID?.replace('USB_', ''), row?.serial, DeviceAction.GET_ALL_OPTIONS);
            });
    };

    handleGetOptionsClick = () => {
        const data = this.getFocusedTableRowData();
        const webSocket = this.props.webSocket;

        if (!webSocket) {
            this.handleOpenDeviceServiceNotRunningToast();
            return;
        }

        if (!data?.usbID) {
            this.handleOpenNoUsbIdDeviceFoundToast();
            return;
        }

        if (data) {
            this.handleOpenLoadingDialog();
            this.sendWsGetOptions(data?.itemID, data?.usbID?.replace('USB_', ''), '', DeviceAction.GET_OPTIONS);
        }
    };

    handleStageAllDevicesClick = () => {
        const webSocket = this.props.webSocket;
        const allRowsHaveNoUsbId = this.state.rowData.every(data => !data?.usbID);

        if (!webSocket) {
            this.handleOpenDeviceServiceNotRunningToast();
            return;
        }

        if (allRowsHaveNoUsbId) {
            this.handleOpenNoUsbIdDeviceFoundToast();
            return;
        }

        this.handleOpenLoadingDialog();
        const activeDetails = this.getActiveDetails();
        const itemID = activeDetails?.batchType === BatchType.BATCH
            ? activeDetails?.items?.[0]?.itemID
            : this.props.activeKitDetailsItemId;

        const usbIdsWithoutPrefix = this.state.rowData
            .filter(row => !!row?.usbID)
            .map(row => row?.usbID?.replace('USB_', ''));

        const usbIdsWithPrefix = this.state.rowData
            .filter(row => !!row?.usbID)
            .map(row => row?.usbID);

        this.handleUpdateStagingStatus(null, StagingStatus.STAGING, DeviceAction.STAGE_ALL, null, usbIdsWithPrefix);

        this.sendWsStageDevice(itemID, usbIdsWithoutPrefix, DeviceAction.STAGE_ALL);
    };

    handleStageDeviceClick = () => {
        const data = this.getFocusedTableRowData();
        const webSocket = this.props.webSocket;

        if (!webSocket) {
            this.handleOpenDeviceServiceNotRunningToast();
            return;
        }

        if (!data?.usbID) {
            this.handleOpenNoUsbIdDeviceFoundToast();
            return;
        }

        this.handleOpenLoadingDialog();
        this.handleUpdateStagingStatus(data?.orderItemID, StagingStatus.STAGING, DeviceAction.STAGE);
        this.sendWsStageDevice(data?.itemID, data?.usbID?.replace('USB_', ''), DeviceAction.STAGE);
    };

    handleIdAllDevicesClick = () => {
        const webSocket = this.props.webSocket;
        const allRowsHaveNoUsbId = this.state.rowData.every(data => !data?.usbID);

        if (!webSocket) {
            this.handleOpenDeviceServiceNotRunningToast();
            return;
        }

        if (allRowsHaveNoUsbId) {
            this.handleOpenNoUsbIdDeviceFoundToast();
            return;
        }

        this.handleOpenLoadingDialog();
        const rowsWithUsbIds = this.state.rowData.filter(row => !!row?.usbID);
        this.handleUpdateStagingStatus(null, StagingStatus.STAGING, DeviceAction.ID_ALL, null, rowsWithUsbIds.map(row => row?.usbID));
        rowsWithUsbIds.forEach(row => {
            this.sendWsIdDevice(row?.itemID, row?.usbID?.replace('USB_', ''), 'green', row?.serial, DeviceAction.ID_ALL);
        });
    };

    handleIdDeviceClick = () => {
        const data = this.getFocusedTableRowData();
        const webSocket = this.props.webSocket;

        if (!webSocket) {
            this.handleOpenDeviceServiceNotRunningToast();
            return;
        }

        if (!data?.usbID) {
            this.handleOpenNoUsbIdDeviceFoundToast();
            return;
        }

        this.handleOpenLoadingDialog();
        this.handleUpdateStagingStatus(data?.orderItemID, StagingStatus.STAGING, DeviceAction.ID);
        this.sendWsIdDevice(data?.itemID, data?.usbID?.replace('USB_', ''), 'green', '', DeviceAction.ID);
    };

    handleResetAllDevicesClick = () => {
        const webSocket = this.props.webSocket;
        const allRowsHaveNoUsbId = this.state.rowData.every(data => !data?.usbID);

        if (!webSocket) {
            this.handleOpenDeviceServiceNotRunningToast();
            return;
        }

        if (allRowsHaveNoUsbId) {
            this.handleOpenNoUsbIdDeviceFoundToast();
            return;
        }

        this.handleOpenLoadingDialog();
        const rowsWithUsbIds = this.state.rowData.filter(row => !!row?.usbID);
        this.handleUpdateStagingStatus(null, StagingStatus.STAGING, DeviceAction.RESET_ALL, null, rowsWithUsbIds.map(row => row?.usbID));
        rowsWithUsbIds.forEach(row => {
            this.sendWsResetDevice(row?.usbID?.replace('USB_', ''), DeviceAction.RESET_ALL);
        });
    };

    handleResetDeviceClick = () => {
        const data = this.getFocusedTableRowData();
        const webSocket = this.props.webSocket;

        if (!webSocket) {
            this.handleOpenDeviceServiceNotRunningToast();
            return;
        }

        if (!data?.usbID) {
            this.handleOpenNoUsbIdDeviceFoundToast();
            return;
        }

        this.handleOpenLoadingDialog();
        this.handleUpdateStagingStatus(data?.orderItemID, StagingStatus.STAGING, DeviceAction.RESET);
        this.sendWsResetDevice(data?.usbID?.replace('USB_', ''), DeviceAction.RESET);
    };

    handleRetrieveMenuOptions = (itemID) => {
        const activeDetails = this.getActiveDetails();
        if (activeDetails && !itemID) {
            itemID = activeDetails?.batchType === BatchType.BATCH
                ? activeDetails?.items?.[0]?.itemID
                : this.props.activeKitDetailsItemId;
        }
        this.sendWsGetMenuOptions(itemID);
    };

    handlePassDevice = async (data) => {
        if ([
            OrderItemStatus.PASSED,
            OrderItemStatus.FAILED,
            OrderItemStatus.KIT_ASSIGNMENT
        ].includes(data?.status)) {
            return;
        }

        const columnValues = Object.entries(data?.completedOptions).filter(([_, value]) => value !== '');
        if (columnValues?.length < data?.options?.length) {
            this.handleOpenCannotPassDeviceDialog();
            return;
        }

        if (data) {
            this.handleOpenLoadingDialog();

            try {
                const passResponse = await passDevice(() => {}, {
                    deviceID: data?.orderItemID,
                    userID: this.props.userID
                });

                this.handleUpdateItemStatus(data?.orderItemID, OrderItemStatus.PASSED, false);

                if (passResponse?.audit) {
                    this.handleOpenBatchRequiresAuditDialog();
                }

                if (this.isKitDetailsPage) {
                    return;
                }

                const isComplete = await this.handleCheckIsBatchComplete(data);
                if (isComplete) {
                    this.props.receivePausedTimers([
                        ...(this.props.pausedTimers || []),
                        {
                            batchID: this.props.activeDetailsId?.toString(),
                            isComplete
                        }
                    ]);
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.handleCloseLoadingDialog();
            }
        }
    };

    handleCheckIsBatchComplete = async (data) => {
        if (data) {
            const responseData = await isBatchComplete(() => { }, data?.batchID);
            const isReadyToComplete = responseData?.complete ?? false;

            if (isReadyToComplete) {
                await this.props.requestCompleteBatch({
                    batchID: data?.batchID,
                    userID: this.props.userID
                });

                this.handleOpenBatchCompletedDialog();
            }

            return isReadyToComplete;
        }

        return false;
    };

    handleUpdateStagingStatus = (orderItemID, status, deviceAction, data, usbIDs) => {
        const dataToIterate = data || this.props.batchDetailsToDisplay;
        const newBatchDetailsToDisplay = dataToIterate?.map(details => {
            if (details?.batchID?.toString() === this.props.activeDetailsId?.toString()) {
                let items = details?.batchType === BatchType.BATCH
                    ? [...details?.items]
                    : [...details?.items?.[this.props.activeKitDetailsItemId]];

                switch (deviceAction) {
                    case DeviceAction.STAGE:
                    case DeviceAction.ID:
                    case DeviceAction.RESET:
                        items = items?.map(item => {
                            let stagingStatus = item?.stagingStatus;

                            if (item?.orderItemID === orderItemID) {
                                stagingStatus = status;
                            }

                            return {
                                ...item,
                                stagingStatus
                            };
                        });
                        break;
                    case DeviceAction.STAGE_ALL:
                        items = items
                            ?.map(item => {
                                if (Array.isArray(usbIDs) && usbIDs.includes(item?.usbID)) {
                                    return {
                                        ...item,
                                        stagingStatus: status
                                    };
                                }

                                return {
                                    ...item
                                };
                            });
                        break;
                    case DeviceAction.ID_ALL:
                    case DeviceAction.RESET_ALL:
                        items = items?.map(item => {
                            let stagingStatus = item?.stagingStatus;
                            
                            if (Array.isArray(usbIDs) && usbIDs.includes(item?.usbID)) {
                                return {
                                    ...item,
                                    stagingStatus: status
                                };
                            }

                            return {
                                ...item,
                                stagingStatus
                            };
                        });
                        break;
                    default:
                        break;
                }

                if (details?.batchType === BatchType.BATCH) {
                    return {
                        ...details,
                        items
                    };
                }

                return {
                    ...details,
                    items: {
                        ...details?.items,
                        [this.props.activeKitDetailsItemId]: items
                    }
                };
            }

            return {
                ...details
            };
        });

        this.props.receiveBatchDetails(newBatchDetailsToDisplay);
        return newBatchDetailsToDisplay;
    };

    handleUpdateItemStatus = (orderItemID, newStatus, shouldUpdateAll, data) => {
        const dataToIterate = data || this.props.batchDetailsToDisplay;
        const newBatchDetailsToDisplay = dataToIterate?.map(details => {
            if (details?.batchID?.toString() === this.props.activeDetailsId?.toString()) {
                let items = details?.batchType === BatchType.BATCH
                    ? [...details?.items]
                    : [...details?.items?.[this.props.activeKitDetailsItemId]];

                if (shouldUpdateAll) {
                    items = items?.map(item => {
                    const passed = newStatus === OrderItemStatus.PASSED ? 1 : 0;  
                        return {
                            ...item,
                            status: newStatus,
                            passed
                        };
                    });
                } else {
                    items = items?.map(item => {
                        let status = item?.status;
                        let passed = item?.passed;

                        if (item?.orderItemID === orderItemID) {
                            status = newStatus;
                            passed = newStatus === OrderItemStatus.PASSED ? 1 : 0;
                        }
                        
                        return {
                            ...item,
                            status,
                            passed
                        };
                    });
                }

                if (details?.batchType === BatchType.BATCH) {
                    return {
                        ...details,
                        items
                    };
                }

                return {
                    ...details,
                    items: {
                        ...details?.items,
                        [this.props.activeKitDetailsItemId]: items
                    }
                };
            }

            return {
                ...details
            };
        });

        this.props.receiveBatchDetails(newBatchDetailsToDisplay);
        return newBatchDetailsToDisplay;
    };

    handleOpenPauseAllTimersDialog = () => {
        this.setState({
            isPauseAllTimersDialogOpen: true
        });
    };

    handleClosePauseAllTimersDialog = () => {
        this.setState({
            isPauseAllTimersDialogOpen: false
        });
    };

    handlePauseAllTimers = async () => {
        try {
            this.setState({
                isPauseAllTimersSubmitting: true
            });

            await this.props.requestPauseAllTimers({
                userID: this.props.userID
            });

            this.props.receiveActiveKitDetailsItemId(null);
            this.props.receiveQaFailures(null);

            this.handleClosePauseAllTimersDialog();
        } catch (error) {
            console.error(error);
        } finally {
            this.setState({
                isPauseAllTimersSubmitting: false
            });
        }
    };

    handleSearchFilterChange = event => {
        const value = event?.target?.value;

        this.setState({
            searchFilterValue: value ?? ''
        });
    };

    handleOpenPausedTimerDialog = () => {
        this.setState({
            isPausedTimerDialogOpen: true
        });
    };

    handleClosePausedTimerDialog = () => {
        this.setState({
            isPausedTimerDialogOpen: false
        });
    };

    handleUnPauseTimer = async () => {
        try {
            await this.props.requestStartTimer({
                batchID: this.props.activeDetailsId,
                userID: this.props.userID
            });

            this.handleClosePausedTimerDialog();
        } catch (error) {
            console.error(error);
        }
    };

    renderPausedTimerDialog = () => {
        return (
            <PausedTimerDialog
                isOpen={this.state.isPausedTimerDialogOpen}
                onUnPause={this.handleUnPauseTimer}
            />
        );
    };

    renderPauseAllTimersDialog = () => {
        return (
            <PauseAllTimersDialog
                isOpen={this.state.isPauseAllTimersDialogOpen}
                onClose={this.handleClosePauseAllTimersDialog}
                onUnPauseAllTimers={this.handlePauseAllTimers}
                isLoading={this.state.isPauseAllTimersSubmitting}
            />
        );
    };

    renderErrorScanningDevicesDialog = () => {
        return (
            <ErrorScanningDevicesDialog
                isOpen={this.state.isErrorScanningDevicesDialogOpen}
                onClose={this.handleCloseErrorScanningDevicesDialog}
                scannedDevices={this.state.scannedDevices}
            />
        );
    };

    renderReportUnitDoaDialog = () => {
        return (
            <ReportUnitDoaDialog
                isOpen={this.state.isReportUnitDoaDialogOpen}
                deviceData={this.state.flagDeviceDoaData}
                onClose={this.handleCloseReportUnitDoaDialog}
                onSave={this.handleFlagDeviceDoaSave}
            />
        );
    };

    renderBatchCompletedDialog = () => {
        return (
            <BatchCompletedDialog
                isOpen={this.state.isBatchCompletedDialogOpen}
                onClose={this.handleCloseBatchCompletedDialog}
                onCloseBatch={this.handleCloseBatch}
            />
        );
    };

    renderMenuOptionPromptDialog = () => {
        return (
            <MenuOptionPromptDialog
                isOpen={this.state.isMenuOptionPromptDialogOpen}
                onClose={this.handleCloseMenuOptionPromptDialog}
                onOk={this.handleOkMenuOptionPromptDialog}
                menuOption={this.state.activeMenuOption}
            />
        );
    };

    renderKitPairingDialogOpen = () => {
        
        return (
            <KitPairingDialog
                isOpen={this.state.isKitPairingDialogOpen}
                onClose={this.handleCloseKitPairingDialog}
                onComplete={this.handleCompletePairing}
                onCheckIsBatchComplete={this.handleCheckIsBatchComplete}
                kitDetails={this.getActiveDetails()}
                kitPairingRows={this.state.kitPairingRows}
                handleKitPairingRows={this.handleKitPairingRows}
            />
        );
    };

    renderCannotPassDeviceDialog = () => {
        return (
            <CannotPassDeviceDialog
                isOpen={this.state.isCannotPassDeviceDialogOpen}
                onClose={this.handleCloseCannotPassDeviceDialog}
                onYes={this.handleYesCannotPassDevice}
            />
        );
    };

    renderBatchRequiresAuditDialog = () => {
        return (
            <BatchRequiresAuditDialog
                isOpen={this.state.isBatchRequiresAuditDialogOpen}
                onClose={this.handleCloseBatchRequiresAuditDialog}
                onOk={this.handleCloseBatchRequiresAuditDialog}
            />
        );
    };

    renderRemoveFromBatchDialog = () => {
        return (
            <RemoveFromBatchDialog
                isOpen={this.state.isRemoveFromBatchDialogOpen}
                rowData={this.getFocusedTableRowData()}
                onClose={this.handleCloseRemoveFromBatchDialog}
                onCheckIsBatchComplete={this.handleCheckIsBatchComplete}
                onRemove={this.handleRemoveDeviceFromBatch}
            />
        );
    };

    handleCloseBatchRequiresAuditDialog = () => {
        this.setState({
            isBatchRequiresAuditDialogOpen: false
        });
    };

    handleOpenBatchRequiresAuditDialog = () => {
        this.setState({
            isBatchRequiresAuditDialogOpen: true
        });
    };

    handleCloseKitPairingDialog = () => {
        this.setState({
            isKitPairingDialogOpen: false
        });
    };

    handleOpenKitPairingDialog = () => {
        this.setState({
            isKitPairingDialogOpen: true
        });
    };

    handleCompletePairing = () => {
        this.handleCloseKitPairingDialog();
        this.handleCloseBatch();
    };

    handleCloseCannotPassDeviceDialog = () => {
        this.setState({
            isCannotPassDeviceDialogOpen: false,
            activeMenuOption: null
        });
    };

    handleOpenCannotPassDeviceDialog = () => {
        this.setState({
            isCannotPassDeviceDialogOpen: true
        });
    };

    handleYesCannotPassDevice = () => {
        this.handleCloseCannotPassDeviceDialog();
        this.props.toggleSidebar(true);

        const loggedUser = this.props.loggedUser;
        const focusedRowData = this.getFocusedTableRowData();
        const debug = {
            deviceId: focusedRowData?.orderItemID,
            user: {
                gn: loggedUser?.gn,
                sn: loggedUser?.sn,
                id: loggedUser?.userID,
                access: loggedUser?.access,
            },
            optionsIncomplete: Object.keys(focusedRowData?.completedOptions)?.length < focusedRowData?.options?.length,
            options: Object.fromEntries(
                focusedRowData?.options?.map(option => [`option-${option?.id}`, focusedRowData?.completedOptions?.[option?.id] || '']) || []
            )
        };
        const template =
            'Unable to pass device.\n----------------------\nWas this a new batch or one you reopened?\n\nEnter any other notes you have here:\n\n\n\n----------------------\nDebug Output:\n' +
            JSON.stringify(debug, null, 2);
        this.props.receiveProblemTemplate(template);
    };

    handleCloseRemoveFromBatchDialog = () => {
        this.setState({
            isRemoveFromBatchDialogOpen: false
        });
    };

    handleOpenRemoveFromBatchDialog = () => {
        this.setState({
            isRemoveFromBatchDialogOpen: true
        });
    };

    handleRemoveDeviceFromBatch = orderItemIdRemoved => {
        const newBatchDetailsToDisplay = this.props.batchDetailsToDisplay?.map(details => {
            if (details?.batchID?.toString() === this.props.activeDetailsId?.toString()) {
                let items = [];

                if (details?.batchType === BatchType.BATCH) {
                    items = [...details?.items];
                }

                if (details?.batchType === BatchType.KIT) {
                    items = [...details?.items?.[this?.props.activeKitDetailsItemId]];
                }

                items = items?.filter(item => item?.orderItemID !== orderItemIdRemoved);

                if (details?.batchType === BatchType.KIT) {
                    items = {
                        ...(details?.items || {}),
                        [this?.props.activeKitDetailsItemId]: items
                    };
                }

                return {
                    ...details,
                    items
                };
            }

            return {
                ...details
            };
        });
        this.props.receiveBatchDetails(newBatchDetailsToDisplay);
    };

    handleCloseMenuOptionPromptDialog = () => {
        this.setState({
            isMenuOptionPromptDialogOpen: false,
            activeMenuOption: null
        });
    };

    handleOpenMenuOptionPromptDialog = () => {
        this.setState({
            isMenuOptionPromptDialogOpen: true
        });
    };

    handleOkMenuOptionPromptDialog = (menuOption, promptValue) => {
        const builtUrl = this.buildProjectSpecificOptionUrl(menuOption, promptValue);
        this.sendWsMenuOptions(menuOption?.text, builtUrl);
    };

    handleCloseBatch = () => {
        this.handleCloseBatchCompletedDialog();

        const newBatchDetailsToDisplay = this.props.batchDetailsToDisplay?.filter(batch => batch?.batchID?.toString() !== this.props.activeDetailsId?.toString());
        if (newBatchDetailsToDisplay?.length >= 1) {
            this.props.receiveActiveDetailsId(newBatchDetailsToDisplay?.[0]?.batchID);
            this.props.receiveBatchDetails(newBatchDetailsToDisplay);
            return;
        }

        this.props.receiveActiveDetailsId(null);
        this.props.receiveBatchDetails([]);
    };

    handleCloseBatchCompletedDialog = () => {
        this.setState({
            isBatchCompletedDialogOpen: false
        });
    };

    handleOpenBatchCompletedDialog = () => {
        this.setState({
            isBatchCompletedDialogOpen: true
        });
    };

    handleCloseReportUnitDoaDialog = () => {
        this.setState({
            isReportUnitDoaDialogOpen: false
        });
    };

    handleOpenReportUnitDoaDialog = () => {
        this.setState({
            isReportUnitDoaDialogOpen: true
        });
    };

    handleFlagDeviceClick = data => {
        if ([
            OrderItemStatus.PASSED,
            OrderItemStatus.FAILED,
            OrderItemStatus.KIT_ASSIGNMENT
        ].includes(data?.status)) {
            return;
        }

        if (data) {
            this.setState({
                flagDeviceDoaData: data
            });
            this.handleOpenReportUnitDoaDialog();
        }
    };

    handleOpenDeviceFlaggedAsDoaToast = () => {
        this.setState({
            isDeviceFlaggedAsDoaToastOpen: true
        });
    };

    handleCloseDeviceFlaggedAsDoaToast = () => {
        this.setState({
            isDeviceFlaggedAsDoaToastOpen: false
        });
    };

    handleOpenNoUsbIdDeviceFoundToast = () => {
        this.setState({
            isNoUsbIdDeviceFoundToastOpen: true
        });
    };

    handleCloseNoUsbIdDeviceFoundToast = () => {
        this.setState({
            isNoUsbIdDeviceFoundToastOpen: false
        });
    };

    handleFlagDeviceDoaSave = async () => {
        this.handleCloseReportUnitDoaDialog();
        this.handleOpenDeviceFlaggedAsDoaToast();
        this.handleUpdateItemStatus(this.state.flagDeviceDoaData?.orderItemID, OrderItemStatus.FAILED, false);
        this.handleOpenLoadingDialog();
        
        try {
            if (this.isBatchDetailsPage) {
                const isComplete = await this.handleCheckIsBatchComplete(this.state.flagDeviceDoaData);
                if (isComplete) {
                    this.props.receivePausedTimers([
                        ...(this.props.pausedTimers || []),
                        {
                            batchID: this.props.activeDetailsId?.toString(),
                            isComplete
                        }
                    ]);
                }
            }

            this.setState({
                flagDeviceDoaData: null
            });
        } catch (error) {
            console.error(error);
        } finally {
            this.handleCloseLoadingDialog();
        }
    };

    handleOpenLoadingDialog = () => {
        this.setState({
            isLoadingDialogOpen: true
        });
    };

    handleCloseLoadingDialog = () => {
        this.setState({
            isLoadingDialogOpen: false
        });
    };

    handleFilterMenuItemClick = selectedFilter => {
        if (selectedFilter) {
            this.setState({
                selectedFilter
            });
        }
    };

    handleOpenTableValidationToast = message => {
        this.setState({
            tableValidationToastMsg: message
        });
    };

    handleSetTableValidationToastMsg = message => {
        this.setState({
            tableValidationToastMsg: message || ''
        });
    };

    handleUpdateRowData = async ({ inputValue, data, option }) => {
        if (typeof inputValue === 'string' && data) {
            try {
                this.handleOpenLoadingDialog();

                let optionUpdates = {};
                for (const dataOption of data?.options) {
                    if (dataOption?.id === option?.id) {
                        optionUpdates[`option-${option?.id}`] = inputValue;
                        continue;
                    }

                    optionUpdates[`option-${dataOption?.id}`] = data?.completedOptions?.[dataOption?.id];
                }

                await this.props.requestUpdateDeviceInfo({
                    deviceID: data?.orderItemID,
                    userID: this.props.userID,
                    ...(Object.keys(optionUpdates).length > 0 ? optionUpdates : {})
                });

                const newBatchDetailsToDisplay = this.props.batchDetailsToDisplay?.map(details => {
                    
                    if (details?.batchID?.toString() === this.props.activeDetailsId?.toString()) {
                        let items = [];

                        if (details?.batchType === BatchType.BATCH) {
                            items = [...details?.items];
                        }

                        if (details?.batchType === BatchType.KIT) {
                            items = [...details?.items?.[this.props.activeKitDetailsItemId]];
                        }

                        items = items?.map(item => {
                            let completedOptions = {
                                ...item.completedOptions,

                            };

                            if (item?.orderItemID === data?.orderItemID) {
                                completedOptions = {
                                    ...(item?.completedOptions || {}),
                                    [option?.id]: inputValue
                                }
                            }

                            return {
                                ...item,
                                completedOptions
                            };
                        });

                        if (details?.batchType === BatchType.KIT) {
                            items = {
                                ...(details?.items || {}),
                                [this.props.activeKitDetailsItemId]: items
                            };
                        }

                        return {
                            ...details,
                            items
                        };
                    }

                    return {
                        ...details
                    };
                });
                this.props.receiveBatchDetails(newBatchDetailsToDisplay);
            } catch (error) {
                console.error(error);
            } finally {
                this.handleCloseLoadingDialog();
            }
        }
    };

    doesTableHaveValidationErrors = () => {
        return !!this.state.tableValidationToastMsg;
    };

    gridOptions = () => {
        return {
            onGridReady: (params) => {
                this.gridApiRef.current = params.api.getColumns();
                this.setState({
                    detailsGridApi: params?.api
                });
            },
            popupParent: document.querySelector('body'),
            postProcessPopup: params => {
                // check callback is for columnFilter
                if (params.type !== "columnFilter") {
                    return;
                }

                // Calculate the position based on the column header width
                const eventSource = params.eventSource;
                const ePopup = params.ePopup;
                const popupWidth = ePopup.offsetWidth;
                const headerRect = eventSource.getBoundingClientRect();
                const popupLeft = headerRect.right - popupWidth + window.scrollX;

                if (popupLeft > 0) {
                    // Consider header padding
                    ePopup.style.left = `${popupLeft + 12}px`;
                }

                ePopup.style.width = '220px';
                if (params?.column?.userProvidedColDef?.headerName === 'Date') {
                    ePopup.style.maxWidth = '220px';
                    ePopup.style.minWidth = '220px';

                }
            },
            getRowStyle: ({ node, data }) => {
                if (data?.validation) {
                    return {
                        background: '#FFDAD6',
                    };
                }

                if (node?.childIndex % 2 === 0) {
                    return {
                        background: '#ffffff',
                        border: '0px'
                    };
                }

                return {
                    background: '#EFF4F9',
                    border: '0px'
                };
            },
            getRowId: ({ data }) => data?.orderItemID,
            isExternalFilterPresent: () => this.state.selectedFilter !== StagingStatusFilter.ALL_DEVICES,
            doesExternalFilterPass: ({ data }) => {
                const rowDataStagingStatus = data?.stagingStatus || StagingStatus.NONE;

                switch (this.state.selectedFilter) {
                    case StagingStatusFilter.READY_FOR_STAGING:
                        return rowDataStagingStatus === StagingStatus.NONE;
                    case StagingStatusFilter.CURRENTLY_STAGING:
                        return rowDataStagingStatus === StagingStatus.STAGING;
                    case StagingStatusFilter.PASSED_STAGING:
                        return rowDataStagingStatus === StagingStatus.PASSED;
                    case StagingStatusFilter.FAILED_STAGING:
                        return rowDataStagingStatus === StagingStatus.FAILED;
                    default:
                        return true;
                }
            },
            onFilterChanged: ({ source, api }) => {
                if (['quickFilter', 'api'].includes(source)) {
                    const rowData = [];
                    api?.forEachNodeAfterFilter(node => rowData.push(node));

                    if (rowData?.length === 0) {
                        api?.showNoRowsOverlay();
                        return;
                    }

                    api?.hideOverlay();
                }
            }
        }
    };

    handleCloseClaimItemsToast = () => {
        this.setState({
            isClaimItemsToastOpen: false
        });
    };

    handleOpenDeviceServiceNotRunningToast = () => {
        this.setState({
            isDeviceServiceNotRunningToastOpen: true
        });
    };

    handleCloseDeviceServiceNotRunningToast = () => {
        this.setState({
            isDeviceServiceNotRunningToastOpen: false
        });
    };

    fetchBatchDetails = async (batchID) => {
        await this.props.requestBatchDetails(batchID);
    };

    getActiveDetails = () => {
        return this.props.batchDetailsToDisplay?.find(batch => batch?.batchID?.toString() === this.props.activeDetailsId?.toString());
    };

    isDetailsNotYetFetched = batchId => {
        const batch = this.props.batchDetailsToDisplay?.find(batch => batch?.batchID?.toString() === batchId?.toString());
        return !batch;
    };

    handleTableCellClicked = ({ column, node, api }) => {
        api?.startEditingCell({
            rowIndex: node?.rowIndex,
            colKey: column?.getId()
        });
    };

    handleTableSuppressKeyboardEvent = ({ event, editing }) => {
        const pressedKey = event?.key;
        let shouldSuppress = false;

        if (!!editing) {
            switch (pressedKey) {
                case KeyboardKeys.ENTER:
                case KeyboardKeys.TAB:
                case KeyboardKeys.ESCAPE:
                    shouldSuppress = true;
                    break;
                default:
                    shouldSuppress = false;
                    break;
            }
        }

        return shouldSuppress;
    };

    buildProjectSpecificOptionUrl = (menuOption, promptValue) => {
        const focusedRowData = this.getFocusedTableRowData();
        let query = {};

        if (menuOption?.prompt?.var_name) {
            query[menuOption.prompt.var_name] = promptValue;
        }

        if (menuOption?.include_item_id === true) {
            query.itemID = focusedRowData?.itemID;
        }

        if (menuOption?.include_serial === true) {
            query.serial = focusedRowData?.serial;
        }

        if (menuOption?.include_ordernumber === true) {
            query.ordernumber = focusedRowData?.orderNumber;
        }

        if (Object.keys(query).length === 0) {
            return menuOption?.url;
        }

        let url = new URL(menuOption?.url, window.location.origin);
        Object.entries(query).forEach(([key, value]) => {
            url.searchParams.set(key, value);
        });

        return url.pathname + url.search;
    };

    handleProjectSpecificOptionClick = menuOption => {
        if (!this.props.webSocket) {
            this.handleOpenDeviceServiceNotRunningToast();
            return;
        }

        if (menuOption?.prompt && Object.keys(menuOption?.prompt)?.length > 0) {
            this.setState({
                activeMenuOption: menuOption
            });
            this.handleOpenMenuOptionPromptDialog();
            return;
        }

        const builtUrl = this.buildProjectSpecificOptionUrl(menuOption);
        this.sendWsMenuOptions(menuOption?.text, builtUrl);
    };

    getIndexOfActiveKit = () => {
        const activeDetails = this.getActiveDetails();

        if (activeDetails) {
            return activeDetails?.kitDef?.items?.findIndex(item => item?.id?.toString() === this.props.activeKitDetailsItemId?.toString());
        }

        return null;
    };

    handlePreviousKit = () => {
        const previousKit = this.getPreviousKit();
        if (previousKit) {
            this.props.receiveActiveKitDetailsItemId(previousKit?.id);
        }
    };

    getPreviousKit = () => {
        const activeDetails = this.getActiveDetails();

        if (activeDetails) {
            const indexOfActiveKit = this.getIndexOfActiveKit();
            return activeDetails?.kitDef?.items?.[indexOfActiveKit - 1];
        }

        return null;
    };

    getNextKit = () => {
        const activeDetails = this.getActiveDetails();

        if (activeDetails) {
            const indexOfActiveKit = this.getIndexOfActiveKit();
            return activeDetails?.kitDef?.items?.[indexOfActiveKit + 1];
        }

        return null;
    };

    handleNextKit = isNextDisabled => {
        if (isNextDisabled) {
            return;
        }

        const nextKit = this.getNextKit();
        if (nextKit) {
            this.props.receiveActiveKitDetailsItemId(nextKit?.id);
            return;
        }

        this.handleOpenKitPairingDialog();
    };

    renderKitStepperToast = () => {
        const activeDetails = this.getActiveDetails();

        const previousKit = this.getPreviousKit();
        const nextKit = this.getNextKit();

        const isPreviousDisabled = !previousKit;
        const isCurrentKitIncomplete = activeDetails?.items?.[this.props.activeKitDetailsItemId]?.some(item =>
            item?.status !== OrderItemStatus.PASSED &&
            item?.status !== OrderItemStatus.FAILED &&
            item?.status !== OrderItemStatus.KIT_ASSIGNMENT
        );
        const isNextDisabled = isCurrentKitIncomplete;

        const activeKitName = activeDetails?.items?.[this.props.activeKitDetailsItemId]?.[0]?.fullname || '';
        const kitsTotal = activeDetails?.kitDef?.items?.length || 0;
        const currentKitStep = this.state.isKitPairingDialogOpen ? kitsTotal + 1 : this.getIndexOfActiveKit() + 1;

        const nextButtonText = currentKitStep >= kitsTotal ? 'PAIRING' : `${nextKit?.product} ${nextKit?.commonname}`;

        if (kitsTotal) {
            return (
                <Toast
                    open
                    className="z-[199] w-[600px] px-[22px] py-[11px] !bottom-[24px]"
                    message={
                        <Stack className="w-full gap-[8px]" justifyContent="space-between">
                            <Stack
                                justifyContent="flex-start"
                                className={`
                                    gap-[8px] w-full
                                    ${isPreviousDisabled ? 'cursor-not-allowed text-outline' : 'text-secondary-fixed'}
                                `}
                                role="button"
                                onClick={this.handlePreviousKit}
                                disabled={isPreviousDisabled}
                            >
                                <ArrowLeftIcon />
                                <p className="font-medium text-[12px] tracking-[0.5px] leading-[16px] w-[120px] truncate">
                                    {previousKit?.product} {previousKit?.commonname}
                                </p>
                            </Stack>
                            <Stack direction="column" className="gap-[2px]">
                                <p className="font-bold text-[12px] tracking-[0.5px] leading-[16px]">{activeKitName}</p>
                                <p className="text-[9px] leading-[12px] tracking-[0.4px]">{currentKitStep} of {kitsTotal + 1}</p>
                            </Stack>
                            <Stack
                                justifyContent="flex-end"
                                className={`
                                    gap-[8px] w-full
                                    ${isNextDisabled ? 'cursor-not-allowed text-outline' : 'text-secondary-fixed'}
                                `}
                                role="button"
                                onClick={() => this.handleNextKit(isNextDisabled)}
                                disabled={isNextDisabled}
                            >
                                <p className="font-medium text-[12px] tracking-[0.5px] leading-[16px] text-right min-w-[84px] w-[120px] truncate">
                                    {nextButtonText}
                                </p>
                                <ArrowRightIcon />
                            </Stack>
                        </Stack>
                    }
                />
            );
        }

        return null;
    };

    handleCloseTableActionsMenu = () => {
        this.setState({ menuAnchorElement: null });
    };

    renderTableActionsMenu = () => {
        return (
            <TableActionsMenu
                anchorElement={this.state.menuAnchorElement}
                activeDetails={this.getActiveDetails()}
                onClose={this.handleCloseTableActionsMenu}
                onRescanDevice={this.handleRescanDeviceClick}
                onGetOptions={this.handleGetOptionsClick}
                onStageDevice={this.handleStageDeviceClick}
                onIdDevice={this.handleIdDeviceClick}
                onResetDevice={this.handleResetDeviceClick}
                onRemoveFromBatch={this.handleOpenRemoveFromBatchDialog}
                onProjectSpecificOptionClick={this.handleProjectSpecificOptionClick}
            />
        );
    };

    handleKitPairingRows = (kitPairingRows) => {
        this.setState({ kitPairingRows: kitPairingRows });
    };

    handleStageAllResponse = (deviceServiceResponse) => {
        const stepsFromDeviceService = Array.from({ length: 10 }, (_, index) => index.toString());
        let passedDevices = [];
        let failedDevices = [];

        if (deviceServiceResponse?.result?.data) {
            const dataEntries = Object.entries(deviceServiceResponse.result.data);
            for (const [key, value] of dataEntries) {
                if (stepsFromDeviceService.includes(key) && Array.isArray(value?.output)) {
                    failedDevices = [
                        ...(failedDevices || []),
                        ...(value?.output?.filter(device => device?.status?.toLowerCase().trim() === 'rejected') || [])
                    ];

                    passedDevices = [
                        ...(passedDevices || []),
                        ...(value?.output?.filter(device => device?.status?.toLowerCase().trim() === 'fulfilled') || [])
                    ];
                }
            }
        }

        let updatedData = this.props.batchDetailsToDisplay;
        if (passedDevices?.length > 0) {
            const successUsbIDs = passedDevices?.map(passedDevice => `USB_${passedDevice?.value?.usbID}`);
            updatedData = this.handleUpdateStagingStatus(null, StagingStatus.PASSED, deviceServiceResponse?.action, null, successUsbIDs);
        }

        if (failedDevices?.length > 0) {
            const failedUsbIDs = failedDevices?.map(failedDevice => `USB_${failedDevice?.reason?.usbID}`);
            this.handleUpdateStagingStatus(null, StagingStatus.FAILED, deviceServiceResponse?.action, updatedData, failedUsbIDs);
        }
    };

    async componentDidMount() {
        if (this.props.location?.state?.createdBatch) {
            // Will execute from Add Items, Claim Items, and Add Kits
            const batchId = this.props.location?.state?.createdBatch?.batchID;
            let isDeviceServiceCallsSkipped = true;

            this.setState({
                isClaimItemsToastOpen: true
            });

            this.props.receiveActiveDetailsId(batchId);

            this.handleOpenLoadingDialog();

            try {
                await Promise.allSettled([
                    this.fetchBatchDetails(batchId),
                    this.props.requestStartTimer({
                        batchID: batchId,
                        userID: this.props.userID
                    })
                ]);

                if (this.props.webSocket && this.props.location?.state?.itemID && this.isBatchDetailsPage) {
                    isDeviceServiceCallsSkipped = false;
                    this.handleRetrieveMenuOptions(this.props.location?.state?.itemID);
                    this.sendWsRescan(this.props.location?.state?.itemID, DeviceAction.RESCAN_ALL);
                }
            } catch (error) {
                console.error(error);
            } finally {
                if (isDeviceServiceCallsSkipped) {
                    this.handleCloseLoadingDialog();
                }
            }

            this.props.history.replace({ state: { ...this.props.location.state, createdBatch: null, itemID: null } });
        }

        if (this.props.location?.state?.batchId) {
            // Will execute from My Batches or My Kits
            const batchId = this.props.location?.state?.batchId;
            let isDeviceServiceCallsSkipped = true;

            this.props.receiveActiveDetailsId(batchId);

            this.handleOpenLoadingDialog();
            try {
                // Fetch details only for the first time
                if (this.isDetailsNotYetFetched(batchId)) {
                    await Promise.allSettled([
                        this.fetchBatchDetails(batchId),
                        this.props.requestStartTimer({
                            batchID: batchId,
                            userID: this.props.userID
                        })
                    ]);
                }
                
                if (this.props.webSocket && this.props.location?.state?.itemID && this.isBatchDetailsPage) {
                    isDeviceServiceCallsSkipped = false;
                    this.sendWsRescan(this.props.location.state?.itemID, DeviceAction.RESCAN_ALL);
                    this.handleRetrieveMenuOptions(this.props.location?.state?.itemID);
                }
            } catch (error) {
                console.error(error);
            } finally {
                if (isDeviceServiceCallsSkipped) {
                    this.handleCloseLoadingDialog();
                }
            }

            this.props.history.replace({ state: { ...this.props.location?.state, batchId: null, itemID: null } });
        }

        if (!this.props.batchDetailsToDisplay && !this.props.activeDetailsId && !this.props.location?.state?.createdBatch) {
            // If all batches or kits are closed, redirect to to either My Batches or My Kits
            this.props.history.replace(this.isBatchDetailsPage ? '/my-batches' : '/my-kits');
        }

        const isTimerPaused = Array.isArray(this.props.pausedTimers)
            && this.props.activeDetailsId
            && this.props.pausedTimers?.find(pausedTimer => pausedTimer?.batchID.toString() === this.props.activeDetailsId?.toString() && pausedTimer?.isComplete === false) !== undefined;
        if (isTimerPaused) {
            // Show the Paused Timer dialog if one of the batches or kit is detected to be paused
            this.handleOpenPausedTimerDialog();
        }
    };

    async componentDidUpdate(prevProps, prevState) {
        if (
            (prevProps?.batchDetailsToDisplay !== this.props.batchDetailsToDisplay && this.props.batchDetailsToDisplay?.length > 0) ||
            (prevProps?.activeDetailsId !== this.props.activeDetailsId && this.props.activeDetailsId) ||
            (prevProps?.activeKitDetailsItemId !== this.props.activeKitDetailsItemId && this.props.activeKitDetailsItemId)
            // This will execute whenever the active details change or the next/previous button (kit) was clicked
        ) {
            const batchData = this.props.batchDetailsToDisplay?.find(batch => batch?.batchID?.toString() === this.props.activeDetailsId?.toString());
            let rowData = batchData?.items;
            let options = rowData?.[0]?.options;

            if (batchData?.batchType === BatchType.KIT) {
                // Execute below only for Kit Details
                let activeKitItemId = batchData?.kitDef?.items?.[0]?.itemID;
                const isActiveKitDetailsInCurrentActiveDetails = batchData?.kitDef.items?.find(item => item?.id?.toString() === this.props.activeKitDetailsItemId?.toString()) !== undefined;
                if (this.props.activeKitDetailsItemId && isActiveKitDetailsInCurrentActiveDetails) {
                    activeKitItemId = this.props.activeKitDetailsItemId;
                }

                rowData = rowData?.[activeKitItemId];
                options = rowData?.[0]?.options;
            }

            if (batchData) {
                const newColumnDefs = [
                    ...this.defaultColumnDefs,
                    ...(Array.isArray(options) ? options?.map((option, index) => {
                        return {
                            headerName: option?.name,
                            field: option?.id?.toString(),
                            valueGetter: ({ data }) => data?.completedOptions?.[option?.id],
                            minWidth: 108,
                            editable: true,
                            cellEditor: CustomCellEditor,
                            filter: FilterSet,
                            filterParams: {
                                gridRef: this.gridRef,
                                gridApiRef: this.gridApiRef,
                                customCol: true,
                                optionId: option?.id,
                                filterLabelOption: {
                                    field: option?.name
                                }
                            },
                            cellEditorParams: {
                                onUpdate: this.handleUpdateRowData,
                                doesTableHaveValidationErrors: this.doesTableHaveValidationErrors,
                                onShowTableValidationToast: this.handleSetTableValidationToastMsg
                            },
                            onCellClicked: this.handleTableCellClicked,
                            suppressKeyboardEvent: this.handleTableSuppressKeyboardEvent,
                            cellRenderer: CustomCellRenderer
                        };
                    }) : [])
                ];

                this.setState({
                    rowData: Array.isArray(rowData) ? rowData : [],
                    columnDefs: newColumnDefs,
                    isGridLoading: false
                });
            }
        }

        if (
            (prevProps?.batchDetailsToDisplay !== this.props.batchDetailsToDisplay && this.props.batchDetailsToDisplay?.length > 0) ||
            (prevProps?.activeDetailsId !== this.props.activeDetailsId && this.props.activeDetailsId)
            // This will execute whenever the active details change or the next/previous button (kit) was clicked
        ) {
            const batchData = this.props.batchDetailsToDisplay?.find(batch => batch?.batchID?.toString() === this.props.activeDetailsId?.toString());
            if (batchData?.batchType === BatchType.KIT) {
                // Execute below only for Kit Details
                let activeKitItemId = batchData?.kitDef?.items?.[0]?.itemID;
                const isActiveKitDetailsInCurrentActiveDetails = batchData?.kitDef.items?.find(item => item?.id?.toString() === this.props.activeKitDetailsItemId?.toString()) !== undefined;
                if (this.props.activeKitDetailsItemId && isActiveKitDetailsInCurrentActiveDetails) {
                    activeKitItemId = this.props.activeKitDetailsItemId;
                }

                this.props.receiveActiveKitDetailsItemId(activeKitItemId);
            }
        }

        if ((
            Array.isArray(this.props.batchDetailsToDisplay) && this.props.batchDetailsToDisplay?.length === 0) &&
            !this.props.activeDetailsId &&
            !this.props.location?.state?.batchId
        ) {
            // If all batches or kits are closed, redirect to to either My Batches or My Kits
            this.props.history.replace(this.isBatchDetailsPage ? '/my-batches' : '/my-kits');
        }

        if (prevProps?.activeDetailsId !== this.props.activeDetailsId) {
            const isTimerPaused = Array.isArray(this.props.pausedTimers) && this.props.pausedTimers?.find(pausedTimer => pausedTimer?.batchID.toString() === this.props.activeDetailsId?.toString() && pausedTimer?.isComplete === false) !== undefined;
            const isBatchFetchedAlready = !this.isDetailsNotYetFetched(this.props.activeDetailsId);
            if (isTimerPaused && isBatchFetchedAlready) {
                this.handleOpenPausedTimerDialog();
            } else {
                this.handleClosePausedTimerDialog();
            }
        }

        if (
            (prevProps?.activeDetailsId !== this.props.activeDetailsId && this.props.activeDetailsId) ||
            (prevProps?.activeKitDetailsItemId !== this.props.activeKitDetailsItemId && this.props.activeKitDetailsItemId)
        ) {
            const itemID = this.getDeviceActionItemId();

            if (itemID && this.props.webSocket) {
                if (this.state.isLoadingDialogOpen === false) {
                    this.handleOpenLoadingDialog();
                }

                this.handleRetrieveMenuOptions(itemID);
                this.sendWsRescan(itemID, DeviceAction.RESCAN_ALL);
            }
        }

        if (prevProps?.deviceServiceResponse !== this.props.deviceServiceResponse) {
            const deviceServiceResponse = this.props.deviceServiceResponse;
            let ongoingDeviceActions = [...this.state.ongoingDeviceActions];

            if (ongoingDeviceActions.includes(deviceServiceResponse?.url)) {
                ongoingDeviceActions = ongoingDeviceActions.filter(deviceActionLoading => deviceActionLoading !== deviceServiceResponse?.url);
            }

            if (ongoingDeviceActions.length === 0) {
                this.handleCloseLoadingDialog();
            }

            this.setState(prevState => {
                return {
                    ...prevState,
                    ongoingDeviceActions
                };
            });

            if (deviceServiceResponse?.success && deviceServiceResponse?.result) {
                switch (deviceServiceResponse?.action) {
                    case DeviceAction.RESCAN:
                    case DeviceAction.RESCAN_ALL:
                        if (deviceServiceResponse?.result?.data?.usb) {
                            const activeDetails = this.getActiveDetails();
                            const items = activeDetails?.batchType === 'batch' ? activeDetails?.items : activeDetails?.items?.[this.props.activeKitDetailsItemId];
                            const serialNumbersInBatch = items?.map(item => item?.serial?.trim());
                            const serialNumbersScanned = Object.entries(deviceServiceResponse?.result?.data?.usb).map(([_, value]) => {
                                const serialNumber = value?.['ril.serialnumber'];
                                if (serialNumber?.startsWith('S')) {
                                    return serialNumber?.slice(1);
                                }
                                return serialNumber;
                            });
                            const scannedRowData = this.getFocusedTableRowData();
                            const serialNumbersInBatchSingle = serialNumbersInBatch?.filter(item => scannedRowData?.serial?.toLowerCase().trim() === item?.toLowerCase().trim());

                            const scannedDevices = {
                                unableToScanDevices: deviceServiceResponse?.action === DeviceAction.RESCAN
                                    ? serialNumbersInBatchSingle?.filter(serialNumberInBatchSingle => !serialNumbersScanned?.includes(serialNumberInBatchSingle)) || []
                                    : serialNumbersInBatch?.filter(serialNumberInBatch => !serialNumbersScanned?.includes(serialNumberInBatch)) || [],
                                notInBatchDevices: Array.isArray(serialNumbersScanned) ? serialNumbersScanned?.filter(serialNumberScanned => !serialNumbersInBatch?.includes(serialNumberScanned)) : [],
                                inBatchDevices: Array.isArray(serialNumbersScanned) ? Object.entries(deviceServiceResponse?.result?.data?.usb).map(([key, value]) => {
                                    let serialNumber = value?.['ril.serialnumber'];
                                    if (serialNumber?.startsWith('S')) {
                                        serialNumber =  serialNumber?.slice(1);
                                    }
                                    
                                    return { 
                                        usbID: key, 
                                        serial: serialNumber
                                    };
                                }) : []
                            };

                            if (scannedDevices.unableToScanDevices.length > 0 || scannedDevices.notInBatchDevices.length > 0) {
                                this.setState({
                                    scannedDevices
                                });
                                this.handleOpenErrorScanningDevicesDialog();
                            }

                            if (scannedDevices.inBatchDevices.length > 0) {
                                const newBatchDetailsToDisplay = this.props.batchDetailsToDisplay?.map(details => {
                                    if (details?.batchID?.toString() === this.props.activeDetailsId?.toString()) {
                                        let items = details?.batchType === BatchType.BATCH
                                            ? [...details?.items]
                                            : [...details?.items?.[this.props.activeKitDetailsItemId]];
                        
                                        items = items?.map(item => {
                                            let usbID = null;

                                            const scannedDevice = scannedDevices.inBatchDevices.find(inBatchDevice => inBatchDevice.serial === item?.serial);
                                            if (scannedDevice) {
                                                usbID = scannedDevice.usbID;
                                            }
                    
                                            return {
                                                ...item,
                                                usbID
                                            };
                                        });
                        
                                        if (details?.batchType === BatchType.BATCH) {
                                            return {
                                                ...details,
                                                items
                                            };
                                        }
                        
                                        return {
                                            ...details,
                                            items: {
                                                ...details?.items,
                                                [this.props.activeKitDetailsItemId]: items
                                            }
                                        };
                                    }
                        
                                    return {
                                        ...details
                                    };
                                });
                                this.props.receiveBatchDetails(newBatchDetailsToDisplay);
                            }
                        }
                        break;
                    case DeviceAction.GET_OPTIONS:
                    case DeviceAction.GET_ALL_OPTIONS:
                        if (deviceServiceResponse?.result?.data?.options) {
                            const newBatchDetailsToDisplay = this.props.batchDetailsToDisplay?.map(details => {
                                if (details?.batchID?.toString() === this.props.activeDetailsId?.toString()) {
                                    let items = details?.batchType === BatchType.BATCH
                                        ? [...details?.items]
                                        : [...details?.items?.[this.props.activeKitDetailsItemId]];

                                    items = items?.map(item => {
                                        let completedOptions = {
                                            ...item.completedOptions
                                        };

                                        const retrievedOptions = deviceServiceResponse?.result?.data?.options?.[item?.usbID];
                                        if (retrievedOptions) {
                                            completedOptions = {
                                                ...(item?.completedOptions || {}),
                                                ...(retrievedOptions || {})
                                            };
                                        }

                                        return {
                                            ...item,
                                            completedOptions
                                        };
                                    });

                                    if (details?.batchType === BatchType.BATCH) {
                                        return {
                                            ...details,
                                            items
                                        };
                                    }
                    
                                    return {
                                        ...details,
                                        items: {
                                            ...details?.items,
                                            [this.props.activeKitDetailsItemId]: items
                                        }
                                    };
                                }

                                return {
                                    ...details
                                };
                            });
                            this.props.receiveBatchDetails(newBatchDetailsToDisplay);
                        };
                        break;
                    case DeviceAction.STAGE: {
                        const rowData = this.getFocusedTableRowData();
                        this.handleUpdateStagingStatus(rowData?.orderItemID, StagingStatus.PASSED, deviceServiceResponse?.action);
                        break;
                    }
                    case DeviceAction.STAGE_ALL:
                        this.handleStageAllResponse(deviceServiceResponse);
                        break;
                    case DeviceAction.ID:
                    case DeviceAction.RESET: {
                        const rowData = this.getFocusedTableRowData();
                        this.handleUpdateStagingStatus(rowData?.orderItemID, StagingStatus.NONE, deviceServiceResponse?.action);
                        break;
                    }
                    case DeviceAction.ID_ALL:
                    case DeviceAction.RESET_ALL:
                        const rowsToUpdateStagingStatus = this.state.rowData.filter(row => row?.usbID === `USB_${deviceServiceResponse?.query?.usbDeviceID}`).map(row => row?.usbID);
                        this.handleUpdateStagingStatus(null, StagingStatus.NONE, deviceServiceResponse?.action, null, rowsToUpdateStagingStatus);
                        break;
                    case DeviceAction.GET_MENU_OPTIONS:
                        if (deviceServiceResponse?.result?.menu) {
                            const newBatchDetailsToDisplay = this.props.batchDetailsToDisplay?.map(details => {
                                if (details?.batchID?.toString() === this.props.activeDetailsId?.toString()) {
                                    const menus = deviceServiceResponse?.result?.menu || {};

                                    return {
                                        ...details,
                                        menus
                                    };
                                }

                                return {
                                    ...details
                                };
                            });
                            this.props.receiveBatchDetails(newBatchDetailsToDisplay);
                        }
                        break;
                    default:
                        if (this.state.isMenuOptionPromptDialogOpen) {
                            this.handleCloseMenuOptionPromptDialog();
                        }
                        break;
                }
            } else {
                switch (deviceServiceResponse?.action) {
                    case DeviceAction.STAGE: {
                        const rowData = this.getFocusedTableRowData();
                        this.handleUpdateStagingStatus(rowData?.orderItemID, StagingStatus.FAILED, deviceServiceResponse?.action, this.props.batchDetailsToDisplay);
                        break;
                    }
                    case DeviceAction.ID:
                    case DeviceAction.RESET: {
                        const rowData = this.getFocusedTableRowData();
                        this.handleUpdateStagingStatus(rowData?.orderItemID, StagingStatus.FAILED, deviceServiceResponse?.action, this.props.batchDetailsToDisplay);
                        break;
                    }
                    case DeviceAction.STAGE_ALL: {
                        this.handleStageAllResponse(deviceServiceResponse);
                        break;
                    }
                    case DeviceAction.ID_ALL:
                    case DeviceAction.RESET_ALL: {
                        const rowsToUpdateStagingStatus = this.state.rowData.filter(row => row?.usbID === `USB_${deviceServiceResponse?.query?.usbDeviceID}`).map(row => row?.usbID);
                        this.handleUpdateStagingStatus(null, StagingStatus.FAILED, deviceServiceResponse?.action, null, rowsToUpdateStagingStatus);
                        break;
                    }
                    default:
                        break;
                }
            }
        }

        if (this.state.detailsGridApi) {
            if (this.state.isGridLoading) {
                this.state.detailsGridApi?.showLoadingOverlay();
            } else {
                this.state.detailsGridApi?.hideOverlay();
            }
        }

        if (prevState?.selectedFilter !== this.state.selectedFilter && this.state.detailsGridApi) {
            this.state.detailsGridApi?.onFilterChanged();
        }
    };

    componentWillUnmount() {
        this.props.receiveActiveDetailsId(null);
        this.props.receiveActiveKitDetailsItemId(null);
    };

    render() {
        const {
            isRefreshing,
            columnDefs,
            rowData,
            isPauseAllTimersDialogOpen,
            filterMenuAnchorElement,
            selectedFilter,
            isErrorScanningDevicesDialogOpen,
            scannedDevices,
            tableValidationToastMsg,
            isClaimItemsToastOpen,
            searchFilterValue,
            isPausedTimerDialogOpen,
            isLoadingDialogOpen,
            isDeviceServiceNotRunningToastOpen,
            isReportUnitDoaDialogOpen,
            isDeviceFlaggedAsDoaToastOpen,
            isBatchCompletedDialogOpen,
            isNoUsbIdDeviceFoundToastOpen,
            isGridLoading,
            isMenuOptionPromptDialogOpen,
            isCannotPassDeviceDialogOpen,
            isKitPairingDialogOpen,
            isBatchRequiresAuditDialogOpen,
            isRemoveFromBatchDialogOpen
        } = this.state;

        const activeDetails = this.getActiveDetails();
        const isForQc = !!(activeDetails?.step && parseInt(activeDetails?.step) > 0);
        const stagingStatusFilters = Object.values(StagingStatusFilter);

        return (
            <div className="relative">
                {this.isBatchDetailsPage && (
                    <div className="flex justify-between py-2 pt-3 mb-2">
                        <TabContainerButton className="flex justify-between w-full">
                            <div className="flex gap-[24px]">
                                <Button
                                    variant="flat"
                                    className="w-[122px]"
                                    startIcon={<FilterListIcon />}
                                    onClick={event => {
                                        this.setState({ filterMenuAnchorElement: event?.currentTarget });
                                    }}
                                >
                                    Filter
                                </Button>
                                <Menu
                                    className="px-[9px] py-[12px]"
                                    menuWidth={171}
                                    anchorEl={filterMenuAnchorElement}
                                    open={Boolean(filterMenuAnchorElement)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    }}
                                    onClose={() => this.setState({ filterMenuAnchorElement: null })}
                                    preventHideOnSelect
                                >
                                    {stagingStatusFilters.map((filter, index) => {
                                        const menuItemClassNames = `
                                        flex gap-[10px] items-center text-on-surface-variant 
                                        ${index < stagingStatusFilters.length - 1 ? 'pb-2 border-b-[0.75px] border-surface-dim' : ''}
                                        ${index > 0 ? 'pt-[10px]' : ''}
                                    `;

                                        return (
                                            <MenuItem key={index} className={menuItemClassNames} onClick={() => this.handleFilterMenuItemClick(filter)}>
                                                <EllipseIcon className={`!size-[6px] ${selectedFilter === filter ? '' : 'invisible'}`} />
                                                <p className={`text-[14px] leading-[20px] tracking-[0.25px] ${selectedFilter === filter ? 'font-medium' : ''}`}>{filter}</p>
                                            </MenuItem>
                                        );
                                    })}
                                </Menu>
                                <div className="w-[500px]">
                                    <TextField startIcon={<SearchIcon />} value={searchFilterValue} onChange={this.handleSearchFilterChange} />
                                </div>
                            </div>
                            <Button
                                className="w-[122px]"
                                startIcon={<RefreshIcon />}
                                isLoading={isRefreshing}
                                onClick={this.handleRefreshClick}
                            >
                                Refresh
                            </Button>
                        </TabContainerButton>
                    </div>
                )}
                {this.isKitDetailsPage && (
                    <Button
                        className="w-[122px] absolute right-0 top-[-47px]"
                        startIcon={<RefreshIcon />}
                        isLoading={isRefreshing}
                        onClick={this.handleRefreshClick}
                    >
                        Refresh
                    </Button>
                )}
                <Stack justifyContent="space-between" className="gap-[60px] overflow-x-auto py-[8px]">
                    <Button
                        variant="flat"
                        startIcon={<PauseIcon />}
                        onClick={this.handlePauseTimerClick}
                        className="border-0 text-[12px] text-on-surface-variant p-[8px] leading-[16px] tracking-[0.4px] hover:bg-primary-fixed shrink-0"
                    >
                        Pause Timer
                    </Button>
                    <Button
                        variant="flat"
                        startIcon={<PauseIcon />}
                        className="border-0 text-[12px] text-on-surface-variant p-[8px] leading-[16px] tracking-[0.4px] hover:bg-primary-fixed shrink-0"
                        onClick={this.handleOpenPauseAllTimersDialog}
                    >
                        Pause ALL Timers
                    </Button>
                    <Button
                        variant="flat"
                        onClick={this.handleRescanAllDevicesClick}
                        startIcon={<BarcodeReaderIcon />}
                        className="border-0 text-[12px] text-on-surface-variant p-[8px] leading-[16px] tracking-[0.4px] hover:bg-primary-fixed shrink-0"
                    >
                        Rescan ALL Devices
                    </Button>
                    <Button
                        variant="flat"
                        onClick={() => this.handleGetAllOptionsClick('all')}
                        startIcon={<AddNotesIcon />}
                        className="border-0 text-[12px] text-on-surface-variant p-[8px] leading-[16px] tracking-[0.4px] hover:bg-primary-fixed shrink-0"
                    >
                        Get ALL Options
                    </Button>
                    <Button
                        variant="flat"
                        onClick={this.handleStageAllDevicesClick}
                        startIcon={<DevicesIcon />}
                        className="border-0 text-[12px] text-on-surface-variant p-[8px] leading-[16px] tracking-[0.4px] hover:bg-primary-fixed shrink-0"
                    >
                        Stage ALL Devices
                    </Button>
                    <Button
                        variant="flat"
                        onClick={this.handleIdAllDevicesClick}
                        startIcon={<PhoneLinkRingIcon />}
                        className="border-0 text-[12px] text-on-surface-variant p-[8px] leading-[16px] tracking-[0.4px] hover:bg-primary-fixed shrink-0"
                    >
                        ID ALL Devices
                    </Button>
                    <Button
                        variant="flat"
                        onClick={this.handleResetAllDevicesClick}
                        startIcon={<PhoneLinkSetupIcon />}
                        className="border-0 text-[12px] text-on-surface-variant p-[8px] leading-[16px] tracking-[0.4px] hover:bg-primary-fixed shrink-0"
                    >
                        Reset ALL Devices
                    </Button>
                </Stack>
                <div className="border rounded-[10px] shadow-custom">
                    <Table
                        className={`
                            ag-theme-quartz details-table
                            ${isForQc ? 'details-table-with-qc' : ''}
                            ${this.isKitDetailsPage ? 'kit-details-table' : ''}
                            ${this.isBatchDetailsPage ? 'batch-details-table' : ''}
                        `}
                        ref={this.gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={this.getDefaultColDef()}
                        suppressLoadingOverlay={false}
                        loadingOverlayComponent={CustomLoadingOverlay}
                        rowClassRules={this.rowClassRules}
                        suppressContextMenu
                        gridOptions={this.gridOptions()}
                        reactiveCustomComponents
                        suppressClickEdit
                        suppressMenuHide
                        columnMenu="new"
                        quickFilterText={searchFilterValue}
                        noRowsOverlayComponent={CustomNoRowsOverlay}
                        noRowsOverlayComponentParams={{
                            message: 'There are no items matching the current filter.'
                        }}
                        suppressNoRowsOverlay={isGridLoading}
                    />
                    {this.renderTableActionsMenu()}
                </div>

                {isForQc && (
                    <>
                        <AddQualityCheckReason />
                    </>
                )}

                <LoadingDialog isOpen={isLoadingDialogOpen} />
                {isPausedTimerDialogOpen && this.renderPausedTimerDialog()}
                {isPauseAllTimersDialogOpen && this.renderPauseAllTimersDialog()}
                {(isErrorScanningDevicesDialogOpen && !!scannedDevices) && this.renderErrorScanningDevicesDialog()}
                {isReportUnitDoaDialogOpen && this.renderReportUnitDoaDialog()}
                {isBatchCompletedDialogOpen && this.renderBatchCompletedDialog()}
                {isMenuOptionPromptDialogOpen && this.renderMenuOptionPromptDialog()}
                {isKitPairingDialogOpen && this.renderKitPairingDialogOpen()}
                {isCannotPassDeviceDialogOpen && this.renderCannotPassDeviceDialog()}
                {isBatchRequiresAuditDialogOpen && this.renderBatchRequiresAuditDialog()}
                {isRemoveFromBatchDialogOpen && this.renderRemoveFromBatchDialog()}

                <Toast
                    message={tableValidationToastMsg}
                    open={!!tableValidationToastMsg}
                    onClose={() => this.handleSetTableValidationToastMsg('')}
                    status="error"
                    autoHide={!!tableValidationToastMsg ? false : true}
                />
                <Toast
                    message="Items Saved and Claimed Successfully"
                    open={isClaimItemsToastOpen}
                    onClose={this.handleCloseClaimItemsToast}
                />
                <Toast
                    message="Device Service is disconnected"
                    status="error"
                    open={isDeviceServiceNotRunningToastOpen}
                    onClose={this.handleCloseDeviceServiceNotRunningToast}
                />
                <Toast
                    message="DOA reported successfully"
                    open={isDeviceFlaggedAsDoaToastOpen}
                    onClose={this.handleCloseDeviceFlaggedAsDoaToast}
                />
                <Toast
                    message="No USB ID found for device. Please Rescan."
                    status="error"
                    open={isNoUsbIdDeviceFoundToastOpen}
                    onClose={this.handleCloseNoUsbIdDeviceFoundToast}
                />
                {this.isKitDetailsPage && this.renderKitStepperToast()}
            </div>
        );
    };
}

const mapStateToProps = ({ batch, user, kit, device }) => {
    return {
        batchDetailsToDisplay: batch?.batchDetailsToDisplay,
        activeDetailsId: batch?.activeDetailsId,
        activeKitDetailsItemId: kit?.activeKitDetailsItemId,
        pausedTimers: batch?.pausedTimers,
        loggedUser: user?.user,
        userID: user?.user?.userID,
        webSocket: device?.webSocket,
        deviceServiceResponse: device?.deviceServiceResponse
    };
};

const mapDispatchToProps = ({ batch, kit, device, qaFailure, user }) => {
    return {
        requestBatchDetails: batchId => batch.dispatch(requestBatchDetails(batchId)),
        receiveActiveDetailsId: activeDetailsId => batch.dispatch(receiveActiveDetailsId(activeDetailsId)),
        requestStartTimer: requestBody => batch.dispatch(requestStartTimer(requestBody)),
        requestPauseTimer: requestBody => batch.dispatch(requestPauseTimer(requestBody)),
        requestPauseAllTimers: requestBody => batch.dispatch(requestPauseAllTimers(requestBody)),
        receiveActiveKitDetailsItemId: itemId => kit.dispatch(receiveActiveKitDetailsItemId(itemId)),
        receiveBatchDetails: batchDetailsToDisplay => batch.dispatch(receiveBatchDetails(batchDetailsToDisplay)),
        requestUpdateDeviceInfo: requestBody => device.dispatch(requestUpdateDeviceInfo(requestBody)),
        requestFlagDevice: requestBody => device.dispatch(requestFlagDevice(requestBody)),
        requestCompleteBatch: requestBody => batch.dispatch(requestCompleteBatch(requestBody)),
        receiveQaFailures: qaFailures => qaFailure.dispatch(receiveQaFailures(qaFailures)),
        toggleSidebar: isOpen => user.dispatch(toggleSidebar(isOpen)),
        receiveProblemTemplate: template => user.dispatch(receiveProblemTemplate(template)),
        receivePausedTimers: pausedTimers => batch.dispatch(receivePausedTimers(pausedTimers))
    };
};

export default withDeviceService(
    withStore(
        mapStateToProps,
        mapDispatchToProps
    )(withRouter(DetailsPage))
);

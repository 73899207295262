import { QaFailureStoreActionConstants, receiveCreatedQaFailures, receiveDeletedQaFailureId, receiveQaFailures } from '@/Store/Actions';
import { createQaFailures, deleteQaFailure, getQaFailures } from '@/Store/Services';

export const QaFailureStoreMiddleware = ({ state, action, dispatch }) => {
    let success;

    switch (action.type) {
        case QaFailureStoreActionConstants.REQUEST_QA_FAILURES:
            const { batchID, kitItemID } = action?.payload || {};

            success = qaFailures => {
                const newQaFailures = [
                    ...(state?.qaFailures || []),
                    {
                        batchID,
                        kitItemID,
                        data: qaFailures
                    }
                ];
                dispatch(receiveQaFailures(newQaFailures));
            };
            return getQaFailures(success, action?.payload);
        case QaFailureStoreActionConstants.REQUEST_CREATE_QA_FAILURES: {
            const { data, userID, batchID, kitItemID } = action?.payload || {};

            const newDataPayload = data?.map(({ orderItemID, checklistID, additionalNotes, isAddedByAuditor }) => {
                return {
                    orderItemID,
                    checklistID,
                    additionalNotes,
                    isAddedByAuditor
                };
            });
            const newPayload = {
                userID,
                data: newDataPayload
            };

            success = createdQaFailures => {
                const newCreatedQaFailures = {
                    batchID,
                    kitItemID,
                    data: createdQaFailures?.map(({ id, createDate, source, orderItemID }) => {
                        const dataFromPayload = data?.find(payload => payload?.orderItemID === orderItemID);

                        return {
                            id,
                            createDate,
                            additionalNotes: source,
                            reasonCode: dataFromPayload?.reasonCode || '',
                            serial: dataFromPayload?.serial || ''
                        };
                    })
                };

                dispatch(receiveCreatedQaFailures(newCreatedQaFailures));
            };

            return createQaFailures(success, newPayload);
        }
        case QaFailureStoreActionConstants.REQUEST_DELETE_QA_FAILURE: {
            success = () => {
                dispatch(receiveDeletedQaFailureId({
                    id: action?.payload,
                    batchID: action?.batchID,
                    kitItemID: action?.kitItemID
                }));
            };
            return deleteQaFailure(success, action?.payload);
        }
        default:
            return;
    }
};

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    useClick,
    useDismiss,
    useRole,
    useInteractions,
    FloatingFocusManager
} from '@floating-ui/react';
import { Calendar } from '@/Components/Display';

const DatePicker = ({
    label,
    value,
    singleDate,
    onSetSelectedDate,
    onApply,
    onCancel,
    className,
    withFooterActions,
    disablePastDates,
    disableFutureDates = true,
    initialText,
    dateRange,
    onSetSelectedDates,
    hideFooterAction,
    showFooterAction,
    inBetweenDays,
    setInBetweenDays
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [offset(10), flip(), shift()],
        whileElementsMounted: autoUpdate,
        placement: 'bottom-start'
    });

    const click = useClick(context);
    const dismiss = useDismiss(context);
    const role = useRole(context);

    // Merge all the interactions into prop getters
    const { getFloatingProps } = useInteractions([
        click,
        dismiss,
        role
    ]);

    const today = new Date();

    const parseDate = date => {
        return moment(date).format('MM/DD/YYYY');
    };

    const [date, setDate] = useState(today);
    const [selectedDate, setSelectedDate] = useState(value);

    const [, setLabelText] = useState(selectedDate ? parseDate(selectedDate) : initialText);

    useEffect(() => {
        setSelectedDate(value);

        setLabelText(initialText || '');
    }, [value, initialText]);

    const handleSetSelectedDate = e => {
        if (typeof onSetSelectedDate !== "function") return
        if (withFooterActions) {
            setSelectedDate(e.singleDate);
        } else {
            onSetSelectedDate(e);
            setLabelText(parseDate(e.singleDate));

            setTimeout(() => {
                setIsOpen(false);
            }, 100);
        }
    };

    const handleApply = () => {
        onApply && onApply();
        singleDate && onSetSelectedDate({ singleDate: selectedDate || new Date() });
        setLabelText(parseDate(selectedDate || new Date()));

        setTimeout(() => {
            setIsOpen(false);
        }, 100);
    };

    const handleClose = () => {
        if (typeof onCancel !== 'function') return;
        onCancel();
        setIsOpen(false);
    }

    const renderFooterActions = () => {
        return (
            <div className="date-picker-calendar-footer font-semibold font-roboto text-[14px] leading-[20px]">
                <button className="action-button" onClick={handleClose}>Cancel</button>
                <button className="action-button" onClick={handleApply}>OK</button>
            </div>
        );
    };

    return (
        <div className={`date-time  ${className ? className : ''}`}>
            <FloatingFocusManager context={context} modal={false}>
                <div
                    className={`date-picker-menu `}
                    ref={refs.setFloating}
                    style={floatingStyles}
                    {...getFloatingProps()}
                >
                    <div className="date-picker-calendar rounded-[5px]">
                        <div className="date-picker-calendar-body">
                            <Calendar
                                inBetweenDays={inBetweenDays}
                                setInBetweenDays={setInBetweenDays}
                                singleDate={singleDate}
                                date={date}
                                today={today}
                                startDate={dateRange.start}
                                endDate={dateRange.end}
                                selectedDate={selectedDate}
                                setDate={setDate}
                                onSetSelectedDates={onSetSelectedDates}
                                onSetSelectedDate={handleSetSelectedDate}
                                disablePastDates={disablePastDates}
                                disableFutureDates={disableFutureDates}
                                hideFooterAction={hideFooterAction}
                                showFooterAction={showFooterAction}
                            />
                        </div>
                        {withFooterActions && renderFooterActions()}
                    </div>
                </div>
            </FloatingFocusManager>
        </div>
    );
};

export default DatePicker;

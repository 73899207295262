

const DownloadIcon = ({ className }) => {
     
     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className}`} viewBox="0 0 24 24" fill="none">
          <mask id="mask0_315_13016" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="#171C20"/>
          </mask>
          <g mask="url(#mask0_315_13016)">
            <path d="M12.2632 16.1709L7.26318 11.1709L8.66318 9.7209L11.2632 12.3209V4.1709H13.2632V12.3209L15.8632 9.7209L17.2632 11.1709L12.2632 16.1709ZM6.26318 20.1709C5.71318 20.1709 5.24235 19.9751 4.85068 19.5834C4.45902 19.1917 4.26318 18.7209 4.26318 18.1709V15.1709H6.26318V18.1709H18.2632V15.1709H20.2632V18.1709C20.2632 18.7209 20.0674 19.1917 19.6757 19.5834C19.284 19.9751 18.8132 20.1709 18.2632 20.1709H6.26318Z"/>
          </g>
        </svg>
     )
}

export default DownloadIcon
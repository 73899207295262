import { Component } from "react";

import { Button } from "@/Components/Inputs";

import { withStore } from "@/Helpers/withStore";
import { BoltIcon, RefreshIcon, RefreshSpinnerIcon } from "@/Icons";
import { withDeviceService } from "@/HOC";

class DeviceServiceStatus extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isHovered: false,
            isReconnecting: false
        };
    }

    handleMouseEnter = () => {
        this.setState({
            isHovered: true
        });
    }

    handleMouseLeave = () => {
        this.setState({
            isHovered: false
        });
    }

    handleClick = async () => {
        if (!this.props?.webSocket && this.props?.initWsDeviceService && !this.state.isReconnecting) {
            this.setState({
                isReconnecting: true
            });

            this.props.resetPollingCount();
            
            try {
                const response = await this.props?.initWsDeviceService();
                if (response?.status?.toLowerCase().trim() === 'connected') {
                    return;
                }

                window.open(`procheck3-device-service://`, '_self');
                await this.props?.initWsDeviceService(true);
            } finally {
                this.setState({
                    isReconnecting: false
                });
            }
        }
    }
    
    renderButtonContent() {
        const { isHovered, isReconnecting } = this.state;
        const isConnected = !!this.props?.webSocket;

        if (isReconnecting) {
            return (
                <>
                    <RefreshSpinnerIcon className="text-[#D9E4EC] !size-[16px] animate-spin" />
                    Reconnecting
                </>
            );
        }

        if (isHovered && !isConnected) {
            return (
                <>
                    <RefreshIcon className="text-[#D9E4EC] !size-[16px]" />
                    Reconnect
                </>
            );
        }

        if (isConnected) {
            return (
                <>
                    <BoltIcon />
                    Connected
                </>
            );
        }

        return 'Disconnected';
    }

    render() {
        return (
            <Button
                className={`
                    px-[11.5px] py-[3px] rounded-[18px] h-[initial] uppercase text-[13.5px] leading-[18px] tracking-[0.6px] w-[150px]
                    ${this.props?.webSocket ? 'text-on-secondary-container cursor-default' : '!bg-[#D9E4EC] hover:!bg-primary-20 text-on-primary-container hover:text-[#D9E4EC]'}
                `}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onClick={this.handleClick}
            >
                <span className="flex items-center gap-[3px]">
                    {this.renderButtonContent()}
                </span>
            </Button>
        );
    }
}

const mapStateToProps = ({ device }) => {
    return {
        webSocket: device?.webSocket
    };
};

export default withStore(
    mapStateToProps
)(withDeviceService(DeviceServiceStatus));

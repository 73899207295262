

const PauseIcon = ({ className }) => {

     return (
          <svg xmlns="http://www.w3.org/2000/svg"  className={`svg-regular ${className}`} viewBox="0 0 18 19" fill="none">
          <mask id="mask0_3588_329" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
            <rect y="0.0224609" width="18" height="18" fill="#171C20"/>
          </mask>
          <g mask="url(#mask0_3588_329)">
            <path d="M7.05289 11.8349H8.17787V6.20996H7.05289V11.8349ZM9.82209 11.8349H10.9471V6.20996H9.82209V11.8349ZM9.00124 16.1474C8.01579 16.1474 7.08951 15.9604 6.22241 15.5864C5.3553 15.2124 4.60104 14.7049 3.95963 14.0637C3.3182 13.4226 2.81041 12.6687 2.43624 11.8019C2.06208 10.9352 1.875 10.0091 1.875 9.0237C1.875 8.03825 2.062 7.11197 2.436 6.24487C2.81 5.37776 3.31756 4.6235 3.95869 3.98209C4.59983 3.34066 5.35376 2.83287 6.22048 2.45871C7.08719 2.08454 8.01328 1.89746 8.99873 1.89746C9.98418 1.89746 10.9105 2.08446 11.7776 2.45846C12.6447 2.83246 13.3989 3.34002 14.0403 3.98115C14.6818 4.62229 15.1896 5.37622 15.5637 6.24294C15.9379 7.10966 16.125 8.03574 16.125 9.02119C16.125 10.0066 15.938 10.9329 15.564 11.8C15.19 12.6671 14.6824 13.4214 14.0413 14.0628C13.4001 14.7042 12.6462 15.212 11.7795 15.5862C10.9128 15.9603 9.98669 16.1474 9.00124 16.1474ZM8.99998 15.0224C10.675 15.0224 12.0937 14.4412 13.2562 13.2787C14.4187 12.1162 15 10.6974 15 9.02244C15 7.34744 14.4187 5.92869 13.2562 4.76619C12.0937 3.60369 10.675 3.02244 8.99998 3.02244C7.32498 3.02244 5.90623 3.60369 4.74373 4.76619C3.58123 5.92869 2.99998 7.34744 2.99998 9.02244C2.99998 10.6974 3.58123 12.1162 4.74373 13.2787C5.90623 14.4412 7.32498 15.0224 8.99998 15.0224Z"/>
          </g>
        </svg>
     )
}

export default PauseIcon
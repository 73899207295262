import {
     createContext,
     useContext,
     useMemo
} from 'react';
import { useReducerWithMiddleware } from '@/Hooks';
import { ProductStoreMiddleware } from '@/Store/Middlewares';
import { ProductStoreReducer } from '@/Store/Reducers';
import { ProviderName } from '@/Helpers/constants';

export const ProductStoreContext = createContext();

const initialState = {
     partNumber: [],
};

export const ProductStoreProvider = ({ children }) => {
     const [state, dispatch] = useReducerWithMiddleware(ProductStoreReducer, initialState, [ProductStoreMiddleware]);

     const ProductStoreState = useMemo(() => {
          return { ...(state || {}) };
     }, [state]);

     return (
          <ProductStoreContext.Provider value={{ state: ProductStoreState, dispatch, name: ProviderName.PART_NUMBER }}>
               {children}
          </ProductStoreContext.Provider>
     );
};

export const useProductStoreContext = () => {
     return useContext(ProductStoreContext);
};

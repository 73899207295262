

const SearchIcon = ({ className }) => {

     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className ?? ""}`} viewBox="0 0 24 25" fill="none">
          <mask id="mask0_3588_15588" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
            <rect y="0.0224609" width="24" height="24" fill="#171C20"/>
          </mask>
          <g mask="url(#mask0_3588_15588)">
            <path d="M19.4846 20.1763L13.223 13.9147C12.723 14.3404 12.148 14.6698 11.498 14.9032C10.848 15.1365 10.1948 15.2532 9.53839 15.2532C7.93673 15.2532 6.58118 14.6988 5.47177 13.59C4.36233 12.4811 3.80762 11.1263 3.80762 9.52553C3.80762 7.92473 4.36203 6.56888 5.47084 5.45798C6.57966 4.34708 7.93447 3.79163 9.53527 3.79163C11.1361 3.79163 12.4919 4.34633 13.6028 5.45575C14.7137 6.56517 15.2692 7.92072 15.2692 9.5224C15.2692 10.2173 15.1461 10.8897 14.8999 11.5397C14.6538 12.1897 14.3307 12.7455 13.9307 13.207L20.1923 19.4686L19.4846 20.1763ZM9.53839 14.2532C10.8653 14.2532 11.9855 13.7964 12.899 12.883C13.8124 11.9695 14.2692 10.8493 14.2692 9.5224C14.2692 8.19549 13.8124 7.07529 12.899 6.16183C11.9855 5.24836 10.8653 4.79163 9.53839 4.79163C8.21147 4.79163 7.09128 5.24836 6.17782 6.16183C5.26437 7.07529 4.80764 8.19549 4.80764 9.5224C4.80764 10.8493 5.26437 11.9695 6.17782 12.883C7.09128 13.7964 8.21147 14.2532 9.53839 14.2532Z" />
          </g>
        </svg>
     )
}

export default SearchIcon
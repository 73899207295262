export const getBrowserScrollbarWidth = () => {
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    outer.style.msOverflowStyle = 'scrollbar';
    document.body.appendChild(outer);

    const inner = document.createElement('div');
    outer.appendChild(inner);

    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
};

export const isElementOverflowing = (element, direction) => {
    if (!element || !direction) {
        return false;
    }

    if (direction?.toLowerCase().trim() === 'vertical') {
        return element?.offsetHeight < element?.scrollHeight;
    }

    if (direction?.toLowerCase().trim() === 'horizontal') {
        return element?.offsetWidth < element?.scrollWidth;
    }

    if (direction?.toLowerCase().trim() === 'both') {
        return element?.offsetHeight < element?.scrollHeight || element?.offsetWidth < element?.scrollWidth;
    }
};

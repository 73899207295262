

const ContentCopyIcon = ({ className }) => {



     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className ?? ""}`} viewBox="0 0 24 24" fill="none">
               <mask id="mask0_781_30693" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                    <rect y="0.0224609" width="24" height="24" fill="#D9D9D9" />
               </mask>
               <g mask="url(#mask0_781_30693)">
                    <path d="M9 18.0225C8.45 18.0225 7.97917 17.8266 7.5875 17.435C7.19583 17.0433 7 16.5725 7 16.0225V4.02246C7 3.47246 7.19583 3.00163 7.5875 2.60996C7.97917 2.21829 8.45 2.02246 9 2.02246H18C18.55 2.02246 19.0208 2.21829 19.4125 2.60996C19.8042 3.00163 20 3.47246 20 4.02246V16.0225C20 16.5725 19.8042 17.0433 19.4125 17.435C19.0208 17.8266 18.55 18.0225 18 18.0225H9ZM9 16.0225H18V4.02246H9V16.0225ZM5 22.0225C4.45 22.0225 3.97917 21.8266 3.5875 21.435C3.19583 21.0433 3 20.5725 3 20.0225V6.02246H5V20.0225H16V22.0225H5Z" />
               </g>
          </svg>
     )
}

export default ContentCopyIcon
import { Component } from "react";
import { Icon, Stack } from "@/Components/Display";
import { DownloadIcon, LaunchIcon } from "@/Icons";
import { Button } from "@/Components/Inputs";
import { withStore } from "@/Helpers/withStore";

class SupportingDocuments extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    handleLaunchClick = url => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    renderFileIcon = (fileName) => {
        if (['.pdf'].some(extension => fileName?.includes(extension))) {
            return <Icon name="PDFIcon" className="shrink-0 !size-[32px]" />;
        }

        if (['.com'].some(extension => fileName?.includes(extension))) {
            return <Icon name="LinkIcon" className="shrink-0 !size-[32px] text-white bg-primary-container rounded-md" />;
        }

        if (['.xlsx', '.xls'].some(extension => fileName?.includes(extension))) {
            return <Icon name="ExcelIcon" className="shrink-0 !size-[32px]" />;
        }

        if (['.jpg', '.jpeg', '.png'].some(extension => fileName?.includes(extension))) {
            return <Icon name="ImageIcon" className="shrink-0 !size-[32px]" />;
        }

        return <Icon name="QuestionMarkIcon" className="shrink-0 !size-[32px]" />
    };

    getActiveDetails = () => {
        const batch = this.props?.batchDetailsToDisplay?.find(batch => batch?.batchID?.toString() === this.props?.activeDetailsId?.toString());
        return batch || {};
    }

    getFiles = () => {
        const activeDetails = this.getActiveDetails();
        const activeKitDetailsItemId = this.props?.activeKitDetailsItemId;
        if (activeDetails) {
            if (activeDetails?.batchType === 'kit' && activeKitDetailsItemId) {
                return activeDetails?.items?.[activeKitDetailsItemId]?.[0]?.files;
            }

            return activeDetails?.items?.[0]?.files;
        }
        return [];
    }

    getActiveDetailsFullName = () => {
        const activeDetails = this.getActiveDetails();
        const activeKitDetailsItemId = this.props?.activeKitDetailsItemId;

        if (activeDetails) {
            if (activeDetails?.batchType === 'kit' && activeKitDetailsItemId) {
                return activeDetails?.items?.[activeKitDetailsItemId]?.[0]?.fullname;
            }

            return activeDetails?.items?.[0]?.fullname;
        }

        return '';
    }

    render() {
        const files = this.getFiles() || [];

        return (
            <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" className="gap-[16px] text-white h-full">
                <p className="text-[16px] font-medium leading-[24px] tracking-[0.5px]">
                    Support Documents for
                    <br />
                    {this.getActiveDetailsFullName()}
                </p>
                <p className="text-[12px] leading-[16px] tracking-[0.4px]">
                    Access and review documents, instructions, videos for this project.
                </p>
                {files?.length > 0 && (
                    <>
                        <Stack direction="column" className="w-full border-t-[0.5px] border-primary-fixed-dim">
                            {files.map(({ name, id, url }) => {
                                return (
                                    <Stack justifyContent="space-between" key={id} className="w-full gap-[8px] py-[12px] border-b-[0.5px] border-primary-fixed-dim">
                                        {this.renderFileIcon(url)}
                                        <p className="text-[12px] leading-[16px] tracking-[0.4px] grow max-w-[156px] break-all">{name}</p>
                                        <Button variant="flat" className="size-[24px] p-0 bg-transparent border-0 hover:bg-opacity-40 shrink-0 text-white" onClick={() => this.handleLaunchClick(url)}>
                                            <LaunchIcon className="svg-small" />
                                        </Button>
                                    </Stack>
                                );
                            })}
                        </Stack>
                        <Button
                            startIcon={<DownloadIcon />}
                            className="ml-[12.5px] mb-[94px] mt-auto"
                        >
                            Download document
                        </Button>
                    </>
                )}
                {files?.length === 0 && (
                    <div className="mt-[9px] rounded-[4px] bg-primary-35 pt-[40px] pb-[41px] pl-[31px] pr-[47px] text-[12px] leading-[16px] tracking-[0.4px]">
                        <p className="w-[134px] h-[47px]">There are no support files for this item.</p>
                    </div>
                )}
            </Stack>
        );
    };
}

const mapStateToProps = ({ batch, kit }) => {
    return {
        batchDetailsToDisplay: batch?.batchDetailsToDisplay,
        activeDetailsId: batch?.activeDetailsId,
        activeKitDetailsItemId: kit?.activeKitDetailsItemId
    };
};

export default withStore(
    mapStateToProps,
)(SupportingDocuments);

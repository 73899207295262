import { useEffect, useMemo, useRef, useState } from "react";

import { Stack } from "@/Components/Display";
import { DropDownIcon } from "@/Icons";

const CustomCellRenderer = ({
    getValue,
    data,
    eGridCell,
    column
}) => {
    const cellRef = useRef(eGridCell);

    const [isHovered, setIsHovered] = useState(false);

    const option = useMemo(() => {
        return data?.options?.find(option => option?.id?.toString() === column?.colId?.toString());
    }, [data?.options, column?.colId]);

    useEffect(() => {
        const handleMouseEnter = () => setIsHovered(true);
        const handleMouseLeave = () => setIsHovered(false);
    
        const cell = cellRef?.current;
        if (cell) {
          cell?.addEventListener('mouseenter', handleMouseEnter);
          cell?.addEventListener('mouseleave', handleMouseLeave);
        }
    
        return () => {
          if (cell) {
            cell?.removeEventListener('mouseenter', handleMouseEnter);
            cell?.removeEventListener('mouseleave', handleMouseLeave);
          }
        };
      }, []);

    return (
        <Stack justifyContent="space-between" className="w-full">
            {getValue()}
            <DropDownIcon className={`ml-auto ${isHovered && option?.selectValues ? '' : '!hidden'}`} />
        </Stack>
    );
};

export default CustomCellRenderer;

import React, { memo, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from '@/Pages/Public/Login/login';
import PrivateRoutes from '@/Routes/private-routes';
// import { useUserStoreContext } from "@/Store/Providers";
// import { Redirect,useLocation} from "react-router-dom";
// import InjectAxiosInterceptors from '@/Services/AxiosInterceptors';
const Playground = lazy(() => import('@/Pages/Public/Playground'));


const Routes = () => {

    return (
        <>
            {/* TODO: UNCOMMENT IF AXIOS INTERCEPTORS WILL BE USED */}
            {/* <InjectAxiosInterceptors /> */}
            <Switch>
                {/* TODO: RETURN THE LOGIN ROUTE HERE ONCE HAVE AUTHENTICATION */}

                <Route exact path="/playground">
                    <Suspense fallback={<>Loading...</>}>
                        <Playground />
                    </Suspense>
                </Route>
                <Route path="/login" exact render={(props) => {
                    return <Login {...props} />
                }} />

                <Route path="/" render={props => {
                    return <PrivateRoutes {...props} />
                }} />

            </Switch>
        </>
    );
};

export default memo(Routes);
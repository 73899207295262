import { useCallback, useEffect, useMemo, useState } from "react";
import { DataTable, Tooltip } from "@/Components/DataDisplay";
import { Dialog } from "@/Components/Feedback";
import { Button, TextField } from "@/Components/Inputs";
import { BarcodeScannerIcon, CheckIcon, ErrorIcon, FlagIcon } from "@/Icons";
import { Stack, Toast } from "@/Components/Display";
import { KitPairingName, OrderItemStatus } from "@/Helpers/constants";
import { useDispatch, useSelector } from "@/Store/StoreContextProvider";
import { receivePausedTimers, requestPassKit, requestRemoveKitPrimaryItem } from "@/Store/Actions";

const TableTextField = ({
    initialValue,
    onChange,
    ...props
}) => {
    const [value, setValue] = useState('');

    const handleChange = event => {
        const value = event?.target?.value || '';
        setValue(value);

        if (typeof onChange === 'function') {
            onChange(event);
        }
    };

    useEffect(() => {
        if (initialValue) {
            setValue(initialValue);
        }
    }, [initialValue]);

    return (
        <TextField
            className="kit-pairing-dialog-textfield"
            value={value}
            onChange={handleChange}
            {...(props || {})}
        />
    );
};

const KitPairingDialog = ({
    isOpen,
    onClose,
    onComplete,
    onCheckIsBatchComplete,
    kitDetails,
    kitPairingRows,
    handleKitPairingRows
}) => {

    const loggedUser = useSelector(({ user }) => user?.user);
    const activeDetailsId = useSelector(({ batch }) => batch?.activeDetailsId);
    const pausedTimers = useSelector(({ batch }) => batch?.pausedTimers);

    const kitDispatch = useDispatch(dispatch => dispatch?.kit);
    const batchDispatch = useDispatch(dispatch => dispatch?.batch);

    const [isKitIncompleteToastOpen, setIsKitIncompleteToastOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [currentItem, setCurrentItems] = useState(null);
    const [rowsOnChange, setRowOnChange] = useState([]);
    const [errorSwitch, setErrorSwitch] = useState(false);

    const isInitialValueShown = !([OrderItemStatus.IN_PROCESS_TECH, OrderItemStatus.KIT_ASSIGNMENT].includes(kitDetails?.status));

    const columns = useMemo(() => {
        const defaultColumns = [
            {
                field: '',
                label: 'Options',
                width: 88,
                style: { minWidth: 'unset' },
                sortable: false
            },
            {
                field: '',
                label: 'Primary Serial',
                width: 277,
                style: { minWidth: 'unset' },
                sortable: false
            }
        ];

        const otherColumns = kitDetails?.kitDef?.items
            ?.filter(item => item?.primary !== 1)
            ?.flatMap(item => {
                const columnName = `${item.product || ''} ${item.commonname || ''}`.trim();
                const isMultipleColumn = item.quantity > 1;

                const renderColumnName = (columnName, countRange, props) => {
                    return (
                        <div {...(props || {})}>
                            {columnName}
                            {isMultipleColumn && (
                                <span> {countRange}</span>
                            )}
                        </div>
                    );
                };

                return Array.from({ length: item.quantity }, (_, index) => {
                    let count = index + 1;
                    const countRange = `(${count} of ${item?.quantity ?? 0})`;
                    const isToolTipShown = `${(columnName ?? '')} ${(countRange ?? '')}`.length > 25;

                    return {
                        field: '',
                        className: 'p-0',
                        label: (
                            <div className="flex items-center gap-2.5">
                                <BarcodeScannerIcon />
                                {isToolTipShown ? (
                                    <Tooltip
                                        trigger={props => {
                                            return renderColumnName(columnName, countRange, props);
                                        }}
                                        content={renderColumnName(columnName, countRange)}
                                        direction="bottom"
                                        offset={3}
                                        className="text-xs justify-center flex items-center font-normal font-roboto tracking-[0.4px]"
                                    />
                                ) : renderColumnName(columnName, countRange)}
                            </div>
                        ),
                        fluid: true,
                        style: { maxWidth: '277px' },
                        sortable: false
                    }
                });
            });

        return [
            ...defaultColumns,
            ...(otherColumns || [])
        ];
    }, [kitDetails?.kitDef?.items]);

    useEffect(() => {
        if (kitDetails && isOpen) {
            const kitDefItems = kitDetails.kitDef?.items;
            const primaryKit = kitDefItems?.find(item => item?.primary === 1);
            const nonPrimaryKits = kitDefItems?.filter(item => item?.primary !== 1);
            const nonPrimaryKitItems = nonPrimaryKits?.reduce((accummulator, nonPrimaryKit) => {
                accummulator[nonPrimaryKit?.id] = kitDetails.items?.[nonPrimaryKit?.id];
                return accummulator;
            }, {});

            const primaryKitItems = kitDetails.items?.[primaryKit.id]?.filter(kit =>
                kit.status === OrderItemStatus.PASSED ||
                kit.passed === 1
            );

            const nonPrimaryKit = kitDefItems?.filter(item => item?.primary !== 1);

            const rows = primaryKitItems?.map(({ orderItemID: primaryOrderItemID, serial: primarySerial }, rowIndex) => {
                const row = {
                    primaryOrderItemID,
                    primarySerial: primarySerial?.slice('-6') || ''
                };

                Object.entries(nonPrimaryKitItems).forEach(([itemID, items]) => {
                    const findSerialItem = items?.find(item => item.kitPrimaryItemID === primaryOrderItemID);
                    const isPrimaryQuantityOne = primaryKit.quantity === 1;

                    items?.forEach(() => {
                        if (isPrimaryQuantityOne) {
                            row[`${KitPairingName.NON_PRIMARY_ORDER_ITEM_ID}-${itemID}-${isPrimaryQuantityOne ? 0 : rowIndex}`] = isInitialValueShown ? findSerialItem.orderItemID : 0;
                            row[`${KitPairingName.NON_PRIMARY_SERIAL}-${itemID}-${isPrimaryQuantityOne ? 0 : rowIndex}`] = isInitialValueShown ? findSerialItem.serial : '';
                        } else {
                            nonPrimaryKit?.forEach((nonPrimaryItem) => {
                                for (let index = 0; index < nonPrimaryItem.quantity; index++) {
                                    row[`${KitPairingName.NON_PRIMARY_ORDER_ITEM_ID}-${itemID}-${index}`] = isInitialValueShown ? items[index].orderItemID : 0;
                                    row[`${KitPairingName.NON_PRIMARY_SERIAL}-${itemID}-${index}`] = isInitialValueShown ? items[index].serial : '';
                                }
                            });
                        }
                    });
                });

                return row;
            });

            const isKitPairingRowsUpdated = Array.isArray(kitPairingRows) && kitPairingRows.length >= 1;
            setRowOnChange(isKitPairingRowsUpdated ? kitPairingRows : rows);

            const getErrorMessage = kitPairingRows?.find(item => item.errorMessage);

            if (getErrorMessage) {
                setErrorMessage(getErrorMessage.errorMessage?.error || '');
                setCurrentItems(getErrorMessage.errorMessage?.key || '');
            } else {
                setErrorMessage('');
                setCurrentItems('');
            }
        }
    }, [kitDetails, isOpen, isInitialValueShown, kitPairingRows]);

    const handleClose = () => {
        if (typeof onClose === 'function') {
            onClose();
            handleKitPairingRows(rowsOnChange);
        }
    };

    const handleOpenKitIncompleteToast = () => {
        setIsKitIncompleteToastOpen(true);
    };

    const handleCloseKitIncompleteToast = () => {
        setIsKitIncompleteToastOpen(false);
    };

    const getErrorMessage = useCallback((value, nonPrimaryKitId, rows) => {
        let error = '';
        if (kitDetails) {
            const kitDefItems = kitDetails.kitDef?.items;
            const primaryKit = kitDefItems?.find(item => item?.primary === 1);
            const nonPrimaryWithSerialKits = Object.values(kitDetails.items).flat().filter(item => item.id !== primaryKit?.id);
            const filterNonPrimaryKits = nonPrimaryWithSerialKits.filter(kits => kits.id === nonPrimaryKitId);
            const isSerialNumberUnknown = !(filterNonPrimaryKits.some(kit => kit.serial === value));
            const hasNotPassed = filterNonPrimaryKits.some(item => item.id === nonPrimaryKitId && item.passed === 0 && item.serial === value);
            const nonPrimaryId = `${KitPairingName.NON_PRIMARY_SERIAL}-${nonPrimaryKitId}`;

            const filteredKits = rows?.map(element => {
                let nonPrimary = [];
                Object.keys(element).forEach((key) => {
                    if (nonPrimaryId === key.slice(0, KitPairingName.NON_PRIMARY_SERIAL.length + 1 + nonPrimaryKitId.toString().length)) {
                        let getNonPrimarySerial = element[key];
                        nonPrimary.push(getNonPrimarySerial);
                    }
                });
                return nonPrimary;
            });

            const flatFilteredKits = filteredKits.flat().filter(item => Object.keys(item).length > 0);
            const hasDuplicate = flatFilteredKits.includes(value);

            if (value === '') {
                return error;
            }

            if (isSerialNumberUnknown) {
                error = 'Unknown Serial Number';
            }
            if (hasNotPassed) {
                error = 'Device marked as DOA';
            }
            if (hasDuplicate) {
                error = 'Serial Number already in use';
            }
        };

        return error;
    }, [kitDetails]);

    const handleTextFieldOnChange = (event, nonPrimaryKitId, rowData, key) => {

        const value = event?.target?.value || '';

        if (value === '') {
            setErrorMessage('');
        }

        const textFieldId = `${rowData.primaryOrderItemID}-${key}`;

        setCurrentItems(textFieldId);

        setRowOnChange((prevRows) => {
            const error = getErrorMessage(value, nonPrimaryKitId, prevRows);
            setErrorMessage(error);

            const updatedRows = prevRows?.map((prevRow) => {
                if (prevRow?.primaryOrderItemID === rowData?.primaryOrderItemID) {
                    return {
                        ...prevRow,
                        errorMessage: { error: error, key: textFieldId },
                        [`${KitPairingName.NON_PRIMARY_SERIAL}-${key}`]: value
                    };
                }
                return prevRow;
            });
            return updatedRows;
        });
    };

    const handleTextFieldOnBlur = () => {
        setErrorSwitch(!!errorMessage);
    };

    const handleTableCompleteKitClick = async (rowData) => {

        const nonPrimaryKeys = Object.keys(rowData)?.filter(key => key?.startsWith(KitPairingName.NON_PRIMARY_SERIAL));

        const isThereAnEmptyField = nonPrimaryKeys?.length > 0
            ? nonPrimaryKeys?.some(key => rowData?.[key]?.length === 0)
            : true;

        const hasError = rowsOnChange.filter(item => item.primaryOrderItemID === rowData.primaryOrderItemID).some(item => item.error);

        if (errorMessage || hasError) {
            setErrorSwitch(true);
            return;
        }

        if (isThereAnEmptyField) {
            handleOpenKitIncompleteToast();
            return;
        }

        const nonPrimaryKits = kitDetails?.kitDef?.items
            ?.filter(item => item?.primary !== 1);

        const nonPrimaryKitItems = nonPrimaryKits?.reduce((accummulator, nonPrimaryKit) => {
            accummulator[nonPrimaryKit?.id] = kitDetails.items?.[nonPrimaryKit?.id];
            return accummulator;
        }, {});

        const getNonPrimary = Object.values(nonPrimaryKitItems).flat();

        const data = nonPrimaryKits?.map(item => item?.id)?.reduce((accumulator, nonPrimaryKitId) => {
            const nonPrimaryKit = nonPrimaryKits?.find(nonPrimaryKit => nonPrimaryKit?.itemID?.toString() === nonPrimaryKitId?.toString());

            if (nonPrimaryKit?.quantity > 1 || rowsOnChange.length > 1) {
                const filteredItems = kitDetails?.items?.[nonPrimaryKitId]
                    .map((_, index) => {
                        const serial = rowData?.[`${KitPairingName.NON_PRIMARY_SERIAL}-${nonPrimaryKitId}-${index}`];
                        const OrderID = getNonPrimary?.find(item => item.serial.toString().toLowerCase() === serial?.toString().toLowerCase());
                        const orderItemID = OrderID ? OrderID.orderItemID : null;
                        return serial !== undefined ? { serial, orderItemID } : null;
                    }).filter(item => item !== null);

                if (filteredItems.length > 0) {
                    accumulator[nonPrimaryKitId] = filteredItems;
                }
            } else {
                const serial = rowData?.[`${KitPairingName.NON_PRIMARY_SERIAL}-${nonPrimaryKitId}-0`];
                const OrderID = getNonPrimary?.find(item => item.serial.toString().toLowerCase() === serial?.toString().toLowerCase());
                const orderID = OrderID ? OrderID.orderItemID : null;
                if (serial !== undefined) {
                    accumulator[nonPrimaryKitId] = [{ serial, orderItemID: orderID }];
                }
            }
            return accumulator;
        }, {});

        const requestBody = {
            primary: rowData?.primaryOrderItemID,
            userID: loggedUser?.userID,
            data
        };

        setIsLoading(true);

        try {
            await kitDispatch(requestPassKit(requestBody));
            setRowOnChange(prevRows => {
                return prevRows?.map(prevRow => {
                    return {
                        ...prevRow,
                        status: prevRow?.primaryOrderItemID === rowData?.primaryOrderItemID ? OrderItemStatus.PASSED : prevRow?.status
                    };
                });
            });

            await handleCheckIsComplete(kitDetails);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCheckIsComplete = useCallback(async (kitDetails) => {
        setIsLoading(true);
        try {
            if (typeof onCheckIsBatchComplete === 'function') {
                const isComplete = await onCheckIsBatchComplete(kitDetails);
                if (isComplete) {
                    batchDispatch(receivePausedTimers([
                        ...(pausedTimers || []),
                        {
                            batchID: activeDetailsId?.toString(),
                            isComplete
                        }
                    ]));
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [
        onCheckIsBatchComplete,
        activeDetailsId,
        batchDispatch,
        pausedTimers
    ]);

    const handleTableRemoveKitClick = async (rowData) => {
        const requestBody = {
            batchID: kitDetails?.batchID,
            orderItemID: rowData?.primaryOrderItemID
        };

        setIsLoading(true);

        try {
            await kitDispatch(requestRemoveKitPrimaryItem(requestBody));
            setRowOnChange(prevRows => {
                return prevRows?.map(prevRow => {
                    return {
                        ...prevRow,
                        status: prevRow?.primaryOrderItemID === rowData?.primaryOrderItemID ? OrderItemStatus.FAILED : prevRow?.status
                    };
                });
            });

            await handleCheckIsComplete(kitDetails);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const renderCompleteKitButton = (props, rowData) => {
        const isPassed = rowData?.status === OrderItemStatus.PASSED;
        const isFailed = rowData?.status === OrderItemStatus.FAILED;

        return (
            <Button
                {...(props || {})}
                variant="flat"
                className={`
                    size-6 p-0 border-0 !bg-transparent
                `}
                disabled={isPassed || isFailed}
                onClick={() => handleTableCompleteKitClick(rowData)}
            >
                <CheckIcon className={`text-on-surface-variant ${isPassed ? " text-secondary-60 " : ""}`} />
            </Button>
        );
    };

    const renderRemoveKitButton = (props, rowData) => {
        const isFailed = rowData?.status === OrderItemStatus.FAILED;
        const isPassed = rowData?.status === OrderItemStatus.PASSED;

        return (
            <Button
                {...(props || {})}
                variant="flat"
                className={`
                    size-6 p-0 border-0 !bg-transparent
                `}
                disabled={isFailed || isPassed}
                onClick={() => handleTableRemoveKitClick(rowData)}
            >
                <FlagIcon className={`text-on-surface-variant ${isFailed ? " !text-error " : ""}`} />
            </Button>
        );
    };

    const renderOptions = rowData => {
        return (
            <Stack className="gap-4 w-full">
                <Tooltip
                    trigger={props => {
                        return renderCompleteKitButton(props, rowData);
                    }}
                    content="Mark Kit as complete"
                    direction="bottom-start"
                    offset={0}
                />
                <Tooltip
                    trigger={props => {
                        return renderRemoveKitButton(props, rowData);
                    }}
                    content={`Remove ${rowData?.primarySerial} from available kits`}
                    direction="bottom-start"
                    offset={0}
                />
            </Stack>
        );
    };

    const handleGetTextFieldValue = (rows, key, id) => {
        const getRows = rows?.find(item => item.primaryOrderItemID === id);
        const serial = getRows?.[`${KitPairingName.NON_PRIMARY_SERIAL}-${key}`];
        return serial || '';
    };

    const renderNonPrimaryKits = (rowData) => {
        const nonPrimaryKits = kitDetails?.kitDef?.items
            ?.filter(item => item?.primary !== 1)
            ?.flatMap(item => {
                const nonPrimaryKitId = item.id
                return Array.from({ length: item.quantity }, (_, index) => {
                    const key = `${nonPrimaryKitId}-${index}`;
                    const id = `${rowData.primaryOrderItemID}-${key}`;
                    const isActive = id === currentItem && errorSwitch;
                    const value = handleGetTextFieldValue(rowsOnChange, key, rowData.primaryOrderItemID);
                    const renderEmail = (props) => (
                        <div {...(props || {})}>
                            <ErrorIcon className='text-error !size-6'></ErrorIcon>
                        </div>
                    );

                    return (
                        <div className="relative w-full">
                            <div className="absolute top-[50%] -translate-y-[50%] right-3">
                                {isActive &&
                                    <Tooltip
                                        trigger={props => {
                                            return renderEmail(props);
                                        }}
                                        content={`${errorMessage}`}
                                        direction="bottom"
                                        offset={3}
                                        className="bg-error-container text-error h-6 text-xs justify-center flex items-center font-normal font-roboto tracking-[0.4px]"
                                    />
                                }
                            </div>
                            <TableTextField
                                key={key}
                                initialValue={value}
                                className={`${isActive ? "border-error border-[0.8px]" : ""}  w-full h-12 non-primary-kit`}
                                id={id}
                                onChange={event => handleTextFieldOnChange(event, nonPrimaryKitId, rowData, key)}
                                onBlur={handleTextFieldOnBlur}
                            />
                        </div>
                    );
                });
            });

        return [
            ...(nonPrimaryKits || [])
        ];
    };

    const rowRenderer = rowData => {
        return [
            renderOptions(rowData),
            rowData?.primarySerial,
            ...(renderNonPrimaryKits(rowData) || '')
        ];
    };

    const getRowClass = rowData => {
        switch (rowData?.status) {
            case OrderItemStatus.PASSED:
                return '!bg-secondary-95';
            case OrderItemStatus.FAILED:
                return '!bg-error-container';
            default:
                return '';
        }
    };

    const renderIncompleteToast = () => {
        return (
            <Toast
                message="Kit is incomplete."
                status="error"
                open={isKitIncompleteToastOpen}
                onClose={handleCloseKitIncompleteToast}
            />
        );
    };

    useEffect(() => {
        try {
            if (kitDetails) {
                const primaryKitId = kitDetails.kitDef?.primary?.Item?.id;
                const filteredPrimaryKit = Object.values(kitDetails.items).flat().filter(item => item.id === primaryKitId);
                const isAllKitFailed = filteredPrimaryKit.every(kit => kit.passed === 0);

                if (isAllKitFailed) {
                    (async () => {
                        await handleCheckIsComplete(kitDetails);
                    })();
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, [handleCheckIsComplete, kitDetails]);

    return (
        <>
            <Dialog
                isOpen={isOpen}
                onClose={handleClose}
                className="kit-pairing-dialog"
            >
                <Dialog.Title>
                    Kit Pairing
                </Dialog.Title>
                <Dialog.Content>
                    <DataTable
                        className="kit-pairing-dialog-table"
                        bodyClassName="h-[200px]"
                        rows={rowsOnChange}
                        rowRenderer={rowRenderer}
                        columns={columns}
                        isLoading={isLoading}
                        getRowClass={getRowClass}
                    />
                    {isKitIncompleteToastOpen && renderIncompleteToast()}
                </Dialog.Content>
                <Dialog.Actions className="justify-end">
                    <Button onClick={handleClose}>Close</Button>
                </Dialog.Actions>
            </Dialog>
        </>
    );
};

export default KitPairingDialog;


import React from 'react';
import loaderLogo from '@/Assets/Loading.png';
import {
    FloatingFocusManager,
    FloatingOverlay,
    FloatingPortal,
    useDismiss,
    useFloating,
    useInteractions
} from "@floating-ui/react";

const LoadingDialog = ({ isOpen,className }) => {

    const { context } = useFloating({
        open: isOpen
    });

    const dismiss = useDismiss(context, {
        outsidePress: false
    });

    const { getFloatingProps } = useInteractions([
        dismiss
    ]);

    return (
        <>
            {isOpen && (
                <FloatingPortal>
                    <FloatingOverlay className={`z-[1200] flex items-center justify-center bg-primary-10 bg-opacity-[0.25] ${className ?? ""}`} lockScroll>
                        <FloatingFocusManager context={context}>
                            <div className={`bg-transparent relative loading`} {...getFloatingProps()}>
                                <img src={loaderLogo} className='animate-spin w-[48px] h-[48px]' alt="loader" />
                            </div>
                        </FloatingFocusManager>
                    </FloatingOverlay>
                </FloatingPortal>
            )}
            {!isOpen && null}
        </>
    );
};

export default LoadingDialog;
import { useContext } from "react"
import { PanelListContext } from "./PanelList"

const TabPanel = ({ value, className, children, ...props }) => {

     const { id } = useContext(PanelListContext)

     if (value === id) {
          return <div className={`${className ?? ""}`} {...props} >
               {children}
          </div>
     }
     return null
}

export default TabPanel
import { UserStoreActionConstants } from '@/Store/Actions';

export const UserStoreReducer = ({ state, initialState, action }) => {
    
    switch (action.type) {
        case UserStoreActionConstants.RECEIVE_USER_INFO:
            return {
                ...state,
                user: action.payload
            };
        case UserStoreActionConstants.CLEAR_LOGGED_USER:
            return {
                ...state,
                user: null,
                isUserAuthenticated: false
            };
        case UserStoreActionConstants.RECEIVE_IS_USER_AUTHENTICATED:
            return {
                ...state,
                isUserAuthenticated: !!action.isUserAuthenticated
            };
        case UserStoreActionConstants.AUTHENTICATE_USER:
            return {
                ...state,
                isUserAuthenticated: !!action.payload
            };
        case UserStoreActionConstants.TOGGLE_SIDEBAR:
            return {
                ...state,
                isSidebarOpen: !!action.payload
            };
        case UserStoreActionConstants.RECEIVE_PROBLEM_TEMPLATE:
            return {
                ...state,
                problemTemplate: action.payload
            };
        case UserStoreActionConstants.RECEIVE_API_HEALTH_CHECK_RESPONSE:
            return {
                ...state,
                apiHealthCheckResponse: action?.payload
            };
        case UserStoreActionConstants.RESET_STATE:
            return initialState;
        default:
            return state;
    }
};

import { AppStorageConstants } from '@/Constants';
import { UserStoreActionConstants, receiveUserInfo, receiveIsUserAuthenticated, receiveApiHealthCheckResponse } from '@/Store/Actions';
import { healthCheck, login } from '@/Store/Services';
import { AppStorage } from '@/Helpers/storage';

export const UserStoreMiddleware = ({ action, dispatch }) => {

    let success;
    switch (action.type) {
        case UserStoreActionConstants.REQUEST_LOGIN:
            success = (user) => {
                const ssoUser = JSON.parse(AppStorage.Local.get(AppStorageConstants.SSO_USER));
                const newUser = { ...user, ...ssoUser };
                AppStorage.Local.set(AppStorageConstants.USER, JSON.stringify(newUser));
                dispatch(receiveUserInfo(newUser));
            };
            return login(success, action.payload);
        case UserStoreActionConstants.IS_USER_AUTHENTICATED:
            const getUserAuth = !!AppStorage.Local.get(action.payload);
            dispatch(receiveIsUserAuthenticated(getUserAuth))
            return;
        case UserStoreActionConstants.AUTHENTICATE_USER:
            AppStorage.Local.set(AppStorageConstants.USERNAME, JSON.stringify(action.payload));
            return;
        case UserStoreActionConstants.REQUEST_API_HEALTH_CHECK:
            success = apiHealthCheckResponse => {
                dispatch(receiveApiHealthCheckResponse(apiHealthCheckResponse));
            };
            return healthCheck(success);
        default:
            return;
    }
};

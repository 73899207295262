const CustomNoRowsOverlay = ({
	message
}) => {
	return (
		<p className="text-outline font-roboto leading-4 tracking-[0.4px] font-normal text-xs">
			{message || 'No Results Found'}
		</p>
	);
};

export default CustomNoRowsOverlay;

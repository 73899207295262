import { OrdersStoreActionConstants, receiveOrders, receiveCompanies, receiveSerialNumbers, receiveOptionPartNumber } from '@/Store/Actions';
import { getOrders, getCompanies, getSerialNumbers, getOptionPartNumber, cancelRequestOrders } from '@/Store/Services';

export const OrdersStoreMiddleware = ({ action, dispatch }) => {
    let success;

    switch (action.type) {
        case OrdersStoreActionConstants.REQUEST_ORDERS:
            success = (batchType) => {
                dispatch(receiveOrders(batchType));
            };
            return getOrders(success, action.payload);
            case OrdersStoreActionConstants.CANCEL_REQUEST_ORDERS:
                success = () => { };
                return cancelRequestOrders();
        case OrdersStoreActionConstants.REQUEST_COMPANIES:
            success = (companies) => {
                dispatch(receiveCompanies(companies));
            };
            return getCompanies(success);
        case OrdersStoreActionConstants.REQUEST_SERIAL_NUMBERS:
            success = serialNumbers => {
                dispatch(receiveSerialNumbers(serialNumbers));
            };
            return getSerialNumbers(success, action?.payload);
        case OrdersStoreActionConstants.REQUEST_OPTION_PART_NUMBER:
            success = (partNumber) => {
                dispatch(receiveOptionPartNumber(partNumber));
            };
            return getOptionPartNumber(success, action?.payload);
        default:
            return;
    }
};



const CheckBoxOutlineBlankIcon = ({ className }) => {

     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className}`} viewBox="0 0 25 24" fill="none">
               <rect x="6.5" y="6" width="12" height="12" fill="white" />
               <path fillRule="evenodd" clipRule="evenodd" d="M17.9878 4H6.42683C5.36267 4 4.5 4.89543 4.5 6V18C4.5 19.1046 5.36267 20 6.42683 20H17.9878C19.052 20 19.9146 19.1046 19.9146 18V6C19.9146 4.89543 19.052 4 17.9878 4ZM6.42683 18V6H17.9878V18H6.42683Z" fill="currentColor" />
          </svg>
     )
}

export default CheckBoxOutlineBlankIcon







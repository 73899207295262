import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "@/Store/StoreContextProvider";
import { requestFlagDeviceDoa } from "@/Store/Actions";

import { Dialog } from "@/Components/Feedback";
import { Button, Select, SelectOption, TextField, TextArea } from "@/Components/Inputs";

const ReportUnitDoaDialog = ({
    isOpen,
    deviceData,
    onClose,
    onSave
}) => {
    const doaReasons = useSelector(({ doaReasons }) => doaReasons?.doaReasons || []);
    const loggedInUser = useSelector(({ user }) => user?.user);
    const dispatch = useDispatch(dispatch => dispatch?.device);

    const [orderNumber, setOrderNumber] = useState('');
    const [selectedOrderType, setSelectedOrderType] = useState('');
    const [selectedReasonCode, setSelectedReasonCode] = useState('');
    const [additionalNotes, setAdditionalNotes] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    const handleSave = async () => {
        try {
            setIsLoading(true);

            await dispatch(requestFlagDeviceDoa({
                deviceID: deviceData?.orderItemID,
                userID: loggedInUser?.userID,
                doaCode: selectedReasonCode,
                doaNotes: additionalNotes,
                orderType: selectedOrderType,
                orderNumber
            }));

            if (typeof onSave === 'function') {
                onSave();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleOrderNumberChange = event => {
        const value = event?.target?.value || '';
        setOrderNumber(value);
    };

    const handleOrderTypeChange = event => {
        const value = event?.target?.value || '';
        setSelectedOrderType(value);
    };

    const handleReasonCodeChange = event => {
        const value = event?.target?.value || '';
        setSelectedReasonCode(value);
    };

    const handleAdditionalNotesChange = event => {
        const value = event?.target?.value || '';
        setAdditionalNotes(value);
    };

    useEffect(() => {
        if (isOpen === false) {
            setOrderNumber('');
            setSelectedReasonCode('');
            setAdditionalNotes('');
        }
    }, [isOpen]);

    useEffect(() => {
        setOrderNumber(deviceData?.orderNumber);
        setSelectedOrderType('Fail');

        if (Array.isArray(doaReasons)) {
            const preselectedReasonText = 'Just need to fail batch and start over';
            const preSelectedReason = doaReasons?.find(doaReason => doaReason?.reason?.toLowerCase().trim().includes(preselectedReasonText.toLowerCase()));
            if (preSelectedReason) {
                setSelectedReasonCode(preSelectedReason?.id);
            }
        }
    }, [deviceData?.orderNumber, doaReasons]);

    return (
        <Dialog
            isOpen={isOpen}
            onClose={handleClose}
            className="w-[484px] p-[32px]"
        >
            <Dialog.Title className="mb-[12px]">
                Report Unit DOA
            </Dialog.Title>
            <Dialog.Content className="flex flex-col gap-[12px] mb-[32px]">
                <TextField
                    label="Order Number"
                    value={orderNumber}
                    onChange={handleOrderNumberChange}
                />
                <Select
                    label="Order Type"
                    value={selectedOrderType}
                    onChange={handleOrderTypeChange}
                >
                    <SelectOption key="Fail" value="Fail">Fail</SelectOption>
                    <SelectOption key="Spare" value="Service/Spare">Spare</SelectOption>
                    <SelectOption key="Value Add" value="Value Add">ValueAdd</SelectOption>
                </Select>
                <Select
                    label="Reason Code"
                    value={selectedReasonCode}
                    onChange={handleReasonCodeChange}
                >
                    {doaReasons?.map(({ id, reason }) => {
                        return (
                            <SelectOption
                                key={reason}
                                value={id}
                                className="text-left"
                            >
                                <span className="truncate max-w-[370px]">
                                    {reason}
                                </span>
                            </SelectOption>
                        );
                    })}
                </Select>
                <TextArea
                    label="Additional Notes"
                    value={additionalNotes}
                    onChange={handleAdditionalNotesChange}
                    resize={false}
                    rows={4}
                />
            </Dialog.Content>
            <Dialog.Actions className="justify-between">
                <Button variant="flat" onClick={handleClose} disabled={!!isLoading}>Cancel</Button>
                <Button className="w-[116px]" onClick={handleSave} isLoading={!!isLoading} disabled={!!isLoading}>Save</Button>
            </Dialog.Actions>
        </Dialog>
    );
};

export default ReportUnitDoaDialog;

import { QaChecklistStoreActionConstants } from '@/Store/Actions';

export const QaChecklistStoreReducer = ({ state, initialState, action }) => {
    switch (action.type) {
        case QaChecklistStoreActionConstants.RECEIVE_QA_CHECKLIST:
            return {
                ...state,
                qaChecklist: action.payload
            };
        default:
            return state;
    }
};

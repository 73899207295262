

const BookmarkIcon = ({ className }) => {
     
     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className}`} viewBox="0 0 24 24" fill="none">
          <mask id="mask0_315_12288" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_315_12288)">
            <path d="M4 21.5556V7.33333C4 6.84444 4.17407 6.42593 4.52222 6.07778C4.87037 5.72963 5.28889 5.55556 5.77778 5.55556H14.6667C15.1556 5.55556 15.5741 5.72963 15.9222 6.07778C16.2704 6.42593 16.4444 6.84444 16.4444 7.33333V21.5556L10.2222 18.8889L4 21.5556ZM5.77778 18.8444L10.2222 16.9333L14.6667 18.8444V7.33333H5.77778V18.8444ZM18.2222 18.8889V3.77778H6.66667V2H18.2222C18.7111 2 19.1296 2.17407 19.4778 2.52222C19.8259 2.87037 20 3.28889 20 3.77778V18.8889H18.2222Z"/>
          </g>
        </svg>
     )
}

export default BookmarkIcon



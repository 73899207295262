import { Stack } from "@/Components/Display";
import { Dialog } from "@/Components/Feedback";
import { Button } from "@/Components/Inputs";
import loaderLogo from '@/Assets/Loading.png';

const PausedTimerDialog = ({
    isOpen,
    onUnPause
}) => {
    const handleUnPause = () => {
        if (typeof onUnPause === 'function') {
            onUnPause();
        }
    };

    return (
        <Dialog
            isOpen={isOpen}
            className="text-on-surface"
            overlayClassName="!top-[130px] w-[calc(100vw-40px)]"
        >
            <Dialog.Title className="flex font-normal text-[32px] leading-[40px] justify-center mb-[53px]">
                Timer Paused
            </Dialog.Title>
            <Dialog.Content>
                <Stack direction="column" className="gap-[56px]">
                    <img src={loaderLogo} className="animate-spin size-[48px]" alt="loader" />
                    <Button onClick={handleUnPause}>Un-Pause Timer</Button>
                </Stack>
            </Dialog.Content>
        </Dialog>
    );
};

export default PausedTimerDialog;

import { storeContexts } from "@/Store/StoreContextProvider";

const extractStoreProperties = (data, callback) => {
    const newState = {};
    Object.keys(data).forEach(key => {
        newState[key] = callback(data[key]);
    });
    return newState;
};

export const withStore = (mapStateToProps, mapDispatchToProps) => (Component) => (props) => {
    const contextValuesPerProvider = {};
    storeContexts.forEach(useContextHook => {
        const { state, dispatch, name } = useContextHook(); 
        contextValuesPerProvider[name] = {
            state,
            dispatch
        };
    });
    
    let state = contextValuesPerProvider;
    let stateToProps = {};
    let dispatchToProps = {};

    if (mapStateToProps && typeof mapStateToProps === 'function') {
        const newState = extractStoreProperties(contextValuesPerProvider, data => data.state)
        stateToProps = { ...mapStateToProps(newState) };
    }
    
    if (mapDispatchToProps && typeof mapDispatchToProps === 'function') {
        const newState = extractStoreProperties(contextValuesPerProvider, data => ({ dispatch: data.dispatch }))

        dispatchToProps = { ...mapDispatchToProps(newState)};
    }

    if (Object.keys(stateToProps).length > 0 || Object.keys(dispatchToProps).length > 0) {
        state = { ...stateToProps, ...dispatchToProps };
    }

    return <Component {...(props || {})} {...(state || {})} />;
};



const ChangeCircleIcon = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className}`} viewBox="0 0 24 24" fill="none">
      <mask id="mask0_8119_88475" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="-1" width="24" height="25">
        <rect y="-0.0234375" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_8119_88475)">
        <path d="M11 20.95C8.98333 20.7 7.3125 19.8208 5.9875 18.3125C4.6625 16.8042 4 15.0333 4 13C4 11.9 4.21667 10.8458 4.65 9.8375C5.08333 8.82917 5.7 7.95 6.5 7.2L7.925 8.625C7.29167 9.19167 6.8125 9.85 6.4875 10.6C6.1625 11.35 6 12.15 6 13C6 14.4667 6.46667 15.7625 7.4 16.8875C8.33333 18.0125 9.53333 18.7 11 18.95V20.95ZM13 20.95V18.95C14.45 18.6833 15.6458 17.9917 16.5875 16.875C17.5292 15.7583 18 14.4667 18 13C18 11.3333 17.4167 9.91667 16.25 8.75C15.0833 7.58333 13.6667 7 12 7H11.925L13.025 8.1L11.625 9.5L8.125 6L11.625 2.5L13.025 3.9L11.925 5H12C14.2333 5 16.125 5.775 17.675 7.325C19.225 8.875 20 10.7667 20 13C20 15.0167 19.3375 16.7792 18.0125 18.2875C16.6875 19.7958 15.0167 20.6833 13 20.95Z" fill="#2F4455" />
      </g>
    </svg>
  )
}

export default ChangeCircleIcon;
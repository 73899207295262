import loaderLogo from '@/Assets/Loading.png';

const CustomLoadingOverlay = ({ api }) => {

    const len = api?.columnModel?.displayedColumns?.length ?? 0;
    const data = api?.getColumnDefs()?.[0] ?? { width: 0 };

    return (
        <div className="ag-overlay w-full translate-y-12 bg-white relative" role="presentation">
            {Array.from({ length: 50 }).map((_, index) => {
                return (
                    <div key={index} className="flex w-full bg-white shadow-skeleton-shadow">
                        {Array.from({ length: len }).map((_, index) => {
                            return (
                                <div key={index} style={{ width: `${data.width}px` }} className=" w-[500px] h-12 px-3 py-4">
                                    <div className="w-[195px] h-[17px] animate-pulse bg-surface"></div>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
            <img src={loaderLogo} className="absolute z-50 top-[240px] left-1/2 -translate-x-1/2 -translate-y-1/2 animate-spin size-12" alt="loader" />
        </div>
    )
};

export default CustomLoadingOverlay;

import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { Icon } from "@/Components/Display";

const CustomColumnHeader = forwardRef(({
    column,
    showFilter,
    displayName,
    enableSorting,
    enableFilterButton,
    progressSort,
    api,
    customCol
}, refs) => {

    const filterRef = useRef();
    const [sortIcon, setSortIcon] = useState('SortListIcon');
    const [filterModel, setFilterModel] = useState({});

    const onHeaderClick = (event) => {
        onSortRequested(event);
    };

    const onSortRequested = event => {
        progressSort(event?.shiftKey);
    };

    const onFilterSelected = useCallback(() => {
        showFilter(filterRef.current);
    }, [showFilter]);

    const onSortChanged = useCallback(() => {
        const sort = column.getSort();
        const sortingAscending = sort === 'asc';
        const sortingDescending = sort === 'desc';
        const notSorting = !sortingAscending && !sortingDescending;

        if (notSorting) {
            setSortIcon('SortListIcon');
            return;
        }

        if (sortingAscending) {
            setSortIcon('SortListAscendingIcon');
            return;
        }

        if (sortingDescending) {
            setSortIcon('SortListDescendingIcon');
            return;
        }
    }, [column]);

    useEffect(() => {
        onSortChanged();
        column?.addEventListener('sortChanged', onSortChanged);
        return () => {
            column?.removeEventListener('sortChanged', onSortChanged);
        };
    }, [column, onSortChanged]);

    useEffect(() => {
        const updateFilterCount = () => {
            api?.eventService?.addEventListener('filterChanged', (params) => {
                setFilterModel(params.api?.getFilterModel());
            });
        };
        api.addEventListener('filterChanged', updateFilterCount);
        updateFilterCount();
        return () => {
            api.removeEventListener('filterChanged', updateFilterCount);
        };
    }, [api]);

    const count = filterModel[column?.colId]
        ? column?.colId === 'total'
            ? '(1)'
            : `(${filterModel[column?.colId]?.length})`
        : null;

    const showCount = "createDate".toLowerCase() !== column?.colId.toLowerCase() && !customCol;

    return (
        <div className="w-full h-full flex items-center justify-between hover:cursor-pointer px-[12px]">
            <div className="w-full font-semibold text-on-surface text-[12px] leading-[14.52px]" onClick={onHeaderClick}>
                {displayName}
                {showCount && (
                    <span> {count}</span>
                )
                }
            </div>
            <div className="flex items-center space-x-[10px]">
                {enableSorting && (
                    <div onClick={onSortRequested} className="hover:bg-white rounded-sm">
                        <Icon name={sortIcon} className="text-primary" />
                    </div>
                )}
                {enableFilterButton && (
                    <div ref={filterRef} onClick={onFilterSelected} className="hover:bg-white rounded-sm">
                        <Icon name="FilterListIcon" className="text-primary" />
                    </div>
                )}
            </div>
        </div>
    );
});

export default CustomColumnHeader;

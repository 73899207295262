import { useContext } from 'react';
import { TablistContext } from './TabList';

const Tab = ({
     label,
     value,
     className,
     ...props
}) => {
     const { onChange, id } = useContext(TablistContext);

     const handleOnchange = (value) => {
          if (typeof onChange !== "function") {
               return;
          }
          onChange(value);
     };

     return (
          <button {...props} value={value} onClick={() => handleOnchange(value)}
               className={`tab ${value === id ? "active-tab" : "hover:bg-primary-fixed"} ${className || ''}`}>
               {label}
          </button>
     );
};

export default Tab;


import { getUserLocales } from 'get-user-locale';

export const formatTimeBasedOnLocale = (rawDate) => {
    try {
        return new Intl.DateTimeFormat(getUserLocales(), {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hourCycle: 'h12'
        }).format(new Date(rawDate));
    }
    catch (e) {
        console.log(`invalid date ${rawDate}`);
    }
    return rawDate;
}

const isValidDate = d => {
    return d instanceof Date && !isNaN(d);
}

/**
 * Lists all supported formats
 * * LT    -  9:33 am
 * * LTS   -  9:33:24 am
 * * L     -  09/05/2022
 * * l     -  9/5/22
 * * LL    -  September 5, 2022,
 * * ll    -  Sep 5, 2022
 * * LLL   -  September 5, 2022 at 9:33 am
 * * lll   -  Sep 5, 2022 9:33 am
 * * LLLL  -  Monday, September 5, 2022 9: 35 am
 * * llll  -  Mon, Sep 5, 2022 9:35 am
 * * MDYYYYhmmss - 9/5/2022 9:33:24 am
 * * MMDDYYhmm - 09/05/22 9:33 am
 * * MDYYYY - 9/5/2022
 * * localDateFormatted - 09/05/2022, 09:33:24 AM
 * * localDateCompressed - 09/05/22, 09:33:24 AM
 * * UTC - YYYY-MM-DDTHH:mm:ss.sssZ
 * * localDateFilters - Sep 05, 09:33
 * * MMDDYYYYHHmma - 03/27/1990 09:33 am
*/
export const UnitOfTime = {
    /**
     * 9:33 AM
     */
    LT: 'LT',

    /**
     * 9:33:24 AM
     */
    LTS: 'LTS',

    /**
     * * MM/DD/YYYY. 09/05/2022 en-us
     * * DD/MM/YYYY. 05/09/2022 en-au 
     */
    L: 'L',

    /**
     * * M/D/YY. 9/5/22 (en-us)
     * * D/M/YY. 5/9/22 (en-au)
     */
    l: 'l',

    /**
     * September 5, 2022
     */
    LL: 'LL',

    /**
     * Sep 5, 2022
     */
    ll: 'll',

    /**
     * September 5, 2022 at 9:33 am
     */
    LLL: 'LLL',

    /**
     * Sep 5, 2022 9:33 am
     */
    lll: 'lll',

    /**
     * Monday, September 5, 2022 9:35 am
     */
    LLLL: 'LLLL',

    /**
     * Mon, Sep 5, 2022 9:35 am
     */
    llll: 'llll',

    /**
     * 9/5/2022, 9:33:24 am
     */
    MDYYYYhmmss: 'MDYYYYhmmss',

    /**
     * 09/05/22, 9:33 am
     */
    MMDDYYhmm: 'MMDDYYhmm',

    /**
     * 9/5/2022
     */
    MDYYYY: 'MDYYYY',

    /**
     * 09/05/2022, 09:33:24 AM
     */
    localDateFormatted: 'localDateFormatted',

    /**
     * 09/05/22, 09:33:24 AM
     */
    localDateCompressed: 'localDateCompressed',

    /**
     * YYYY-MM-DDTHH:mm:ss.sssZ
     */
    UTC: 'UTC',

    /**
     * 09/05/2022
     */
    MMDDYYYY: 'MMDDYYYY',

    /**
     * Sep 05, 09:33
     */
    localDateFilters: 'localDateFilters',

    /**
     * 03/27/1990 09:33
     */
    MMDDYYYYHHmm: 'MMDDYYYYHHmm',

    /**
    * 03/27/1990 09:33 am
    */
    MMDDYYYYHHmma: 'MMDDYYYYHHmma'
}

const getIntlDateTimeOptions = (format) => {

    let unitOfTime = {};


    switch (format) {
        case UnitOfTime.LT:
            unitOfTime = {
                hour: 'numeric',
                minute: 'numeric'
            };
            break;

        case UnitOfTime.LTS:
            unitOfTime = {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            };
            break;
        case UnitOfTime.L:
            unitOfTime = {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit'
            };
            break;
        case UnitOfTime.l:
            unitOfTime = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            };
            break;
        case UnitOfTime.LL:
            unitOfTime = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            };
            break;
        case UnitOfTime.ll:
            unitOfTime = {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            };
            break;
        case UnitOfTime.LLL:
            unitOfTime = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hourCycle: 'h12'
            };
            break;
        case UnitOfTime.lll:
            unitOfTime = {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hourCycle: 'h12'
            };
            break;
        case UnitOfTime.LLLL:
            unitOfTime = {
                weekday: 'long',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hourCycle: 'h12'
            };
            break;
        case UnitOfTime.llll:
            unitOfTime = {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hourCycle: 'h12'
            };
            break;
        case UnitOfTime.MDYYYYhmmss:
            unitOfTime = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            };
            break;
        case UnitOfTime.MMDDYYhmm:
            unitOfTime = {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
                hour: 'numeric',
                minute: 'numeric'
            };
            break;
        case UnitOfTime.MDYYYY:
            unitOfTime = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            };
            break;
        case UnitOfTime.localDateFormatted:
            unitOfTime = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            };
            break;
        case UnitOfTime.localDateCompressed:
            unitOfTime = {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            };
            break;
        case UnitOfTime.MMDDYYYY:
            unitOfTime = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            };
            break;
        case UnitOfTime.localDateFilters:
            unitOfTime = {
                month: 'short',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            };
            break;
        case UnitOfTime.MMDDYYYYHHmm:
            unitOfTime = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hourCycle: 'h23',
                hour12: false
            };
            break;
        case UnitOfTime.MMDDYYYYHHmma:
            unitOfTime = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            };
            break;
        default: {
            unitOfTime = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            }
            break;
        }
    }

    return unitOfTime;
}

/**
 * 
 * @param {(datetime|string)} datetime - (new Date() | string) - raw datetime value that will be converted to specific format  
 * @example 
 * getIntlDateTime('1990/03/27');
 * returns
 * {
 *    DateTime: "Tuesday, March 27, 1990 at 12:00:00 AM GMT+8",
 *    format : UnitOfTime => {...}
 *    error: ""
 * }
 * getIntlDateTime('1990/03/27').format(UnitOfTime.L)
 * returns '03/27/90' for en-us and '27/03/90' for en-GB
 *  
 * @returns Object {DateTime, format(), error}. 
 */
export const getIntlDateTime = (datetime) => {

    let dateObj;
    let errorMsg = '';
    let result = null;

    const DIVISIONS = [
        { amount: 60, name: 'seconds' },
        { amount: 60, name: 'minutes' },
        { amount: 24, name: 'hours' },
        { amount: 7, name: 'days' },
        { amount: 4.34524, name: 'weeks' },
        { amount: 12, name: 'months' },
        { amount: Number.POSITIVE_INFINITY, name: 'years' }
    ];

    try {
        if (isValidDate(datetime)) {
            dateObj = datetime;
        } else if (datetime && typeof datetime === 'string') {

            if (isNaN(Date.parse(datetime))) {
                throw new RangeError('Invalid DateTime');
            }

            dateObj = new Date(datetime);
        } else {
            dateObj = new Date();
        }

        result = new Intl.DateTimeFormat(getUserLocales()).format(dateObj);
    } catch (e) {
        result = null;
        errorMsg = e.message;
    }

    return {
        DateTime: result,
        /**
         * Format DateTime object to locale specific format.
         * @param {Object} UnitOfTime
         * @returns Formatted DateTime as string.
         */
        format: (UnitOfTime) => {
            if (errorMsg.length === 0) {
                if (!UnitOfTime) {
                    return new Date(dateObj).toISOString();
                }

                return new Intl.DateTimeFormat(
                    getUserLocales(),
                    getIntlDateTimeOptions(UnitOfTime))
                    .format(dateObj)
            }
            return errorMsg;
        },
        /**
         *Language-sensitive relative time formatting (time ago)
         * @returns Formatted Relative Datetime as string.
         */
        fromNow: () => {
            if (errorMsg.length === 0) {
                let duration = (dateObj - new Date()) / 1000;

                for (let i = 0; i <= DIVISIONS.length; i++) {
                    const division = DIVISIONS[i];
                    if (Math.abs(duration) < division.amount) {
                        return new Intl.RelativeTimeFormat(getUserLocales()).format(Math.round(duration), division.name);
                    }
                    duration /= division.amount;
                }
            }
            return errorMsg;
        },
        error: errorMsg
    };
};

/**
 * List of Formatters
 * @example
 * returns
 * {
 *    name: "default",
 *    description : "Month (2-digit), Day (2-digit), Year (4-digit), Hour (2-digit, not military time), Minute (2-digit), am/pm (if your timezone supports it)"
 *    formatter: (params) => {...}
 * }
 * @returns Object {name, description, formatter()}.
 */
export const DateTimeFormatters = [
    {
        name: 'default',
        description: 'No formatting',
        format: (params) => {
            if (params.value) {
                return params.value;
            }
        }
    },
    {
        name: 'localRelative',
        description: 'Relative time or time ago',
        format: (params) => {
            if (params.value) {
                return getIntlDateTime(params.value).fromNow();
            }
        }
    },
    {
        name: 'localDateISO',
        description: '',
        format: (params) => {
            if (params.value) {
                return getIntlDateTime(params.value).format();
            }
        }
    },
    {
        name: 'localDateFormatted',
        description: 'Month (2-digit), Day (2-digit), Year (4-digit), Hour (2-digit, not military time), Minute (2-digit), Second (2-digit), am/pm (if your timezone supports it)',
        format: (params) => {
            if (params.value) {
                return getIntlDateTime(params.value).format(UnitOfTime.localDateFormatted);
            }
        }
    },
    {
        name: 'localDateCalendar',
        description: 'Month (2-digit), Day (2-digit), Year (4-digit)',
        format: (params) => {
            if (params.value) {
                return getIntlDateTime(params.value).format(UnitOfTime.MMDDYYYY);
            }
        }
    },
    {
        name: 'localCompressed',
        description: 'Month (2-digit), Day (2-digit), Year (2-digit), Hour (2-digit, not military time), Minute (2-digit), Second (2-digit), am/pm (if your timezone supports it)',
        format: (params) => {
            if (params.value) {
                return getIntlDateTime(params.value).format(UnitOfTime.localDateCompressed);
            }
        }
    }
];
import { forwardRef } from "react";
import { RefreshSpinnerIcon } from "@/Icons";

const Button = forwardRef(({
    variant,
    className,
    children,
    startIcon,
    onClick,
    isLoading,
    ...rest
}, ref) => {
    const getButtonVariant = () => {
        if (variant && typeof variant === 'string') {
            switch (variant.toLowerCase()) {
                case 'flat':
                    return 'button-flat';
                case 'fixed-dim':
                        return 'fixed-dim';
                case 'button-text-1':
                    return 'button-text-1';
                default:
                    return 'button-primary';
            }
        } else {
            return 'button-primary';
        }
    };

    const handleOnClick = event => {
        if (onClick && typeof onClick === 'function') {
            onClick(event);
        }
    };

    return (
        <button ref={ref} onClick={handleOnClick} className={`button ${getButtonVariant()} ${className || ''} ${isLoading ? 'cursor-wait' : ''}`} {...rest}>
            <div className="button-content">
                {(isLoading || startIcon) && (
                    <div className="button-start-icon">
                        {isLoading ? <RefreshSpinnerIcon className="animate-spin" /> : startIcon}
                    </div>
                )}
                <p>{children}</p>
            </div>
        </button>
    );
});

export default Button;

import { Stack } from "@/Components/Display";
import { Component } from "react";

class AdminLayout extends Component {
    constructor(props) {
        super(props)
        this.state = {

        };
    }

    render() {
        return (
            <Stack>
                <div></div>
                <div></div>
                <div></div>
            </Stack>
        );
    }
}

export default AdminLayout;

const ExcelIcon = ({ className }) => {
     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className || ''}`} width="32" height="33" viewBox="0 0 32 33" fill="none">
               <rect y="0.5" width="32" height="32" rx="5.33333" fill="white" />
               <path fillRule="evenodd" clipRule="evenodd" d="M3.84 0.5C1.71923 0.5 0 2.21923 0 4.34V28.66C0 30.7808 1.71923 32.5 3.84 32.5H28.16C30.2808 32.5 32 30.7808 32 28.66V4.34C32 2.21923 30.2808 0.5 28.16 0.5H3.84ZM20.3616 22.7293C20.5611 22.6589 20.7957 22.5063 21.0656 22.2717C21.4645 21.9314 21.6581 21.6263 21.6464 21.3565C21.6464 21.0866 21.4821 20.7698 21.1536 20.4061L17.7669 16.4513L21.1536 12.5037C21.4821 12.1517 21.6464 11.8349 21.6464 11.5533C21.6581 11.2717 21.4645 10.9607 21.0656 10.6205C20.8075 10.3741 20.5787 10.2157 20.3792 10.1453C20.1797 10.0749 19.9861 10.0866 19.7984 10.1805C19.6107 10.2743 19.4053 10.4562 19.1824 10.7261L16.0232 14.4151L12.864 10.7261C12.6411 10.4562 12.4357 10.2743 12.248 10.1805C12.0603 10.0866 11.8667 10.0749 11.6672 10.1453C11.4677 10.2157 11.2389 10.3741 10.9808 10.6205C10.5936 10.9607 10.4 11.2717 10.4 11.5533C10.4 11.8349 10.5643 12.1517 10.8928 12.5037L14.2795 16.4513L10.8928 20.4061C10.5643 20.7698 10.4 21.0866 10.4 21.3565C10.4 21.6263 10.5936 21.9314 10.9808 22.2717C11.2507 22.5063 11.4853 22.6589 11.6848 22.7293C11.8843 22.7997 12.0779 22.7879 12.2656 22.6941C12.4533 22.6002 12.6528 22.4242 12.864 22.1661L16.0232 18.4837L19.1824 22.1661C19.4053 22.4242 19.6048 22.6002 19.7808 22.6941C19.9685 22.7879 20.1621 22.7997 20.3616 22.7293Z" fill="#01AC49" />
          </svg>
     );
};

export default ExcelIcon;

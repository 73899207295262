import { Component } from "react";
import { Button, TextArea } from "@/Components/Inputs";
import { withStore } from "@/Helpers/withStore";
import { BatchType } from "@/Helpers/constants";

class ReportAProblem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isReporting: false,
			message: ''
		};
	}

	handleMessageChange = event => {
		const target = event?.target;
		const value = target?.value ?? '';

		this.setState({
			message: value
		});
	};

	handleReportAProblem = () => {
		if (this.state.isReporting || this.state.message.length === 0) {
			return;
		}

		this.setState({
			isReporting: true
		});

		setTimeout(() => {
			this.setState({
				isReporting: false,
				message: ''
			});
			this.props?.handleShowToast();
		}, 2000);
	};

	handleSetProblemTemplate = prevProps => {
		if (this.props?.problemTemplate !== prevProps?.problemTemplate) {
			this.setState({
				message: this.props?.problemTemplate || ''
			});
		}
	}

	getActiveName = () => {
		const activeDetails = this.props?.batchDetailsToDisplay?.find(batch => batch?.batchID?.toString() === this.props?.activeDetailsId?.toString());
		return activeDetails?.batchType === BatchType.KIT ? activeDetails?.kitDef?.name : activeDetails?.items?.[0]?.fullname;
	}

	componentDidMount(prevProps) {
		this.handleSetProblemTemplate(prevProps);
	}

	componentDidUpdate(prevProps) {
		this.handleSetProblemTemplate(prevProps);
	}

	render() {
		const activeName = this.getActiveName();
		
		return (
			<>
				<div className="flex flex-col gap-[21px] pb-[27px]">
					<h1 className=" w-[195px] text-on-primary font-roboto tracking-[0.15px] font-medium text-base">
						Report a problem {activeName ? <>for <br />{activeName}</> : ''}
					</h1>
					<p className=" text-xs tracking-[0.4px] font-normal text-on-primary">Please fill out the form below <br /> with your issue.</p>
					<div className=" bg-inverse-primary h-[0.5px] w-[231px]" />
				</div>
				<div className="overflow-auto">
					<div className="h-fit flex flex-col pl-[9px] gap-[16px]">
						<TextArea
							className="sidebar-textarea"
							placeholder="Type your message..."
							value={this.state.message}
							onChange={this.handleMessageChange}
						/>
						<Button isLoading={this.state.isReporting} onClick={this.handleReportAProblem}>Report a Problem</Button>
					</div>
				</div>
			</>
		);
	};
}

const mapStateToProps = ({ batch, kit, user }) => {
	return {
		batchDetailsToDisplay: batch?.batchDetailsToDisplay,
		activeDetailsId: batch?.activeDetailsId,
		activeKitDetailsItemId: kit?.activeKitDetailsItemId,
		problemTemplate: user?.problemTemplate
	};
};

export default withStore(mapStateToProps)(ReportAProblem);

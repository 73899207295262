import { ProductStoreActionConstants, receivePartNumber } from '@/Store/Actions';
import { getPartNumber } from '@/Store/Services';

export const ProductStoreMiddleware = ({ action, dispatch }) => {

     let success;

     switch (action.type) {
          case ProductStoreActionConstants.REQUEST_PART_NUMBER:
               success = (partNumber) => {
                    dispatch(receivePartNumber(partNumber))
               }
               return getPartNumber(success);
          default:
               return;
     }
};

const ArrowRightIcon = ({
     className,
     ...props
}) => {
     return (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className={`svg-regular ${className || ''}`}  {...props}>
               <mask id="mask0_7571_50527" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                    <rect width="24" height="24" fill="#D9D9D9" />
               </mask>
               <g mask="url(#mask0_7571_50527)">
                    <path d="M16.175 13L4 13L4 11L16.175 11L10.575 5.4L12 4L20 12L12 20L10.575 18.6L16.175 13Z" fill="currentColor" />
               </g>
          </svg>
     );
};

export default ArrowRightIcon;

import { QaFailureStoreActionConstants } from '@/Store/Actions';

export const QaFailureStoreReducer = ({ state, initialState, action }) => {
    switch (action.type) {
        case QaFailureStoreActionConstants.RECEIVE_QA_FAILURES:
            return {
                ...state,
                qaFailures: action?.payload
            };
        case QaFailureStoreActionConstants.RECEIVE_CREATED_QA_FAILURES: {
            const { batchID, kitItemID, data } = action?.payload || {};

            const newQaFailures = state?.qaFailures?.map(qaFailure => {
                if (qaFailure?.batchID?.toString() === batchID?.toString() && qaFailure?.kitItemID?.toString() === kitItemID?.toString()) {
                    return {
                        batchID,
                        kitItemID,
                        data: [
                            ...(qaFailure?.data || []),
                            ...(data || [])
                        ]
                    };
                }

                return {
                    ...qaFailure
                };
            });

            return {
                ...state,
                qaFailures: newQaFailures
            };
        }
        case QaFailureStoreActionConstants.RECEIVE_DELETED_QA_FAILURE_ID: {
            const { batchID, kitItemID, id } = action?.payload || {};

            const newQaFailures = state?.qaFailures?.map(qaFailure => {
                if (qaFailure?.batchID?.toString() === batchID?.toString() && qaFailure?.kitItemID?.toString() === kitItemID?.toString()) {
                    const newData = qaFailure?.data?.filter(data => data?.id?.toString() !== id?.toString());

                    return {
                        batchID,
                        kitItemID,
                        data: newData
                    };
                }

                return {
                    ...qaFailure
                };
            });

            return {
                ...state,
                qaFailures: newQaFailures
            };
        }
        default:
            return state;
    }
};

import {
    createContext,
    useContext,
    useMemo
} from 'react';
import { useReducerWithMiddleware } from '@/Hooks';
import { QaFailureStoreMiddleware } from '@/Store/Middlewares';
import { QaFailureStoreReducer } from '@/Store/Reducers';
import { ProviderName } from '@/Helpers/constants';

export const QaFailureStoreContext = createContext();

const initialState = {};

export const QaFailureStoreProvider = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(QaFailureStoreReducer, initialState, [QaFailureStoreMiddleware]);

    const QaFailureStoreState = useMemo(() => {
        return { ...(state || {}) };
    }, [state]);

    return (
        <QaFailureStoreContext.Provider value={{ state: QaFailureStoreState, dispatch, name: ProviderName.QA_FAILURE }}>
            {children}
        </QaFailureStoreContext.Provider>
    );
};

export const useQaFailureStoreContext = () => {
    return useContext(QaFailureStoreContext);
};

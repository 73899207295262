
import { Toast } from "@/Components/Display";
import { Dialog } from "@/Components/Feedback";
import { Button } from "@/Components/Inputs";
import { ContentCopyIcon } from "@/Icons";
import { useMemo, useState } from "react";

const ServerErrorDialog = ({
    handleCloseServerErrorDialog,
    isOpen,
    error,
    ...props
}) => {
    const errorMessage = useMemo(() => {
        const { errorTitle, sqlError, responseCode, requestJson } = error || {};

        const message = `Error:\n${errorTitle}\n\nError SQL:\n${sqlError ? JSON.stringify(sqlError, null, 2) : ''}\n\nResponse:\n${responseCode}\n\nRequest JSON:\n${requestJson ? JSON.stringify(requestJson, null, 2) : ''}`;

        return message;
    }, [error]);

    const [isCopiedToClipboardToastOpen, setIsCopiedToClipboardToastOpen] = useState(false);

    const handleCopyToClipboard = () => {
        setIsCopiedToClipboardToastOpen(true);
        navigator.clipboard.writeText(errorMessage);
    };

    const handleCloseCopiedToClipboardToast = () => {
        setIsCopiedToClipboardToastOpen(false);
    };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={handleCloseServerErrorDialog}
            {...props}
        >
            <Dialog.Title className="flex font-normal text-[32px] leading-[40px] mb-[24px] text-on-surface">
                Server Error
            </Dialog.Title>
            <Dialog.Content className="space-y-6">
                <p className="text-base leading-[24px] tracking-[0.5px] font-normal text-on-surface">
                    There has been a server error with your most recent request.<br />
                    Please send the following information to your manager:
                </p>
                <div className="pt-[20px] pb-[31px] px-[28px] server-error-dialog-custom-scroll overflow-y-auto border-[1px] h-[225px] border-[#A2AEB6] rounded-[5px] min-h-[225px] whitespace-pre-wrap">
                    <p className="text-xs leading-4 tracking-[0.4px] text-on-surface">{errorMessage}</p>
                </div>
            </Dialog.Content>
            <Dialog.Actions className="justify-between">
                <Button
                    onClick={handleCopyToClipboard}
                    variant="flat"
                    startIcon={<ContentCopyIcon className="!size-6 text-on-surface-variant" />}>
                    Copy to Clipboard
                </Button>
                <Button onClick={handleCloseServerErrorDialog} className="uppercase">ok</Button>
            </Dialog.Actions>
            <Toast
                message="Server Error copied to clipboard"
                open={isCopiedToClipboardToastOpen}
                onClose={handleCloseCopiedToClipboardToast}
            />
        </Dialog>
    );
};

export default ServerErrorDialog;

const LinkIcon = ({ className }) => {
  return (
    <svg className={`svg-regular ${className || ''}`} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <mask id="mask0_7748_7520" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7748_7520)">
        <path d="M8.10574 12.4038H5.27884C4.33711 12.4038 3.53438 12.072 2.87063 11.4083C2.20687 10.7447 1.875 9.94204 1.875 9.00042C1.875 8.05881 2.20687 7.25604 2.87063 6.5921C3.53438 5.92816 4.33711 5.59619 5.27884 5.59619H8.10574V6.72115H5.27884C4.64903 6.72115 4.11176 6.94351 3.66705 7.38822C3.22234 7.83294 2.99998 8.3702 2.99998 9.00001C2.99998 9.62982 3.22234 10.1671 3.66705 10.6118C4.11176 11.0565 4.64903 11.2789 5.27884 11.2789H8.10574V12.4038ZM6.1875 9.56249V8.43753H11.8125V9.56249H6.1875ZM9.89423 12.4038V11.2789H12.7211C13.3509 11.2789 13.8882 11.0565 14.3329 10.6118C14.7776 10.1671 15 9.62982 15 9.00001C15 8.3702 14.7776 7.83294 14.3329 7.38822C13.8882 6.94351 13.3509 6.72115 12.7211 6.72115H9.89423V5.59619H12.7211C13.6629 5.59619 14.4656 5.92802 15.1293 6.59169C15.7931 7.25535 16.125 8.05799 16.125 8.9996C16.125 9.94121 15.7931 10.744 15.1293 11.4079C14.4656 12.0719 13.6629 12.4038 12.7211 12.4038H9.89423Z" fill="currentColor" />
      </g>
    </svg>
  );
};

export default LinkIcon;

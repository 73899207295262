import { useEffect, useMemo, useRef, useState } from "react";

import { Popover } from "@/Components/Display";
import { Button, Select, SelectOption } from "@/Components/Inputs";

import { CheckIcon, CloseIcon } from "@/Icons";

import InputMask from "@mona-health/react-input-mask";

import { KeyboardKeys } from "@/Helpers/constants";

const CustomCellEditor = ({
    // custom props
    onUpdate,
    onShowTableValidationToast,
    doesTableHaveValidationErrors,
    // ag-grid props
    value,
    data,
    column,
    initialValue,
    api,
    node,
    eGridCell
}) => {
    const inputRef = useRef();
    const selectRef = useRef();

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [inputValue, setInputValue] = useState(value || '');
    const [selectedOption, setSelectedOption] = useState('');

    const option = useMemo(() => {
        return data?.options?.find(option => option?.id?.toString() === column?.colId?.toString());
    }, [data?.options, column?.colId]);

    const inputMask = useMemo(() => {
        const format = option?.format?.replace('?', '');
        return format || '';
    }, [option]);

    const maxChars = useMemo(() => {
        return option?.maxChars || option?.format?.length;
    }, [option?.maxChars, option?.format?.length]);

    const handleInputChange = event => {
        const value = event?.target?.value || '';
        setInputValue(value);

        if (value?.replace(/_/g, '').length === inputMask?.length) {
            node?.setData({
                ...data,
                validation: ''
            });

            if (typeof onShowTableValidationToast === 'function') {
                onShowTableValidationToast();
            }
        }
    };

    const handleUpdate = async (pressedKey) => {
        if (!option?.selectValues) {
            const value = inputValue?.replace(/_/g, '');

            if (inputMask?.length > 0 && (value?.length !== inputMask?.length)) {
                onShowTableValidationToast(`The required length for the ${option?.name} is ${maxChars.toString()}`);
                node?.setData({
                    ...data,
                    validation: 'max_chars'
                });
    
                if (inputRef?.current) {
                    inputRef.current?.focus();
                }
                return;
            }
        }

        if (typeof onUpdate === 'function') {
            const updateValue = option?.selectValues ? selectedOption : inputValue?.replace(/_/g, '');
            await onUpdate({ inputValue: updateValue, data, option });
            
            const totalRows = api?.getDisplayedRowCount();
            const rowIndex = isNaN(node?.rowIndex) ? -1 : node.rowIndex + 1;
            const isNextRowExisting = rowIndex >= 0 && rowIndex < totalRows;

            if (pressedKey === KeyboardKeys.TAB && isNextRowExisting) {
                api?.startEditingCell({
                    rowIndex: node?.rowIndex + 1,
                    colKey: column
                });
                return;
            }

            api?.stopEditing();
        }
    };

    const handleCancel = () => {
        if (typeof onShowTableValidationToast === 'function') {
            onShowTableValidationToast();
        }

        setInputValue(initialValue);
        setIsPopoverOpen(false);
        api?.clearFocusedCell();
        api?.stopEditing();
        node?.setData({
            ...data,
            validation: ''
        });
    };

    const handleInputKeyDown = event => {
        const pressedKey = event?.key;

        switch (pressedKey) {
            case KeyboardKeys.ENTER:
            case KeyboardKeys.TAB:
                handleUpdate(pressedKey);
                break;
            default:
                break;
        }
    };

    const handleSelectChange = event => {
        const value = event?.target?.value || '';
        setSelectedOption(value);

        if (selectRef?.current) {
            selectRef.current.focus();
        }
    }; 

    const renderPopoverTrigger = () => {
        if (option?.selectValues) {
            let selectOptions = [...(option.selectValues?.split('\n') || [])];
            selectOptions = [
                '',
                ...selectOptions?.filter(selectValue => !!selectValue),
            ];
            
            const cellRect = eGridCell.getBoundingClientRect();

            return (
                <Select
                    ref={selectRef}
                    className="custom-cell-editor-select"
                    value={selectedOption}
                    onChange={handleSelectChange}
                    portalContainer={document.body}
                    menuStyle={{
                        top: `${cellRect?.bottom}px`,
                        left: `${cellRect?.left}px`,
                        width: `${cellRect?.width}px`
                    }}
                >
                    {selectOptions?.map(selectOption => {
                        return (
                            <SelectOption key={selectOption} value={selectOption}>
                                {selectOption}
                            </SelectOption>
                        );
                    })}
                </Select>
            );
        }


        return (
            <InputMask
                ref={inputRef}
                mask={inputMask}
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                className={`
                    h-full w-full px-[12px] py-[16px] text-[12px] leading-[16px] tracking-[0.4px] text-inverse-surface rounded-none border-[1px] outline-0 focus:border-2
                    ${doesTableHaveValidationErrors() ? 'bg-error-container focus:border-error' : 'bg-tertiary-95 focus:border-tertiary-container'}
                `}
            />
        );
    };

    useEffect(() => {
        setIsPopoverOpen(true);
    }, []);

    useEffect(() => {
        if (isPopoverOpen) {
            if (inputRef?.current) {
                setTimeout(() => {
                    inputRef.current?.focus();
                }, 100);
            }
            
            if (selectRef?.current) {
                setTimeout(() => {
                    selectRef.current?.focus();
                    selectRef.current?.click();
                }, 100);
                setSelectedOption(initialValue);
            }
        }

    }, [isPopoverOpen, initialValue]);

    return (
        <Popover
            open={isPopoverOpen}
            placement="bottom"
            offsetOptions={{
                mainAxis: 5,
                crossAxis: 5
            }}
            dismissOptions={{
                escapeKey: false
            }}
        >
            <Popover.Trigger asCustomComponent>
                {renderPopoverTrigger()}
            </Popover.Trigger>
            <Popover.Content className="flex gap-[10px] bg-transparent border-0 shadow-none z-[1]">
                <Button
                    startIcon={<CheckIcon />}
                    className={`
                        hover:bg-secondary-fixed
                        ${doesTableHaveValidationErrors() ? '!bg-surface-dim hover:bg-surface-dim' : ''}
                    `}
                    disabled={doesTableHaveValidationErrors()}
                    onClick={handleUpdate}
                >
                    Update
                </Button>
                <Button
                    startIcon={<CloseIcon />}
                    className="bg-tertiary-fixed-dim hover:bg-tertiary-fixed-dim"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
            </Popover.Content>
        </Popover>
    );
};

export default CustomCellEditor;

import React, { useState, useEffect, useRef, forwardRef } from 'react';

const withHandleClickOutside = WrappedComponent => {
    return forwardRef((props, ref) => {
        const [isOpen, setIsOpen] = useState(false);
        const refNode = useRef();
        const portalRef = useRef();

        const handleClickOutside = event => {
            if (refNode?.current?.contains(event?.target) || portalRef?.current?.contains(event?.target)) {
                return;
            }
            setIsOpen(false);
        };

        const onChange = e => {
            props.onChange(e);
            if (props.multiselect) {
                return;
            }
            setIsOpen(false);
        };

        const updatedProps = { ...props, refNode, isOpen, setIsOpen, onChange, portalRef };

        useEffect(() => {
            if (isOpen) {
                document.addEventListener("mousedown", handleClickOutside);
            } else {
                document.removeEventListener("mousedown", handleClickOutside);
            }

            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [isOpen]);

        return <WrappedComponent ref={ref} {...updatedProps} />;
    });
};

export default withHandleClickOutside;

import { BaseStore } from '@/Store/BaseStore';

export const getQaChecklist = (success, payload) => {
    return BaseStore.httpClient.get('/qa-checklist', {
        params: {
            ...(payload || {})
        }
    })
        .then((response) => {
            if (response) {
                success(response?.data);
                return Promise.resolve(response?.data);
            }
        })
        .catch(err => {
            return Promise.reject(err);
        });
};

import {
    createContext,
    useContext,
    useMemo
} from 'react';
import { useReducerWithMiddleware } from '@/Hooks';
import { DoaReasonsStoreMiddleware } from '@/Store/Middlewares';
import { DoaReasonsStoreReducer } from '@/Store/Reducers';
import { ProviderName } from '@/Helpers/constants';

export const DoaReasonsStoreContext = createContext();

const initialState = {};

export const DoaReasonsStoreProvider = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(DoaReasonsStoreReducer, initialState, [DoaReasonsStoreMiddleware]);

    const DoaReasonsStoreState = useMemo(() => {
        return { ...(state || {}) };
    }, [state]);

    return (
        <DoaReasonsStoreContext.Provider value={{ state: DoaReasonsStoreState, dispatch, name: ProviderName.DOA_REASONS }}>
            {children}
        </DoaReasonsStoreContext.Provider>
    );
};

export const useDoaReasonsStoreContext = () => {
    return useContext(DoaReasonsStoreContext);
};

import {
    createContext,
    useContext,
    useMemo
} from 'react';
import { useReducerWithMiddleware } from '@/Hooks';
import { BatchStoreMiddleware } from '@/Store/Middlewares';
import { BatchStoreReducer } from '@/Store/Reducers';
import { ProviderName } from '@/Helpers/constants';

export const BatchStoreContext = createContext();

const initialState = {
    activeDetailsId: null,
    batchDetailsToDisplay: null,
    myBatches: null,
    pausedTimers: null,
    myKits: null
};

export const BatchStoreProvider = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(BatchStoreReducer, initialState, [BatchStoreMiddleware]);

    const BatchStoreState = useMemo(() => {
        return { ...(state || {}) };
    }, [state]);

    return (
        <BatchStoreContext.Provider value={{ state: BatchStoreState, dispatch, name: ProviderName.BATCH }}>
            {children}
        </BatchStoreContext.Provider>
    );
};

export const useBatchStoreContext = () => {
    return useContext(BatchStoreContext);
};

import {
    createContext,
    useContext,
    useMemo
} from 'react';
import { useReducerWithMiddleware } from '@/Hooks';
import { kitStoreMiddleware } from '@/Store/Middlewares';
import { kitStoreReducer } from '@/Store/Reducers';
import { ProviderName } from '@/Helpers/constants';

export const KitStoreContext = createContext();

const initialState = {
    kit: [],
    kitsInfo: [],
    addedKits: [],
};

export const KitStoreProvider = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(kitStoreReducer, initialState, [kitStoreMiddleware]);

    const kitStoreState = useMemo(() => {
        return { ...(state || {}) };
    }, [state]);

    return (
        <KitStoreContext.Provider value={{ state: kitStoreState, dispatch, name: ProviderName.KIT }}>
            {children}
        </KitStoreContext.Provider>
    );
};

export const useKitStoreContext = () => {
    return useContext(KitStoreContext);
};

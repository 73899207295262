import {
    createContext,
    useContext,
    useMemo
} from 'react';
import { useReducerWithMiddleware } from '@/Hooks';
import { CompaniesStoreMiddleware } from '@/Store/Middlewares';
import { CompaniesStoreReducer } from '@/Store/Reducers';
import { ProviderName } from '@/Helpers/constants';

export const CompaniesStoreContext = createContext();

const initialState = {
    companies: null,
};

export const CompaniesStoreProvider = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(CompaniesStoreReducer, initialState, [CompaniesStoreMiddleware]);

    const CompaniesStoreState = useMemo(() => {
        return { ...(state || {}) };
    }, [state]);

    return (
        <CompaniesStoreContext.Provider value={{ state: CompaniesStoreState, dispatch, name: ProviderName.COMPANIES }}>
            {children}
        </CompaniesStoreContext.Provider>
    );
};

export const useCompaniesStoreContext = () => {
    return useContext(CompaniesStoreContext);
};

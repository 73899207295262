const OptionItem = ({
    className,
    icon,
    text,
    ...props
})=>{
    return (
        <div {...props} className={`${className} cursor-pointer font-[400] flex py-2 px-[8px] items-center space-x-2 hover:bg-[#C2E8FF]`}>
            <div className="flex space-x-[2px] items-center">
                {icon}
                <p className=" text-sm tracking-[0.25px] text-on-surface-variant ">{text}</p>
             </div>
        </div>
    );
};

export default OptionItem;

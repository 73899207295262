import React, { useState, useCallback, useEffect } from 'react';
import {
    FloatingFocusManager,
    FloatingOverlay,
    FloatingPortal,
    useDismiss,
    useFloating,
    useInteractions
} from "@floating-ui/react";

import { Stack } from '@/Components/Display';
import { Button } from "@/Components/Inputs";
import { CloseIcon } from "@/Icons";

const classNameBuilder = (existingClass, className) => {
    const classNames = [];
    classNames.push(existingClass);

    if (className) {
        classNames.push(className);
    }

    return classNames.join(' ');
};

const Dialog = ({
    isOpen,
    onClose,
    children,
    className,
    style,
    maxWidth,
    overlayClassName,
    ...props
}) => {
    const [classNames] = useState(classNameBuilder('', className));
    const [styles, setStyles] = useState(style);
    const [clientHeight, setClientHeight] = useState(null);

    const handleResize = useCallback(() => {
        const viewportHeight = window && window.innerHeight ? window.innerHeight : 0;
        const messageBoxHeight = clientHeight ? clientHeight : 0;

        if (messageBoxHeight > viewportHeight) {
            return setStyles({
                ...styles,
                maxHeight: 'calc(100vh - 119px)',
                overflowY: 'auto'
            });
        }
    }, [clientHeight, styles]);

    const handleSetRef = node => {
        setClientHeight(node?.clientHeight);
    };

    const handleClose = () => {
        if (onClose && typeof onClose === 'function') {
            onClose();
        }
    };

    const { context } = useFloating({
        open: isOpen,
        onOpenChange: handleClose
    });

    const dismiss = useDismiss(context, {
        outsidePress: false
    });

    const { getFloatingProps } = useInteractions([
        dismiss
    ]);

    useEffect(() => {
        if (isOpen && clientHeight) {
            handleResize();
        }
    }, [isOpen, clientHeight, handleResize]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    useEffect(() => {
        if (maxWidth) {
            if (typeof maxWidth === 'string') {
                let trimmedMaxWidth = maxWidth.trim().toLowerCase();

                if (trimmedMaxWidth === 'xs') {
                    setStyles({ ...styles, width: '501px', maxWidth: '501px' });
                }
            } else {
                return;
            }
        }
    }, [maxWidth, styles]);

    return (
        <>
            {isOpen && (
                <FloatingPortal>
                    <FloatingOverlay className={`dialog-overlay ${overlayClassName || ''}`} lockScroll>
                        <FloatingFocusManager context={context}>
                            <div className={`dialog-container ${classNames}`} style={styles} ref={handleSetRef} {...getFloatingProps()} {...props}>
                                {onClose && (
                                    <Button
                                        className="dialog-close-button border-0 bg-transparent hover:bg-transparent"
                                        onClick={handleClose}
                                    >
                                        <CloseIcon />
                                    </Button>
                                )}
                                {children}
                            </div>
                        </FloatingFocusManager>
                    </FloatingOverlay>
                </FloatingPortal>
            )}
        </>
    );
};

Dialog.Title = ({ children, className, style }) => {
    const classNames = classNameBuilder('dialog-title', className);
    return (
        <div className={classNames} style={style}>{children}</div>
    );
};

Dialog.Content = ({ children, className, style }) => {
    const classNames = classNameBuilder('dialog-content', className);
    return (
        <div className={classNames} style={style}>{children}</div>
    );
};

Dialog.Actions = ({ children, align, className, style }) => {
    let actionAlignment = 'flex-end';

    if (align === 'center') {
        actionAlignment = 'center';
    } else if (align === 'space-between') {
        actionAlignment = 'space-between';
    }

    const classNames = classNameBuilder('dialog-actions', className);

    return (
        <Stack
            className={classNames}
            justifyContent={actionAlignment}
            alignItems="center"
            spacing={0}
            style={style}
        >
            {children}
        </Stack>
    );
};

export default Dialog;
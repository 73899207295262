export function isArrayWithLength(array) {
	return (Array.isArray(array) && array.length)
};

export const getAllowedRoutes = (routes) => {
	return routes.filter(({ permission }) => {
		if (!permission) {
			//Without permission property, Route is accessible.
			return true;
		} else if (!isArrayWithLength(permission)) {
			//'permission' property exists but does not have entries.
			// permission : []
			return true;
		} else {
			//Intersection of User Permissions and Component Permission
			//return intersection(permission, userPermissions).length;
			return true;
		}
	});
};

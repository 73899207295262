import {CompaniesStoreActionConstants,receiveCompanies } from '@/Store/Actions';
import { getCompanies } from '@/Store/Services';

export const CompaniesStoreMiddleware = ({ action, dispatch }) => {
    let success;

    switch (action.type) {
        case CompaniesStoreActionConstants.REQUEST_COMPANIES:
                success = (companies)=>{
                    dispatch(receiveCompanies(companies))
                }
                return getCompanies(success);
        default:
            return;
    }
};

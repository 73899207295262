import { useReducer, useRef } from 'react';

export const useReducerWithMiddleware = (
    reducer,
    initialState,
    middleware
) => {

    const [state, dispatch] = useReducer(
        (state, action) => reducer({ state, action, initialState }),
        initialState
    );

    const actionRef = useRef();


    const dispatchWithMiddleware = async (action) => {
        // middleware.forEach((middlewareFn) => middlewareFn(state, action, dispatch));

        for (const iterator of middleware) {
            await iterator({
                state,
                initialState,
                action,
                dispatch
            });
        }
        actionRef.current = action;

        dispatch(action);
    };

    return [state, dispatchWithMiddleware];
};

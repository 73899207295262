import axios from 'axios';

export class BaseStore {
    static httpClient = axios.create({
        baseURL: process.env.REACT_APP_API_SERVER_URL || 'https://14z2z6zu4g.execute-api.us-east-1.amazonaws.com/dev/api/v1',
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

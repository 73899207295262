import React, { Component, createRef } from "react";
import { NavBar, Sidebar } from "@/Components/Layout";
import { Toast } from "@/Components/Display";
import { Dialog } from "@/Components/Feedback";
import { Button } from "@/Components/Inputs";
import packageJson from '../../package.json';
import { AppStorageConstants } from "@/Constants";
import { withStore } from "@/Helpers/withStore";
import { receiveUserInfo } from "@/Store/Actions";
import { AppStorage } from "@/Helpers/storage";

class MainLayout extends Component {
     constructor(props) {
          super(props);

          this.state = {
               showToast: false,
               onShowProfileMenu: false,
               showAboutDialog: false
          };

          this.sidebarRef = createRef();
     }

     handleShowAboutDialog = () => {
          this.setState({ showAboutDialog: true });
     }

     handleCloseAboutDialog = () => {
          this.setState({ showAboutDialog: false });
     }

     renderAboutDialog = () => {
          return (
               <Dialog
                    className="w-[624px] pt-8 px-8 pb-[24px] text-on-surface"
                    isOpen={this.state.showAboutDialog}
               >
                    <Dialog.Title className="text-2xl mb-[13px] font-roboto font-normal leading-10">
                         About
                    </Dialog.Title>
                    <Dialog.Content className="mb-[24px]">
                         <p className="font-roboto text-[16px] font-normal leading-6 tracking-[0.5px]">
                              ProCheck Version {packageJson.version}
                              <br />
                              API Version {this.props?.apiHealthCheckResponse?.version || ''}
                              <br />
                              Device Service Version {this.props?.deviceServiceVersion || ''}
                         </p>
                    </Dialog.Content>
                    <Dialog.Actions className="justify-end">
                         <Button className="uppercase" onClick={this.handleCloseAboutDialog}>Close</Button>
                    </Dialog.Actions>
               </Dialog>
          );
     }

     handleShowToast = () => {
          this.setState({ showToast: true });
     }

     renderToast = () => {
          return (
               <Toast
                    open={this.state.showToast}
                    onClose={() => this.setState({ showToast: false })}
                    message="Issue reported. Your report is being addressed by our team.">
               </Toast>
          );
     }

     onCloseProfileMenu = () => {
          this.setState({ onShowProfileMenu: false });
     }

     onToggleProfileMenu = () => {
          this.setState({ onShowProfileMenu: !this.state.onShowProfileMenu });
     };

     handleMainClick = event => {
          this.onCloseProfileMenu();
     };

     componentDidMount() {
          const loggedUser = AppStorage.Local.get(AppStorageConstants.USER);
          if (loggedUser) {
               this.props?.receiveUserInfo(JSON.parse(loggedUser));
          }
     }

     render() {
          const { children } = this.props;

          const { onShowProfileMenu, showAboutDialog } = this.state

          return (
               <div className="h-[100dvh] overflow-hidden w-full">
                    <NavBar
                         handleShowAboutDialog={this.handleShowAboutDialog}
                         onCloseProfileMenu={this.onCloseProfileMenu}
                         onToggleProfileMenu={this.onToggleProfileMenu}
                         onShowProfileMenu={onShowProfileMenu}
                    />
                    <div style={{ height: 'calc(100vh - 64px)' }} className="flex w-full z-50">
                         <main onClick={this.handleMainClick} className="w-full">
                              {children}
                         </main>
                         <Sidebar handleShowToast={this.handleShowToast} />
                    </div>
                    {this.state.showToast && this.renderToast()}
                    {showAboutDialog && this.renderAboutDialog()}
               </div>
          );
     }
}

const mapStateToProps = ({ user, device }) => {
     return {
          apiHealthCheckResponse: user?.apiHealthCheckResponse,
          deviceServiceVersion: device?.deviceServiceVersion
     };
};

const mapDispatchToProps = ({ user }) => {
     return {
          receiveUserInfo: loggedUser => user.dispatch(receiveUserInfo(loggedUser))
     };
};

export default withStore(
     mapStateToProps,
     mapDispatchToProps
)(MainLayout);

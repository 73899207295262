

const DoubleArrowIcon = ({ className }) => {
    
     return (
          <svg xmlns="http://www.w3.org/2000/svg"  className={`svg-regular ${className}`} viewBox="0 0 21 20" fill="none">
          <mask id="mask0_1540_46787" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="20">
            <rect x="0.864868" y="0.742188" width="19.2" height="19.2" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_1540_46787)">
            <path d="M5.70483 15.9422L9.70483 10.3422L5.70483 4.74219H7.66483L11.6648 10.3422L7.66483 15.9422H5.70483ZM10.4648 15.9422L14.4648 10.3422L10.4648 4.74219H12.4248L16.4248 10.3422L12.4248 15.9422H10.4648Z"/>
          </g>
        </svg>
     )
}

export default DoubleArrowIcon
import { DeviceStoreActionConstants, receiveOptions, receiveScannedDevices } from '@/Store/Actions';
import { flagDevice, flagDeviceDoa, passDevice, scanCli, scanUsbCli, updateDeviceInfo } from '@/Store/Services';

export const DeviceStoreMiddleware = ({ action, dispatch }) => {
    let success;
    switch (action.type) {
        case DeviceStoreActionConstants.REQUEST_SCAN_DEVICES:
            success = responseData => {
                dispatch(receiveScannedDevices(responseData?.data));
            };
            return scanUsbCli(success, action?.payload);
        case DeviceStoreActionConstants.REQUEST_OPTIONS:
            success = responseData => {
                dispatch(receiveOptions(responseData?.data));
            };
            return scanCli(success, action?.payload);
        case DeviceStoreActionConstants.REQUEST_UPDATE_DEVICE_INFO:
            success = responseData => {

            };
            return updateDeviceInfo(success, action?.payload);
        case DeviceStoreActionConstants.REQUEST_PASS_DEVICE:
            success = responseData => {

            };
            return passDevice(success, action?.payload);
        case DeviceStoreActionConstants.REQUEST_FLAG_DEVICE:
            success = responseData => {

            };
            return flagDevice(success, action?.payload);
        case DeviceStoreActionConstants.REQUEST_FLAG_DEVICE_DOA:
            success = responseData => {

            };
            return flagDeviceDoa(success, action?.payload);
        default:
            return;
    }
};

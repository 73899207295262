import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { TabContainerButton } from "@/Components/Navigation";
import { RefreshIcon, ChangeCircleIcon } from "@/Icons";
import { AgGridIcon, Table, Tag, Toast } from "@/Components/Display";
import {
    CustomColumnHeader,
    ReorderColumnsPopover,
    CustomNoRowsOverlay,
    CustomLoadingOverlay,
    CustomFilterSection
} from "@/Components/Display/Table/Custom/Components";
import { Button } from "@/Components/Inputs";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import { withStore } from "@/Helpers/withStore";
import { receiveOpenItemsTableRowGroupState, requestOrders } from "@/Store/Actions";
import { AddItemsDialog } from "./Dialog/AddItemsDialog";
import { ClaimItemsDialog } from "./Dialog/ClaimItemsDialog";
import { OrderItemStatus } from "@/Helpers/constants";
import { getColumnState, setColumnState } from "@/Helpers/TableColumnHelper";
import ClaimItemsButton from "./Button/ClaimItemButton";

class OpenItemsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rowData: [],
            isRefreshing: false,
            showAddItemsDialog: false,
            isClaimItemsDialogOpen: false,
            claimItemRowData: null,
            isClaimItemsButtonDisabled: true,
            rowCount: 0,
            gridApi: null,
            isNotPermittedToClaimToastOpen: false,
            isGridLoading: true,
            columnDefs: props.openItemsColumnDefs
        };
    };

    gridOptions = () => {
        return {
            groupDisplayType: "custom",
            onFilterChanged: (params) => this.handleShowNoOverlay(params),
            getRowHeight: params => params?.node?.group ? 48 : 40,
            onRowGroupOpened: this.handleOnRowGroupOpened,
            onGridReady: ({ api, columnApi, type, ...rest }) => {
                this.props.handleGridApiSetState(api);
            },
            onColumnMoved: ({ columnApi }) => {
                setColumnState(columnApi, this.props.currentTab);
            },
            onColumnVisible: ({ columnApi }) => {
                setColumnState(columnApi, this.props.currentTab);
            },
            onColumnResized: ({ columnApi }) => {
                setColumnState(columnApi, this.props.currentTab);
            },
            icons: {
                groupExpanded: () => AgGridIcon('ArrowDropUpIcon', { className: 'text-primary' }),
                groupContracted: () => AgGridIcon('DropDownIcon', { className: 'text-primary' }),
            },
            popupParent: document.querySelector('body'),
            postProcessPopup: params => {
                // check callback is for columnFilter
                if (params?.type !== 'columnFilter') {
                    return;
                }
                // Calculate the position based on the column header width
                const eventSource = params.eventSource;
                const ePopup = params.ePopup;
                const popupWidth = ePopup.offsetWidth;
                const headerRect = eventSource.getBoundingClientRect();
                const popupLeft = headerRect.right - popupWidth + window.scrollX;

                if (popupLeft > 0) {
                    // Consider header padding
                    ePopup.style.left = `${popupLeft + 12}px`;
                }
                ePopup.style.width = `220px`;
                if (params?.column?.userProvidedColDef?.headerName === 'Date') {
                    ePopup.style.maxWidth = '360px';
                    ePopup.style.minWidth = '360px';
                }
            },
            suppressLoadingOverlay: true,
            alwaysMultiSort: true,
            getRowStyle: params => {
                if (params?.node?.rowIndex === 0) {
                    return {
                        background: '#E2F3FF',
                        'border': '0px',
                    };
                }
                if (params?.node?.group) {
                    return {
                        background: '#E2F3FF',
                        borderTop: '1px solid #77D1FF',
                        borderBottom: '0px'
                    };
                }
                if (params?.node?.childIndex % 2 === 0) {
                    return { background: '#ffffff', border: '0px' };
                }
                return { background: '#EFF4F9', border: '0px' };
            }
        };
    };

    getDefaultColDef = () => {
        return {
            rowHeight: 50,
            flex: 1,
            filter: true,
            suppressHeaderMenuButton: true,
            menuTabs: [],
            minWidth: 220,
            headerComponent: CustomColumnHeader,
            headerComponentParams: {
                gridRef: this.props.openItemsGridRef
            },
            resizable: false,
            suppressMovable: true,
            cellStyle: params => {
                if (!params?.node?.group) {
                    return { 'border': '1px solid #EAEEF3' };
                }
                return null;
            }
        };
    }

    handleOnRowGroupOpened = (params) => {
        const rowGroupState = this.props.openItemsTableRowGroupState;
        if (!rowGroupState.includes(params?.node?.id)) {
            rowGroupState.push({ node: params?.node?.id, expanded: params.node.expanded });
        }
    };

    formatToTags = (props) => {
        return <Tag className={props.getValue()} label={props.getValue()} />
    };

    renderClaimItemsDialog = () => {
        return (
            <ClaimItemsDialog
                isOpen={this.state.isClaimItemsDialogOpen}
                rowData={this.state.claimItemRowData}
                gridApi={this.props.openItemsGridApi}
                onClose={this.handleCloseClaimItemsDialog}
                onClaim={this.handleCloseClaimItemsDialog}
            />
        );
    };

    handleClaimItemsClick = () => {
        const isUserIdSameWithRowData = this.state.claimItemRowData?.modUserID === this.props.userID;
        if (isUserIdSameWithRowData) {
            this.setState({
                isNotPermittedToClaimToastOpen: true
            });
            return;
        }
        this.handleOpenClaimItemsDialog();
    };

    handleOpenClaimItemsDialog = () => {
        this.setState({
            isClaimItemsDialogOpen: true
        });
    };

    handleCloseClaimItemsDialog = () => {
        this.setState({
            isClaimItemsDialogOpen: false
        });
    };

    handleRefreshClick = async () => {
        if (this.props.openItemsGridApi) {
            this.setState({
                isRefreshing: true,
                isGridLoading: true
            }, async () => {
                await this.fetchBatchOrders();
                this.setState({
                    isRefreshing: false,
                    isGridLoading: false
                });
            });
        }
    };

    handleOpenAddItemsDialog = () => {
        this.setState({
            showAddItemsDialog: true
        });
    };

    handleCloseAdddItemsDialog = () => {
        this.setState({
            showAddItemsDialog: false,
        });
    };

    handleCloseNotPermittedToClaimToast = () => {
        this.setState({
            isNotPermittedToClaimToastOpen: false
        });
    };

    renderNotPermittedToClaimToast = () => {
        return (
            <Toast
                message="You are not permitted to claim items you have previously tested."
                status="error"
                open={this.state.isNotPermittedToClaimToastOpen}
                onClose={this.handleCloseNotPermittedToClaimToast}
            />
        );
    };

    fetchBatchOrders = async () => {
        try {
            await this.props.requestOrders('batch');
        } catch (error) {
            console.error(error);
        } finally {
            this.setState({
                isGridLoading: false
            });
        };
    };

    handleIsRowSelectable = ({ group }) => {
        return !group;
    };

    handleRowDoubleClicked = ({ node, data }) => {
        const isStatusReady = [
            OrderItemStatus.READY_FOR_STAGING,
            OrderItemStatus.READY_FOR_QA,
            OrderItemStatus.READY_FOR_AUDIT
        ].includes(data?.status);
        const isUserIdSameWithRowData = data?.modUserID === this.props.userID;

        if (!node?.group && isStatusReady) {
            if (isUserIdSameWithRowData) {
                this.setState({
                    isNotPermittedToClaimToastOpen: true
                });
                return;
            };
            this.handleOpenClaimItemsDialog(data);
        };
    };

    handleFirstDataRendered = ({ api }) => {
        if (api) {
            const rowGroupState = this.props.openItemsTableRowGroupState;

            if (rowGroupState) {
                if (Array.isArray(rowGroupState)) {
                    rowGroupState?.map(expandedRowGroupId => ({ node: api?.getRowNode(expandedRowGroupId.node), expanded: expandedRowGroupId.expanded }))
                        ?.forEach(expandedRowGroupNode => api?.setRowNodeExpanded(expandedRowGroupNode.node, expandedRowGroupNode.expanded));
                };
            }
        }
    };

    async componentDidMount() {
        const getState = getColumnState(this.props.currentTab, this.state.columnDefs);
        if (getState) {
            this.setState({ columnDefs: getState });
        }
        this.fetchBatchOrders();
    };

    componentDidUpdate(prevProps, prevState) {
        const orders = prevProps?.orders;
        const stateOrders = this.props.orders;

        if (orders !== stateOrders) {
            this.setState({
                rowData: this.props.orders
            });
        }
        if (this.props.openItemsGridApi) {
            if (this.state.isGridLoading) {
                this.props.openItemsGridApi?.showLoadingOverlay();
            } else {
                this.props.openItemsGridApi?.hideOverlay();
            }
        }
    };

    handleShowNoOverlay = params => params.api.getDisplayedRowCount() === 0 ? this.props.openItemsGridApi.showNoRowsOverlay() : this.props.openItemsGridApi.hideOverlay();

    renderResetFilterButton = () => {
        const filterRef = this.props.openItemsFilterGridApiRef.current;
        const openItemsGridApi = this.props.openItemsGridApi;
        const filterDateRef = this.props.openItemsFilterDateGridApiRef.current;
        const resetSortModel = this.props.sortModelResetRef.current;
        const reOrderResetRef = this.props.reOrderResetRef.current;

        const handleResetFilters = () => {
            if (filterRef && openItemsGridApi && filterDateRef && resetSortModel && reOrderResetRef) {
                openItemsGridApi.setFilterModel(null);
                openItemsGridApi.resetColumnState();
                const colIds = openItemsGridApi.getColumnDefs().map(item => (item.colId));

                colIds?.forEach((colId) => {
                    filterRef.getFilterInstance(colId, (filterInstance) => {
                        if (filterInstance) {
                            const currentFunctions = filterInstance.sourceParams.gridApiRef?.current;
                            if (currentFunctions) {
                                currentFunctions.resetSelectedOptions();
                            }
                        }
                    });
                });
                filterDateRef.getFilterInstance('createDate', (filterInstance) => {
                    if (filterInstance) {
                        const currentFunctions = filterInstance.sourceParams.gridApiRef?.current;
                        if (currentFunctions) {
                            currentFunctions.resetDateSelected();
                        }
                    }
                });
                resetSortModel.resetFilterSortModel();
                reOrderResetRef.resetReOrderModel();
            };
        }

        return (
            <Button
                variant="flat"
                className="w-[152px]"
                startIcon={<ChangeCircleIcon className="text-on-surface-variant" />}
                onClick={handleResetFilters}
            >
                Reset Filters
            </Button>
        );
    };

    render() {
        const {
            isClaimItemsDialogOpen,
            showAddItemsDialog,
            rowData,
            isRefreshing,
            isNotPermittedToClaimToastOpen,
            isGridLoading,
            columnDefs
        } = this.state;

        const {
            openItemsColumnDefs,
            openItemsGridApi,
            openItemsGridRef,
            currentTab,
            openItemsFilterGridApiRef,
            sortModelResetRef,
            reOrderResetRef
        } = this.props;

        return (
            <div className="">
                <div className="flex justify-between py-2 pt-3 mb-2 ">
                    <TabContainerButton className="">
                        <ReorderColumnsPopover
                            gridApi={openItemsGridApi}
                            columns={openItemsColumnDefs}
                            currentTab={currentTab}
                            reOrderResetRef={reOrderResetRef}
                        />
                        {this.renderResetFilterButton()}
                    </TabContainerButton>
                    <TabContainerButton className="!gap-[26px]">
                        <ClaimItemsButton
                            gridApi={openItemsGridApi}
                            onClick={this.handleClaimItemsClick}
                        />
                        <Button onClick={this.handleOpenAddItemsDialog} >Add Items</Button>
                        <Button
                            className="w-[122px]"
                            startIcon={<RefreshIcon />}
                            isLoading={isRefreshing}
                            onClick={this.handleRefreshClick}
                        >
                            Refresh
                        </Button>
                    </TabContainerButton>
                </div>
                <CustomFilterSection
                    api={openItemsGridApi}
                    gridApiRef={openItemsFilterGridApiRef}
                    columns={openItemsColumnDefs}
                    sortModelResetRef={sortModelResetRef}
                    currentTab={currentTab}
                />
                <Table
                    className="open-items-table"
                    reactiveCustomComponents
                    ref={openItemsGridRef}
                    rowData={rowData}
                    groupDefaultExpanded={0}
                    columnDefs={columnDefs}
                    defaultColDef={this.getDefaultColDef()}
                    suppressMenuHide
                    columnMenu="new"
                    enableRowGroup
                    rowGroupPanelSuppressSort
                    gridOptions={this.gridOptions()}
                    suppressLoadingOverlay={false}
                    loadingOverlayComponent={CustomLoadingOverlay}
                    noRowsOverlayComponent={CustomNoRowsOverlay}
                    suppressNoRowsOverlay={isGridLoading}
                    suppressContextMenu
                    rowSelection="single"
                    onRowDoubleClicked={this.handleRowDoubleClicked}
                    isRowSelectable={this.handleIsRowSelectable}
                    onFirstDataRendered={this.handleFirstDataRendered}
                />
                {isClaimItemsDialogOpen && this.renderClaimItemsDialog()}
                {showAddItemsDialog &&
                    <AddItemsDialog
                        showAddItemsDialog={showAddItemsDialog}
                        handleCloseAdddItemsDialog={this.handleCloseAdddItemsDialog}
                    />}
                {isNotPermittedToClaimToastOpen && this.renderNotPermittedToClaimToast()}
            </div>

        );
    }
};

const mapStateToProps = ({ orders, user }) => {
    return {
        orders: orders?.orders,
        userID: user?.user?.userID,
        openItemsColumnItems: orders?.openItemsColumnItems,
        openItemsTableRowGroupState: orders?.openItemsTableRowGroupState,
    };
};

const mapDispatchToProps = ({ orders }) => {
    return {
        requestOrders: batchType => orders.dispatch(requestOrders(batchType)),
        receiveOpenItemsTableRowGroupState: tableState => orders.dispatch(receiveOpenItemsTableRowGroupState(tableState)),
    };
};

export default withStore(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(OpenItemsPage));

import { DropDownIcon } from "@/Icons";

import ProfileContent from "./ProfileContent";

const Profile = ({ onToggleProfileMenu, onShowProfileMenu, user, handleShowAboutDialog }) => {
    // const userFullName = user ? `${user?.gn} ${user?.sn}` : '';
    const userFullName = user?.name;
    return (
        <div>
            <div onClick={() => onToggleProfileMenu()} className={` ${onShowProfileMenu ? " bg-on-primary-fixed-variant" : ""} z-50 w-auto rounded-md relative cursor-pointer select-none hover:bg-on-primary-fixed-variant items-center flex gap-2 min-w-32 py-2 pl-4 pr-2`}>
                <h1  className=" text-inverse-on-surface text-[14px] leading-[20px] tracking-[0.25px] font-roboto font-normal">{userFullName}</h1>
                <DropDownIcon  className=" text-on-primary"></DropDownIcon>
            </div>

            <div className="relative z-50">
               {onShowProfileMenu && <ProfileContent user={user} handleShowAboutDialog={handleShowAboutDialog} />}
            </div>
        </div>
    );
};

export default Profile;

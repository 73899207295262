import { ErrorIcon } from "@/Icons";
import { useEffect } from "react";

const Toast = ({
	position,
	open,
	message,
	className,
	onClose,
	status,
	autoHide,
	...props
}) => {

	useEffect(() => {
		let interval;

		if (autoHide && open) {
			interval = setTimeout(() => {
				if (typeof onClose === 'function') {
					onClose();
				}
			}, 2500);
		}

		return () => {
			if (interval) {
				clearTimeout(interval);
			}
		}
	}, [open, autoHide, onClose]);

	return (
		<>
			{open && (
				<div className={`toast ${open ? 'show' : 'hide'} ${position} ${className}`} {...props}>
					{status === 'error' && (
						<ErrorIcon className="text-error bg-white rounded-full" />
					)}
					{message}
				</div>
			)}
		</>
	);
};

Toast.defaultProps = {
	position: 'bottom-center',
	status: 'success',
	autoHide: true
};

export default Toast;


import React from 'react';

const TabContext = ({
     className,
     children 
}) => {
     return (
           <div className={`tab-context ${className}`}>
             {children}
          </div>
     );
};

export default TabContext;

const StagingFailedIcon = ({ className }) => {
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className || ''}`}>
			<rect x="1" y="1" width="16" height="16" rx="8" fill="#E5B5B5" />
			<path fill-rule="evenodd" clip-rule="evenodd" d="M11.0388 6.00353C11.3682 5.70913 11.8739 5.73754 12.1684 6.06699C12.4401 6.3711 12.4368 6.82542 12.1756 7.12507L12.1049 7.19658L6.73346 11.9966C6.40401 12.291 5.89828 12.2626 5.60387 11.9331C5.33212 11.629 5.33542 11.1747 5.59666 10.875L5.66733 10.8035L11.0388 6.00353Z" fill="#A90A0A" />
			<path fill-rule="evenodd" clip-rule="evenodd" d="M5.59861 6.07295C5.86734 5.76615 6.31867 5.71407 6.64792 5.93684L6.72752 5.99827L12.1463 10.7447C12.4787 11.0358 12.5121 11.5412 12.221 11.8736C11.9523 12.1804 11.501 12.2325 11.1717 12.0097L11.0921 11.9483L5.67329 7.20185C5.34093 6.91073 5.30749 6.4053 5.59861 6.07295Z" fill="#A90A0A" />
		</svg>
	);
};

export default StagingFailedIcon;

import {
    createContext,
    useContext,
    useMemo
} from 'react';
import { useReducerWithMiddleware } from '@/Hooks';
import { UserStoreMiddleware } from '@/Store/Middlewares';
import { UserStoreReducer } from '@/Store/Reducers';
import { ProviderName } from '@/Helpers/constants';

export const UserStoreContext = createContext();

const initialState = {
    user: null,
    isUserAuthenticated: false,
    isSidebarOpen: false,
    problemTemplate: '',
    apiHealthCheckResponse: null
};

export const UserStoreProvider = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(UserStoreReducer, initialState, [UserStoreMiddleware]);

    const userStoreState = useMemo(() => {
        return { ...(state || {}) };
    }, [state]);

    return (
        <UserStoreContext.Provider value={{ state: userStoreState, dispatch, name: ProviderName.USER }}>
            {children}
        </UserStoreContext.Provider>
    );
};

export const useUserStoreContext = () => {
    return useContext(UserStoreContext);
};



const DevicesIcon = ({ className }) => {

     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className}`} viewBox="0 0 18 19" fill="none">
               <mask id="mask0_3588_347" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
                    <rect y="0.0224609" width="18" height="18" fill="#171C20" />
               </mask>
               <g mask="url(#mask0_3588_347)">
                    <path d="M1.875 14.6474V12.96H3.375V4.75324C3.375 4.3804 3.50776 4.06123 3.77327 3.79573C4.03877 3.53022 4.35794 3.39746 4.73078 3.39746H15.375V4.52244H4.73078C4.66346 4.52244 4.60817 4.54408 4.56489 4.58735C4.52162 4.63063 4.49998 4.68592 4.49998 4.75324V12.96H8.80526V14.6474H1.875ZM11.2716 14.6474C11.0796 14.6474 10.9186 14.5825 10.7886 14.4525C10.6587 14.3226 10.5937 14.1616 10.5937 13.9695V6.84456C10.5937 6.65249 10.6587 6.4915 10.7886 6.36157C10.9186 6.23165 11.0796 6.16669 11.2716 6.16669H15.4543C15.6464 6.16669 15.8074 6.23165 15.9373 6.36157C16.0672 6.4915 16.1322 6.65249 16.1322 6.84456V13.9695C16.1322 14.1616 16.0672 14.3226 15.9373 14.4525C15.8074 14.5825 15.6464 14.6474 15.4543 14.6474H11.2716ZM11.7187 12.96H15.0072V7.29165H11.7187V12.96Z" />
               </g>
          </svg>
     )
}

export default DevicesIcon














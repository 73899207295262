const DropDownIcon = ({ className }) => {

     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={`svg-regular ${className}`} viewBox="0 0 20 20" fill="none">
               <path fillRule="evenodd" clipRule="evenodd" d="M4.99976 7.5L9.99975 12.5L14.9998 7.5L4.99976 7.5Z" fill="currentColor" />
          </svg>
     )
}

export default DropDownIcon

import { memo, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

const BuildAllowedRoutes = ({ routes }) => {
    return (
        <Switch>
            {routes.map((route, index) => {
                const { 
                    path,
                    component: Component,
                    children,
                    ...rest
                } = route;

                if (route.redirect) {
                    return (
                        <Route {...rest} key={index} path={route.path} render={props =>
                            <Redirect {...props} from={path} to={route.redirectTo} />
                        } />
                    );
                }

                return (
                    <Route {...rest} key={index} path={path} render={props =>
                        <Suspense fallback={<>Loading</>}>
                            <Component {...props} {...rest} children={children} />
                        </Suspense>

                    } />
                );
            })}
        </Switch>
    );
};

export default memo(BuildAllowedRoutes);

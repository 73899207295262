import { useSelector } from "@/Store/StoreContextProvider";

import { Button } from '@/Components/Inputs';
import { useEffect, useState } from 'react';
import { OrderItemStatus } from '@/Helpers/constants';

const ClaimItemsButton = ({ gridApi, onClick }) => {
    const loggedUser = useSelector(({ user }) => user?.user);

    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (gridApi) {
            const getRowSelected = () => {
                gridApi.eventService.addEventListener('rowSelected', (event) => {
                    const { node, data, type } = event;
                    if (type.toLowerCase() === 'rowselected') {
                        const isStatusNotReady = ![
                            OrderItemStatus.READY_FOR_STAGING,
                            OrderItemStatus.READY_FOR_QA,
                            OrderItemStatus.READY_FOR_AUDIT
                        ].includes(data?.status);
                        const isUserIdSameWithRowData = data?.modUserID === loggedUser?.userID;

                        if (node?.selected) {
                            setIsDisabled(isStatusNotReady || isUserIdSameWithRowData);
                        }
                    }
                });
            };

            getRowSelected();

            return () => {
                gridApi?.eventService?.removeEventListener('rowSelected', getRowSelected);
            };
        }
    }, [
        gridApi,
        loggedUser?.userID
    ]);

    return (
        <Button onClick={onClick} disabled={isDisabled}>Claim Items</Button>
    );
};

export default ClaimItemsButton;

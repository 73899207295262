import {
    createContext,
    useContext,
    useMemo
} from 'react';
import { useReducerWithMiddleware } from '@/Hooks';
import { QaChecklistStoreMiddleware } from '@/Store/Middlewares';
import { QaChecklistStoreReducer } from '@/Store/Reducers';
import { ProviderName } from '@/Helpers/constants';

export const QaChecklistStoreContext = createContext();

const initialState = {};

export const QaChecklistStoreProvider = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(QaChecklistStoreReducer, initialState, [QaChecklistStoreMiddleware]);

    const QaChecklistStoreState = useMemo(() => {
        return { ...(state || {}) };
    }, [state]);

    return (
        <QaChecklistStoreContext.Provider value={{ state: QaChecklistStoreState, dispatch, name: ProviderName.QA_CHECKLIST }}>
            {children}
        </QaChecklistStoreContext.Provider>
    );
};

export const useQaChecklistStoreContext = () => {
    return useContext(QaChecklistStoreContext);
};

import { Component } from "react";
import { Button } from "@/Components/Inputs";
import Logo from "@/Assets/logo.png";
import { LoadingDialog } from "@/Components/Feedback";
import { withStore } from "@/Helpers/withStore";
import { isUserAuthenticated } from "@/Authentication";
import { authenticateUser, requestLogin, } from "@/Store/Actions";
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig, msalScopes } from "@/Config/msal.config";
import { AppStorage } from "@/Helpers/storage";
import { AppStorageConstants } from "@/Constants";

class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoadingDialogOpen: false
        };

        this.publicClientApplication = new PublicClientApplication(msalConfig);
        this.publicClientApplication.initialize();

        this.popupWindow = null;
    }

    handleUsernameOnchange = (e) => {
        this.setState({ username: e.target.value })
    }

    handlePasswordOnchange = (e) => {
        this.setState({ password: e.target.value })
    }

    apiLogin = async (userAccount) => {
        this.setState({ isLoadingDialogOpen: true });

        try {
            const email = userAccount?.username;
            const splitName = userAccount?.name?.split(' ');
            const username = email?.split('@')?.[0];

            await this.props?.requestLogin({
                email,
                username,
                gn: splitName?.[0] || '',
                sn: splitName?.[1] || ''
            });

            await this.props?.authenticateUser({
                username
            });

            this.handleRedirect();
        } catch (error) {
            console.log(error);
        } finally {
            setTimeout(() => {
                this.setState({ isLoadingDialogOpen: false });
            }, 250);
        }
    }

    handleRedirect = () => {
        this.props?.history?.push(`/`);
    }

    azureLogin = async () => {
        try {
            await this.publicClientApplication.handleRedirectPromise();
            return await this.publicClientApplication.loginPopup(msalScopes);
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    handleLoginClick = async () => {
        // If the pop-up window is already open, just re-focus it.
        if (this.popupWindow && !this.popupWindow?.closed) {
            this.popupWindow?.focus();
            return;
        }

        try {
            const user = await this.azureLogin();

            if (!user) {
                throw new Error('User not found.');
            }

            if (user && !user?.account) {
                throw new Error('User account not found.');
            }

            if (user?.account) {
                AppStorage.Local.set(AppStorageConstants.SSO_USER, JSON.stringify(user.account));
            }

            return await this.apiLogin(user?.account);
        } catch (error) {
            console.log('error', error);
            AppStorage.Local.remove(AppStorageConstants.USER);
            AppStorage.Local.remove(AppStorageConstants.TOKEN);
            AppStorage.Local.remove(AppStorageConstants.META_DATA);
            AppStorage.Local.remove(AppStorageConstants.LOCATIONS);
            return error;
        }
    }

    componentDidMount() {
        if (isUserAuthenticated()) {
            this.props?.history?.replace(`/`);
        }

        // Listen for the event when the pop-up window is opened.
        this.publicClientApplication.addEventCallback(message => {
            if (message?.eventType === EventType.POPUP_OPENED) {
                this.popupWindow = message?.payload?.popupWindow;
            }
        });
    }

    render() {
        const { isLoadingDialogOpen } = this.state

        return (
            <div className="h-[100dvh] pt-[156px] bg-primary w-full min-w-screen">
                <div className="flex flex-col space-y-5 justify-center items-center">
                    <LoadingDialog className="login-loading" isOpen={isLoadingDialogOpen} />
                    <img alt="logo" className="select-none w-[228px] h-[78px]" src={Logo}></img>
                    <h1 className=" text-surface-container-low text-[36px] font-roboto font-normal leading-[44px]">Procheck</h1>
                    <div className="w-[420px] bg-transparent rounded-lg space-y-1">
                        <div className="flex justify-center">
                            <Button onClick={this.handleLoginClick} className="w-[224px] min-w-fit text-on-secondary-container px-[24px] bg-[#8CE568]">Log in</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = () => { };

const mapDispatchToProps = ({ user }) => {
    return {
        requestLogin: userObj => user.dispatch(requestLogin(userObj)),
        authenticateUser: userObj => user.dispatch(authenticateUser(userObj))
    };
};

export default withStore(
    mapStateToProps,
    mapDispatchToProps
)(Login);

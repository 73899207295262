
const ModeEditIcon = ({ className }) => {

     return (
          <svg xmlns="http://www.w3.org/2000/svg" className={` ${className}`} viewBox="0 0 19 19" >
               <path fillRule="evenodd" clipRule="evenodd" d="M14.7773 3.43291L15.7673 4.42291C16.3467 4.99491 16.3467 5.92624 15.7673 6.49824L6.06533 16.2002H3V13.1349L10.6267 5.50091L12.702 3.43291C13.274 2.86091 14.2053 2.86091 14.7773 3.43291ZM4.46667 14.7336L5.50067 14.7776L12.702 7.56891L11.668 6.53491L4.46667 13.7362V14.7336Z" fill="currentColor" />
          </svg>
     )
}

export default ModeEditIcon
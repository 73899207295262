import { ErrorIcon } from "@/Icons";
import { forwardRef } from "react";

const TextField = forwardRef(({
     variant,
     className,
     style,
     startIcon,
     endIcon,
     label,
     error,
     helperText,
     ...props
}, ref) => {
     return (
          <div className={`${className ?? ""}`}>
               {label && (
                    <div className='textfield-label'>
                         {label}
                    </div>
               )}

               <div className={`textfield border ${error ? 'border-error' : ''} ${props.disabled ? 'disabled-textfield' : ''} `} style={style}>
                    {startIcon && <div>{startIcon}</div>}
                    <input {...props} className="input" ref={ref} />
                    {endIcon && <div>{endIcon}</div>}
               </div>
               {(error || helperText) && <div className='flex w-full mt-2 items-center text-12'>

                    {helperText && <div className={`textfield-helper-text  ${error ? 'text-error' : 'text-black'} `}>
                         <ErrorIcon className="!h-[14px] !w-[14px] " />
                         <p>{helperText}</p>
                    </div>}
               </div>}

          </div>
     );
});

export default TextField;
